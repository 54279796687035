import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
const initialState = {
  loading: false,
  updateClientLoading: false,
  deleteClientLoading: false,
  deleteClientSuccess: false,
  clients: null,
  client: null,
  error: null,
  success: null,
  importLoading: false,
  importSuccess: false,
  importError: null,
  addSuccess:false,
  addLoading: false,
};

export const getClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.addSuccess = false;
      state.deleteClientSuccess = false;
      state.importError = null;
      state.importSuccess = false;
      state.addLoading = false;
    },
  },
  extraReducers: (builder) => {
    // Get Clients Cases
    builder.addCase(getClients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.loading = false;
      state.clients = action.payload;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getClientsForDropDown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientsForDropDown.fulfilled, (state, action) => {
      state.loading = false;
      state.clients = action.payload;
    });
    builder.addCase(getClientsForDropDown.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Add Client Cases
    builder.addCase(addClient.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addClient.fulfilled, (state) => {
      state.success = true;
      state.addSuccess = true;
      state.addLoading = false;
    });
    builder.addCase(addClient.rejected, (state, action) => {
      state.addLoading = false;
      state.error = action.payload;
    });
    // Get Single Client Cases
    builder.addCase(getClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClient.fulfilled, (state, action) => {
      state.loading = false;
      state.client = action.payload;
    });
    builder.addCase(getClient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Update client Cases
    builder.addCase(updateClient.pending, (state) => {
      state.updateClientLoading = true;
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.updateClientLoading = false;
      state.success = true;
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.updateClientLoading = false;
      state.error = action.payload;
    });
    // Delete Client
    builder.addCase(deleteCLient.pending, (state) => {
      state.deleteClientLoading = true;
    });
    builder.addCase(deleteCLient.fulfilled, (state) => {
      state.deleteClientLoading = false;
      state.deleteClientSuccess = true;
    });
    builder.addCase(deleteCLient.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(importClients.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importClients.fulfilled, (state) => {
      state.importLoading = false;
      state.importSuccess = true;
    });
    builder.addCase(importClients.rejected, (state, action) => {
      state.importLoading = false;
      state.importError = action.payload;
    });
  },
});

export const getClients = createAsyncThunk(
  "getClients",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/clients/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getClientsForDropDown = createAsyncThunk(
  "getClientsForDropDown",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/clients/dropdown`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addClient = createAsyncThunk(
  "addClient",
  async (apiData, { rejectWithValue, getState }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      const { email } = getState().auth.userData;
      if (apiData.data.email === email) {
        return rejectWithValue("Email Already Exists");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/clients/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      if (data.statusCode === 200) {
        return data.result;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getClient = createAsyncThunk(
  "getClient",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/clients/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateClient = createAsyncThunk(
  "updateClient",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/clients/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteCLient = createAsyncThunk(
  "deleteClient",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/clients/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const importClients = createAsyncThunk(
  "importClients",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return;
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/clients/import`,
        apiData.file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const { clearState } = getClientsSlice.actions;
