import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { ClientDetailsBadge } from "../ClientDetails/ClientDetails";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Badge from "../../../components/styled/Badge/Badge";
import Flex from "../../../components/styled/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  clearState,
  getSingleTeamMember,
} from "../../../features/ServiceProviderPortal/teamMemberSlice";
import { convertToAMPM, prettifyDate } from "../../../utills/prettifyDate";
import { handleUndefined } from "../../../utills/handleUndefined";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import Button from "../../../components/styled/Button/Button";
const TeamMemberDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, teamMember, delLoading, delError } =
    useSelector((state) => state.team);
  const { token } = useSelector((state) => state.auth);
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  useEffect(() => {
    if (token) {
      dispatch(getSingleTeamMember({ token, id }));
    }
  }, [token, dispatch, id]);

  const handleDeleteTeamMember = () => {};

  return (
    <div
      className="team-member-details-container"
      style={{ position: "relative" }}
    >
      <Link to={"/portal/team"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <h1 className="edit-client-heading">
              {`${teamMember?.full_name}`}
            </h1>

            <div className="client-information">
              <ClientDetailsBadge
                label="Team Member ID"
                value={teamMember?.id}
              />
              <ClientDetailsBadge label="Phone No" value={teamMember?.mobile} />
              <ClientDetailsBadge
                label="Joining Date"
                value={
                  teamMember?.created_at
                    ? prettifyDate(teamMember?.created_at)
                    : "-"
                }
              />
              <ClientDetailsBadge
                label="Business Role"
                value={handleUndefined(teamMember?.job_title)}
              />
              {/* <ClientDetailsBadge
                label="System Role"
                value={handleUndefined(teamMember?.roles[0].name)}
              /> */}
            </div>

            <div className="team-member-working-hours-container">
              <h1>Working Hours</h1>
              <div className="team-member-working-hours">
                {teamMember?.working_hours.map((item, index) => {
                  return (
                    <div className="team-member-working-hour">
                      <h2>{item?.day}</h2>
                      <p className="team-member-day-timing">
                        {convertToAMPM(item?.opening_time)} -{" "}
                        {convertToAMPM(item?.close_time)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>

            <h1 className="team-member-details-head">Appointments</h1>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Client Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Cell No</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Appointment </TableHeadData>
                  <TableHeadData>Status </TableHeadData>
                  <TableHeadData>Action </TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                teamMember?.appointments?.length > 0,
                <TableBody>
                  <TableRow>
                    <TableBodyData>
                      <TableText variant="link">23244</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <TableText>AndersonSmire</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <TableText>M.Iqbal@Gmail.com</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <TableText>03445568654</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <TableText>11/22/2023</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <TableText>Nail Art</TableText>
                    </TableBodyData>
                    <TableBodyData>
                      <Flex align="center" justify="center">
                        <Badge label="Upcomming" tone="success" />
                      </Flex>
                    </TableBodyData>
                    <TableBodyData>
                      <Flex align="center" justify="center" gap={10}>
                        <img src="/icons/edit.png" alt="edit" />
                        <img src="/icons/delete.png" alt="delete" />
                      </Flex>
                    </TableBodyData>
                  </TableRow>
                </TableBody>,
                <GlobalEmptyDataHandler
                  label={`No appoinments associated with ${teamMember?.full_name}`}
                />
              )}
            </Table>
          </>,
          <h1>{error}</h1>
        )
      )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteTeamMember}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default TeamMemberDetails;
