import React from "react";
import "./Blog.css";
import LandingPageLayout from "../../../components/LandingPage/Layout/LandingPageLayout";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
const Blogs = () => {
  return (
    <LandingPageLayout>
      <div className="max-width-1440 blog-page">
        <div className="blog-inner">
          <div className="blog-page">

        <Link to={-1} className="back">
              <ArrowLeft size={24} color="#5459FF" /> Back
            </Link>
          </div>
          <h1 className="headings blog-heading">Welcome To Our Blogs</h1>
          <p className="blogs-para">
          Check out our blog for the latest tips, trick and resources for running a service based business
          </p>
          <div className="blog-posts">
            <div className="blog">
              <h2 className="blog-title headings">Blog 1</h2>
              <div className="blog-details">
                <div className="blog-paras">
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <Link to='/blog-details'>
                    <button className="read-more">Read More</button>
                  </Link>
                </div>
                <div className="blog-image">
                  <img
                    src={"/blogs/blogImg1.png"}
                    alt="Blog"
                    className="blog-img"
                  />
                </div>
              </div>
            </div>
            <div className="blog">
              <h2 className="blog-title headings">Blog 2</h2>
              <div className="blog-details">
                <div className="blog-paras">
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <Link to='/blog-details'>
                    <button className="read-more">Read More</button>
                  </Link>
                </div>
                <div className="blog-image">
                  <img
                    src={"/blogs/blogImg2.png"}
                    alt="Blog"
                    className="blog-img"
                  />
                </div>
              </div>
            </div>
            <div className="blog">
              <h2 className="blog-title headings">Blog 3</h2>
              <div className="blog-details">
                <div className="blog-paras">
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p className="blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <Link to='/blog-details'>
                    <button className="read-more">Read More</button>
                  </Link>
                </div>
                <div className="blog-image">
                  <img
                    src={"/blogs/blogImg3.png"}
                    alt="Blog"
                    className="blog-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default Blogs;
