import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Flex from "../../../components/styled/Flex/Flex";
import BtnLoader from "../../../components/styled/BtnLoader/BtnLoader";
import { decryptObject } from "../../../utills/encryptionDecription";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  createAppointment,
  clearState,
} from "../../../features/Websites/websitesSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CheckoutForm({ data, dataToken }) {
  const stripe = useStripe();
  const elements = useElements();
  const { addLoading, addError, addSuccess } = useSelector(
    (state) => state.websiteStyles
  );
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const query = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (addSuccess) {
      navigate(`/websites/${data?.business_url}/book/success`);
      dispatch(clearState());
    }
    if (addError) {
      toast.error(addError);
    }
  }, [addSuccess, addError, dispatch, navigate]);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            // onPaymentSuccess();
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }, [stripe]);

  const onPaymentSuccess = () => {
    let decryptedData = decryptObject(decodeURIComponent(query.data));
    const services = decryptedData.services.map((service)=> service.service_id)
    decryptedData.services = services;
    dispatch(createAppointment({ data: decryptedData }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // No return_url needed if we handle success in this function
        return_url: window.location.href, // Just to satisfy the API requirement
      },
      redirect: "if_required", // Prevent redirection
    });

    // Handle immediate errors from confirmPayment
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
      return;
    }

    // Handle payment intent status
    switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Payment succeeded!");
        onPaymentSuccess();
        break;
      case "processing":
        setMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        setMessage("Your payment was not successful, please try again.");
        break;
      default:
        setMessage("Something went wrong.");
        break;
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div
      style={{ marginTop: "150px", maxWidth: "1260px", margin: "150px auto" }}
    >
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Flex align="center" justify="flex-end" className="mt-30">
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className="global-btn primary"
          >
            <span id="button-text">
              {isLoading || addLoading ? <BtnLoader color="#fff" /> : "Pay now"}
            </span>
          </button>
        </Flex>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
