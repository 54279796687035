import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  globalInitialState,
  BASE_URL,
  globalClearState,
  throwError,
} from "../config";
import axios from "axios";

const initialState = {
  ...globalInitialState,
  testimonials: null,
  testimonial: null,
};

export const testimonialSlice = createSlice({
  name: "TestimonailSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      globalClearState(state);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addTestimonial.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addTestimonial.fulfilled, (state, action) => {
      state.addLoading = false;
      state.addSuccess = action.payload;
    });
    builder.addCase(addTestimonial.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });
    builder.addCase(getAllTestimonials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTestimonials.fulfilled, (state, action) => {
      state.loading = false;
      state.testimonials = action.payload;
    });
    builder.addCase(getAllTestimonials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteTestimonial.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteTestimonial.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteTestimonial.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
    builder.addCase(updateTestimonial.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateTestimonial.fulfilled, (state) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateTestimonial.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
    builder.addCase(getSingleTestimonial.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getSingleTestimonial.fulfilled, (state,action) => {
      state.getLoading = false;
      state.testimonial = action.payload;
    });
    builder.addCase(getSingleTestimonial.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
  },
});

export const addTestimonial = createAsyncThunk(
  "addTestimonial",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/testimonials/create`,
        apiData.data,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return data.result;
    } catch (error) {
        return throwError(error, rejectWithValue);
    }
  }
);
export const getAllTestimonials = createAsyncThunk(
  "getAllTestimonials",
  async (apiData, { rejectWithValue }) => {
      try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/testimonials/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
        return throwError(error, rejectWithValue);
    }
  }
);
export const deleteTestimonial = createAsyncThunk(
  "deleteTestimonial",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/testimonials/delete/${apiData.id}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
        return throwError(error, rejectWithValue);
    }
  }
);
export const updateTestimonial = createAsyncThunk(
  "updateTestimonial",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/testimonials/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      return throwError(error, rejectWithValue);
    }
  }
);
export const getSingleTestimonial = createAsyncThunk(
  "getSingleTestimonial",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/testimonials/view/${apiData.id}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
        return throwError(error, rejectWithValue);
    }
  }
);

export const { clearState } = testimonialSlice.actions;
