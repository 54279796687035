import React, { useEffect, useState } from "react";
import "./ClientReferrals.css";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Button from "../../../components/styled/Button/Button";
import Flex from "../../../components/styled/Flex/Flex";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Badge from "../../../components/styled/Badge/Badge";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllClientReferrals,
  clearState,
  deleteRefferal,
} from "../../../features/ServiceProviderPortal/referralSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { Link } from "react-router-dom";
import { prettifyDate } from "../../../utills/prettifyDate";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { concatNames } from "../../../utills/concatNames";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";

const ClientReferrals = () => {
  const { token } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, referrals, delLoading, delSuccess, delError } =
    useSelector((state) => state.referrals);

  const dispatch = useDispatch();
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  useEffect(() => {
    if (token) {
      dispatch(getAllClientReferrals({ token }));
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
    }
  }, [token, dispatch, delSuccess]);

  const handleDeleteClientRefferal = () => {
    dispatch(deleteRefferal({ token, id: delId }));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="edit-client-heading">Clients Referrals</h1>
        <Flex align="center" gap={15}>
          <span className="referral-bonus">
            'Earn <b>"£20"</b> for each referral of a <b>'Super'</b> account
          </span>
          <Link to="/portal/add-client-referrals">
            <Button label="Add Referrals" />
          </Link>
        </Flex>
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          error,
          <GlobalErrorHandler label={error} />,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>From</TableHeadData>
                  <TableHeadData>To</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  <TableHeadData>Service Type</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                referrals?.items?.length > 0,
                <TableBody>
                  {referrals?.items.map((item, index) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableBodyData>
                          <TableText>{item?.id}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{item?.from?.full_name}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {concatNames(
                              item?.to?.first_name,
                              item?.to?.last_name
                            )}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{item?.email}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                item?.status === 33
                                  ? "error"
                                  : item?.status === 34
                                  ? "success"
                                  : "info"
                              }
                            >
                              {item?.status === 33
                                ? "Expired"
                                : item?.status === 34
                                ? "Redeemed"
                                : "Sent"}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>Hair Dresser</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            <img
                              src="/icons/delete.png"
                              alt="edit"
                              onClick={() => {
                                setDelId(item?.id);
                                setDelPopup(true);
                              }}
                            />
                            <Link
                              to={`/portal/edit-client-referrals/${item?.id}`}
                            >
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Referrals to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                referrals?.items?.length > 0,
                referrals?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {item?.id}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>From</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {item?.from?.full_name}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>To</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {concatNames(
                                  item?.to?.first_name,
                                  item?.to?.last_name
                                )}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                <TableText>{item?.email}</TableText>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.created_at)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <Badge
                                  tone={
                                    item?.status === 33
                                      ? "error"
                                      : item?.status === 34
                                      ? "success"
                                      : "info"
                                  }
                                >
                                  {item?.status === 33
                                    ? "Expired"
                                    : item?.status === 34
                                    ? "Redeemed"
                                    : "Sent"}
                                </Badge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex
                                align="center"
                                justify="flex-start"
                                gap={10}
                              >
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                                <Link
                                  to={`/portal/edit-client-referrals/${item?.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>
        )
      )}

      {referrals?.meta?.totalItems > referrals?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={referrals?.meta?.totalPages}
          />
        )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClientRefferal}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default ClientReferrals;
