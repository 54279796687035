import React, { useEffect, useState } from "react";
import "./Appointments.css";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { Link } from "react-router-dom";
import Flex from "../../../components/styled/Flex/Flex";
import Badge from "../../../components/styled/Badge/Badge";
import ClientPortalTabs from "../../../components/styled/ClientPortalTabs/ClientPortalTabs";
import Button from "../../../components/styled/Button/Button";
import Popup from "../../../components/styled/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAppointments,
  clearState,
} from "../../../features/ClientPortal/appointmentSlice";
import { toast } from "react-toastify";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { handleUndefined } from "../../../utills/handleUndefined";
import { prettifyDate } from "../../../utills/prettifyDate";
import TableTag from "../../../components/styled/TableTag/TableTag";
import { calculateServiceCost } from "../../PortalSection/Appoinments/Appoinments";
import Pagination from "../../../components/styled/Pagination/Pagination";
// import calculateServiceCost from '../'
const Appointments = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    loading,
    error,
    appointments,
    success,
    updateLoading,
    updateError,
    delLoading,
    delSuccess,
    delError,
  } = useSelector((state) => state.appointments);

  useEffect(() => {
    if (token) {
      dispatch(
        getAllAppointments({
          token,
          query: {
            status: activeTab,
            currentPage,
            itemsPerPage,
          },
        })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
      toast.success("Appointment deleted successfully", {
        position: "top-right",
      });
    }
  }, [
    token,
    dispatch,
    activeTab,
    success,
    delSuccess,
    currentPage,
    itemsPerPage,
  ]);

  const [delPopup, setDelPopup] = useState(false);

  return (
    <div
      className="outer client-portal-appointments"
      style={{ position: "relative" }}
    >
      <Flex align={"center"} justify={"space-between"} gap={10}>
        <h1 className="edit-client-heading">Appointments</h1>
        <Link to={"/client-portal/add-appointment"}>
          <Button variant="primary" label={"Request Appointment"} />
        </Link>
      </Flex>
      <ClientPortalTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        options={["All", "Upcoming", "Completed"]}
        width={400}
      />
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Service Provider</TableHeadData>
                  <TableHeadData>Phone</TableHeadData>
                  {/* <TableHeadData>Number</TableHeadData> */}
                  <TableHeadData>Service(s)</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Amount</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                appointments?.items?.length > 0,
                <TableBody>
                  {appointments?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>
                            {handleUndefined(item?.business_id?.business_name)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>
                            {handleUndefined(item?.user_id?.mobile)}
                          </TableText>
                        </TableBodyData>
                        {/* <TableBodyData>
                          <TableText>
                            {handleUndefined(item?.business_id?.business_name)}
                          </TableText>
                        </TableBodyData> */}
                        <TableBodyData className="appointment-services-data">
                          <Flex
                            className="appointment-services-tags"
                            align="center"
                            justify="center"
                          >
                            {item?.services.map((item) => (
                              <TableTag>{item.name}</TableTag>
                            ))}
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{prettifyDate(item?.date)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            £{calculateServiceCost(item.services)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                item?.status === 25
                                  ? "error"
                                  : item?.status === 22
                                  ? "success"
                                  : item?.status === 24
                                  ? "no-show"
                                  : "info"
                              }
                            >
                              {item?.status === 25
                                ? "Cancelled"
                                : item?.status === 22
                                ? "Completed"
                                : item?.status === 24
                                ? "No Show"
                                : "Upcoming"}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            <Link
                              to={`/client-portal/edit-appointment/${item?.id}`}
                            >
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Appoinments to show" />
              )}
            </Table>
            {/* <ResponsiveContainer>
              {conditionalRender(
                appoinments?.items?.length > 0,
                appoinments?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Name</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Link
                                to={`/portal/appoinment-detail/${item?.id}`}
                              >
                                <PhoneTableSimpleText variant="link">
                                  {conditionalRender(
                                    item?.client_id?.first_name,
                                    concatNames(
                                      item?.client_id?.first_name,
                                      item?.client_id?.last_name
                                    ),
                                    "-"
                                  )}
                                </PhoneTableSimpleText>
                              </Link>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {conditionalRender(
                                  item?.date,
                                  prettifyDate(item?.date),
                                  "-"
                                )}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Cell No</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {conditionalRender(
                                  item?.client_id?.phone,
                                  item?.client_id?.phone,
                                  "-"
                                )}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Services</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                <Flex
                                  className="appointment-services-tags"
                                  align="center"
                                  justify="flex-start"
                                >
                                  {item?.services.map((item) => (
                                    <TableTag>{item.name}</TableTag>
                                  ))}
                                </Flex>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Cost</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{calculateServiceCost(item.services)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <Badge
                                  tone={
                                    item?.status === 25
                                      ? "error"
                                      : item?.status === 22
                                      ? "success"
                                      : item?.status === 24
                                      ? "no-show"
                                      : "info"
                                  }
                                >
                                  {item?.status === 25
                                    ? "Cancelled"
                                    : item?.status === 22
                                    ? "Completed"
                                    : item?.status === 24
                                    ? "No Show"
                                    : "Upcoming"}
                                </Badge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" gap={10}>
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelPopup(true);
                                    setDelId(item?.id);
                                  }}
                                />
                                <Link
                                  to={`/portal/edit-appointment/${item.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                                <Button
                                  label={conditionalRender(
                                    item?.is_assigned,
                                    "Re Assign",
                                    "Assign"
                                  )}
                                  variant="outline-sm"
                                  handleClick={() => {
                                    setAssignId(item?.id);
                                    setAssignAppoinmentPopup(true);
                                  }}
                                />
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Appointments To Show"} />
              )}
            </ResponsiveContainer> */}
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {appointments?.meta?.totalItems > appointments?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={appointments?.meta?.totalPages}
          />
        )}

      <div className="mt-20"></div>

      {delPopup && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={() => {}}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  // setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
    </div>
  );
};

export default Appointments;
