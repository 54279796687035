import React from "react";
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import Button from "../../../components/styled/Button/Button";

const EditScheduling = () => {
  return (
    <div className="outer">
      <Link to={"/portal/dashboard"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Edit Client</h1>
      <div
        className="edit-client-lables-inputs"
        id="edit-voucher-lables-inputs"
      >
        <div className="lable-input-group">
          <label htmlFor="id">ID*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/user.png" alt="" />
            <input type="text" placeholder="123" id="id" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="name">Name*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/user.png" alt="" />
            <input type="text" placeholder="Muhammad" id="name" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="email">Email*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/sms.png" alt="" />
            <input type="email" placeholder="m.iqbal@gmail.com" id="email" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="c-number">cell Number*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/call-1.png" alt="" />
            <input type="number" placeholder="0300000000" id="c-number" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="status">Status*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/calendar.png" alt="" />
            {/* <input type="number" placeholder="sent" id="id" /> */}
            <select name="" id="status">
              <option value="">Sent</option>
              <option value="">Not Sent</option>
            </select>
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="services">Services*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/profile-2user.png" alt="" />
            {/* <input type="text" placeholder="1234" id="services" /> */}
            <select name="" id="services">
              <option value="">Hair Stylist</option>
              <option value="">Service 2</option>
              <option value="">Service 3</option>
              <option value="">Service 4</option>
            </select>
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="date">Date*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/calendar.png" alt="" />
            <input type="date" placeholder="11/2/2023" id="date" />
          </div>
        </div>
      </div>

      <div className="edit-client-btns">
        {/* <button className="edit-client-save">Save</button> */}
        <Button variant="primary" label={"Save"} />
        <Button variant="outline" label={"Cancel"} />
        {/* <button className="edit-client-cancel">Cancel</button> */}
      </div>
    </div>
  );
};

export default EditScheduling;
