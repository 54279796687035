import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../../features/config";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  businessProfileUpdate,
} from "../../../features/ServiceProviderPortal/updateProfileSlice";
import {
  getUserData,
} from "../../../features/auth/authSlice";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { handlePaymentAndConnectAccountRedirection } from "../../../utills/authorizeRole";
const CreateConnectAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isFormLoading, setIsFormLoading] = useState(false);

  const { token,userData } = useSelector((state) => state.auth);
  const { loading, error } = useSelector(
    (state) => state.updateProfile
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);
  useEffect(() => {
    if(userData){
      const response = handlePaymentAndConnectAccountRedirection(userData);
      if (response.ok) {
        navigate('/portal/dashboard');
      }
    }
  }, [navigate, userData]);
  // useEffect(() => {
  //   if (success) {
  //     dispatch(
  //       updateStripeAccountPaymentStatus({
  //         is_payment: true,
  //         is_stripe_account: "true",
  //       })
  //     );
  //     navigate("/portal/dashboard");
  //   }
  // }, [success, dispatch]);
  const handleStripeConnectAccount = async () => {
    setIsFormLoading(true);
    const response = await fetch(
      `${BASE_URL}/v1/payment/create-stripe-connect-account`,
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201) {
      const data = await response.json();
      if (data && data.result.url) {
        window.open(data.result.url, "_blank");
      }
      setIsFormLoading(false);
    } else {
      setIsFormLoading(false);
    }
  };
  const businessUpdateMutation = useMutation({
    mutationFn: businessProfileUpdate,
    onError: (err) => {
      const message =
        err?.response?.data?.message || err?.message || "Some error occured";
      toast.error(message);
    },
    onSuccess: () => {
      dispatch(getUserData())
    },
  });
  // const userDataMutaion = useMutation({
  //   mutationFn: () => {
  //     dispatch(getUserData());
  //   },
  //   onError: (err) => {
  //     const message =
  //       err?.response?.data?.message || err?.message || "Some error occured";
  //     toast.error(message);
  //     console.log(err)
  //   },
  //   onSuccess: () => {
  //     navigate("/portal/dashboard");
  //   },
  // });
  return (
    <div className="create-connect-account-main-container">
      <div className="create-connect-account-inner">
        <Flex
          align="center"
          justify="center"
          className="create-connect-account-logo"
        >
          <img src="/footer/logo.png" alt="" />
        </Flex>
        <h1>Create Connect Account</h1>
        <p>
          Click The button and Complete your connect account from stripe to
          receive apppointment payments and provide secret and private key from
          your stripe dashboard
        </p>

        <Flex
          align="center"
          justify="center"
          className="create-connect-account-btn"
        >
          <Button
            label="Create Connect Account"
            variant="outline"
            handleClick={() => handleStripeConnectAccount()}
            loading={isFormLoading}
            loaderColor="#3E0FFE"
          />
        </Flex>
        <p>
          After completing account setup , copy your Public and private key from
          your dashboard and paste them here
        </p>
        <form
          onSubmit={handleSubmit((values) => {
            const apiData = new FormData();
            apiData.append("public_key", values.public_key);
            apiData.append("secret_key", values.secret_key);
            apiData.append("is_stripe_account", true);
            businessUpdateMutation.mutate({ token, data: apiData });
          })}
        >
          <Flex
            className={`global-input-container add-extra-service-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="public_key">Public Key *</label>
            <div className="global-input-container-input">
              <input
                type={"text"}
                placeholder={"Paste your public key"}
                {...register("public_key", {
                  required: "Please provide public key",
                })}
                id="public_key"
              />
            </div>
            <p className="global-input-error mb-10">
              {errors?.public_key && errors?.public_key?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container add-extra-service-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="secret_key">Secret Key *</label>
            <div className="global-input-container-input">
              <input
                type={"text"}
                placeholder={"Paste your secret key"}
                {...register("secret_key", {
                  required: "Please provide secret key",
                })}
                id="secret_key"
              />
            </div>
            <p className="global-input-error mb-10">
              {errors?.secret_key && errors?.secret_key?.message}
            </p>
          </Flex>
          <Flex align="center" justify="space-between">
            <p className="global-input-error">{error && error}</p>
            <Button
              type="submit"
              label="Save"
              loading={loading}
              loaderColor="#fff"
            />
          </Flex>
        </form>
      </div>
    </div>
  );
};

export default CreateConnectAccount;
