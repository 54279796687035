import React, { useEffect, useRef, useState } from "react";
import { ChevronUp, ChevronDown } from "react-feather";
import "./style.css";
const PortalDrop = ({ options, active, setAcvtive,handleClick }) => {
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  if(handleClick){
    return (
      <div className="portal-dropdown" ref={dropdownRef}>
        <div className="portal-drop-head" onClick={() => setOpen(!open)}>
          <span>{active}</span>
          {open ? (
            <ChevronUp className="portal-drop-chevron" />
          ) : (
            <ChevronDown className="portal-drop-chevron" />
          )}
        </div>
        <div
          className="portal-drop-options"
          style={{ maxHeight: `${open ? "100px" : "0px"}` }}
        >
          {options.map((option, index) => {
            return (
              <div
                className="portal-drop-option"
                onClick={(e) => {
                  setAcvtive(option);
                  setOpen(!open);
                  handleClick(e)
                }}
                data-indentifier={option}
                key={index}
              >
                {option}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="portal-dropdown" ref={dropdownRef}>
      <div className="portal-drop-head" onClick={() => setOpen(!open)}>
        <span>{active}</span>
        {open ? (
          <ChevronUp className="portal-drop-chevron" />
        ) : (
          <ChevronDown className="portal-drop-chevron" />
        )}
      </div>
      <div
        className="portal-drop-options"
        style={{ maxHeight: `${open ? "100px" : "0px"}` }}
      >
        {options.map((option, index) => {
          return (
            <div
              className="portal-drop-option"
              onClick={() => {
                setAcvtive(option);
                setOpen(!open);
              }}
              data-indentifier={option}
              key={index}
            >
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortalDrop;
