import React, { useEffect } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { ClientDetailsBadge } from "../ClientDetails/ClientDetails";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleAppoinment,
  clearState,
} from "../../../features/ServiceProviderPortal/appoinmentSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import Badge from "../../../components/styled/Badge/Badge";
import { handleUndefined } from "../../../utills/handleUndefined";
import { prettifyDate } from "../../../utills/prettifyDate";
import { concatNames } from "../../../utills/concatNames";
const AppoinmentDetail = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading, error, appointment } = useSelector(
    (state) => state.appoinments
  );
  const { id } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(getSingleAppoinment({ id, token }));
    }
  }, [id, dispatch, token]);
  const calculateServiceCost = (services) => {
    let cost = 0;
    services?.forEach((service) => (cost += service?.cost));
    return cost;
  };
  return (
    <div className="client-appoinment-detail-container">
      <Link
        to={"/portal/appointments"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">{"Appointment Details"}</h1>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <div className="client-information">
              <ClientDetailsBadge
                label="Assigned to"
                value={handleUndefined(appointment?.user_id?.full_name)}
              />
              <ClientDetailsBadge
                label="Client Name"
                value={
                  appointment?.client_id
                    ? concatNames(
                        appointment?.client_id?.first_name,
                        appointment?.client_id?.last_name
                      )
                    : "-"
                }
              />
              <ClientDetailsBadge
                label="Status"
                value={
                  <Badge
                    tone={
                      appointment?.status === 25
                        ? "error"
                        : appointment?.status === 22
                        ? "success"
                        : appointment?.status === 24
                        ? "error"
                        : "info"
                    }
                  >
                    {appointment?.status === 25
                      ? "Cancelled"
                      : appointment?.status === 22
                      ? "Completed"
                      : appointment?.status === 24
                      ? "No Show"
                      : "Upcoming"}
                  </Badge>
                }
              />
              <ClientDetailsBadge
                label="Appointment ID"
                value={handleUndefined(appointment?.id)}
              />
              <ClientDetailsBadge
                label="Total "
                value={`$${calculateServiceCost(appointment?.services)}`}
              />
              <ClientDetailsBadge
                label="Appointment Date "
                value={prettifyDate(appointment?.date)}
              />
            </div>

            <div className="appointment-notes mb-30">
              <ClientDetailsBadge
                label="Notes "
                value={handleUndefined(appointment?.notes)}
              />
            </div>

            <h1 className="client-appoinment-details-head">Booking Details</h1>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Service Name</TableHeadData>
                  <TableHeadData>Category</TableHeadData>
                  <TableHeadData>Amount</TableHeadData>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointment?.services &&
                  appointment?.services?.map((item, index) => {
                    return (
                      <TableRow>
                        <TableBodyData>
                          <TableText>{item?.name}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>Nail Art</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>${item?.cost}</TableText>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </>,
          <h1>{error}</h1>
        )
      )}
    </div>
  );
};

export default AppoinmentDetail;
