import React, { useEffect } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleInvoice,
  clearState,
} from "../../../features/ServiceProviderPortal/invoiceSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { concatNames } from "../../../utills/concatNames";
import { prettifyDate } from "../../../utills/prettifyDate";
const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token, userData } = useSelector((state) => state.auth);
  const { loading, error, invoice } = useSelector((state) => state.invoices);
  useEffect(() => {
    dispatch(getSingleInvoice({ token, id }));
  }, [id, token, dispatch]);
  return (
    <div className="invoice-details-container">
      <Link
        to={"/portal/invoices"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Invoice Details</h1>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Flex
              className="invoice-details-top-badge"
              justify="space-between"
              align="center"
            >
              <span>Invoice No: #{invoice?.id}</span>
              <span>
                Appoinment Date# {prettifyDate(invoice?.appointment_date)}
              </span>
            </Flex>
            <Flex justify="space-between" gap={10} align="center">
              <InvoiceBadge
                label="From"
                name={invoice?.created_by}
                phone="023334455"
                email="masam@gmail.com"
              />
              <InvoiceBadge
                label="Send To"
                name={conditionalRender(
                  invoice?.client,
                  concatNames(
                    invoice?.client?.first_name,
                    invoice?.client?.last_name
                  ),
                  "-"
                )}
                phone={conditionalRender(
                  invoice?.client,
                  invoice?.client?.phone,
                  "-"
                )}
                email={conditionalRender(
                  invoice?.client,
                  invoice?.client?.email,
                  "-"
                )}
              />
            </Flex>

            <div className="invoice-information">
              <div className="invoice-information-left">
                <div className="invoice-table">
                  <table>
                    <tr className="mb-45">
                      <td className="mb-45">
                        <InvoiceHeading>Total Amount:</InvoiceHeading>
                      </td>
                      <td className="mb-45">
                        {" "}
                        <span>${invoice?.total_amount}</span>
                      </td>
                    </tr>
                    <tr className="mb-45">
                      <td className="mb-45">
                        <InvoiceHeading>Due Date:</InvoiceHeading>
                      </td>
                      <td className="mb-45">
                        <span>{prettifyDate(invoice?.due_date)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <InvoiceHeading>Service Type</InvoiceHeading>
                      </td>
                      <td>
                        <span>Hair Stylist</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="invoice-information-right">
                <div>
                  <table>
                    <tr>
                      <td className="mb-45">
                        <InvoiceHeading>Transaction No:</InvoiceHeading>
                      </td>
                      <td className="mb-45">
                        <span>12345</span>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td className="mb-45">
                        <InvoiceHeading>Status:</InvoiceHeading>
                      </td>
                      <td className="mb-45">
                        <InvoiceStatusBadge
                          label={
                            invoice?.status === 30
                              ? "Overdue"
                              : invoice?.status === 29
                              ? "Paid"
                              : "Unpaid"
                          }
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div className="invoice-details-bottom-table">
              <table>
                <tr>
                  <td>
                    <InvoiceHeading>Appoinment Date: </InvoiceHeading>
                  </td>
                  <td>
                    <span>{prettifyDate(invoice?.appointment_date)}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <InvoiceHeading>Invoice No: </InvoiceHeading>
                  </td>
                  <td>
                    <span>{invoice?.id}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <InvoiceHeading>Created By: </InvoiceHeading>
                  </td>
                  <td>
                    <span>{userData?.business_id?.business_name}</span>
                  </td>
                </tr>
              </table>
            </div>

            <Button label="Print Receipt" />
            <div className="mt-30"></div>
          </>,
          <h1>{error}</h1>
        )
      )}
    </div>
  );
};

export function InvoiceBadge({ label, name, phone, email }) {
  return (
    <div className="invoice-badge-container">
      <InvoiceHeading>{label}</InvoiceHeading>
      <span>{name}</span>
      <span>Phone# {phone}</span>
      <span>Email: {email}</span>
    </div>
  );
}

export function InvoiceHeading({ children }) {
  return <h2 className="invoice-details-heading">{children}</h2>;
}

export function InvoiceStatusBadge({ label }) {
  return <button className="invoice-status-badge">{label}</button>;
}
export default InvoiceDetails;
