import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
import { getUserData } from "../auth/authSlice";

const initialState = {
  loading: false,
  error: null,
  success: false,
  updatePassLoading: false,
  updatePassSuccess: false,
  updatePassError: null,
};

export const updateProfileSlice = createSlice({
  name: "updateProfileSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.updatePassError = null;
      state.updatePassSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserProfile.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateBusinessProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBusinessProfile.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateBusinessProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateIsVisited.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateIsVisited.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateIsVisited.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.updatePassLoading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.updatePassLoading = false;
      state.updatePassSuccess = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.updatePassLoading = false;
      state.updatePassError = action.payload;
    });
  },
});

export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/update/user_profile`,
        apiData.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const updateBusinessProfile = createAsyncThunk(
  "updateBusinessProfile",
  async (apiData, { rejectWithValue, dispatch }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/update/business_profile`,
        apiData.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      dispatch(getUserData());
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const businessProfileUpdate = (apiData) => {
  return axios.post(
    `${BASE_URL}/v1/users/update/business_profile`,
    apiData.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${apiData.token}`,
      },
    }
  );
};
export const updateIsVisited = createAsyncThunk(
  "updateIsVisited",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData?.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }

      if (!apiData?.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/business/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (apiData, { rejectWithValue, getState }) => {
    const id = getState().auth.userData.id;
    try {
      if (!apiData?.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/change_password`,
        { ...apiData.data, id },
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = updateProfileSlice.actions;
