import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import Flex from "../../Flex/Flex";
import { ChevronDown, ChevronUp } from "react-feather";

const GlobalDropDown = ({
  stateHandler,
  setStateHandler,
  options,
  label,
  isRequired,
  icon,
  defaultId,
  defaultName,
  background = "#f4f6f8",
  labelColor='#000'
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const hasSetDefaults = useRef(false); // Ref to track whether useEffect has run
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!hasSetDefaults.current) {
      if (defaultId) {
        const idItem = options?.find((item) => item?.id === defaultId);
        if (idItem) {
          setStateHandler(idItem);
          hasSetDefaults.current = true;
          return;
        }
      }
  
      if (defaultName) {
        const nameItem = options?.find((item) => item?.name === defaultName);
        if (nameItem) {
          setStateHandler(nameItem);
          hasSetDefaults.current = true;
        }
      }
    }
  }, [defaultId, defaultName, options, setStateHandler]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="global-dropdown" ref={dropdownRef}>
      <h3 style={{color:labelColor}}>
        {label}
        {isRequired ? "*" : ""}
      </h3>
      <div
        className="global-drop-head"
        onClick={() => setOpen(!open)}
        style={{ background: background }}
      >
        <Flex align="center" justify="space-between">
          <Flex align="center" gap={15}>
            {icon ? <img src={icon} alt="user" /> : null}
            <p>{stateHandler ? stateHandler?.name : "Please Select"}</p>
          </Flex>
          {open ? (
            <ChevronUp color="#666666" />
          ) : (
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>
      <div
        className="global-drop-dropdown"
        style={{ maxHeight: `${open ? "200px" : "0px"}` }}
      >
        {options &&
          options.map((option, index) => {
            return (
              <div key={index}>
                <h2
                  onClick={() => {
                    setSelectedItemIndex(index);
                    setStateHandler(option);
                    setOpen(false);
                  }}
                  style={{
                    color: `${
                      selectedItemIndex === index ? "#4d40ff" : "#000"
                    }`,
                  }}
                >
                  {option?.name}
                </h2>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GlobalDropDown;
