import { eachDayOfInterval, startOfMonth, endOfMonth, format } from "date-fns";

export const getCalendarData = (monthName) => {
  const currentYear = new Date().getFullYear();
  const monthIndex = new Date(
    Date.parse(monthName + " 1, " + currentYear)
  ).getMonth();
  const startDate = startOfMonth(new Date(currentYear, monthIndex, 1));
  const endDate = endOfMonth(startDate);

  const daysInMonth = eachDayOfInterval({ start: startDate, end: endDate });
  const data = daysInMonth.map((day) => ({
    date: day,
    dayOfMonth: format(day, "d"),
    dayOfWeek: format(day, "EEEE"),
  }));

  return {
    monthName: monthName,
    calendarData: data,
  };
};

export function getCurrentYearMonths() {
  const currentYear = new Date().getFullYear();
  const currentDate = new Date();
  const currentMonth = format(currentDate, "LLLL");
  const months = [];
  for (let i = 0; i < 12; i++) {
    const formattedMonth = format(new Date(currentYear, i, 1), "LLLL");
    months.push(formattedMonth);
  }

  return {
    currentMonth,
    months,
  };
}

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const weekDaysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getFirstDayOfMonthName(monthName, year = new Date().getFullYear()) {
  const monthIndex = new Date(Date.parse(monthName + ' 1, ' + year)).getMonth();
  const firstDayOfMonth = startOfMonth(new Date(year, monthIndex, 1));
  const firstDayName = format(firstDayOfMonth, 'EEEE');
  return firstDayName;
}
