import React from "react";
import { Link } from "react-router-dom";
import Flex from "../../../../../components/styled/Flex/Flex";
import GlobalDropDown from "../../../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import Button from "../../../../../components/styled/Button/Button";

const AddPricing = ()=>{
    const dropOptions = [{name: "XYZ"},{name: "ABC"}]
    return(
        <div className="outer">
      <h1 className="team-member-details-head">Add Pricing</h1>
      <div className="mt-20">
        <form>
          <div className="add-team-member-inputs">
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="serviceName">Service Name **</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Hair Stylist"}
                  id="serviceName"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="last_name">Email*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="email"
                />
                <img src="/icons/sms.png" alt="icon" />
              </div>
            </Flex>
            <Flex className="add-team-member-input" direction="column" gap={10}>
              <GlobalDropDown
                options={dropOptions}
                label="Features*"
                icon="/icons/appointments.png"
                defaultName={dropOptions[0]}
              />
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="price">Price*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"number"} placeholder={"0300000000"} id="price" />
                <img src="/icons/amount.png" alt="icon" />
              </div>
            </Flex>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Add"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/pricing">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
    );
};

export default AddPricing;