import React, { useEffect, useState } from "react";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  clearState,
  getSingleAppoinment,
  updateAppointnment,
} from "../../../features/ServiceProviderPortal/appoinmentSlice";
import { Controller, useForm } from "react-hook-form";
import Flex from "../../../components/styled/Flex/Flex";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import "./style.css";
import { conditionalRender } from "../../../utills/conditionalRender";
import Tag from "../../../components/styled/Tag/Tag";
import ReactDatePicker from "react-datepicker";
import { getCurrentDate } from "../../../utills/prettifyDate";
import { getAppointemtSettings } from "../../../features/ServiceProviderPortal/appointmentSettingsSlice";
import { toast } from "react-toastify";
import {
  getAppointmentDateTimeCombined,
  isCancellationAllowed,
} from "../../../utills/appointments";
const EditAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    success,
    appoinmentDropLoading,
    appoinmentDropError,
    updateError,
    singleLoading,
    singleError,
    appointment,
  } = useSelector((state) => state.appoinments);
  const settingsState = useSelector((state) => state.appointmentSettings);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (token) {
      dispatch(getAppointemtSettings(token));
      // dispatch(appoinmentDrops({ token }));
      dispatch(getSingleAppoinment({ token, id }));
    }
  }, [token, dispatch, id]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [dropError, setDropError] = useState(null);

  const handleAddAppoinment = (values) => {
    if (selectedStatus) {
      values.status = selectedStatus.id;
    }
    if (selectedClient) {
      values.client_id = selectedClient.id;
    }
    const services = selectedServices.map((service) => service.id);
    values.services = services;

    if (selectedStatus.id === 25) {
      if (settingsState.settings?.cancellation_min_before_booking) {
        if (
          isCancellationAllowed(
            settingsState.settings?.cancellation_min_before_booking,
            getAppointmentDateTimeCombined(appointment.date, appointment.time)
          )
        ) {
          dispatch(updateAppointnment({ token, data: values, id }));
        } else {
          toast.error(
            `You cannot cancel appointment before ${
              settingsState.settings?.cancellation_min_before_booking * 24
            } hours to appointment`
          );
        }
      } else {
        dispatch(updateAppointnment({ token, data: values, id }));
      }
    } else {
      dispatch(updateAppointnment({ token, data: values, id }));
    }
  };

  useEffect(() => {
    if (success) {
      toast.success("Appointment updated Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/portal/appointments");
    }
  }, [success, navigate, dispatch]);
  useEffect(() => {
    if (appointment) {
      reset({
        name: appointment?.name,
        date: appointment?.date,
        notes: appointment?.notes,
        time: appointment?.time,
      });
    }
  }, [appointment, reset]);

  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (appointment?.services?.length > 0) {
      setSelectedServices(appointment?.services);
    }
  }, [appointment?.services]);

  const is_resheduling = settingsState?.settings?.rescheduling
    ? settingsState?.settings?.rescheduling === 4
      ? false
      : isCancellationAllowed(
          settingsState.settings?.rescheduling_min_before_booking,
          getAppointmentDateTimeCombined(appointment?.date, appointment?.time)
        )
      ? true
      : false
    : false;
  const is_cancellation = settingsState?.settings?.cancellation
    ? settingsState?.settings?.cancellation === 41
      ? false
      : true
    : false;
  return (
    <div className="outer">
      <Link to={"/portal/appointments"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Update Appointment</h1>
      {conditionalRender(
        appoinmentDropLoading || singleLoading || settingsState.getLoading,
        <PageLoader />,
        conditionalRender(
          !error && !singleError && !settingsState.getError,
          <form onSubmit={handleSubmit(handleAddAppoinment)}>
            <div
              className="add-appoinment-inputs mb-20"
              id="add-appoinment-inputs"
            >
              <div className="add-appoinment-input">
                <ClientsDropDown
                  label="Select Client"
                  selectedClient={selectedClient}
                  setSelectedCLient={setSelectedClient}
                  defaultSelected={appointment?.client_id}
                />
                <p className="global-input-error"></p>
              </div>
              {is_resheduling && (
                <Flex
                  className={`global-input-container add-appoinment-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="date">Reschedule Date</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <Controller
                      control={control}
                      name="date"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          minDate={getCurrentDate()}
                          excludeDates={settingsState?.settings?.unavailable_dates?.map(
                            (dateString) => new Date(dateString)
                          )}
                        />
                      )}
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors?.date && errors?.date?.message}
                  </p>
                </Flex>
              )}
              {is_resheduling && (
                <Flex
                  className={`global-input-container add-appoinment-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="time">Reschedule Time</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"time"}
                      {...register("time", {
                        required: "Please Select Appointment Time",
                      })}
                      id="time"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors?.time && errors?.time?.message}
                  </p>
                </Flex>
              )}

              <Flex
                className="add-appoinment-input"
                direction="column"
                gap={10}
              >
                <GlobalDropDown
                  options={
                    is_cancellation
                      ? [
                          {
                            id: 22,
                            name: "Completed",
                          },
                          { id: 23, name: "Upcoming" },
                          {
                            id: 24,
                            name: "No Show",
                          },
                          { id: 25, name: "Cancelled" },
                        ]
                      : [
                          {
                            id: 22,
                            name: "Completed",
                          },
                          { id: 23, name: "Upcoming" },
                          {
                            id: 24,
                            name: "No Show",
                          },
                        ]
                  }
                  label="Status"
                  setStateHandler={setSelectedStatus}
                  icon="/icons/user.png"
                  stateHandler={selectedStatus}
                  defaultId={appointment?.status}
                />
                <p className="global-input-error"></p>
              </Flex>
            </div>
            <div className="add-appoinment-input">
              <ServicesDropDown
                label="Select Service(s)"
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                handleChange={(service) => {
                  if (selectedServices.find((m) => m.id === service.id)) {
                    return;
                  } else {
                    setSelectedServices((prevData) => [...prevData, service]);
                  }
                }}
              />
              <p className="global-input-error">
                {dropError && !selectedService && dropError}
              </p>
            </div>
            <div className="selected-team-members selected-services-in-appointments mb-20">
              {selectedServices && selectedServices.length > 0
                ? selectedServices.map((service, index) => {
                    return (
                      <Tag
                        onClick={() => {
                          const updatedMembers = selectedServices.filter(
                            (m) => m.id !== service.id
                          );
                          setSelectedServices(updatedMembers);
                        }}
                        key={index}
                      >
                        {service?.name}
                      </Tag>
                    );
                  })
                : null}
            </div>

            <div className="lable-textarea-group lable-input-group">
              <label htmlFor="notes">Add Notes</label>
              <div className="edit-client-icon-textarea">
                <img src="/icons/profile-2user.png" alt="" />
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="Notes"
                  {...register("notes", { required: false })}
                ></textarea>
              </div>
            </div>
            <div className="edit-client-btns">
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loaderColor="#fff"
                loading={loading}
              />
            </div>
          </form>,
          <h1>
            {appoinmentDropError
              ? appoinmentDropError
              : singleError
              ? singleError
              : settingsState.getError}
          </h1>
        )
      )}

      {updateError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={updateError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default EditAppointment;
