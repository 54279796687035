import queryString from "query-string";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSingleTeamMemberAppointments } from "../../../features/ServiceProviderPortal/appoinmentSlice";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Flex from "../../../components/styled/Flex/Flex";
import { timeStamps } from "../../../utills/data";
import {
  calculateEndTime12Hour,
  convertToAMPM,
  getWeeklyAppointmentMessage,
} from "../../../utills/prettifyDate";
const colors = ["#BB86FF", "#F4C465", "#8AD7B7", "#8BC7FF"];
const AppointmentTeamCalender = () => {
  const query = queryString.parse(window.location.search);
  const { token } = useSelector((state) => state.auth);
  const { loading, error, appointmentsByDate } = useSelector(
    (state) => state.appoinments
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      if (query?.id) {
        dispatch(
          getSingleTeamMemberAppointments({
            token,
            id: query.id,
          })
        );
      } else {
        navigate(-1);
      }
    }
  }, [token, dispatch, query?.id, navigate]);

  function isAppointmentWithinTimeframe(startTime, endTime, initialTimeFrame) {
    let startHour = parseInt(startTime.split(":")[0]);
    startHour = startHour === 0 ? 24 : startHour;
    let initialHour = parseInt(initialTimeFrame);
    let finalHour = initialHour + 2;
    if (startHour >= initialHour && startHour < finalHour) {
      return true;
    }

    return false;
  }
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : !error ? (
        <>
          <h1 className="appointment-week-message">{getWeeklyAppointmentMessage()}</h1>
          <div className="appointments-calender-main-container">
            <table>
              <thead>
                <tr>
                  {appointmentsByDate &&
                    appointmentsByDate?.map((item, index) => (
                      <th key={item.id}>
                        <div className="appointment-calender-team">
                          <Flex align="center" justify="center" gap={10}>
                            {/* <Flex
                              align="center"
                              justify="center"
                              className="global-clients-drop-user"
                            >
                              <User
                                color="#14142B"
                                fill="#14142B"
                                size={15}
                              />
                            </Flex> */}
                            <p>{item.day}</p>
                          </Flex>
                        </div>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {timeStamps?.map((stamp, index) => (
                  <tr key={stamp.value} className="appointment-calender-row">
                    {appointmentsByDate?.map((member, memberIndex) => {
                      let appointmentFound = false;
                      member.appointments &&
                        member.appointments.forEach((appointment) => {
                          if (
                            isAppointmentWithinTimeframe(
                              appointment.time,
                              calculateEndTime12Hour(
                                convertToAMPM(appointment.time),
                                appointment.services
                              ),
                              stamp.value,
                              stamp.value + 2
                            )
                          ) {
                            appointmentFound = true;
                            return;
                          }
                        });

                      return (
                        <td key={member.id} style={{ minWidth: "100px" }}>
                          {appointmentFound ? (
                            member.appointments.map((appointment) => {
                              if (
                                isAppointmentWithinTimeframe(
                                  appointment.time,
                                  calculateEndTime12Hour(
                                    convertToAMPM(appointment.time),
                                    appointment.services
                                  ),
                                  stamp.value,
                                  stamp.value + 2
                                )
                              ) {
                                return (
                                  <Link
                                    to={`/portal/appoinment-detail/${appointment.id}`}
                                    key={appointment.id}
                                  >
                                    <div
                                      className="appointment-team-schedule"
                                      style={{
                                        background:
                                          colors[Math.floor(Math.random() * 4)],
                                      }}
                                    >
                                      <div className="appointment-calender-badge">
                                        {convertToAMPM(appointment.time)} -{" "}
                                        {calculateEndTime12Hour(
                                          convertToAMPM(appointment.time),
                                          appointment.services
                                        )}
                                      </div>
                                      <div className="appointment-calender-heading">
                                        {convertToAMPM(appointment.time)} -{" "}
                                        {calculateEndTime12Hour(
                                          convertToAMPM(appointment.time),
                                          appointment.services
                                        )}
                                      </div>
                                      <div className="appointment-calender-service-name">
                                        {appointment?.services?.map(
                                          (item, index) => (
                                            <div className="appointment-calender-service">
                                              {item?.name}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Link>
                                );
                              } else {
                                return null;
                              }
                            })
                          ) : (
                            <div style={{ height: "50px" }}></div>
                          )}
                        </td>
                      );
                    })}
                    <span>{stamp.label}</span>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <GlobalErrorHandler label={error} />
        </>
      )}
    </div>
  );
};

export default AppointmentTeamCalender;
