import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  addAppointmentSetting,
  clearState,
  getAppointemtDrop,
  getAppointemtSettings,
  updateAppointmentSetting,
} from "../../../features/ServiceProviderPortal/appointmentSettingsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import { timeZones } from "../../../utills/timeZones";
import Button from "../../../components/styled/Button/Button";
import Toggle from "../../../components/Toggle/Toggle";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { convBoolToNumber } from "../../../utills/conversion";
import { toast } from "react-toastify";
import {
  getCalendarData,
  getCurrentYearMonths,
  getFirstDayOfMonthName,
  weekDays,
  weekDaysFull,
} from "../../../utills/getCalender";
import PortalDrop from "../../../components/Portal/PortalDrop/PortalDrop";
import { formatDate } from "../../../utills/prettifyDate";
import Checkbox from "../../../components/Checkbox/Checkbox";
import TimePicker from "../../../components/styled/Form/DateTimePickers/TimePicker";
const AppoinmentSetting = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    loading,
    error,
    addLoading,
    addError,
    success,
    dropDown,
    settings,
    getLoading,
    getError,
  } = useSelector((state) => state.appointmentSettings);

  useEffect(() => {
    if (token) {
      dispatch(getAppointemtDrop({ token }));
      dispatch(getAppointemtSettings(token));
    }
    if (success) {
      toast.success("Settings updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
    }
  }, [dispatch, token, success]);
  useEffect(() => {
    if (settings) {
      reset({
        prevent_booking: settings?.prevent_booking,
        monday_opening_time: settings?.working_hours[0]?.opening_time,
        monday_closing_time: settings?.working_hours[0]?.close_time,
        tuesday_opening_time: settings?.working_hours[1]?.opening_time,
        tuesday_closing_time: settings?.working_hours[1]?.close_time,
        wednesday_opening_time: settings?.working_hours[2]?.opening_time,
        wednesday_closing_time: settings?.working_hours[2]?.close_time,
        thursday_opening_time: settings?.working_hours[3]?.opening_time,
        thursday_closing_time: settings?.working_hours[3]?.close_time,
        friday_opening_time: settings?.working_hours[4]?.opening_time,
        friday_closing_time: settings?.working_hours[4]?.close_time,
        saturday_opening_time: settings?.working_hours[5]?.opening_time,
        saturday_closing_time: settings?.working_hours[5]?.close_time,
        sunday_opening_time: settings?.working_hours[6]?.opening_time,
        sunday_closing_time: settings?.working_hours[6]?.close_time,
        max_day_before_booking: settings?.max_day_before_booking,
        min_day_before_booking: settings?.min_day_before_booking,
        notes: settings?.notes,
      });
      setUnavailableDates(
        settings?.unavailable_dates ? settings?.unavailable_dates : []
      );
    }
  }, [settings, reset]);

  const [selectedCancellation, setSelectedCancellation] = useState(null);
  const [cancellationMinDays, setCancellationMinDays] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [selectedRescheduleBefore, setSelectedRescheduleBefore] =
    useState(null);
  const [selectedCancelAppointmentBefore, setSelectedCancelAppointmentBefore] =
    useState(null);
  const [rescheduleMinDays, setRescheduleMinDays] = useState(null);
  const [dropError, setDropError] = useState(null);
  const [releaseAppointment, setReleaseAppointment] = useState(null);
  const monday_opening_time = watch("monday_opening_time");
  const tuesday_opening_time = watch("tuesday_opening_time");
  const wednesday_opening_time = watch("wednesday_opening_time");
  const thursday_opening_time = watch("thursday_opening_time");
  const friday_opening_time = watch("friday_opening_time");
  const saturday_opening_time = watch("saturday_opening_time");
  const sunday_opening_time = watch("sunday_opening_time");
  const monday_closing_time = watch("monday_closing_time");
  const tuesday_closing_time = watch("tuesday_closing_time");
  const wednesday_closing_time = watch("wednesday_closing_time");
  const thursday_closing_time = watch("thursday_closing_time");
  const friday_closing_time = watch("friday_closing_time");
  const saturday_closing_time = watch("saturday_closing_time");
  const sunday_closing_time = watch("sunday_closing_time");

  const handleAddAppointmentSetting = (values) => {
    const timings = [];
    if (values?.monday_opening_time?.length > 0) {
      const timingData = {
        day: "Monday",
        opening_time: values.monday_opening_time,
        close_time: values.monday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.tuesday_opening_time?.length > 0) {
      const timingData = {
        day: "Tuesday",
        opening_time: values.tuesday_opening_time,
        close_time: values.tuesday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.wednesday_opening_time?.length > 0) {
      const timingData = {
        day: "Wednesday",
        opening_time: values.wednesday_opening_time,
        close_time: values.wednesday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.thursday_opening_time?.length > 0) {
      const timingData = {
        day: "Thursday",
        opening_time: values.thursday_opening_time,
        close_time: values.thursday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.friday_opening_time?.length > 0) {
      const timingData = {
        day: "Friday",
        opening_time: values.friday_opening_time,
        close_time: values.friday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.saturday_opening_time?.length > 0) {
      const timingData = {
        day: "Saturday",
        opening_time: values.saturday_opening_time,
        close_time: values.saturday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.sunday_opening_time?.length > 0) {
      const timingData = {
        day: "Sunday",
        opening_time: values.sunday_opening_time,
        close_time: values.sunday_closing_time,
      };
      timings.push(timingData);
    }
    const clientNots = [];

    values.prevent_booking = parseInt(values.prevent_booking);
    if (selectedCancellation) {
      values.cancellation = selectedCancellation.id;
    }
    // if (selectedMaxNoDaysBeforeBooking) {
    //   values.max_day_before_booking = selectedMaxNoDaysBeforeBooking.name;
    // }
    if (cancellationMinDays) {
      values.cancellation_min_before_booking = cancellationMinDays.name;
    }
    if (selectedTimeZone) {
      values.time_zone = selectedTimeZone.name;
    }
    if (rescheduleMinDays) {
      values.rescheduling_min_before_booking = rescheduleMinDays.id;
    }
    if (selectedRescheduleBefore) {
      values.rescheduling = selectedRescheduleBefore.id;
    }
    if (selectedCancelAppointmentBefore) {
      values.cancel_before = selectedCancelAppointmentBefore.id;
    }
    if (releaseAppointment) {
      values.relase_appointment = releaseAppointment.name;
    }
    values.working_hours = timings;

    clientNots.push({
      is_client_review: convBoolToNumber(values.is_client_review),
      is_confirmation: convBoolToNumber(values.is_confirmation),
      is_reminder: convBoolToNumber(values.is_reminder),
      is_followup: convBoolToNumber(values.is_followup),
      is_cancellation_deadline: convBoolToNumber(
        values.is_cancellation_deadline
      ),
      is_birthday: convBoolToNumber(values.is_birthday),
    });
    values.client_notifications = clientNots;
    values.unavailable_dates = unavailableDates;

    if (settings) {
      dispatch(updateAppointmentSetting({ token, data: values }));
    } else {
      dispatch(addAppointmentSetting({ token, data: values }));
    }
  };
  const [selectedMonth, setSelectedMonth] = useState(
    getCurrentYearMonths()?.currentMonth
  );
  const [unavailableDates, setUnavailableDates] = useState([]);

  const isActive = (date) => {
    if (unavailableDates.includes(formatDate(date))) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="appoinment-settings-container">
      <h1>Appoinment settings page</h1>
      <h3 className="appointment-setting-info">
        Set Parameters on minimum and maximum requirements for booking
        appointments.{" "}
      </h3>
      {conditionalRender(
        loading || getLoading,
        <PageLoader />,
        conditionalRender(
          !error && !getError,
          <form onSubmit={handleSubmit(handleAddAppointmentSetting)}>
            <h3 className="prevent-booking-head">Prevent Bookings</h3>
            <div className="appointment-settings-first-input-outer">
              <div className="appointment-settings-first-input">
                <Flex
                  className={`global-input-container prevent-booking-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="prevent_booking" style={{ height: "45px" }}>
                    Hours before appointment
                  </label>
                  <div className="global-input-container-input">
                    <input
                      type={"number"}
                      {...register("prevent_booking", {
                        required: false,
                        min: { value: 0, message: "Hours cannot be negative" },
                      })}
                      id="prevent_booking"
                      maxLength={2}
                    />
                  </div>

                  <p className="global-input-error">
                    {errors?.prevent_booking &&
                      errors?.prevent_booking?.message}
                  </p>
                </Flex>
              </div>
              {/* <div className="appointment-settings-first-input">
                <Flex
                  className={`global-input-container prevent-booking-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="">
                    Minimum days before an appointment <br />
                    <span>1 day will be evaluated to 24 hours</span>
                  </label>
                  <div className="global-input-container-input">
                    <input
                      type={"number"}
                      {...register("min_day_before_booking", {
                        required: false,
                        min: { value: 0, message: "Days cannot be negative" },
                      })}
                      id="min_day_before_booking"
                      maxLength={2}
                    />
                  </div>

                  <p className="global-input-error">
                    {errors?.max_day_before_booking &&
                      errors?.max_day_before_booking?.message}
                  </p>
                </Flex>
              </div> */}
            </div>
            <div className="appointment-settings-below-inputs">
              <div
                className="appointment-settings-below-input"
                style={{ zIndex: 5 }}
              >
                <GlobalDropDown
                  stateHandler={selectedCancellation}
                  setStateHandler={setSelectedCancellation}
                  label="Allow Cancellations"
                  options={dropDown}
                  defaultId={settings?.cancellation}
                />
                <p className="global-input-error">
                  {dropError && !selectedCancellation && dropError}
                </p>
              </div>

              {selectedCancellation && selectedCancellation.id === 40 ? (
                <div
                  className="appointment-settings-below-input"
                  style={{ zIndex: 4 }}
                >
                  <GlobalDropDown
                    stateHandler={cancellationMinDays}
                    setStateHandler={setCancellationMinDays}
                    label="Minimum n.o of days before booking "
                    defaultName={settings?.cancellation_min_before_booking}
                    options={[
                      {
                        name: 5,
                      },
                      {
                        name: 4,
                      },
                      {
                        name: 3,
                      },
                      {
                        name: 2,
                      },
                      {
                        name: 1,
                      },
                    ]}
                  />
                  <p className="global-input-error">
                    {dropError && !setCancellationMinDays && dropError}
                  </p>
                </div>
              ) : (
                <div></div>
              )}

              <div
                className="appointment-settings-below-input"
                style={{ zIndex: 3 }}
              >
                <GlobalDropDown
                  label="Allow Rescheduling"
                  stateHandler={selectedRescheduleBefore}
                  setStateHandler={setSelectedRescheduleBefore}
                  defaultId={settings?.rescheduling}
                  options={[
                    {
                      name: "Yes",
                      id: 5,
                    },
                    {
                      name: "No",
                      id: 4,
                    },
                  ]}
                />
                <p className="global-input-error">
                  {dropError && !selectedRescheduleBefore && dropError}
                </p>
              </div>
              {selectedRescheduleBefore && selectedRescheduleBefore.id === 5 ? (
                <div
                  className="appointment-settings-below-input"
                  style={{ zIndex: 2 }}
                >
                  <GlobalDropDown
                    label="Minimum no of days before booking"
                    stateHandler={rescheduleMinDays}
                    setStateHandler={setRescheduleMinDays}
                    defaultId={settings?.rescheduling_min_before_booking}
                    options={[
                      {
                        name: "5",
                        id: 5,
                      },
                      {
                        name: "4",
                        id: 4,
                      },
                      {
                        name: "3",
                        id: 3,
                      },
                      {
                        name: "2",
                        id: 2,
                      },
                      {
                        name: "1",
                        id: 1,
                      },
                    ]}
                  />
                  <p className="global-input-error">
                    {dropError && !selectedCancelAppointmentBefore && dropError}
                  </p>
                </div>
              ) : (
                <div></div>
              )}

              <div
                className="appointment-settings-below-input"
                style={{ zIndex: 1 }}
              >
                <GlobalDropDown
                  label="Time Zone"
                  stateHandler={selectedTimeZone}
                  setStateHandler={setSelectedTimeZone}
                  defaultName={settings?.time_zone}
                  options={timeZones}
                />
                <p className="global-input-error">
                  {dropError && !selectedTimeZone && dropError}
                </p>
              </div>
            </div>

            <div className="appointment-settings-available-slots">
              <h2 className="website-design-head mt-30">Availability Slots</h2>
              <div className="working-timings mt-20">
                <div className="working-time-open-close">
                  <label htmlFor="monday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="monday-timing"
                      value={"Nail Art"}
                      checked
                    />
                    <span className="business-service-label">Monday</span>
                  </label>
                  <div className="open-close-time">
                    <div className="open-time-label-input">
                      <label htmlFor="monday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={monday_opening_time}
                          onChange={(time) => {
                            setValue("monday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="monday-timing">Last slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={monday_closing_time}
                          onChange={(time) => {
                            setValue("monday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>

                    <p className="website-design-date-error global-input-error">
                      {errors?.monday_closing_time &&
                        errors?.monday_closing_time?.message}
                    </p>
                  </div>
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="tuesday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="tuesday-timing"
                      value={"Tuesday"}
                      checked
                    />
                    <span className="business-service-label">Tuesday</span>
                  </label>
                  <div className="open-close-time">
                    {monday_opening_time?.length > 0 &&
                      monday_closing_time?.length > 0 &&
                      (!tuesday_opening_time?.length > 0 ||
                        !tuesday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              tuesday_opening_time: monday_opening_time,
                              tuesday_closing_time: monday_closing_time,
                              monday_opening_time,
                              monday_closing_time,
                            })
                          }
                        >
                          Copy Previous Day
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="tuesday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={tuesday_opening_time}
                          onChange={(time) => {
                            setValue("tuesday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="tuesday-close-timing">Last slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={tuesday_closing_time}
                          onChange={(time) => {
                            setValue("tuesday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.tuesday_closing_time &&
                      errors?.tuesday_closing_time?.message}
                  </p>
                </div>
                <div className="working-time-open-close">
                  <label
                    htmlFor="wednesday-timing"
                    className="business-service"
                  >
                    <input
                      type="checkbox"
                      name=""
                      id="wednesday-timing"
                      value={"Wednesday"}
                      checked
                    />
                    <span className="business-service-label">Wednesday</span>
                  </label>
                  <div className="open-close-time">
                    {tuesday_opening_time?.length > 0 &&
                      tuesday_closing_time?.length > 0 &&
                      (!wednesday_opening_time?.length > 0 ||
                        !wednesday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              wednesday_opening_time: tuesday_opening_time,
                              wednesday_closing_time: tuesday_closing_time,
                              tuesday_closing_time,
                              tuesday_opening_time,
                              monday_closing_time,
                              monday_opening_time,
                            })
                          }
                        >
                          Copy Previous Day
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="wednesday-open-timing">
                        First Slot
                      </label>
                      <div id="width-118">
                        <TimePicker
                          value={wednesday_opening_time}
                          onChange={(time) => {
                            setValue("wednesday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="wednesday-close-timing">
                        Last slot
                      </label>
                      <div id="width-118">
                        <TimePicker
                          value={wednesday_closing_time}
                          onChange={(time) => {
                            setValue("wednesday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.wednesday_closing_time &&
                      errors?.wednesday_closing_time?.message}
                  </p>
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="thursday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="thursday-timing"
                      value={"Thursday"}
                      checked
                    />
                    <span className="business-service-label">Thursday</span>
                  </label>
                  <div className="open-close-time">
                    {wednesday_opening_time?.length > 0 &&
                      wednesday_closing_time?.length > 0 &&
                      (!thursday_opening_time?.length > 0 ||
                        !thursday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              thursday_opening_time: wednesday_opening_time,
                              thursday_closing_time: wednesday_closing_time,
                              monday_closing_time,
                              monday_opening_time,
                              tuesday_opening_time,
                              tuesday_closing_time,
                              wednesday_opening_time,
                              wednesday_closing_time,
                            })
                          }
                        >
                          Copy Previous Day
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="thursday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={thursday_opening_time}
                          onChange={(time) => {
                            setValue("thursday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="thursday-close-timing">
                        Last slot
                      </label>
                      <div id="width-118">
                        <TimePicker
                          value={thursday_closing_time}
                          onChange={(time) => {
                            setValue("thursday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.thursday_closing_time &&
                      errors?.thursday_closing_time?.message}
                  </p>
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="friday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="friday-timing"
                      value={"Friday"}
                      checked
                    />
                    <span className="business-service-label">Friday</span>
                  </label>
                  <div className="open-close-time">
                    {thursday_opening_time?.length > 0 &&
                      thursday_closing_time?.length > 0 &&
                      (!friday_opening_time?.length > 0 ||
                        !friday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              friday_opening_time: thursday_opening_time,
                              friday_closing_time: thursday_closing_time,
                              monday_opening_time,
                              monday_closing_time,
                              tuesday_closing_time,
                              tuesday_opening_time,
                              wednesday_closing_time,
                              wednesday_opening_time,
                              thursday_closing_time,
                              thursday_opening_time,
                            })
                          }
                        >
                          Copy Previous Day
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="friday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={friday_opening_time}
                          onChange={(time) => {
                            setValue("friday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="friday-close-timing">Last slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={friday_closing_time}
                          onChange={(time) => {
                            setValue("friday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.friday_closing_time &&
                      errors?.friday_closing_time?.message}
                  </p>
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="saturday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="saturday-timing"
                      value={"Saturday"}
                      checked
                    />
                    <span className="business-service-label">Saturday</span>
                  </label>
                  <div className="open-close-time">
                    {friday_opening_time?.length > 0 &&
                      friday_closing_time?.length > 0 &&
                      (!saturday_opening_time?.length > 0 ||
                        !saturday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              saturday_opening_time: friday_opening_time,
                              saturday_closing_time: friday_closing_time,
                              monday_opening_time,
                              monday_closing_time,
                              tuesday_closing_time,
                              tuesday_opening_time,
                              wednesday_closing_time,
                              wednesday_opening_time,
                              thursday_closing_time,
                              thursday_opening_time,
                              friday_opening_time,
                              friday_closing_time,
                            })
                          }
                        >
                          Copy Previous Day
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="saturday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={saturday_opening_time}
                          onChange={(time) => {
                            setValue("saturday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="saturday-close-timing">
                        Last slot
                      </label>
                      <div id="width-118">
                        <TimePicker
                          value={saturday_closing_time}
                          onChange={(time) => {
                            setValue("saturday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.saturday_closing_time &&
                      errors?.saturday_closing_time?.message}
                  </p>
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="sunday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="sunday-timing"
                      value={"Sunday"}
                      checked
                    />
                    <span className="business-service-label">Sunday</span>
                  </label>
                  <div className="open-close-time">
                    {saturday_opening_time?.length > 0 &&
                      saturday_closing_time?.length > 0 &&
                      (!sunday_opening_time?.length > 0 ||
                        !sunday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              sunday_opening_time: saturday_opening_time,
                              sunday_closing_time: saturday_closing_time,
                              monday_closing_time,
                              monday_opening_time,
                              tuesday_closing_time,
                              tuesday_opening_time,
                              wednesday_closing_time,
                              wednesday_opening_time,
                              thursday_closing_time,
                              thursday_opening_time,
                              friday_opening_time,
                              friday_closing_time,
                              saturday_closing_time,
                              saturday_opening_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="sunday-open-timing">First Slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={sunday_opening_time}
                          onChange={(time) => {
                            setValue("sunday_opening_time", time);
                          }}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="sunday-close-timing">Last slot</label>
                      <div id="width-118">
                        <TimePicker
                          value={sunday_closing_time}
                          onChange={(time) => {
                            setValue("sunday_closing_time", time);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="website-design-date-error global-input-error">
                    {errors?.sunday_closing_time &&
                      errors?.sunday_closing_time?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="unavailable-dated-container">
              <div className="unavailable-dated-container-left">
                <Flex align="center" justify="space-between" className="mb-20">
                  <h3 className="appointment-settings-head">
                    Unavailable dates
                  </h3>
                  <PortalDrop
                    options={getCurrentYearMonths()?.months}
                    active={selectedMonth}
                    setAcvtive={setSelectedMonth}
                  />
                </Flex>
                {/* <img src="/cal.png" alt="calender" /> */}
                <div className="calender-outer">
                  <div class="calendar">
                    {weekDays.map((day, index) => {
                      return (
                        <div className="top-days">
                          <div className="day-of-week" key={index}>
                            {day}
                          </div>
                        </div>
                      );
                    })}
                    {weekDaysFull
                      .slice(
                        0,
                        weekDaysFull.indexOf(
                          getFirstDayOfMonthName(selectedMonth)
                        )
                      )
                      .map((day, index) => (
                        <div key={index}></div>
                      ))}
                    {getCalendarData(selectedMonth)?.calendarData.map(
                      (dayData) => (
                        <div
                          className={`day ${
                            isActive(dayData.date) ? "date-active" : ""
                          }`}
                          key={dayData.date.getDate()}
                          onClick={() => {
                            if (
                              unavailableDates.find(
                                (m) => m === formatDate(dayData.date)
                              )
                            ) {
                              const updatedDates = unavailableDates.filter(
                                (m) => m !== formatDate(dayData.date)
                              );
                              setUnavailableDates(updatedDates);
                            } else {
                              setUnavailableDates((prevData) => [
                                ...prevData,
                                formatDate(dayData.date),
                              ]);
                            }
                          }}
                        >
                          <div className="day-of-month">
                            {dayData.dayOfMonth}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="unavailable-dated-container-right">
                <GlobalDropDown
                  label="Release appointments on"
                  stateHandler={releaseAppointment}
                  setStateHandler={setReleaseAppointment}
                  defaultName={settings?.relase_appointment}
                  options={[
                    {
                      name: "15th",
                    },
                    {
                      name: "14th",
                    },
                    {
                      name: "13th",
                    },
                    {
                      name: "12th",
                    },
                  ]}
                />
              </div>
            </div>

            <div className="lable-textarea-group lable-input-group mt-20 mb-20">
              <label htmlFor="notes">Add Notes</label>
              <div className="edit-client-icon-textarea">
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="10"
                  placeholder="Notes"
                  {...register("notes", { required: false })}
                ></textarea>
              </div>
            </div>

            <Flex className="mb-20">
              <div className="client-not">
                <h3
                  className="appointment-settings-head"
                  style={{ marginBottom: "30px" }}
                >
                  Client Notifications
                </h3>
                <div className="client-not-info">
                  <ul style={{ marginLeft: "20px" }}>
                    <li>
                      <Toggle
                        label={"Request Review Clients"}
                        register={register}
                        name={"is_client_review"}
                        id={"is_client_review"}
                        reverse={true}
                        checked={
                          settings?.client_notifications?.is_client_review
                        }
                      />
                    </li>
                    <li>
                      {" "}
                      <Toggle
                        label={"Confirmation"}
                        register={register}
                        name={"is_confirmation"}
                        id={"is_confirmation"}
                        reverse={true}
                        checked={
                          settings?.client_notifications?.is_confirmation
                        }
                      />
                    </li>
                    <li>
                      <Toggle
                        label={"Reminderss"}
                        register={register}
                        name={"is_reminder"}
                        id={"is_reminder"}
                        reverse={true}
                        checked={settings?.client_notifications?.is_reminder}
                      />
                    </li>
                    <li>
                      <Toggle
                        label={"Follow ups"}
                        register={register}
                        name={"is_followup"}
                        id={"is_followup"}
                        reverse={true}
                        checked={settings?.client_notifications?.is_followup}
                      />
                    </li>
                    <li>
                      <Toggle
                        label={"Cancellation and deadlines"}
                        register={register}
                        name={"is_cancellation_deadline"}
                        id={"is_cancellation_deadline"}
                        reverse={true}
                        checked={
                          settings?.client_notifications
                            ?.is_cancellation_deadline
                        }
                      />
                    </li>
                    <li>
                      <Toggle
                        label={"Birthdays"}
                        register={register}
                        name={"is_birthday"}
                        id={"is_birthday"}
                        reverse={true}
                        checked={settings?.client_notifications?.is_birthday}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </Flex>

            <Checkbox
              register={register}
              name="gifts"
              id="gifts"
              label="Enable Gifts Cards"
            />
            <Flex align="center" justify="flex-end" gap={20}>
              {/* <Button type="reset" label={"Cancel"} variant="outline" /> */}
              <Button
                type="submit"
                label={"Update Settings"}
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>,
          <h1>{error ? error : getError}</h1>
        )
      )}

      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AppoinmentSetting;
