import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
const initialState = {
  loading: false,
  success: false,
  deleteVoucherLoading: false,
  deleteVoucherSuccess: false,
  deleteVoucherError: null,
  updateVoucherLoading: false,
  updateVoucherError: null,
  error: null,
  vouchers: null,
  voucher: null,
  dropDown: null,
  getSingleLoading: false,
};

export const vouchersSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = false;
      state.error = null;
      state.deleteVoucherSuccess = false;
      state.deleteVoucherError = null;
    },
  },
  extraReducers: (builder) => {
    // Get All vouchers cases
    builder.addCase(getAllVouchers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVouchers.fulfilled, (state, action) => {
      state.loading = false;
      state.vouchers = action.payload;
    });
    builder.addCase(getAllVouchers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add Voucher cases
    builder.addCase(addVoucher.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVoucher.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(addVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Voucher
    builder.addCase(deleteVoucher.pending, (state) => {
      state.deleteVoucherLoading = true;
    });
    builder.addCase(deleteVoucher.fulfilled, (state) => {
      state.deleteVoucherLoading = false;
      state.deleteVoucherSuccess = true;
    });
    builder.addCase(deleteVoucher.rejected, (state, action) => {
      state.deleteVoucherLoading = false;
      state.deleteVoucherError = action.payload;
    });

    // Get Single Voucher

    builder.addCase(getSingleVoucher.pending, (state) => {
      state.getSingleLoading = true;
    });
    builder.addCase(getSingleVoucher.fulfilled, (state, action) => {
      state.getSingleLoading = false;
      state.voucher = action.payload;
    });
    builder.addCase(getSingleVoucher.rejected, (state, action) => {
      state.getSingleLoading = false;
      state.getSingleError = action.payload;
    });

    // Update voucher
    builder.addCase(updateVoucher.pending, (state) => {
      state.updateVoucherLoading = true;
    });
    builder.addCase(updateVoucher.fulfilled, (state) => {
      state.updateVoucherLoading = false;
      state.success = true;
    });
    builder.addCase(updateVoucher.rejected, (state, action) => {
      state.updateVoucherLoading = false;
      state.updateVoucherError = action.payload;
    });

    // Get Vouchers dropdown
    builder.addCase(getVoucherDropDown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVoucherDropDown.fulfilled, (state, action) => {
      state.dropDown = action.payload;
      state.loading = false;
    });
    builder.addCase(getVoucherDropDown.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getAllVouchers = createAsyncThunk(
  "getVouchers",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/vouchers/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addVoucher = createAsyncThunk(
  "addVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide id");
        }
        const { data } = await axios.post(
          `${BASE_URL}/v1/vouchers/create`,
          apiData.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occured Please Try Again Later");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteVoucher = createAsyncThunk(
  "deleteVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/vouchers/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getSingleVoucher = createAsyncThunk(
  "getSingleVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/vouchers/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateVoucher = createAsyncThunk(
  "updateVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/vouchers/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getVoucherDropDown = createAsyncThunk(
  "getVoucherDropDown",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/7`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const { resetState } = vouchersSlice.actions;
