import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
const initialState = {
  loading: false,
  success: false,
  delLoading: false,
  delSuccess: false,
  delError: null,
  updateServiceLoading: false,
  error: null,
  services: null,
  service: null,
  getSingleLoading: false,
  getSingleError: null,
  servicesDrop: null,
};

export const servicesSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = false;
      state.error = null;
      state.delError = false;
      state.delSuccess = null;
    },
  },
  extraReducers: (builder) => {
    // Get All vouchers cases
    builder.addCase(getAllServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllServices.fulfilled, (state, action) => {
      state.loading = false;
      state.services = action.payload;
    });
    builder.addCase(getAllServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getPublicServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPublicServices.fulfilled, (state, action) => {
      state.loading = false;
      state.services = action.payload;
    });
    builder.addCase(getPublicServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getServicesDrops.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServicesDrops.fulfilled, (state, action) => {
      state.loading = false;
      state.servicesDrop = action.payload;
    });
    builder.addCase(getServicesDrops.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add Voucher cases
    builder.addCase(addService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addService.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(addService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Voucher
    builder.addCase(deleteService.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteService.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });

    // Get Single Voucher

    builder.addCase(getSingleService.pending, (state) => {
      state.getSingleLoading = true;
    });
    builder.addCase(getSingleService.fulfilled, (state, action) => {
      state.getSingleLoading = false;
      state.service = action.payload;
    });
    builder.addCase(getSingleService.rejected, (state, action) => {
      state.getSingleLoading = false;
      state.getSingleError = action.payload;
    });

    // Update voucher
    builder.addCase(updateService.pending, (state) => {
      state.updateServiceLoading = true;
    });
    builder.addCase(updateService.fulfilled, (state) => {
      state.updateServiceLoading = false;
      state.success = true;
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.updateServiceLoading = false;
      state.error = action.payload;
    });
  },
});

export const getAllServices = createAsyncThunk(
  "getServices",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_services/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getPublicServices = createAsyncThunk(
  "getPublicServices",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_services/list/${apiData.id}`
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getServicesDrops = createAsyncThunk(
  "getServicesDrops",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_services/dropdown`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addService = createAsyncThunk(
  "addVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide id");
        }
        const { data } = await axios.post(
          `${BASE_URL}/v1/business_services/create`,
          apiData.data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occured Please Try Again Later");
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteService = createAsyncThunk(
  "deleteVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/business_services/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getSingleService = createAsyncThunk(
  "getSingleVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_services/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateService = createAsyncThunk(
  "updateVoucher",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/business_services/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      //   console.log(data);
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { resetState } = servicesSlice.actions;
