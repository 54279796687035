import React from "react";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { addBusinessRefferal } from "../../../features/ServiceProviderPortal/businessReferralsSlice";
import { useForm } from "react-hook-form";
import { namePattern } from "../../../utills/Schems";
const AddReferral = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const handleAddReferral = (values) => {
    dispatch(addBusinessRefferal({ token, data: { ...values } }));
  };
  // const [serviceProviderError, setServiceProviderError] = useState(null)
  return (
    <div className="outer">
      <h1 className="team-member-details-head">Add Referral</h1>
      <div className="mt-20">
        <form onSubmit={handleSubmit(handleAddReferral)}>
          <div className="add-team-member-inputs">
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="from">From*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Full Name"}
                  {...register("business_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="from"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="to">To*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"text"} placeholder={"Full Name"} id="to" />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="username">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"number"} placeholder={"0300000000"} id="number" />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="last_name">Email*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="email"
                />
                <img src="/icons/sms.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="date">Date*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"date"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="date"
                />
                <img src="/icons/calendar.png" alt="icon" />
              </div>
            </Flex>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Add Referral"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/referrals">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddReferral;
