import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  username: "",
  password: "",
  mobile: "",
  business_type: null
};

const signUpDataSlice = createSlice({
  name: "signupData",
  initialState,
  reducers: {
    updateStateStep1: (state, action) => {
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.business_type = action.payload.business_type;
      state.mobile = action.payload.mobile;
      state.password = action.payload.password;
    },
  },
});

export const { updateStateStep1 } = signUpDataSlice.actions;
export default signUpDataSlice.reducer;
