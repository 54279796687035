import React, { useEffect, useRef, useState } from "react";
import DropDown from "../../../components/Forms/DropDown/DropDown";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { BASE_URL } from "../../../features/config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updateStateStep1 } from "../../../features/signup/signupSlice";
import {
  emailPattern,
  namePattern,
  passwordPattern,
  phonePattern,
  userNamePattern,
} from "../../../utills/Schems";
import Layout from "../../SignUp/Layout/Layout";
import { Check, Eye, EyeOff, X } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import { clientSignup } from "../../../features/auth/authActions";
import { toast } from "react-toastify";
import Button from "../../../components/styled/Button/Button";
import Popup from "../../../components/styled/Popup/Popup";
import { signUpOtpVerification, tokenVerify } from "../../../features/auth/forgetPasswordSlice";
import { useNavigate } from "react-router-dom";
const TemplateSignup = ({ setStep, formData, setFormData }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
 
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const onSubmit = (values) => {
    values.business_id = data?.business_id?.id
    dispatch(clientSignup(values))
  };

  const password = watch("password");

  const { loading, data } = useSelector((state) => state.websiteStyles);
  const {clientSignupLoading,clientSignupSuccess,clientSignupError} = useSelector(state => state.authTemp)
  const [passwordConstraints, setPasswordConstraints] = useState({
    length: false,
    uppercase: false,
    specialCharacter: false,
    number: false,
  });

  useEffect(()=>{
    if(clientSignupError){
      toast.error(clientSignupError)
    }
  },[clientSignupError])

  const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/;

  const handlePasswordChange = (e) => {
    setPasswordConstraints({
      length: e.target.value.length >= 8,
      uppercase: /[A-Z]/.test(e.target.value),
      specialCharacter: /[!@#$&*]/.test(e.target.value),
      number: /[0-9]/.test(e.target.value),
    });
  };

  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);

  const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
  const [enteredOtp, setEnteredOtp] = useState("");
  const inputRefs = useRef(
    new Array(6).fill(null).map(() => React.createRef())
  );
  const handleOtpInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].current.focus();
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }

    setOtpInputs(newOtpInputs);
    setEnteredOtp(newOtpInputs.join(""));
  };
  const [otpError, setOtpError] = useState(null);
  const renderOtpInputs = () => {
    return otpInputs.map((value, index) => (
      <input
        key={index}
        type="number"
        value={value}
        onChange={(e) => handleOtpInputChange(index, e.target.value)}
        ref={inputRefs.current[index]}
      />
    ));
  };

  // const { loading:websiteDataLoading, error, data:websiteData } = useSelector((state) => state.websiteStyles);
  const handleOtpSubmit = () => {
    if (enteredOtp.length === 6) {
      setOtpError(null);
      dispatch(signUpOtpVerification({ verification_code: enteredOtp}));
    } else {
      setOtpError("Please enter all 6 digits.");
    }
  };
  const { verifyLoading, verifyError, verifySuccess } = useSelector(
    (state) => state.forgetPass
  );
  useEffect(()=>{
    if(verifySuccess){
      navigate('/login')
    }
  },[verifySuccess,navigate])
  return (
    <Layout steps={false} isFooter={false}>
      <div>
        <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="signup-forms-input">
            <div className="input-group">
              <label htmlFor="fName"> First Name *</label>
              <div className="input">
                <input
                  type="text"
                  id="fName"
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Should not be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Should not be less then three characters",
                    },

                    pattern: namePattern,
                  })}
                />
                <img src="/icons/user.png" alt="user" />
                {errors?.first_name && (
                  <p className="error">{errors?.first_name?.message}</p>
                )}
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="lName"> Last Name *</label>
              <div className="input">
                <input
                  type="text"
                  id="lName"
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Should not be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Should not be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                />
                <img src="/icons/user.png" alt="" />
                {errors?.last_name && (
                  <p className="error">{errors?.last_name?.message}</p>
                )}
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="email"> Email*</label>
              <div className="input">
                <input
                  type="text"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: emailPattern,
                  })}
                />
                <img src="/icons/mail.png" alt="" />
                {errors?.email && (
                  <p className="error">{errors?.email?.message}</p>
                )}
              </div>
            </div>
            {/* <div className="input-group mb-20">
              <DropDown
                options={data}
                label="Business Type*"
                icon="/icons/user.png"
                active="Hair Dresses"
                selectedItemIndex={selectedItemIndex}
                setSelectedItemIndex={setSelectedItemIndex}
                isLoading={isLoading}
              />
            </div> */}
            <div className="input-group">
              <label htmlFor="phone1"> Phone Number*</label>
              <div className="input">
                <input
                  type="text"
                  id="phone1"
                  {...register("mobile", {
                    required: "Please Enter your Phone Number",
                    pattern: phonePattern,
                  })}
                />
                <img src="/icons/call.png" alt="call" />
                {errors?.mobile && (
                  <p className="error">{errors?.mobile?.message}</p>
                )}
              </div>
            </div>
            <div className="input-group">
            <label htmlFor="password"> Password*</label>
            <div className="input">
              <input
                type={`${showPass ? "text" : "password"}`}
                id="password"
                {...register("password", {
                  required: "Please Enter your password",
                  pattern: passwordPattern,
                })}
                onChange={handlePasswordChange}
              />
              <div className="password-view-icons">
                {showPass ? (
                  <Eye
                    onClick={() => setShowPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>
            </div>
            <div className="global-password-constraints">
              {errors?.password && (
                <>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.length ? (
                      <Check size={15} color={"#14A800"} />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.length ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      At least 8 characters
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.uppercase ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.uppercase ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Capital letter(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.specialCharacter ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.specialCharacter
                            ? "#14A800"
                            : "#FF0000"
                        }`,
                      }}
                    >
                      Special character(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.number ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.number ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Number(s)
                    </p>
                  </Flex>
                </>
              )}
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="password"> Confirm Password *</label>
            <div className="input">
              <input
                type={`${showCPass ? "text" : "password"}`}
                id="password"
                {...register("confirm_password", {
                  validate: (value) =>
                    value === password || "password doesn't match",
                  required: "confirm password is required",
                })}
              />
              <div className="password-view-icons">
                {showCPass ? (
                  <Eye
                    onClick={() => setShowCPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowCPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>

              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.confirm_password && errors?.confirm_password?.message}
              </p>
            </div>
          </div>
          </div>

          <div className="submit-btn-outer">
            <Button label='Sign Up' type="submit" loading={clientSignupLoading} loaderColor='#3E0FFE' variant="rounded-outline"/>
          </div>
        </form>
      </div>

      {clientSignupSuccess && (
        <Popup className="forget-pass-request-popup" setPopUp={() => {}}>
          <h1>Please verify your email</h1>
          <h3>Please enter the verification code sent to your email</h3>
          <div className="forget-pass-request-inputs">
            <div className="forget-pass-inputs-inner">{renderOtpInputs()}</div>
            <Flex align="center" justify="flex-end">
              <p className="global-input-error">
                {otpError || verifyError
                  ? verifyError
                    ? verifyError
                    : otpError
                  : null}
              </p>
            </Flex>
          </div>
          <div className="forget-pass-request-button">
          <Button
            label="Submit"
            variant="rounded-outline"
            handleClick={handleOtpSubmit}
            loading={verifyLoading}
            loaderColor="#3E0FFE"
          />

          </div>
        </Popup>
      )}
    </Layout>
  );
};

export default TemplateSignup;
