import React, { useEffect, useState } from "react";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate, useParams  } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import {
  updateSubscription,
  clearState,
  getSubscriptionDrop,
  getSingleSubscription,
} from "../../../features/ServiceProviderPortal/SubscriptionsSlice";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { emailPattern, namePattern, phonePattern } from "../../../utills/Schems";
import ServiceProviderDropDown from "../../../components/Portal/ServiceProviderDropDown/ServiceProviderDropDown";

const EditSubscription = () => {

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { token } = useSelector((state) => state.auth);
  const {
    success,
    subscription,
  } = useSelector((state) => state.subscriptions);
  const navigate = useNavigate();
  const { id } = useParams();
  const [serviceProviderError, setServiceProviderError] = useState(null);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(getSubscriptionDrop({ token }));
      dispatch(getSingleSubscription({ token, id }));
    }
  }, [token, dispatch, id]);

  useEffect(() => {
    if (subscription) {
      reset({
        first_name: subscription?.first_name,
        last_name: subscription?.last_name,
        email: subscription?.email,
        date: subscription?.date,
        phone: subscription?.phone,
      });
    }
  }, [subscription, reset]);
  const handleEditSubscription = (values) => {
    if (!selectedServiceProvider) {
      setServiceProviderError("Please Select Subscription");
    } else {
      values.business_id = selectedServiceProvider.id
      dispatch(updateSubscription({ token, data: { ...values }, id }));
    }
  };
  useEffect(() => {
    if (success) {
      navigate("/admin-portal/subscriptions");
      dispatch(clearState());
    }
  }, [success, navigate, dispatch]);
  return (
    <div className="outer">
      <Link to={"/admin-portal/subscriptions"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Edit Subscription</h1>
      <div className="mt-20">
        <form onSubmit={handleSubmit(handleEditSubscription)}>
          <div className="add-team-member-inputs">
            <Flex className="add-team-member-input" direction="column" gap={10}>
              <ServiceProviderDropDown
              label='Service Provider'
                selectedServiceProvider={selectedServiceProvider}
                setSelectedServiceProvider={setSelectedServiceProvider}
              />
              <p className="global-input-error">
                {serviceProviderError && serviceProviderError}
              </p>

            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">First Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="last_name">Last Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="last_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email *</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"Enter Email"}
                  {...register("email", {
                    required: "Please Enter Email",
                    pattern: emailPattern,
                  })}
                  id="email"
                />
                <img src="/icons/mail.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="phone">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"Enter phone number"}
                  {...register("phone", {
                    required: "Please Enter Phone Number",
                    pattern: phonePattern,
                    min: {value:0, message:"Number cannot start with (-)"},
                  })}
                  id="phone"
                />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.phone && errors?.phone?.message}
              </p>
            </Flex>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Save Subscription"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/subscriptions">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSubscription;
