import React, { useEffect, useState } from "react";
import "./style.css";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import Flex from "../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccessLevels,
  removeUserAccess,
  updateUserAccess,
} from "../../../features/ServiceProviderPortal/accessLevelsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import BtnLoader from "../../../components/styled/BtnLoader/BtnLoader";
import { toast } from "react-toastify";
const AssignTeamAccessLevels = () => {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const {
    permissions,
    error,
    updateLoading,
    updateError,
    updateSuccess,
    delLoading,
    delError,
    delSuccess,
  } = useSelector((state) => state.permissions);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token && selectedTeamMember) {
      dispatch(getAllAccessLevels({ token }));
    }
    if(delSuccess || updateSuccess){
      setActiveLoading(null)
    }
  }, [token, dispatch, selectedTeamMember, delSuccess, updateSuccess]);

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      setActiveLoading(null)
    }
    if (delError) {
      toast.error(delError);
      setActiveLoading(null)
    }
  }, [delError, updateError]);
  const [activeLoading,setActiveLoading] = useState(null)
  return (
    <div className="outer select-access-levels">
      <div className="mb-20">
        <h1 className="team-member-details-head">Team Management</h1>
      </div>

      <div className="access-level-team-member-drop mb-20">
        <TeamMembersDrop
          label="Team Member"
          selectedMember={selectedTeamMember}
          setSelectedMember={setSelectedTeamMember}
          handleChange={() => {}}
        />
      </div>
      <h2 className="website-design-head mb-20">Select Access Level</h2>

      {conditionalRender(
        selectedTeamMember,
        conditionalRender(
          !error,
          <div>
            <table className="assign-access-levels-table">
              <thead>
                <tr>
                  <th>Features</th>
                  <th>Permissions</th>
                </tr>
              </thead>
              <tbody>
                {permissions?.items?.map((item) => {
                  return (
                    <>
                      <tr style={{ background: "#F4F6F8" }}>
                        <td>{item.name}</td>
                        <td>
                          <Flex align="center" gap={10}>
                            {(updateLoading || delLoading ) && item?.id === activeLoading ? (
                              <BtnLoader color="#666666" />
                            ) : (
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  setActiveLoading(item.id)
                                  if (e.target.checked) {
                                    dispatch(
                                      updateUserAccess({
                                        token,
                                        data: {
                                          user_id: selectedTeamMember.id,
                                          permission_id: item.id,
                                        },
                                      })
                                    );
                                  } else {
                                    dispatch(
                                      removeUserAccess({
                                        token,
                                        data: {
                                          user_id: selectedTeamMember.id,
                                          permission_id: item.id,
                                        },
                                      })
                                    );
                                  }
                                }}
                              />
                            )}
                          </Flex>
                        </td>
                      </tr>
                      {item?.permissions?.map((item) => {
                        return (
                          <tr>
                            <td>{item.name}</td>
                            <td>
                                <Flex align="center" gap={10}>
                                  {(updateLoading || delLoading) && item.id === activeLoading ? (
                                    <BtnLoader color="#666666" />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setActiveLoading(item.id)
                                          dispatch(
                                            updateUserAccess({
                                              token,
                                              data: {
                                                user_id: selectedTeamMember.id,
                                                permission_id: item.id,
                                              },
                                            })
                                          );
                                        } else {
                                          dispatch(
                                            removeUserAccess({
                                              token,
                                              data: {
                                                user_id: selectedTeamMember.id,
                                                permission_id: item.id,
                                              },
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </Flex>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>,

          <GlobalErrorHandler />
        ),
        <h2>Select a team member</h2>
      )}
    </div>
  );
};

export default AssignTeamAccessLevels;
