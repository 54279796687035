import React, { useEffect, useState } from "react";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Badge from "../../../components/styled/Badge/Badge";
import { useSelector, useDispatch } from "react-redux";
import {
  clearState,
  deleteBusinessRefferal,
  getAllBusinessReferrals,
} from "../../../features/ServiceProviderPortal/businessReferralsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import { handleUndefined } from "../../../utills/handleUndefined";
import { prettifyDate } from "../../../utills/prettifyDate";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import "./style.css";
import { toast } from "react-toastify";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";
const BusinessReferrals = () => {
  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  const { loading, error, referrals, delLoading, delError, delSuccess } =
    useSelector((state) => state.businessReferrals);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeResRow, setActiveResRow] = useState(1);
  useEffect(() => {
    if (token) {
      dispatch(
        getAllBusinessReferrals({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
      toast.success("Business referral deleted successfully", {
        position: "top-right",
      });
    }
  }, [token, dispatch, delSuccess, currentPage, itemsPerPage]);
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteRefferal = () => {
    dispatch(deleteBusinessRefferal({ token, id: delId }));
  };
  return (
    <div className="outer" style={{ position: "relative" }}>
      <div className="clients-container-top">
        <h1 className="edit-client-heading">Business Referrals</h1>
        <div className="clients-container-top-buttons">
          <span className="referral-bonus">
            'Earn <b>"£20"</b> for each referral of a <b>'Super'</b> account
          </span>
        </div>
      </div>
      {checkIfPermittedToSingleTab(permissions, 58, userData?.roles) && (
        <Flex align="center" justify="flex-end" className="mb-20">
          <Link to="/portal/add-business-referrals">
            <Button label="Add Referral" />
          </Link>
        </Flex>
      )}

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Business Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  {!(
                    !checkIfPermittedToSingleTab(
                      permissions,
                      57,
                      userData?.roles
                    ) &&
                    !checkIfPermittedToSingleTab(
                      permissions,
                      55,
                      userData?.roles
                    )
                  ) ? (
                    <TableHeadData>Action</TableHeadData>
                  ) : null}
                </TableRow>
              </TableHead>
              {conditionalRender(
                referrals?.items?.length > 0,
                <TableBody>
                  {referrals?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.id)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.name)}</TableText>
                        </TableBodyData>
                        <TableBodyData className="business-refferals-email">
                          <TableText>{item?.email}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            <Flex align="center" justify="center">
                              <RefferalBadge status={item?.status} />
                            </Flex>
                          </TableText>
                        </TableBodyData>

                        {!(
                          !checkIfPermittedToSingleTab(
                            permissions,
                            57,
                            userData?.roles
                          ) &&
                          !checkIfPermittedToSingleTab(
                            permissions,
                            55,
                            userData?.roles
                          )
                        ) ? (
                          <TableBodyData>
                            <Flex align="center" justify="center" gap={10}>
                              {checkIfPermittedToSingleTab(
                                permissions,
                                57,
                                userData?.roles
                              ) && (
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelPopup(true);
                                    setDelId(item.id);
                                  }}
                                />
                              )}

                              {checkIfPermittedToSingleTab(
                                permissions,
                                55,
                                userData?.roles
                              ) && (
                                <Link
                                  to={`/portal/edit-business-referral/${item?.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              )}
                            </Flex>
                          </TableBodyData>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No business referrals to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                referrals?.items?.length > 0,
                referrals?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>ID</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {handleUndefined(item?.id)}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Business Name
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {handleUndefined(item?.name)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {handleUndefined(item?.email)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.created_at)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                <Flex align="center">
                                  <RefferalBadge status={item?.status} />
                                </Flex>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          {/* <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Service Provider</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>Business</PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow> */}
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {" "}
                                <Flex align="center" gap={10}>
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setDelPopup(true);
                                      setDelId(item.id);
                                    }}
                                  />
                                  <Link
                                    to={`/portal/edit-business-referral/${item?.id}`}
                                  >
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                </Flex>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler
                  label={"No business referrals to show"}
                />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}
      {referrals?.meta?.totalItems > referrals?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={referrals?.meta?.totalPages}
          />
        )}

      {delPopup && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteRefferal}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

function RefferalBadge({ status }) {
  if (status === 36) {
    return <Badge tone="info">Sent</Badge>;
  }
  if (status === 35) {
    return <Badge tone="success">Redeemed</Badge>;
  }
  if (status === 37) {
    return <Badge tone="error">Expired</Badge>;
  }
}

export default BusinessReferrals;
