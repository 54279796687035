import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import Tabs from "../../../components/styled/Tabs/Tabs";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Badge from "../../../components/styled/Badge/Badge";
import Flex from "../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  deleteAppointment,
  getAllAppoinments,
  updateAppointnment,
} from "../../../features/ServiceProviderPortal/appoinmentSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import {
  convertToAMPM,
  deriveDayInfo,
  prettifyDate,
} from "../../../utills/prettifyDate";
import { concatNames } from "../../../utills/concatNames";
import Popup from "../../../components/styled/Popup/Popup";
import TeamMemberDropdown from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { transformAppointmentsByDateAndStatus } from "../../../utills/transformAppointments";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import { toast } from "react-toastify";
import CheckBox from "../../../components/Checkbox/Checkbox";
import TableTag from "../../../components/styled/TableTag/TableTag";
import { useForm } from "react-hook-form";
import { handleUndefined } from "../../../utills/handleUndefined";
const Appoinments = () => {
  const { token } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    appoinments,
    success,
    updateLoading,
    updateError,
    delLoading,
    delSuccess,
    delError,
  } = useSelector((state) => state.appoinments);
  const { register, handleSubmit, watch, reset } = useForm();
  const remove_assignment = watch("remove_assignment");
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const dispatch = useDispatch();
  const [sortByActiveOption, setSortByActiveOption] = useState("Monthly");
  const [viewActiveDropOption, setViewActiveDropOption] = useState("List");
  const [activeOption, setActiveOption] = useState("All");
  useEffect(() => {
    if (token) {
      dispatch(
        getAllAppoinments({
          token,
          query: {
            status: activeOption,
            currentPage,
            itemsPerPage,
          },
        })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
      toast.success("Appointment deleted successfully", {
        position: "top-right",
      });
    }
  }, [
    token,
    dispatch,
    activeOption,
    sortByActiveOption,
    success,
    delSuccess,
    currentPage,
    itemsPerPage,
  ]);

  useEffect(() => {
    if (success) {
      setAssignAppoinmentPopup(false);
      dispatch(clearState());
      if (remove_assignment) {
        toast.success("Appointment assignment successfully removed", {
          position: "top-right",
        });
      } else {
        toast.success("Appointment assigned successfully", {
          position: "top-right",
        });
      }
      setSelectedMember(null);
      reset({
        remove_assignment: false,
      });
    }
  }, [
    reset,
    success,
    dispatch,
    token,
    activeOption,
    sortByActiveOption,
    remove_assignment,
  ]);

  useEffect(() => {
    if (viewActiveDropOption === "List") {
      setCalenderView(false);
      setItemsPerPage(5);
    }
    if (viewActiveDropOption === "Calender") {
      setCalenderView(true);
      setItemsPerPage(30);
    }
  }, [viewActiveDropOption]);

  const [calenderView, setCalenderView] = useState(false);
  const [assignId, setAssignId] = useState(null);
  const [assignAppoinmentPopup, setAssignAppoinmentPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [dropError, setDropError] = useState(null);
  const handleAssignAppoinment = (values) => {
    if (selectedMember && values.remove_assignment) {
      setDropError("Please Select any one");
    } else {
      if (!selectedMember) {
        if (!values.remove_assignment) {
          setDropError("Please Select");
        } else {
          dispatch(
            updateAppointnment({
              token,
              data: { user_id: null },
              id: assignId,
            })
          );
        }
      } else {
        dispatch(
          updateAppointnment({
            token,
            data: { user_id: selectedMember.id },
            id: assignId,
          })
        );
      }
    }
  };

  const handleDeleteAppointment = () => {
    dispatch(deleteAppointment({ token, id: delId }));
  };
  const [calenderViewAppointments, setCalenderViewAppointments] = useState([]);
  useEffect(() => {
    if (appoinments) {
      const transformed = transformAppointmentsByDateAndStatus(
        appoinments.items
      );
      setCalenderViewAppointments(transformed);
    }
  }, [appoinments]);

  const calculateServiceCost = (services) => {
    let cost = 0;
    services.forEach((service) => (cost += service.cost));
    return cost;
  };

  const [activeResRow, setActiveResRow] = useState(1);

  return (
    <div className="appoinments-container" style={{ position: "relative" }}>
      <div className="clients-container-top appointments-top-container">
        <h1 className="edit-client-heading">Appointments</h1>
        <div className="clients-container-top-buttons">
          {/* <div className="appointment-sort-containers">
            <span className="appoinment-view">Sort By</span>
            <PortalDrop
              options={["Monthly", "Weekly"]}
              active={sortByActiveOption}
              setAcvtive={setSortByActiveOption}
            />
          </div> */}
          {/* <div className="appointment-sort-containers">
            <span className="appoinment-view">View</span>
            <PortalDrop
              options={["List", "Calender"]}
              active={viewActiveDropOption}
              setAcvtive={setViewActiveDropOption}
            />
          </div> */}
          {/* <Button label="Calendar View" variant="outline" /> */}
          {/* <Link to="/portal/add-appointment">
            <Button label="Add Appointment" />
          </Link> */}
        </div>
      </div>

      <Tabs
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        options={["All", "Completed", "Upcoming", "No Show", "Cancelled"]}
        width={900}
      />

      {conditionalRender(
        loading,
        <PageLoader />,

        conditionalRender(
          !error,
          <>
            {calenderView ? (
              <div className="appoinments-calender-view-outer">
                {calenderViewAppointments &&
                  conditionalRender(
                    calenderViewAppointments?.length > 0,
                    calenderViewAppointments.map((appointment, index) => {
                      return (
                        <Link
                          to={`/portal/appointment-calender?date=${appointment?.day}`}
                        >
                          <AppoinmentsBadge data={appointment} key={index} />
                        </Link>
                      );
                    }),
                    <Flex align="center" justify="center" className="w-100">
                      <GlobalEmptyDataHandler
                        label="No appointments to show"
                        position="static"
                      />
                    </Flex>
                  )}
              </div>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadData>ID</TableHeadData>
                      <TableHeadData>Name</TableHeadData>
                      <TableHeadData>Service Provider</TableHeadData>
                      <TableHeadData>Date</TableHeadData>
                      <TableHeadData>Service(s)</TableHeadData>
                      <TableHeadData>Cost</TableHeadData>
                      <TableHeadData>Status</TableHeadData>
                      {/* <TableHeadData>Action</TableHeadData> */}
                    </TableRow>
                  </TableHead>
                  {conditionalRender(
                    appoinments?.items?.length > 0,
                    <TableBody>
                      {appoinments?.items.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableBodyData>
                              <Link
                                to={`/admin-portal/appoinment-detail/${item?.id}`}
                              >
                                <TableText variant="link">{item?.id}</TableText>
                              </Link>
                            </TableBodyData>
                            <TableBodyData>
                              <Link
                                to={`/admin-portal/client-details/${item?.client_id?.id}`}
                              >
                                <TableText variant="link">
                                  {conditionalRender(
                                    item?.client_id?.first_name,
                                    concatNames(
                                      item?.client_id?.first_name,
                                      item?.client_id?.last_name
                                    ),
                                    "-"
                                  )}
                                </TableText>
                              </Link>
                            </TableBodyData>
                            <TableBodyData>
                                <TableText>
                                  {handleUndefined(item?.business_id?.business_name)}
                                </TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>
                                {conditionalRender(
                                  item?.date,
                                  prettifyDate(item?.date),
                                  "-"
                                )}
                                {", "}
                                {convertToAMPM(item?.time)}
                              </TableText>
                            </TableBodyData>
                            <TableBodyData className="appointment-services-data">
                              <Flex
                                className="appointment-services-tags"
                                align="center"
                                justify="center"
                              >
                                {item?.services.map((item) => (
                                  <TableTag>{item.name}</TableTag>
                                ))}
                              </Flex>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>
                                £{calculateServiceCost(item.services)}
                              </TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <Flex align="center" justify="center">
                                <Badge
                                  tone={
                                    item?.status === 25
                                      ? "error"
                                      : item?.status === 22
                                      ? "success"
                                      : item?.status === 24
                                      ? "no-show"
                                      : "info"
                                  }
                                >
                                  {item?.status === 25
                                    ? "Cancelled"
                                    : item?.status === 22
                                    ? "Completed"
                                    : item?.status === 24
                                    ? "No Show"
                                    : "Upcoming"}
                                </Badge>
                              </Flex>
                            </TableBodyData>
                            {/* <TableBodyData>
                              <Flex align="center" justify="center" gap={10}>
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelPopup(true);
                                    setDelId(item?.id);
                                  }}
                                />
                                <Link
                                  to={`/portal/edit-appointment/${item.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                                <Button
                                  label={conditionalRender(
                                    item?.is_assigned,
                                    "Re Assign",
                                    "Assign"
                                  )}
                                  variant="outline-sm"
                                  handleClick={() => {
                                    setAssignId(item?.id);
                                    setAssignAppoinmentPopup(true);
                                  }}
                                />
                              </Flex>
                            </TableBodyData> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>,
                    <GlobalEmptyDataHandler label="No Appoinments to show" />
                  )}
                </Table>
                <ResponsiveContainer>
                  {conditionalRender(
                    appoinments?.items?.length > 0,
                    appoinments?.items?.map((item, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }}>
                          <PhoneTable>
                            <PhoneTableHead
                              onClick={() => setActiveResRow(index + 1)}
                            >
                              <PhoneTableRow>
                                <PhoneTableHeadData>
                                  <PhoneTableBoldText>Id</PhoneTableBoldText>
                                </PhoneTableHeadData>
                                <PhoneTableHeadData>
                                  <PhoneTableBoldText>
                                    {conditionalRender(item.id, item.id, "-")}
                                  </PhoneTableBoldText>
                                </PhoneTableHeadData>
                              </PhoneTableRow>
                            </PhoneTableHead>
                            <PhoneTableBody
                              className={`${
                                index + 1 === activeResRow
                                  ? "responsive-table-body-show"
                                  : "responsive-table-body-hide"
                              }`}
                            >
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>Name</PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <Link
                                    to={`/portal/appoinment-detail/${item?.id}`}
                                  >
                                    <PhoneTableSimpleText variant="link">
                                      {conditionalRender(
                                        item?.client_id?.first_name,
                                        concatNames(
                                          item?.client_id?.first_name,
                                          item?.client_id?.last_name
                                        ),
                                        "-"
                                      )}
                                    </PhoneTableSimpleText>
                                  </Link>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>Date</PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData isEmail={true}>
                                  <PhoneTableSimpleText>
                                    {conditionalRender(
                                      item?.date,
                                      prettifyDate(item?.date),
                                      "-"
                                    )}
                                  </PhoneTableSimpleText>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>
                                    Cell No
                                  </PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <PhoneTableSimpleText>
                                    {conditionalRender(
                                      item?.client_id?.phone,
                                      item?.client_id?.phone,
                                      "-"
                                    )}
                                  </PhoneTableSimpleText>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>
                                    Services
                                  </PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <PhoneTableSimpleText>
                                    <Flex
                                      className="appointment-services-tags"
                                      align="center"
                                      justify="flex-start"
                                    >
                                      {item?.services.map((item) => (
                                        <TableTag>{item.name}</TableTag>
                                      ))}
                                    </Flex>
                                  </PhoneTableSimpleText>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>Cost</PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <PhoneTableSimpleText>
                                    £{calculateServiceCost(item.services)}
                                  </PhoneTableSimpleText>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>
                                    Actions
                                  </PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <Flex align="center" justify="flex-start">
                                    <Badge
                                      tone={
                                        item?.status === 25
                                          ? "error"
                                          : item?.status === 22
                                          ? "success"
                                          : item?.status === 24
                                          ? "no-show"
                                          : "info"
                                      }
                                    >
                                      {item?.status === 25
                                        ? "Cancelled"
                                        : item?.status === 22
                                        ? "Completed"
                                        : item?.status === 24
                                        ? "No Show"
                                        : "Upcoming"}
                                    </Badge>
                                  </Flex>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                              <PhoneTableRow>
                                <PhoneTableBodyData>
                                  <PhoneTableBoldText>
                                    Actions
                                  </PhoneTableBoldText>
                                </PhoneTableBodyData>
                                <PhoneTableBodyData>
                                  <Flex align="center" gap={10}>
                                    <img
                                      src="/icons/delete.png"
                                      alt="edit"
                                      onClick={() => {
                                        setDelPopup(true);
                                        setDelId(item?.id);
                                      }}
                                    />
                                    <Link
                                      to={`/portal/edit-appointment/${item.id}`}
                                    >
                                      <img src="/icons/edit.png" alt="edit" />
                                    </Link>
                                    <Button
                                      label={conditionalRender(
                                        item?.is_assigned,
                                        "Re Assign",
                                        "Assign"
                                      )}
                                      variant="outline-sm"
                                      handleClick={() => {
                                        setAssignId(item?.id);
                                        setAssignAppoinmentPopup(true);
                                      }}
                                    />
                                  </Flex>
                                </PhoneTableBodyData>
                              </PhoneTableRow>
                            </PhoneTableBody>
                          </PhoneTable>
                          <div className="global-responsive-table-chevron">
                            <ChevronDown
                              className={`${
                                index + 1 === activeResRow
                                  ? "table-chevron-rotate"
                                  : "table-chevron-rerotate"
                              }`}
                            />
                          </div>
                        </div>
                      );
                    }),
                    <GlobalEmptyDataHandler label={"No Appointments To Show"} />
                  )}
                </ResponsiveContainer>
              </>
            )}
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {appoinments?.meta?.totalItems > appoinments?.meta?.itemsPerPage &&
        !calenderView &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={appoinments?.meta?.totalPages}
          />
        )}
      {assignAppoinmentPopup && (
        <Popup
          className="assign-appoinment-popup"
          popUp={assignAppoinmentPopup}
          setPopUp={setAssignAppoinmentPopup}
        >
          <form onSubmit={handleSubmit(handleAssignAppoinment)}>
            <div className="assign-appoinment-popup-inner">
              <TeamMemberDropdown
                label="Select Member to Assign"
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
                handleChange={() => {}}
              />
            </div>
            <div>
              <p
                className="global-input-error mb-10"
                style={{ textAlign: "left" }}
              >
                {dropError && dropError}
              </p>
            </div>

            <div className="mb-20">
              <CheckBox
                register={register}
                name="remove_assignment"
                label="Remove Assignment"
                id="remove_assignment"
              />
            </div>
            <Flex align="center" justify="flex-end" gap={15}>
              <Button
                label="cancel"
                handleClick={() => {
                  setAssignId(null);
                  setAssignAppoinmentPopup(false);
                }}
                variant="outline"
              />
              <Button
                label={`${remove_assignment ? "Remove" : "Assign"}`}
                type="submit"
                loading={updateLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}

      {updateError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={updateError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteAppointment}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
    </div>
  );
};

function AppoinmentsBadge({ data }) {
  const { dayOfWeek, dayOfMonth } = deriveDayInfo(data?.day);
  return (
    <div className="appoinments-badge">
      <Flex
        className="appoinments-badge-top"
        align="center"
        justify="space-between"
      >
        <span>{dayOfWeek}</span>
        <div
          className={`appoinments-badge-btn ${
            data.status === "23"
              ? "appoinments-badge-new"
              : data.status === "25"
              ? "appoinments-badge-cancelled"
              : data.status === "22"
              ? "appoinments-badge-completed"
              : "appoinments-badge-no-show"
          }`}
        >
          <Flex align="center" justify="center">
            {data.status === "23"
              ? "Upcoming"
              : data.status === "25"
              ? "Cancelled"
              : data.status === "22"
              ? "Completed"
              : "No Show"}
          </Flex>
        </div>
      </Flex>
      <Flex
        align="center"
        justify="space-between"
        className={`appoinments-badge-lower ${
          data.status === "23"
            ? "appoinments-badge-lower-new"
            : data.status === "25"
            ? "appoinments-badge-lower-cancelled"
            : data.status === "22"
            ? "appoinments-badge-lower-completed"
            : "appoinments-badge-lower-no-show"
        }`}
      >
        <span>{data.bookings} Booking(s)</span>
        <span>{dayOfMonth}</span>
      </Flex>
    </div>
  );
}

export default Appoinments;
