import React, { useEffect, useState } from "react";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";

import {
  addSubscription,
  clearState,
} from "../../../features/ServiceProviderPortal/SubscriptionsSlice";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ServiceProviderDropDown from "../../../components/Portal/ServiceProviderDropDown/ServiceProviderDropDown";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";

const AddSubscription = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { token } = useSelector((state) => state.auth);
  const { success } = useSelector((state) => state.subscriptions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  const [serviceProviderError, setServiceProviderError] = useState(null);

  const handleAddSubscription = (values) => {
    if (!selectedServiceProvider) {
      setServiceProviderError("Please select service provider");
    } else {
      setServiceProviderError(null);
      values.business_id = selectedServiceProvider.id;
      dispatch(addSubscription({ token, data: { ...values } }));
    }
  };
  useEffect(() => {
    if (success) {
      navigate("/admin-portal/subscriptions");
      dispatch(clearState());
    }
  }, [success, navigate, dispatch]);
  return (
    <div className="outer">
      <Link to={"/admin-portal/subscriptions"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Add Subscription</h1>
      <div className="mt-20">
        <form onSubmit={handleSubmit(handleAddSubscription)}>
          <div className="add-team-member-inputs">
            <Flex className="add-team-member-input" direction="column" gap={10}>
              <ServiceProviderDropDown
                label="Service Provider"
                selectedServiceProvider={selectedServiceProvider}
                setSelectedServiceProvider={setSelectedServiceProvider}
              />
              <p className="global-input-error">
                {serviceProviderError && serviceProviderError}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">First Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">Last Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email *</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"Enter Email"}
                  {...register("email", {
                    required: "Please Enter Email",
                    pattern: emailPattern,
                  })}
                  id="email"
                />
                <img src="/icons/mail.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="phone">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"Enter phone number"}
                  {...register("phone", {
                    required: "Please Enter Phone Number",
                    pattern: phonePattern,
                  })}
                  id="phone"
                />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.phone && errors?.phone?.message}
              </p>
            </Flex>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Add Subscription"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/subscriptions">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSubscription;
