export const convBoolToNumber = (value) => {
  if (value) {
    return 1;
  } else {
    return 0;
  }
};

export function groupServicesByCategory(data) {
  const groupedData = [];

  data.forEach((item) => {
    if (
      item.category_name &&
      item.service_name &&
      item.total_revenue !== null
    ) {
      let category = groupedData.find(
        (cat) => cat.category_name === item.category_name
      );

      if (!category) {
        category = {
          category_name: item.category_name,
          services: [],
        };
        groupedData.push(category);
      }
      category.services.push({
        name: item.service_name,
        cost: item.total_revenue,
      });
    }
  });

  return groupedData;
}
