import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  referrals: null,
  dropDown: null,
  addLoading: false,
  addError: null,
  addSuccess: false,
  updateSuccess: false,
  updateLoading: false,
  updateError: null,
  delLoading: false,
  delError: null,
  delSuccess: false,
  getLoading:false,
  getError:null,
  refferal:null
};

export const businessReferralsSlice = createSlice({
  name: "businessReferrals",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addError = null;
      state.addSuccess = false;
      state.delSuccess = false;
      state.delError = null;
      state.updateError = null;
      state.updateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBusinessReferrals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBusinessReferrals.fulfilled, (state, action) => {
      state.loading = false;
      state.referrals = action.payload;
    });
    builder.addCase(getAllBusinessReferrals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleBusinessRefferal.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getSingleBusinessRefferal.fulfilled, (state, action) => {
      state.getLoading = false;
      state.refferal = action.payload;
    });
    builder.addCase(getSingleBusinessRefferal.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
    builder.addCase(getBusinessRefferalDrop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBusinessRefferalDrop.fulfilled, (state, action) => {
      state.loading = false;
      state.dropDown = action.payload;
    });
    builder.addCase(getBusinessRefferalDrop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addBusinessRefferal.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addBusinessRefferal.fulfilled, (state) => {
      state.addSuccess = true;
      state.addLoading = false;
    });
    builder.addCase(addBusinessRefferal.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });
    builder.addCase(updateBusinessRefferal.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateBusinessRefferal.fulfilled, (state) => {
      state.updateSuccess = true;
      state.updateLoading = false;
    });
    builder.addCase(updateBusinessRefferal.rejected, (state, action) => {
      state.addError = action.payload;
      state.updateLoading = false;
    });

    builder.addCase(deleteBusinessRefferal.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteBusinessRefferal.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteBusinessRefferal.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllBusinessReferrals = createAsyncThunk(
  "businessReferrals",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_refferals/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);
export const getSingleBusinessRefferal = createAsyncThunk(
  "getSingleBusinessRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/business_refferals/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);

export const getBusinessRefferalDrop = createAsyncThunk(
  "getBusinessRefferalDrop",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/3`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addBusinessRefferal = createAsyncThunk(
  "addBusinessRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/business_refferals/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteBusinessRefferal = createAsyncThunk(
  "deleteBusinessRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/business_refferals/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateBusinessRefferal = createAsyncThunk(
  "updateBusinessRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/business_refferals/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = businessReferralsSlice.actions;
