import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useForm } from "react-hook-form";
import Flex from "../../../components/styled/Flex/Flex";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import { emailPattern } from "../../../utills/Schems";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  clearState,
  addClientReferral,
  getClientRefferalsDropDown,
  updateClientRefferal,
} from "../../../features/ServiceProviderPortal/referralSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
const AddClientReferrals = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, success, dropDownLoading, dropDownError, dropDown } =
    useSelector((state) => state.referrals);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [dropError, setDropError] = useState(null);
  const [status, setStatus] = useState(null);
  const handleAddClientRef = (values) => {
    const apiData = {};
    if (from) {
      apiData.from = from.id;
    }
    if (to) {
      apiData.to = to.id;
    }
    if (status) {
      apiData.status = status.id;
    }
    dispatch(updateClientRefferal({ token, data: { ...apiData }, id }));
  };

  useEffect(() => {
    if (success) {
      dispatch(clearState());
      navigate("/portal/client-referrals");
    }
  }, [success, navigate, dispatch]);
  useEffect(() => {
    dispatch(getClientRefferalsDropDown({ token }));
  }, [token, dispatch]);

  return (
    <div className="outer">
      <Link
        to={"/portal/client-referrals"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Edit Referral</h1>
      {conditionalRender(
        dropDownLoading,
        <PageLoader />,
        conditionalRender(
          !dropDownError,
          <form onSubmit={handleSubmit(handleAddClientRef)}>
            <div className="add-client-refs-inputs">
              <div className="add-client-refs-input">
                <TeamMembersDrop
                  label="From"
                  selectedMember={from}
                  setSelectedMember={setFrom}
                  handleChange={() => {}}
                />
                <p className="global-input-error">
                  {dropError && !from && dropError}
                </p>
              </div>
              <div className="add-client-refs-input">
                <ClientsDropDown
                  label="To"
                  setSelectedCLient={setTo}
                  selectedClient={to}
                  handleChange={() => {}}
                />
                <p className="global-input-error">
                  {dropError && !to && dropError}
                </p>
              </div>
              <div className="add-client-refs-input">
                <GlobalDropDown
                  label="Status"
                  stateHandler={status}
                  setStateHandler={setStatus}
                  options={dropDown}
                  icon="/icons/user.png"
                />
              </div>
            </div>

            <div className="edit-client-btns">
              {/* <button className="edit-client-save">Save</button> */}
              <Button
                variant="primary"
                label={"Add Referral"}
                type="submit"
                loaderColor="#fff"
                loading={loading}
              />
            </div>
          </form>,
          <h1>{dropDownError}</h1>
        )
      )}

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddClientReferrals;
