import React, { useEffect, useState } from "react";
import "./AdminPortalLayout.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Portal/Navbar/Navbar";
import SideBar from "../../../components/Portal/Sidebar/SideBar";
import SideBarSingleMenu from "../../../components/Portal/Sidebar/SideBarSingleMenu/SideBarSingleMenu";
import SideBarDropdownMenu from "../../../components/Portal/Sidebar/SideBarDropdownMenu/SideBarDropdownMenu";
import { Outlet } from "react-router-dom";
import { getActiveTab } from "../../../utills/activeTabs";
import { useSelector } from "react-redux";

const AdminPortalLayout = () => {
  const location = useLocation();
  const [sideBarActive, setSideBarActive] = useState(false);
  const {userData} = useSelector(state => state.auth)
  const navigate = useNavigate()
  useEffect(()=>{
    if(userData?.roles[0].name === 'Business Owner'){
      navigate('/portal/dashboard')
    }
  },[userData,navigate])
  return (
    <div>
      <Navbar
        setSideBarActive={setSideBarActive}
        notificationsLink="/admin-portal/notifications"
        profileSettingsLink="/admin-portal/profile-settings"
      />
      <SideBar
        sideBarActive={sideBarActive}
        setSideBarActive={setSideBarActive}
      >
        <SideBarSingleMenu
          label="Dashboard"
          link="/admin-portal/dashboard"
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/dashboard",
          ])}
          setSideBarActive={setSideBarActive}
        />
        <SideBarDropdownMenu
          label="Service Providers"
          setSideBarActive={setSideBarActive}
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/service-providers",
            "/admin-portal/businesses",
            "/admin-portal/scheduling",
            "/admin-portal/invoice-payments",
            "/admin-portal/add-service-provider",
            "/admin-portal/edit-service-provider",
            "/admin-portal/add-schedule",
            "/admin-portal/edit-schedule",
            /^\/admin-portal\/add-service-provider\/\d+$/,
            /^\/admin-portal\/edit-service-provider\/\d+$/,
            /^\/admin-portal\/scheduling\/\d+$/,
            /^\/admin-portal\/add-scheduling\/\d+$/,
            /^\/admin-portal\/edit-scheduling\/\d+$/,
          ])}
          options={[
            {
              title: "Businesses",
              link: "/admin-portal/service-providers",
              isActive: getActiveTab(location.pathname, [
                "/admin-portal/service-providers",
                "/admin-portal/add-service-provider",
                "/admin-portal/edit-service-provider",
                /^\/admin-portal\/edit-service-provider\/\d+$/,
                /^\/portal\/businesses-details\/\d+$/,
              ]),
            },

            {
              title: "Scheduling",
              link: "/admin-portal/scheduling",
              isActive: getActiveTab(location.pathname, [
                "/admin-portal/scheduling",
                "/admin-portal/add-schedule",
                "/admin-portal/edit-schedule",
                /^\/portal\/invoice-details\/\d+$/,
                /^\/portal\/edit-invoice\/\d+$/,
              ]),
            },
          ]}
          dropHeight="200px"
        />
        <SideBarSingleMenu
          label="Clients"
          link="/admin-portal/service-provider-clients"
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/service-provider-clients",
            "/admin-portal/add-client",
            "/admin-portal/edit-client",
            "/admin-portal/client-details",
          ])}
          setSideBarActive={setSideBarActive}
        />

        <SideBarDropdownMenu
          label="Main Website"
          setSideBarActive={setSideBarActive}
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/blogs",
            "/admin-portal/blog-details",
            "/admin-portal/testimonials",
            "/admin-portal/pricing",
            "/admin-portal/add-pricing",
            "/admin-portal/edit-pricing",
            "/admin-portal/add-blog",
            "/admin-portal/edit-blog",
            "/admin-portal/view-blog",
            "/admin-portal/add-testimonial",
            "/admin-portal/edit-testimonial",
            "/admin-portal/featured-blogs",
            // /^\/portal\/team\/details\/\d+$/,
            // /^\/portal\/edit-team-member\/\d+$/,
            // /^\/portal\/edit-service\/\d+$/,
          ])}
          options={[
            {
              title: "Blogs",
              link: "/admin-portal/blogs",
              isActive: getActiveTab(location.pathname, [
                "/admin-portal/blogs",
                "/admin-portal/blog-details",
                "/admin-portal/add-blog",
                "/admin-portal/edit-blog",
                "/admin-portal/featured-blogs",
              ]),
            },
            {
              title: "Testimonials",
              link: "/admin-portal/testimonials",
              isActive: getActiveTab(location.pathname, [
                "/admin-portal/testimonials",
                "/admin-portal/add-testimonial",
                "/admin-portal/edit-testimonial",
              ]),
            },
            {
              title: "Pricing",
              link: "/admin-portal/pricing",
              isActive: getActiveTab(location.pathname, [
                "/admin-portal/pricing",
                "/admin-portal/add-pricing",
                "/admin-portal/edit-pricing",
              ]),
            },
          ]}
          dropHeight="300px"
        />
        <SideBarSingleMenu
          label="Subscriptions"
          link="/admin-portal/subscriptions"
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/subscriptions",
            "/admin-portal/add-subscription",
            "/admin-portal/edit-subscription",
          ])}
          setSideBarActive={setSideBarActive}
        />

        <SideBarSingleMenu
          label="Referrals"
          link="/admin-portal/referrals"
          isActive={getActiveTab(location.pathname, [
            "/admin-portal/referrals",
            "/admin-portal/add-referral",
            "/admin-portal/edit-referral",
          ])}
          setSideBarActive={setSideBarActive}
        />
      </SideBar>
      <div className="portal-layout-right-side">
        <Outlet />
      </div>
      <div
        className={`portal-blackout-screen ${
          sideBarActive ? "portal-blackout-show" : "portal-blackout-hide"
        }`}
      ></div>
    </div>
  );
};

export default AdminPortalLayout;
