
const stage = 'prod'
export const BASE_URL = "https://bizopspro.com/api";
export const FRONTEND_BASE_URL = stage==='dev'?'http://localhost:3000':'https://bizopspro.com'
export const globalInitialState = {
  loading: false,
  error: null,
  addLoading: false,
  addError: null,
  addSuccess: false,
  delLoading: false,
  delError: null,
  delSuccess: false,
  updateLoading: false,
  updateSuccess: false,
  updateError: null,
  getLoading:false,
  getError:null,
};

export const globalClearState = (state) => {
  state.addError = null;
  state.delError = null;
  state.addSuccess = false;
  state.delSuccess = false;
  state.updateError = null;
  state.updateSuccess = false;
};

export const throwError = (error,rejectWithValue)=>{
  if(error?.response?.data?.message){
    return rejectWithValue(error?.response?.data?.message)
  }else{
    return rejectWithValue("Some error occured please try again later")
  }
}