import React from "react";
import "./Checkbox.css";

const Checkbox = ({ id, label, register, name,checked=0 }) => {
  return (
    <div className="checkbox-component">
      <input type="checkbox" id={id} {...register(name)} defaultChecked={checked}/>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
