
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  settings: null,
  setting: null,
  dropDown: null,
  addLoading: false,
  addError: null,
  delLoading: false,
  delError: null,
  delSuccess: false,
  getLoading: false,
  getError: null,
};

export const appointmentSettingsSlice = createSlice({
  name: "appointmentSettingsSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addError = null;
      state.delSuccess = false;
      state.delError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAppointmentSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAppointmentSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.settings = action.payload;
    });
    builder.addCase(getAllAppointmentSettings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAppointemtSettings.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getAppointemtSettings.fulfilled, (state, action) => {
      state.getLoading = false;
      state.settings = action.payload;
    });
    builder.addCase(getAppointemtSettings.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
    builder.addCase(getAppointemtDrop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppointemtDrop.fulfilled, (state, action) => {
      state.loading = false;
      state.dropDown = action.payload;
    });
    builder.addCase(getAppointemtDrop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addAppointmentSetting.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addAppointmentSetting.fulfilled, (state) => {
      state.success = true;
      state.addLoading = false;
    });
    builder.addCase(addAppointmentSetting.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });
    builder.addCase(updateAppointmentSetting.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(updateAppointmentSetting.fulfilled, (state) => {
      state.success = true;
      state.addLoading = false;
    });
    builder.addCase(updateAppointmentSetting.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });

    builder.addCase(deleteAppointmentSetting.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteAppointmentSetting.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteAppointmentSetting.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllAppointmentSettings = createAsyncThunk(
  "getAllAppointmentSettings",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointment_settings/manage?page=1&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);

export const getAppointemtDrop = createAsyncThunk(
  "getBusinessRefferalDrop",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/13`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addAppointmentSetting = createAsyncThunk(
  "addAppointmentSetting",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/appointment_settings/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      console.log(data.result);
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteAppointmentSetting = createAsyncThunk(
  "deleteAppointmentSetting",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/appointment_settings/remove/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateAppointmentSetting = createAsyncThunk(
  "updateAppointmentSetting",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/appointment_settings/update`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getAppointemtSettings = createAsyncThunk(
  "getAppointemtSettings",
  async (token, { rejectWithValue }) => {
    try {
      if (!token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/appointment_settings/view`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      } else {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } 
      }
    }
  }
);

export const { clearState } = appointmentSettingsSlice.actions;
