import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import StandardTemplateContact from '../StandardTemplate/Contact/Contact'
import { authorizeRole, BUSINESS_ADMIN } from "../../../utills/authorizeRole";

const WebsiteContact = ({color}) => {
  const { loading, data } = useSelector((state) => state.websiteStyles);
  const {token,userData} = useSelector(state => state.auth)

  const isEditAccess = useMemo(()=>{
    if(token && data.business_id.id === userData.business_id.id && authorizeRole(userData.roles,BUSINESS_ADMIN)){
      return true
    }else{
      return false
    }
  },[token,data?.business_id,userData?.business_id,userData?.roles])
  if (loading) {
    return <PageLoader />;
  }
  return <StandardTemplateContact color={color} data={data} isEditAccess={isEditAccess}/>;
};

export default WebsiteContact;
