import React, { useEffect, useState } from "react";
import "./AddServiceProviderClient.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  addClient,
  clearState,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";
import ServiceProviderDropDown from "../../../components/Portal/ServiceProviderDropDown/ServiceProviderDropDown";
import { toast } from "react-toastify";

const AddServiceProviderClient = () => {
  const { token } = useSelector((state) => state.auth);
  const { addLoading, error, addSuccess } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [serviceProviderError, setServiceProviderError] = useState(null)
  useEffect(() => {
    if (addSuccess) {
      toast.success("Client Added Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/admin-portal/service-provider-clients");
    }
    if(error){
      toast.error(error, {
        position: "top-right",
      });
      dispatch(clearState())
    }
  }, [error, addSuccess, navigate, dispatch]);

  const handleAddClient = (values) => {
    if(!selectedServiceProvider){
      setServiceProviderError('Please select service provider')
    }
    else{
      setServiceProviderError(null)
      console.log(values);
      values.business_id = selectedServiceProvider.id
      const _apiData = {
        token,
        data: { ...values },
      };
      dispatch(addClient(_apiData));
    }

   
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  // const dropOptions = [{ name: "XYZ" }, { name: "ABC" }];
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  return (
    <div className="outer">
      <Link to={"/admin-portal/service-provider-clients"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Add Client</h1>
      <div className="mt-20">
        <form onSubmit={handleSubmit(handleAddClient)}>
          <div className="add-team-member-inputs">
            <Flex className="add-team-member-input" direction="column" gap={10}>
              {/* <GlobalDropDown
                options={dropOptions}
                label="Associated Service Provider*"
                icon="/icons/appointments.png"
                stateHandler={selectedServiceProvider}
                setStateHandler={setSelectedServiceProvider}
              /> */}
              <ServiceProviderDropDown
                label="Associated Service Provider"
                selectedServiceProvider={selectedServiceProvider}
                setSelectedServiceProvider={setSelectedServiceProvider}
              />
               <p className="global-input-error">
                {serviceProviderError && serviceProviderError}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">First Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="last_name">Last Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="last_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email *</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"Enter Email"}
                  {...register("email", {
                    required: "Please Enter Email",
                    pattern: emailPattern,
                  })}
                  id="email"
                />
                <img src="/icons/mail.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-client-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="phone">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"Enter phone number"}
                  {...register("phone", {
                    required: "Please Enter Phone Number",
                    min: {value:0, message:"Number cannot start with (-)"},
                    pattern: phonePattern,
                  })}
                  id="phone"
                />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.phone && errors?.phone?.message}
              </p>
            </Flex>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Add"}
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
              <Link to="/admin-portal/service-provider-clients">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddServiceProviderClient;
