import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  titles: null,
  title: null,
  addLoading: false,
  addError: null,
  addSuccess: false,
  delLoading: false,
  delError: null,
  delSuccess: false,
  updateLoading:false,
  updateError:null,
  updateSuccess:false,
  dropdown:null,
  dropLoading:false,
  dropError:null
};

export const jobTitleSlice = createSlice({
    name: "jobTitleSlice",
    initialState,
    reducers: {
      clearState: (state) => {
        state.error = null;
        state.success = false;
        state.addSuccess = false;
        state.addError = null;
        state.delSuccess = false;
        state.delError = null;
        state.updateSuccess = false;
        state.updateError = null
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllJobTitles.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getAllJobTitles.fulfilled, (state, action) => {
        state.loading = false;
        state.titles = action.payload;
      });
      builder.addCase(getAllJobTitles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      builder.addCase(addJobTitle.pending, (state) => {
        state.addLoading = true;
      });
      builder.addCase(addJobTitle.fulfilled, (state) => {
        state.addSuccess = true;
        state.addLoading = false;
      });
      builder.addCase(addJobTitle.rejected, (state, action) => {
        state.addError = action.payload;
        state.addLoading = false;
      });
      builder.addCase(updateJobTitle.pending, (state) => {
        state.updateLoading = true;
      });
      builder.addCase(updateJobTitle.fulfilled, (state) => {
        state.updateSuccess = true;
        state.updateLoading = false;
      });
      builder.addCase(updateJobTitle.rejected, (state, action) => {
        state.updateError = action.payload;
        state.updateLoading = false;
      });
  
      builder.addCase(deleteJobTitle.pending, (state) => {
        state.delLoading = true;
      });
      builder.addCase(deleteJobTitle.fulfilled, (state) => {
        state.delLoading = false;
        state.delSuccess = true;
      });
      builder.addCase(deleteJobTitle.rejected, (state, action) => {
        state.delLoading = false;
        state.delError = action.payload;
      });
      builder.addCase(getJobTitleDropdown.pending, (state) => {
        state.dropLoading = true;
      });
      builder.addCase(getJobTitleDropdown.fulfilled, (state,action) => {
        state.dropLoading = false;
        state.dropdown = action.payload;
      });
      builder.addCase(getJobTitleDropdown.rejected, (state, action) => {
        state.dropLoading = false;
        state.dropError = action.payload;
      });
    },
  });
  
  export const getAllJobTitles = createAsyncThunk(
    "getAllJobTitles",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized");
        }
        const { data } = await axios.get(
          `${BASE_URL}/v1/jobs_title/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        }
  
        return rejectWithValue("Some error occurred please try again");
      }
    }
  );
  
  
  export const addJobTitle = createAsyncThunk(
    "addJobTitle",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        const { data } = await axios.post(
          `${BASE_URL}/v1/jobs_title/create`,
          apiData.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const deleteJobTitle = createAsyncThunk(
    "deleteJobTitle",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized");
        }
        if (!apiData.id) {
          return rejectWithValue("Please Provide id");
        }
        const { data } = await axios.delete(
          `${BASE_URL}/v1/jobs_title/delete/${apiData.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const updateJobTitle = createAsyncThunk(
    "updateJobTitle",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        if (!apiData.id) {
          return rejectWithValue("Please provide id");
        }
        const { data } = await axios.patch(
          `${BASE_URL}/v1/jobs_title/update/${apiData.id}`,
          apiData.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  export const getJobTitleDropdown = createAsyncThunk(
    "getJobTitleDropdown",
    async (apiData, { rejectWithValue,getState }) => {

      const token = getState()?.auth?.token
      try {
        if (!token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        const { data } = await axios.get(
          `${BASE_URL}/v1/jobs_title/dropdown`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const { clearState } = jobTitleSlice.actions;
  