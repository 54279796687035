import React from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Badge from "../../../components/styled/Badge/Badge";
const Scheduling = () => {
  return (
    <div className="scheduling-main-container">
      <Flex align="center" justify="space-between" className='scheduling-main-container-head'>
        <h1 className="team-member-details-head">Scheduling</h1>
        <Button label="View Calender" />
      </Flex>

      <Table>
        <TableHead>
          <TableRow>
            <TableHeadData>ID</TableHeadData>
            <TableHeadData>Name</TableHeadData>
            <TableHeadData>Cell No</TableHeadData>
            <TableHeadData>Email</TableHeadData>
            <TableHeadData>Service</TableHeadData>
            <TableHeadData>Date, Time</TableHeadData>
            <TableHeadData>Status</TableHeadData>
            <TableHeadData>Action</TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableBodyData>
              <TableText variant="link">23244</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>AndersonSmire</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>03445568654</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>M.Iqbal@Gmail.com</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>Nail Art</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>Nail Art</TableText>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center">
                <Badge label="upcomming" tone="success" />
              </Flex>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center" gap={10}>
                <Button label='Assign' variant="outline-sm"/>
                <img src="/icons/delete.png" alt="delete" />
                <img src="/icons/edit.png" alt="edit" />
              </Flex>
            </TableBodyData>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Scheduling;
