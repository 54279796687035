import React, { useEffect } from "react";
import "./Home.css";
import LandingPageLayout from "../../../components/LandingPage/Layout/LandingPageLayout";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getWebsiteData } from "../../../features/MainWebsite/mainWebsiteSlice";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { User } from "react-feather";
const Home = () => {
  const { loading, error, data } = useSelector(
    (state) => state.mainWebsiteData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebsiteData());
  }, [dispatch]);
  const navigate = useNavigate()
  return (
    <LandingPageLayout>
      <div>
        <section className="home-section1 max-width-1440">
          <div className="section1-inner">
            <div className="section1-left">
              <h1 className="main-heading">
                Business management and scheduling software
              </h1>
              <h2 className="semi-heading">
                taking solopreneurs & small businesses to the next level
              </h2>
              <p className="sec1-para">
                BizOps Pro all in one software reduces the day to day hassles of
                managing a small business, allowing you to spend more time
                providing a superior customer experience, earn more money and
                build your brand with ease and efficiency.
              </p>
              <Link to="/signup?step=1">
                <button className="trail-button">Get a free trial</button>
              </Link>
            </div>
            <div className="section1-right">
              <img src={"/home/home1.png"} alt="" />
            </div>
          </div>
        </section>

        <section className="section2 max-width-1440">
          <div className="section2-inner">
            <h1 className="sec2-heading headings">Business Types</h1>
            <div className="sec2-cards">
              <div className="sec2-card" id="card1">
                <div className="overlay">
                  <h1 className="sec2-card-head">BEAUTY AND WELLNESS</h1>
                </div>
                <div className="after-hover">
                  <img src="/home/business1-icon.png" alt="" />
                  <h1 className="sec2-card-head-hover">BEAUTY AND WELLNESS</h1>
                  {/* <p className="sec2-card-para-hover">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text since the 1500s
                  </p> */} 
                  <button className="sec2-card-btn-hover" onClick={()=>{
                    navigate('/contact')
                  }}>Contact Us</button>
                </div>
              </div>
              <div className="sec2-card" id="card2">
                <div className="overlay">
                  <h1 className="sec2-card-head">HAIRSTYLISTS AND SALONS</h1>
                </div>
                <div className="after-hover">
                  <img src="/home/business2-icon.png" alt="" />
                  <h1 className="sec2-card-head-hover">
                    HAIRSTYLISTS AND SALONS
                  </h1>
                 <button className="sec2-card-btn-hover" onClick={()=>{
                    navigate('/contact')
                  }}>Contact Us</button>
                </div>
              </div>
              <div className="sec2-card" id="card3">
                <div className="overlay">
                  <h1 className="sec2-card-head">SPORTS AND FITNESS</h1>
                </div>
                <div className="after-hover">
                  <img src="/home/business3-icon.png" alt="" />
                  <h1 className="sec2-card-head-hover">SPORTS AND FITNESS</h1>
                  <button className="sec2-card-btn-hover" onClick={()=>{
                    navigate('/contact')
                  }}>Contact Us</button>
                  
                </div>
              </div>
              <div className="sec2-card" id="card4">
                <div className="overlay">
                  <h1 className="sec2-card-head">PROFESSIONAL SERVICES</h1>
                </div>
                <div className="after-hover">
                  <img src="/home/business4-icon.png" alt="" />
                  <h1 className="sec2-card-head-hover">
                    PROFESSIONAL SERVICES
                  </h1>

                 <button className="sec2-card-btn-hover" onClick={()=>{
                    navigate('/contact')
                  }}>Contact Us</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section3 max-width-1440">
          <div className="sec3-inner">
            <h1 className="headings sec3-heading">Features</h1>
            <p className="sec3-para">
              Everything needed to manage your business wrapped up in one
              solution
            </p>
            <div className="sec3-features-cards">
              <div className="sec3-features-cards-left">
                <div className="sec3-feature-card-large">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/appointment 1.png"} alt="" />
                    </div>
                    <div className="card-heading">
                      Appointments and Bookings
                    </div>
                  </div>
                  <ul className="card-list">
                    <li>Appointment calendar</li>
                    <li>Customer login and profiles</li>
                    <li>Book via Instagram</li>
                    <li>Text message appointment reminders</li>
                    <li>Book via Facebook</li>
                  </ul>
                </div>
                <div className="sec3-feature-card-large" id="feature-card1">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/integration 1.png"} alt="" />
                    </div>
                    <div className="card-heading">Integrations</div>
                  </div>
                  <ul className="feature-smallcard">
                    <div className="card-list-left">
                      <li>Quickbook</li>
                      <li>Zero</li>
                    </div>
                    <div className="card-list-right">
                      <li>Google</li>
                      <li>Outlook</li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="sec3-features-cards-middle">
                <div className="sec3-feature-card-large" id="feature-card2">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/clientMng.png"} alt="" />
                    </div>
                    <div className="card-heading">Client Management</div>
                  </div>
                  <ul className="card-list">
                    <li>View customer profiles</li>
                    <li>Custom Forms</li>
                  </ul>
                </div>
                <div className="sec3-feature-card-large" id="feature-card3">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/payment.png"} alt="" />
                    </div>
                    <div className="card-heading">Payment Processing</div>
                  </div>
                  <ul className="card-list">
                    <li>Appointment deposits</li>
                    <li>Cardless checkout</li>
                    <li>Online payments via third parties</li>
                    <li>Invoices</li>
                  </ul>
                </div>
              </div>
              <div className="sec3-features-cards-right sec3-features-cards-middle">
                <div className="sec3-feature-card-large" id="feature-card2">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/communication.png"} alt="" />
                    </div>
                    <div className="card-heading">Communication</div>
                  </div>
                  <ul className="card-list">
                    <li>Appointment confirmation, cancellation emails</li>
                    <li>'Contact Us' feature on custom website</li>
                  </ul>
                </div>
                <div className="sec3-feature-card-large" id="feature-card3">
                  <div className="feature-card-head">
                    <div className="card-icon">
                      <img src={"/home/marketing.png"} alt="" />
                    </div>
                    <div className="card-heading">Marketing</div>
                  </div>
                  <ul className="card-list">
                    <li>Custom website</li>
                    <li>Coupons & Promotion codes</li>
                    <li>Portolio /feed from instagram</li>
                    <li>Gather customer reviews</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section4 max-width-1440">
          <div className="sec4-inner">
            <h1 className="sec4-heading headings">Customized Templates</h1>
            <p className="sec4-para paragraphs">
              Build brand awareness with your very own customisable website.
            </p>
            <div className="templates">
              <img src={"/home/template1.png"} alt="" />
              <img src={"/home/template2.png"} alt="" />
              <img src={"/home/template3.png"} alt="" />
            </div>
          </div>
        </section>

        <section className="section5 max-width-1440">
          <div className="sec5-inner" style={{ position: "relative" }}>
            <h1 className="headings sec5-heading">What Our Customers Say</h1>
            <p className="paragraphs sec5-para">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              since the 1500s
            </p>
            {conditionalRender(
              loading,
              <PageLoader />,
              conditionalRender(
                !error,
                conditionalRender(
                  data?.length > 0,
                  <div className="testimonials">
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={50}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        1000: { slidesPerView: 2 },
                      }}
                      // loop={true}
                      keyboard={{
                        enabled: true,
                      }}
                      pagination={{
                        dynamicBullets: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {data?.map((testimonial, index) => {
                        return (
                          <SwiperSlide>
                            <div className="testimonial">
                              <img
                                className="qouts1"
                                src={"/home/qouts.png"}
                                alt=""
                              />
                              <div className="textImg">
                                {/* <img
                            // className="textImg"
                            src={testimonial?.image_path + testimonial?.image}
                            alt=""
                            
                          /> */}
                                {conditionalRender(
                                  testimonial?.business_id?.image_url,
                                  <img
                                    src={testimonial?.business_id?.image_url}
                                    alt=""
                                  />,
                                  <User
                                    color="#14142B"
                                    fill="#14142B"
                                    size={44}
                                  />
                                )}
                              </div>
                              <img
                                className="pngLogo"
                                src={"/home/pngLogo.png"}
                                alt=""
                              />
                              <p className="paragraphs testimonials-para">
                                {testimonial?.feedback}
                              </p>
                              <img
                                className="qouts2"
                                src={"/home/quot2.png"}
                                alt=""
                              />
                              <span className="test-client-name">
                                {testimonial?.title}
                              </span>
                              <span className="test-loc">
                                {testimonial?.business_id?.business_name}
                              </span>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>,
                  <></>
                ),
                // <GlobalErrorHandler label={error} />
                <p className="error">{error}</p>
              )
            )}
          </div>
        </section>

        <section className="section6 max-width-1440">
          <div className="sec6-inner">
            <div className="sec6-left">
              <img src="/home/trailImg.png" alt="" />
              {/* <video src={'/home/video.mp4'} alt="video" autoPlay loop muted/> */}
            </div>
            <div className="sec6-right">
              <h1 className="sec6-heading">Get your 15 Days Free Trial</h1>
              <ul className="sec6-list">
                <li>15 Free bookings</li>
                <li>Scheduling option</li>
                <li>2 offered services</li>
              </ul>
              <Link to="/signup?step=1">
                <button className="sec6-btn">GET STARTED</button>
              </Link>
            </div>
          </div>
        </section>
        {/* <section className="video-section">
          <video src={"/home/video.mp4"} alt="video" autoPlay loop muted />
        </section> */}
      </div>
    </LandingPageLayout>
  );
};

export default Home;
