import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import { ArrowUp, ArrowDown } from "react-feather";
import AreaChart from "../../../components/Portal/Charts/AreaChart";
import PortalDrop from "../../../components/Portal/PortalDrop/PortalDrop";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import PortalPieChart from "../../../components/Portal/Charts/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../features/SuperAdminPortal/adminDashboardSlice";

import {
  LineChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Line,
} from "recharts";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";

const AdminDashboard = () => {
  const lineGraphData = [
    {
      name: "0",
      uv: 4000,
      pv: 7800,
      amt: 2400,
    },
    {
      name: "1",
      uv: 3000,
      pv: 7900,
      amt: 2210,
    },
    {
      name: "2",
      uv: 2000,
      pv: 8400,
      amt: 2290,
    },
    {
      name: "3",
      uv: 2780,
      pv: 2500,
      amt: 2000,
    },
    {
      name: "4",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "5",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "6",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "7",
      uv: 3490,
      pv: 9000,
      amt: 2100,
    },
    {
      name: "8",
      uv: 3490,
      pv: 6000,
      amt: 2100,
    },
    {
      name: "9",
      uv: 3490,
      pv: 9500,
      amt: 2100,
    },
    {
      name: "10",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const { token } = useSelector((state) => state.auth);
  const { loading, data, error } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getDashboardData({ token }));
    }
  }, [token, dispatch]);
  const [active, setActive] = useState("Monthly");
  return (
    <div className="portal-dashboard" style={{minHeight:'100vh'}}>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <div className="dashboard-stats-boxes">
              <div className="dashboard-stats-box">
                <h3>Total Appointments</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead
                    variant="blue"
                    text={
                      parseInt(data?.upcomming) +
                      parseInt(data?.noshow) +
                      parseInt(data?.completed) +
                      parseInt(data?.cancelled)
                    }
                  />
                  <StatsBadge value={2.5} isProfit={false} />
                </div>
              </div>
              <div className="dashboard-stats-box">
                <h3>Total service providers</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead variant="blue" text={data?.service_provider} dollar={false} />
                  <StatsBadge value={data?.service_provider} isProfit={true} />
                </div>
              </div>
              <div className="dashboard-stats-box">
                <h3>Total Customers</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead text={data?.total_customer} dollar={false} />
                  <StatsBadge value={data?.total_customer} isProfit={false} />
                </div>
              </div>
              <div className="dashboard-stats-box">
                <h3>Total Revenue</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead text="567,899" />
                  <StatsBadge value={2.5} isProfit={true} />
                </div>
              </div>
            </div>

            <div className="admin-dashboard-lower-section">
              <div className="portal-dashboard-left-side">
                <div className="portal-dashboard-left-side-inner">
                  <div className="graph-outer">
                    <div className="portal-dashboard-area-chart-top">
                      <span>Total Number of Subscriptions</span>
                      <div className="portal-dashboard-area-chart-top-inputs">
                        <PortalDrop
                          options={["Monthly", "Weekly", "Yearly"]}
                          active={active}
                          setAcvtive={setActive}
                        />
                      </div>
                    </div>
                    <div className="portal-dashboard-area-chart admin-dashboard-line-chart">
                      <ResponsiveContainer>
                        <LineChart
                          width={730}
                          height={250}
                          data={lineGraphData}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend /> */}
                          <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  {/* <DashboardHeading>List of Services</DashboardHeading> */}
                  {/* <div className="portal-dashboard-left-side-top">
              <PortalDrop
                options={["Monthly", "Weekly", "Yearly"]}
                active={active}
                setAcvtive={setActive}
              />
            </div> */}
                  <div className="graph-outer">
                    <div className="portal-dashboard-area-chart-top">
                      <span>Monthly Revenue</span>
                      <div className="portal-dashboard-area-chart-top-inputs">
                        <div className="portal-dashboard-area-chart-top-input">
                          <input type="checkbox" id="revenue" />
                          <label htmlFor="revenue">Revenue</label>
                        </div>
                        <div className="portal-dashboard-area-chart-top-input">
                          <input type="checkbox" id="profit" />
                          <label htmlFor="profit">Profit</label>
                        </div>
                      </div>
                    </div>
                    <div className="portal-dashboard-area-chart">
                      <AreaChart />
                    </div>
                  </div>
                </div>
              </div>

              <div className="portal-dashboard-right-side">
                <div className="admin-dashboard-piechart">
                  <PortalPieChart dashData={data} />
                </div>

                <div className="admin-today-bookings">
                  <div className="portal-today-bookings-heading">
                    <DashboardHeading>Today Bookings</DashboardHeading>
                  </div>
                  <div className="portal-today-bookings-inner">
                    <TodayBookingsBadge
                      value="89"
                      label="Facebook"
                      percentage="22.4"
                    />
                    <TodayBookingsBadge
                      value="89"
                      label="Google"
                      percentage="22.4"
                    />
                    <TodayBookingsBadge
                      value="89"
                      label="Instagram"
                      percentage="22.4"
                    />
                    <TodayBookingsBadge
                      value="89"
                      label="WhatsApp"
                      percentage="22.4"
                    />
                    <TodayBookingsBadge
                      value="89"
                      label="Calender"
                      percentage="22.4"
                    />
                    <TodayBookingsBadge
                      value="89"
                      label="Website"
                      percentage="50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}
    </div>
  );
};

function StatsHead({ variant = "black", text, dollar = false }) {
  return (
    <h2 style={{ color: `${variant === "blue" ? "#5459FF" : "#25233C"}` }}>
      {dollar ? "$" : ""}
      {text}
    </h2>
  );
}

function StatsBadge({ value, isProfit }) {
  return (
    <div className="dashboard-stats-badge">
      {isProfit ? (
        <ArrowUp color="#30E702" size={15} />
      ) : (
        <ArrowDown color="#F60505" size={15} />
      )}
      <span style={{ color: `${isProfit ? "#30E702" : "#F60505"}` }}>
        {value}%
      </span>
    </div>
  );
}

function DashboardHeading({ children }) {
  return <h2 className="dashboard-heading">{children}</h2>;
}

function TodayBookingsBadge({ value, label, percentage }) {
  return (
    <div className="today-bookings-badge">
      <div className="today-bookings-badge-top">
        <div className="today-bookings-badge-top-left">
          <DashboardHeading>{value}</DashboardHeading>
          <span>{label}</span>
        </div>
        <p>{percentage}%</p>
      </div>
      <div className="today-bookings-badge-line">
        <div
          className="today-bookings-badge-line-inner"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
}

export default AdminDashboard;
