import React, { useEffect, useState } from "react";
import "./Settings.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  updateUserProfile,
} from "../../../features/ServiceProviderPortal/updateProfileSlice";
import { useForm } from "react-hook-form";
import { getUserData } from "../../../features/auth/authSlice";
import { namePattern, phonePattern } from "../../../utills/Schems";
import { toast } from "react-toastify";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
const Settings = () => {
  const { token, userData } = useSelector((state) => state.auth);
  const { loading, success } = useSelector(
    (state) => state.updateProfile
  );
  console.log(userData);
  const { getUserLoading, getUserError } = useSelector(
    (state) => state.authTemp
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBinaryImage, setSelectedBinaryImage] = useState(null);
  const [businessImage, setBusinessImage] = useState(null);
  const [businessBinaryImage, setBusinessBinaryImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();

        image.onload = () => {
          // Get the width and height of the image
          const { width, height } = image;
          // console.log(image)
          // console.log(width,height)
          // Define the minimum and maximum allowed sizes
          const minWidth = 20;
          const minHeight = 20;
          const maxWidth = 4600;
          const maxHeight = 4600;

          // Perform size validation here
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            // Image size is valid, set the selected image and show success message
            if (event.target.name === "image") {
              setBusinessBinaryImage(file);
              setBusinessImage(reader.result);
            }
            if (event.target.name === "image1") {
              setSelectedImage(reader.result);
              setSelectedBinaryImage(file);
            }

            setErrorMessage("");
          } else {
            // Image size is not valid, show an error message
            setErrorMessage(
              "Image size is not within the allowed limits(100-4600 x 100-4600). Please choose an image with appropriate dimensions."
            );
            setSelectedImage(null);
            setSelectedBinaryImage(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      last_name: userData?.last_name,
      first_name: userData?.first_name,
      mobile: Number(userData?.mobile),
    },
  });

  useEffect(() => {
    if (token) {
      dispatch(getUserData(token));
    }
    if (success) {
      toast.success("Profile Updated Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
    }
  }, [token, dispatch, success]);

  const handleUpdateUserProfile = (values) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("file_name", selectedBinaryImage);
    formData.append("mobile", values.mobile);
    dispatch(updateUserProfile({ token, data: formData }));
  };
  return (
    <div
      className="outer client-portal-reviews"
      style={{ position: "relative" }}
    >
      <Flex align={"center"} justify={"space-between"} gap={10}>
        <h1 className="edit-client-heading">Settings</h1>
      </Flex>
      <div className="mt-20">
        {conditionalRender(getUserLoading,<PageLoader/>,conditionalRender(!getUserError,<form onSubmit={handleSubmit(handleUpdateUserProfile)}>
          <div className="add-team-member-inputs">
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first-name">First Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"First Name"}
                  {...register("first_name", {
                    required: false,
                    minLength: {
                      value: 3,
                      message: "Should not be less then 3 characters",
                    },
                    maxLength: {
                      value: 20,
                      message: "Should not be greater then 20 characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first-name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="Last Name">Last Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  id="last-name"
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    minLength: {
                      value: 3,
                      message: "Should not be less then 3 characters",
                    },
                    maxLength: {
                      value: 20,
                      message: "Should not be greater then 20 characters",
                    },
                    pattern: namePattern,
                  })}
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>

            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="mobile">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"0300000000000"}
                  id="mobile"
                  {...register("mobile", {
                    required: "Please Enter Number",
                    pattern: phonePattern,
                    min: { value: 0, message: "number cannot be negative" },
                  })}
                />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.mobile && errors?.mobile?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email*</label>
              <div
                className="global-input-container-input global-input-container-input-with-image"
                style={{
                  cursor: "not-allowed",
                  background: `rgba(84, 89, 255, 0.10)`,
                  borderRadius: "8px",
                }}
              >
                <input
                  style={{
                    cursor: "not-allowed",
                    background: `rgba(84, 89, 255, 0.10)`,
                  }}
                  type={"email"}
                  placeholder={userData?.email}
                  id="email"
                  disabled
                />
                <img src="/icons/sms.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
          </div>
          <div className="lable-input-group mt-20">
            <label htmlFor="address">Profile-picture</label>
            <div className="upload-content-image-section " id="img-box">
              <label htmlFor="file">
                <div>
                  {selectedImage || userData?.image_url ? (
                    <div className="upload-img">
                      {/* <img src={selectedImage} alt="Preview" /> */}
                      {(selectedImage || userData?.image_url) && (
                        <img
                          src={
                            selectedImage ? selectedImage : userData?.image_url
                          }
                          alt="Selected"
                        />
                      )}
                    </div>
                  ) : (
                    // <img src={uploadimg} alt="Preview" />
                    <>
                      <div className="upload-photo">
                        <div className="up-img">
                          <span className="drag-drop">Please Select Image</span>
                        </div>
                      </div>
                      {errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                      )}
                    </>
                  )}
                </div>
              </label>
              <input
                className="upload-content-image"
                type="file"
                accept="image/*"
                name="image1"
                id="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="file" className="upload-content-label">
                Choose image
              </label>
            </div>
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loaderColor="#fff"
                loading={loading}
              />
            </Flex>
          </div>
        </form>,<GlobalErrorHandler label={getUserError}/>))}
        
      </div>
    </div>
  );
};

export default Settings;
