import React, { useEffect, useRef, useState } from "react";
import "./Steps.css";
import { ChevronUp, ChevronDown } from "react-feather";
import LandingPageLayout from "../../../components/LandingPage/Layout/LandingPageLayout";
import { Link, useLocation } from "react-router-dom";
const stepsData = [
  {
    title: "How can BizOps Pro Business Management software help my business?",
    description:
      "BizOps Pro has been designed to help service providers streamline their operations, manage appointments, track finances, build brand awareness and improve overall efficiency in running their businesses.",
  },
  {
    title: "What type of functionality does the product include?",
    description:
      "BizOps Pro software offers a range of features, including appointment scheduling, client management, invoicing, and reporting tools, which can save you time, reduce administrative tasks, and increase customer satisfaction.",
  },
  {
    title:
      "Can I integrate this software with other tools I use for my business?",
    description:
      "Yes, we offer integrations with various popular business tools, such as accounting software, marketing platforms, and payment processors, to streamline your workflow",
  },
  {
    title: "Is this software suitable for various service based businesses?",
    description:
      "Yes, our platform is versatile and can be customized to meet the needs of a wide range of service providers, including salons, spas, consultants, fitness trainers, therapists, and many more.",
  },
  {
    title: "Can I access the software from anywhere?",
    description:
      "Yes, our platform is cloud-based, meaning you can access your business information and tools from any device with an internet connection.",
  },
  {
    title: "How user friendly is the software?",
    description:
      "BizOps pro platform is designed to be intuitive and user-friendly, with a user interface that is easy to navigate. We also provide training and support to help you get the most out of it.",
  },
  {
    title: "Do you offer Customer Support?",
    description:
      "Absolutely, we provide customer support through various channels, including email, simply send a message to <a class='faq-email' href='mailto:support@bizopspro.com'>support@bizopspro.com</a> and we will be sure to get back to you to assist you with any questions or issues you may have.",
  },
  {
    title: "Do you offer a free trial?",
    description:
      "Yes, we offer a free trial so you can explore the software and its features before committing to a subscription.",
  },
  {
    title: "How often can I expect to receive payments?",
    description:
      "Payments can be withdrawn via Stripe or Paypal. Depending on the payments selected you can expect these to arrive between 3-5 business days. The payment amount will be the total of all customer transactions minus any processing fees from Stripe or Paypal and any relevant service fees.",
  },
  {
    title:
      "Earn additional cash by referring other professional service providers?",
    description:
      "Simply refer other professionals to subscribe to our Supreme Pro package within the portal and earn £20 once they sign up..",
  },
  {
    title: "How much does the service cost?",
    description:
      "We offer transparent pricing with no hidden fees. Pricing varies depending on the features and the number of users you require. We offer flexible pricing plans to accommodate businesses of different sizes and budgets. <a href='/pricing'>CLICK HERE</a> for more information.",
  },
];

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const Steps = () => {
  const [open, setOpen] = useState(0);
  const sectionRef = useRef();

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const section = document.getElementById("faqs-section");
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <LandingPageLayout>
      <div className="max-width-1440 steps-container">
        <div className="steps-container-inner">
          <div className="steps-main-headings">
            <h1 className="headings mb-10">How It Works</h1>
            <p className="paragraphs">
              Get started with taking your business to the next level in 3
              simple steps.
            </p>
          </div>

          <div className="steps-container-steps">
            <div className="steps-container-step">
              <div className="circle">
                <span>1</span>
              </div>
              <h2>Choose Your Package</h2>
              <p className="paragraphs">
                Browse our range of flexible packages. We have three to select
                from Scaling Por, Super Pro and Supreme Pro. <Link to='/pricing' className="faq-email">CLICK HERE</Link> for more
                information on each.
              </p>
            </div>
            <div className="steps-container-step">
              <div className="circle">
                <span>2</span>
              </div>
              <h2>Select Website Template</h2>
              <p className="paragraphs">
                Select from a range of website templates to make your own. Each
                can be easily edited to fit your brand and style.
              </p>
            </div>
            <div className="steps-container-step">
              <div className="circle">
                <span>3</span>
              </div>
              <h2>Get Back To Business</h2>
              <p className="paragraphs">
                Start accepting booking,s, growing your business and pushing
                your brand with ease and efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="steps-faqs max-width-1440" ref={sectionRef}>
        <div className="steps-faqs-inner" id="faqs-section">
          <h1 className="faq-head">Frequently Asked Questions</h1>
          {stepsData.map((data, index) => {
            return (
              <div className="steps-faq">
                <div className="steps-faq-head" onClick={() => setOpen(index)}>
                  <p>{data.title}</p>
                  <div className="step-chevron">
                    {open === index ? <ChevronUp /> : <ChevronDown />}
                  </div>
                </div>
                <div
                  className="steps-faq-content"
                  style={{ maxHeight: `${open === index ? "100px" : "0px"}` }}
                >
                  <p className="paragraphs" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: data.description }}>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default Steps;
