import React, { useState } from "react";
import "./EditSchedule.css";
import Flex from "../../../components/styled/Flex/Flex";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";

const EditSchedule = () => {
  const dropOptions = [{ name: "XYZ" }, { name: "ABC" }];
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  return (
    <div className="outer">
      <Link to={"/admin-portal/scheduling"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Edit Scheduling</h1>
      <div className="mt-20">
        <form>
          <div className="add-team-member-inputs">
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="firstName">Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"First Name"}
                  id="firstName"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="email"
                />
                <img src="/icons/sms.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="number">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"number"} placeholder={"0300000000"} id="number" />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
            </Flex>

            <Flex className="add-team-member-input" direction="column" gap={10}>
              <GlobalDropDown
                options={dropOptions}
                label="Appointment*"
                icon="/icons/appointments.png"
                stateHandler={selectedAppointment}
                setStateHandler={setSelectedAppointment}
              />
            </Flex>
            <Flex className="add-team-member-input" direction="column" gap={10}>
              <GlobalDropDown
                options={dropOptions}
                label="Service Type*"
                icon="/icons/profile-2user.png"
                stateHandler={selectedService}
                setStateHandler={setSelectedService}
              />
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="appointment-date">Appointment Date*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"date"} id="appointment-date" />
                <img src="/icons/calendar.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="appointment-time">Appointment Time*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"time"} id="appointment-time" />
                <img src="/icons/calendar.png" alt="icon" />
              </div>
            </Flex>
            <Flex className="add-team-member-input" direction="column" gap={10}>
              <GlobalDropDown
                options={dropOptions}
                label="Company*"
                icon="/icons/appointments.png"
                stateHandler={selectedCompany}
                setStateHandler={setSelectedCompany}
              />
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="price">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"number"} placeholder={"150"} id="price" />
                <img src="/icons/amount.png" alt="icon" />
              </div>
            </Flex>
            {/* <Flex className="add-team-member-input" direction="column" gap={10}>
            <GlobalDropDown
              options={teamMembersDrop?.systemRole}
              label="System Role"
              setStateHandler={setSystemRole}
              stateHandler={systemRole}
              icon="/icons/user.png"
              defaultId={teamMember?.roles[0]?.id}
            />
            {roleError && !systemRole && (
              <p className="global-input-error">{roleError}</p>
            )}
          </Flex> */}
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/scheduling">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSchedule;
