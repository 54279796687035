import React from 'react'
import Flex from '../../../components/styled/Flex/Flex'
import Button from '../../../components/styled/Button/Button'
import { Link } from 'react-router-dom'

const EditReferral = () => {
  return (
    <div className="outer">
      <h1 className="team-member-details-head">Edit Referral</h1>
      <div className="mt-20">
        <form>
          <div className="add-team-member-inputs">
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="from">From*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"text"} placeholder={"Full Name"} id="from" />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="to">To*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"text"} placeholder={"Full Name"} id="to" />
                <img src="/icons/user.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="username">Phone*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input type={"number"} placeholder={"0300000000"} id="number" />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="last_name">Email*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="email"
                />
                <img src="/icons/sms.png" alt="icon" />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="date">Date*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"date"}
                  placeholder={"m.iqbal@gmail.com"}
                  id="date"
                />
                <img src="/icons/calendar.png" alt="icon" />
              </div>
            </Flex>
            
          </div>

          <div className="edit-client-btns">
            <Flex align="center" gap={20}>
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loaderColor="#fff"
              />
              <Link to="/admin-portal/referrals">
                <Button variant="outline" label={"Cancel"} />
              </Link>
            </Flex>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditReferral