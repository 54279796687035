import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import {
  getAllSubscriptions,
  clearState,
  deleteSubscription,
} from "../../../features/ServiceProviderPortal/SubscriptionsSlice";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import { handleUndefined } from "../../../utills/handleUndefined";
import { prettifyDate } from "../../../utills/prettifyDate";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import Badge from "../../../components/styled/Badge/Badge";
const Subscriptions = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, subscriptions, delLoading, delError, delSuccess } =
    useSelector((state) => state.subscriptions);

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  useEffect(() => {
    if (token) {
      dispatch(
        getAllSubscriptions({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      setDelId(null);
      dispatch(clearState());
    }
  }, [dispatch, token, delSuccess, currentPage, itemsPerPage]);

  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteSubscription = () => {
    dispatch(deleteSubscription({ token, id: delId }));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div
      className="subscriptions-main-container"
      style={{ position: "relative" }}
    >
      <Flex
        align="center"
        justify="space-between"
        className="scheduling-main-container-head"
      >
        <h1 className="team-member-details-head">Subscriptions</h1>
        {/* <Flex align="center">
          <Link to="/portal/add-subscription">
            <Button label="Add Subscription" />
          </Link>
        </Flex> */}
      </Flex>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Subscription Type</TableHeadData>
                  <TableHeadData>Purchase Date</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                subscriptions?.items?.length > 0,
                <TableBody>
                  {subscriptions?.items.map((item, index) => {
                    return (
                      <TableRow>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.id)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{item?.subscription_type}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.purchase_date)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge tone="success">{item?.status}</Badge>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={5}>
                            <Link to={`/portal/edit-subscription/${item?.id}`}>
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                            <img
                              src="/icons/delete.png"
                              alt="delete"
                              onClick={() => {
                                setDelId(item?.id);
                                setDelPopup(true);
                              }}
                            />
                            {/* <Button variant="outline-sm" label="Upgrade" /> */}
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No subscriptions to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                subscriptions?.items?.length > 0,
                subscriptions?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Name</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {item?.name}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Purchase Date
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.purchase_date)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Expiry</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.expiry_date)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Amount</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{item?.amount}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Type</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                Supreme Pro
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <SubscriptionBadge
                                  variant={
                                    item?.status === 38 ? "active" : "expired"
                                  }
                                >
                                  {item?.status === 38 ? "Active" : "Expired"}
                                </SubscriptionBadge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start" gap={5}>
                                <Link
                                  to={`/portal/edit-subscription/${item?.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                                {/* <Button variant="outline-sm" label="Upgrade" /> */}
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {subscriptions?.meta?.totalItems > subscriptions?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={subscriptions?.meta?.totalPages}
          />
        )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteSubscription}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

function SubscriptionBadge({ children, variant }) {
  return (
    <Flex
      className={`subscription-badge ${
        variant === "expired"
          ? "subscription-badge-expired"
          : "subscription-badge-active"
      }`}
      align="center"
      justify="center"
    >
      {children}
    </Flex>
  );
}

export default Subscriptions;
