import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, X } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  getSingleVoucher,
  getVoucherDropDown,
  resetState,
  updateVoucher,
} from "../../../features/ServiceProviderPortal/vouchersSlice";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import Flex from "../../../components/styled/Flex/Flex";
import ReactDatePicker from "react-datepicker";
import { getCurrentDate, prettifyDate } from "../../../utills/prettifyDate";
import { concatNames } from "../../../utills/concatNames";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { toast } from "react-toastify";
const SendVoucher = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const {
    loading,
    error,
    success,
    updateVoucherLoading,
    updateVoucherError,
    getSingleLoading,
    getSingleError,
    voucher,
  } = useSelector((state) => state.vouchers);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const handleAddVoucher = (values) => {
    console.log("here");
    const apiData = {};
    if (status) {
      apiData.status = status.id;
    }
    if (selectedClient) {
      apiData.client = selectedClient.id;
    }
    if (values.total_amount) {
      apiData.total_amount = values.total_amount;
    }
    if (values.valid_date_from) {
      apiData.valid_date_from = values.valid_date_from;
    }
    if (values.valid_date_to) {
      apiData.valid_date_to = values.valid_date_to;
    }
    if (values.notes) {
      apiData.notes = values.notes;
    }
    dispatch(updateVoucher({ token, data: { ...apiData }, id }));
  };

  const [selectedClient, setSelectedCLient] = useState(null);
  useEffect(() => {
    if (success) {
      toast.success("Voucher updated Successfully", {
        position: "top-right",
      });
      dispatch(resetState());
      navigate("/portal/vouchers");
    }
  }, [success, navigate, dispatch]);

  useEffect(() => {
    dispatch(getVoucherDropDown({ token }));
    dispatch(getSingleVoucher({ token, id }));
  }, [dispatch, token, id]);
  useEffect(() => {
    if (voucher) {
      reset({
        total_amount: voucher.total_amount,
        valid_date_from: voucher.valid_date_from,
        valid_date_to: voucher.valid_date_to,
        notes: voucher.notes,
      });
    }
  }, [voucher, reset]);
  const [status, setStatus] = useState(null);
  const valid_date_from = watch("valid_date_from");
  const total_amount = watch("total_amount");
  const [previewVoucher, setPreviewVoucher] = useState(false);
  const valid_date_to = watch("valid_date_to");
  return (
    <div className="outer">
      <Link
        to={"/portal/vouchers"}
        className="back"
        onClick={() => dispatch(resetState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Update Voucher</h1>
      {conditionalRender(
        loading || getSingleLoading,
        <PageLoader />,
        conditionalRender(
          !error && !getSingleError,
          <form onSubmit={handleSubmit(handleAddVoucher)}>
            <div className="edit-voucher-inputs">
              <Flex
                className={`global-input-container edit-voucher-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="voucher">Amount</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    placeholder={"Amount"}
                    {...register("total_amount", {
                      required: "Please Enter Ammount",
                      maxLength: {
                        value: 5,
                        message: "Amount not should be more then 5 characters",
                      },
                      min: { value: 0, message: "Amount cannot be negative" },
                    })}
                    id="total_amount"
                  />
                  <img src="/icons/amount.png" alt="icon" />
                </div>
                <p className="global-input-error mb-10">
                  {errors?.total_amount && errors?.total_amount?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container edit-voucher-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="valid_date_from">Valid From</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  {/* <input
                    type={"date"}
                    placeholder={"Name"}
                    {...register("valid_date_from", {
                      required: "Please Select From Date",
                    })}
                    id="valid_date_from"
                  /> */}
                  <Controller
                    control={control}
                    name="valid_date_from"
                    rules={{ required: "Please select valid from date" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={getCurrentDate()}
                        placeholderText="Please select date"
                      />
                    )}
                  />
                  <img src="/icons/appointments.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.name && errors?.valid_date_from?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container edit-voucher-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="valid_date_to">Valid To</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  {/* <input
                    type={"date"}
                    placeholder={"Name"}
                    {...register("valid_date_to", {
                      required: "Please Select To Date",
                    })}
                    id="valid_date_to"
                  /> */}
                  <Controller
                    control={control}
                    name="valid_date_to"
                    rules={{
                      required: "Please select valid to date",
                      validate: (value) =>
                        value > valid_date_from ||
                        "Valid to cannot be less then or equal to valid from",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={getCurrentDate()}
                        placeholderText="Please select date"
                      />
                    )}
                  />
                  <img src="/icons/appointments.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.name && errors?.valid_date_to?.message}
                </p>
              </Flex>

              <div className="edit-voucher-input">
                <ClientsDropDown
                  label="Select Client"
                  selectedClient={selectedClient}
                  setSelectedCLient={setSelectedCLient}
                  defaultSelected={voucher?.client}
                />
              </div>
            </div>

            <div className="lable-textarea-group lable-input-group">
              <label htmlFor="notes">Message</label>
              <div
                className="edit-client-icon-textarea"
                style={{ position: "relative" }}
              >
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="11/2/2023"
                  {...register("notes", { required: "Please Add Notes" })}
                ></textarea>
                {errors?.notes && (
                  <p className="error">{errors?.notes?.message}</p>
                )}
              </div>
              <span className="voucher-code" style={{ marginTop: "20px" }}>
                Please note voucher code will be auto generated once created
              </span>
            </div>
            <div className="edit-client-btns">
              <Button
                variant="outline"
                label={"Preview Voucher"}
                handleClick={() => setPreviewVoucher(true)}
              />
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loading={updateVoucherLoading}
                loaderColor="#fff"
              />
            </div>
          </form>,
          <GlobalErrorHandler label={error ? error : getSingleError} />
        )
      )}

      {updateVoucherError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={updateVoucherError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(resetState())}
          />
        </Popup>
      )}
      {previewVoucher && (
        <Popup className="preview-voucher-popup" setPopUp={() => {}}>
          <div className="voucher-preview-popup-inner">
            <h1>Voucher</h1>
            <h3>Special for you</h3>

            <div className="preview-voucher-details">
              <Flex align="center" justify="space-between">
                <p>Voucher Amount</p>
                <p>£{total_amount}</p>
              </Flex>

              <Flex align="center" justify="space-between">
                <p>Sent To</p>
                <p>
                  {concatNames(
                    selectedClient?.first_name,
                    selectedClient?.last_name
                  )}
                </p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Email</p>
                <p>{selectedClient?.email}</p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Valid from</p>
                <p>{prettifyDate(valid_date_from)}</p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Valid to</p>
                <p>{prettifyDate(valid_date_to)}</p>
              </Flex>
            </div>
          </div>
          <img src="/portal/voucher.png" alt="" />

          <div className="preview-voucher-top-bar">
            <div className="preview-voucher-top-bar-inner"></div>
          </div>
          <div className="preview-voucher-bottom-bar">
            <div className="preview-voucher-bottom-bar-inner"></div>
          </div>
          <span>www.bizopspro.com/modernstyler</span>
          <X
            className="preview-voucher-close"
            onClick={() => setPreviewVoucher(false)}
          />
        </Popup>
      )}
    </div>
  );
};

export default SendVoucher;
