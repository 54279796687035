import React, { useEffect, useState } from "react";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  addAppoinment,
  clearState,
} from "../../../features/ServiceProviderPortal/appoinmentSlice";
import { useForm, Controller } from "react-hook-form";
import Flex from "../../../components/styled/Flex/Flex";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { getCurrentDate, getDayOfWeek } from "../../../utills/prettifyDate";
import "./style.css";
import { conditionalRender } from "../../../utills/conditionalRender";
import Toggle from "../../../components/Toggle/Toggle";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";
import { convBoolToNumber } from "../../../utills/conversion";
import { addClient } from "../../../features/ServiceProviderPortal/getClientsSlice";
import { clearState as clearClientState } from "../../../features/ServiceProviderPortal/getClientsSlice";
import { toast } from "react-toastify";
import Tag from "../../../components/styled/Tag/Tag";
import { getAppointemtSettings } from "../../../features/ServiceProviderPortal/appointmentSettingsSlice";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { isAppointmentAllowed } from "../../../utills/appointments";
import { convertToAMPM } from "../../../utills/prettifyDate";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import TimePicker from "../../../components/styled/Form/DateTimePickers/TimePicker";
const AddAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const {
    addLoading,
    addError,
    addSuccess,
    appoinmentDrop,
    appoinmentDropLoading,
    appoinmentDropError,
  } = useSelector((state) => state.appoinments);
  const addClientState = useSelector((state) => state.clients);
  const settingsState = useSelector((state) => state.appointmentSettings);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      date: new Date(Date.now()),
    },
  });
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [dropError, setDropError] = useState(null);
  const [addClientPopup, setClientPopup] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(getAppointemtSettings(token));
    }
  }, [token, dispatch]);

  const handleAddAppoinment = (values) => {
    // if (!selectedClient || !selectedServices.length > 0 || !selectedMember) {
      // setDropError("Please Select");
    // } else {
      let apiData = {};
      apiData.status = 23;
      if (selectedMember) {
        apiData.user_id = selectedMember.id;
      }
      apiData.time = values.time;
      apiData.name = values.name;
      apiData.date = values.date;
      apiData.notes = values.notes;
      apiData.client_id = selectedClient.id;
      const services = selectedServices.map((service) => service.id);
      apiData.services = services;
      if (
        settingsState.settings?.prevent_booking ||
        settingsState?.settings?.min_day_before_booking > 0
      ) {
        const user_selected_date_str = values.date;
        const user_selected_time_str = values.time;
        const user_selected_date = new Date(user_selected_date_str);
        const [hours, minutes] = user_selected_time_str.split(":").map(Number);
        user_selected_date.setHours(hours);
        user_selected_date.setMinutes(minutes);
        user_selected_date.setSeconds(0);
        const total_preventing_hours =
          settingsState?.settings?.prevent_booking +
          settingsState?.settings?.min_day_before_booking * 24;
        if (isAppointmentAllowed(total_preventing_hours, user_selected_date)) {
          dispatch(addAppoinment({ token, data: { ...apiData } }));
        } else {
          toast.error(
            `You can't make appointment ${
              settingsState.settings.prevent_booking +
              settingsState?.settings?.min_day_before_booking * 24
            } hours after current time`
          );
        }
      } else {
        dispatch(addAppoinment({ token, data: { ...apiData } }));
      }
    // }
  };

  useEffect(() => {
    if (addSuccess) {
      toast.success("Appointment added Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/portal/appointments");
    }
    if (addClientState?.success) {
      setClientPopup(false);
      dispatch(clearClientState());
      toast.success("Client added successfully", {
        position: "top-right",
      });
    }
    if (addClientState?.error) {
      toast.error(addClientState?.error, {
        position: "top-right",
      });
      dispatch(clearClientState());
      setClientPopup(false);
    }
  }, [
    addSuccess,
    navigate,
    dispatch,
    addClientState?.success,
    addClientState?.error,
  ]);

  const handleAddClient = (values) => {
    values.is_email = convBoolToNumber(values.is_email);
    values.is_sms = convBoolToNumber(values.is_sms);
    values.is_whatsapp = convBoolToNumber(values.is_whatsapp);
    values.enable_marketing = convBoolToNumber(values.enable_marketing);
    values.total_appointment = parseInt(values.total_appointment);
    const _apiData = {
      token,
      data: { ...values },
    };
    dispatch(addClient(_apiData));
  };
  const timeValidation = (value) => {
    const day = getDayOfWeek();
    const currentDay = settingsState?.settings?.working_hours?.filter(
      (d) => d.day === day
    );
    if (currentDay?.length > 0) {
      const selectedTime = value;
      const minTime = currentDay[0].opening_time;
      const maxTime = currentDay[0].close_time;
      if (selectedTime < minTime || selectedTime > maxTime) {
        return (
          "Please select a time between " +
          convertToAMPM(minTime) +
          " and " +
          convertToAMPM(maxTime)
        );
      }

      return true;
    } else {
      return true;
    }
  };

  return (
    <div className="outer">
      <Link to={"/portal/appointments"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Appointment</h1>
      {conditionalRender(
        settingsState.getLoading,
        <PageLoader />,
        conditionalRender(
          !settingsState.getError,
          <form onSubmit={handleSubmit(handleAddAppoinment)}>
            <div className="add-appoinment-inputs" id="add-appoinment-inputs">
              <div className="add-appoinment-input">
                <ClientsDropDown
                  label="Select Client*"
                  selectedClient={selectedClient}
                  setSelectedCLient={setSelectedClient}
                  setClientPopup={setClientPopup}
                  addClientPopup={addClientPopup}
                />
                <p className="global-input-error">
                  {dropError && !selectedClient && dropError}
                </p>
              </div>
              <Flex
                className={`global-input-container add-appoinment-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="date">Appoinment Date*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          value={field.value ? dayjs(field.value) : null}
                          onChange={(date) => {
                            field.onChange(
                              date ? date.format("YYYY-MM-DD") : null
                            );
                          }}
                          format={"YYYY-MM-DD"}
                          // showTime
                          className="add-appointment-date-picker"
                          changeOnScroll
                          needConfirm={false}
                          minDate={dayjs(getCurrentDate())}
                        />
                      );
                    }}
                  />

                  {/* <img src="/icons/user.png" alt="icon" /> */}
                </div>
                <p className="global-input-error">
                  {errors?.date && errors?.date?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-appoinment-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="date">Appoinment Time*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <Controller
                    control={control}
                    name="time"
                    render={({ field }) => {
                      return (
                        <TimePicker
                          value={field.value ?? null}
                          onChange={(time) => {
                            field.onChange(time);
                          }}
                          className="add-appointment-date-picker"
                        />
                      );
                    }}
                  />

                  {/* <img src="/icons/user.png" alt="icon" /> */}
                </div>
                <p className="global-input-error">
                  {errors?.date && errors?.date?.message}
                </p>
              </Flex>
              <div className="add-appoinment-input">
                <TeamMembersDrop
                  label="Select Team Member"
                  selectedMember={selectedMember}
                  setSelectedMember={setSelectedMember}
                  handleChange={() => {}}
                />
                <p className="global-input-error">
                  {dropError && !selectedMember && dropError}
                </p>
              </div>
              <div className="add-appoinment-input">
                <ServicesDropDown
                  label="Select Service*"
                  selectedService={selectedService}
                  setSelectedService={setSelectedService}
                  handleChange={(service) => {
                    if (selectedServices.find((m) => m.id === service.id)) {
                      return;
                    } else {
                      setSelectedServices((prevData) => [...prevData, service]);
                    }
                  }}
                />
                <p className="global-input-error">
                  {dropError && !selectedService && dropError}
                </p>
              </div>
              <div className="selected-team-members selected-services-in-appointments">
                {selectedServices && selectedServices.length > 0
                  ? selectedServices.map((service, index) => {
                      return (
                        <Tag
                          onClick={() => {
                            const updatedMembers = selectedServices.filter(
                              (m) => m.id !== service.id
                            );
                            setSelectedServices(updatedMembers);
                          }}
                          key={index}
                        >
                          {service?.name}
                        </Tag>
                      );
                    })
                  : null}
              </div>

              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">Add Notes</label>
                <div className="edit-client-icon-textarea">
                  <img src="/icons/profile-2user.png" alt="" />
                  <textarea
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Notes"
                    {...register("notes", { required: false })}
                  ></textarea>
                </div>
                <p className="global-input-error">
                  {errors?.notes && errors?.notes?.message}
                </p>
              </div>
            </div>
            <div className="edit-client-btns">
              <Button
                variant="primary"
                label={"Add Appointment"}
                type="submit"
                loaderColor="#fff"
                loading={addLoading}
              />
            </div>
          </form>,
          <GlobalErrorHandler label={settingsState.getError} />
        )
      )}

      {addClientPopup && (
        <Popup
          className="add-client-popup"
          popUp={addClientPopup}
          setPopUp={setClientPopup}
        >
          <h1 className="edit-client-heading">Add Client</h1>
          <form onSubmit={handleSubmit1(handleAddClient)}>
            <div className="add-client-inputs-outer">
              <div className="add-client-inputs">
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="first_name">First Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      {...register1("first_name", {
                        required: "Please Enter First Name",
                        maxLength: {
                          value: 10,
                          message: "Not should be more then ten characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Not should be less then three characters",
                        },
                        pattern: namePattern,
                      })}
                      id="first_name"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors1?.first_name && errors1?.first_name?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="first_name">Last Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      {...register1("last_name", {
                        required: "Please Enter Last Name",
                        maxLength: {
                          value: 10,
                          message: "Not should be more then ten characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Not should be less then three characters",
                        },
                        pattern: namePattern,
                      })}
                      id="first_name"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors1?.last_name && errors1?.last_name?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="email">Email *</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"email"}
                      placeholder={"Enter Email"}
                      {...register1("email", {
                        required: "Please Enter Email",
                        pattern: emailPattern,
                      })}
                      id="email"
                    />
                    <img src="/icons/mail.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors1?.email && errors1?.email?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="phone">Phone*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"number"}
                      placeholder={"Enter Email"}
                      {...register1("phone", {
                        required: "Please Enter Phone Number",
                        pattern: phonePattern,
                      })}
                      id="phone"
                    />
                    <img src="/icons/call-1.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors1?.phone && errors1?.phone?.message}
                  </p>
                </Flex>
              </div>

              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">
                  Internal Notes{" "}
                  <span>(This is only visible to internal users)</span>
                </label>
                <div className="edit-client-icon-textarea">
                  <textarea
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Enter Notes"
                    {...register1("notes", { required: false })}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="notification-setting">
              <h1 className="notification-heading">Notification Settings</h1>
              <div className="notificaton-toggle-btn">
                <Toggle
                  label={"Enable email notifications"}
                  id={"email-toggle"}
                  register={register1}
                  name="is_email"
                />
                <Toggle
                  label={"Enable text notifications"}
                  id={"text-notification"}
                  register={register1}
                  name="is_sms"
                />
                <Toggle
                  label={"Enable whatsapp communication"}
                  id={"whatsapp-communication"}
                  register={register1}
                  name="is_whatsapp"
                />
                <Toggle
                  label={"Enable marketing notifications"}
                  id={"marketing-notification"}
                  register={register1}
                  name="enable_marketing"
                />
              </div>
            </div>

            <div className="edit-client-btns">
              <Button
                variant="primary"
                label={"Add Client"}
                type="submit"
                loaderColor="#fff"
                loading={addClientState?.loading}
              />
              <Button
                variant="outline"
                label={"Cancel"}
                type="button"
                handleClick={() => setClientPopup(false)}
              />
            </div>
          </form>
        </Popup>
      )}

      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddAppointment;
