import React from "react";
import "./style.css";
import LandingPageLayout from "../../../components/LandingPage/Layout/LandingPageLayout";
import rocketIcon from './rocket-icon.svg'
const HelpCenter = () => {
  return (
    <LandingPageLayout>
      <div className="help-center-container">
        <div class="container">
          {/* <header>
            <div class="logo">
              <div class="logo-circle">
                <div class="logo-inner-circle"></div>
              </div>
              <span class="logo-text">BizOps Pro</span>
            </div>
          </header> */}

          <main>
            <h1>Coming Soon</h1>
            <div class="tagline">
              <p>Bizops Pro, Taking solopreneurs and small businesses to next level</p>
              <img
                src={rocketIcon}
                alt="Rocket icon"
                class="rocket-icon"
              />
            </div>

            {/* <div class="registration-form">
              <h2>Register now for early access</h2>
              <form>
                <div class="form-row">
                  <input type="text" placeholder="Full Name" required />
                  <input type="email" placeholder="Enter your email" required />
                </div>
                <div class="checkbox-container">
                  <input type="checkbox" id="newsletter" />
                  <label for="newsletter">
                    Send newsletter and marketing emails
                  </label>
                </div>
                <button type="submit">Get notified</button>
              </form>
            </div> */}
          </main>

          {/* <footer>
            <p>BizOps Pro All Rights Reserved</p>
          </footer> */}
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default HelpCenter;
