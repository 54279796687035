import React, { useEffect, useState } from "react";
import "./About.css";
import { useDispatch, useSelector } from "react-redux";
import { useFileHandler } from "6pp";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  updateWebsiteDesign,
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import Flex from "../../../../components/styled/Flex/Flex";
import { Switch } from "antd";
import Button from "../../../../components/styled/Button/Button";
import { Edit2 } from "react-feather";
import { convertToAMPM } from "../../../../utills/prettifyDate";
const About = ({ data, isEditAccess }) => {
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website Updated Successfully");
      dispatch(clearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      about_header: data?.about_header ? data?.about_header : "About Us",
      about_company: data?.about_company
        ? data?.about_company
        : `${data.business_id.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      about_team: data?.about_team
        ? data?.about_team
        : `At ${data.business_id.business_name}, our team is our greatest asset. Comprising passionate and talented professionals, each member brings unique expertise and a shared commitment to excellence. our diverse team collaborates to deliver exceptional results for our clients. We believe in fostering a supportive and dynamic work environment where creativity and dedication thrive, driving our success and ensuring we consistently exceed expectations.`,
      team_header: data?.team_header ? data?.team_header : "About Our Team",
      booking_cancellation_policy:
        data?.booking_cancellation_policy || "Booking Cancellation Policy",
    },
  });
  

  const team_photo = useFileHandler("single");
  const about_us_image = useFileHandler("single");
  const [showTeamSection, setShowTeamSection] = useState(
    data?.show_team_section === "false" ? false : true
  );
  return (
    <>
      <div
        className="standard-about-section1"
        style={{
          position: "relative",
          height: "60vh",
        }}
      >
        <img
          src={
            about_us_image.file
              ? about_us_image.preview
              : data?.about_us_image
              ? data?.about_us_image
              : "/templates/standardTemplate/about/about.png"
          }
          alt=""
        />
        <div className="standard-about-sec1-overlay">
          <div className="standard-about-sec1-overlay-inner">
            <div style={{ position: "relative" }}>
              <input
                className="standard-about-heading"
                {...register("about_header")}
                disabled={!isEditAccess}
              />
              {isEditAccess && (
                <Edit2
                  style={{
                    position: "absolute",
                    left: "-10px",
                    top: "-10px",
                  }}
                  size={18}
                  onClick={() => {
                    setFocus("about_header");
                  }}
                  color="#fff"
                />
              )}
            </div>
            <div style={{ position: "relative" }}>
              <textarea
                className="std-about-para"
                {...register("about_company")}
                readOnly={!isEditAccess}
              ></textarea>
              {isEditAccess && (
                <Edit2
                  style={{
                    position: "absolute",
                    left: "-10px",
                    top: "-10px",
                  }}
                  size={18}
                  onClick={() => {
                    setFocus("about_company");
                  }}
                  color="#fff"
                />
              )}
            </div>
          </div>
        </div>
        <div className="global-overlay"></div>

        {isEditAccess && (
          <div className="update-banner-image-input">
            <label htmlFor="banner-image">
              <div className="update-banner-image">Update About Image</div>
            </label>
            <input
              type="file"
              id="banner-image"
              onChange={about_us_image.changeHandler}
            />
          </div>
        )}

        {isEditAccess && (
          <div className="standard-template-switch">
            <Flex align="center" gap={5} className="mb-30">
              <label htmlFor="team" style={{ color: "#fff" }}>
                Show Team Section
              </label>
              <Switch
                defaultChecked={showTeamSection}
                onChange={(checked) => {
                  setShowTeamSection(checked);
                }}
              />
            </Flex>
          </div>
        )}
      </div>

      {isEditAccess && (
        <div className="max-width-1440" style={{ padding: "20px 0" }}>
          <Flex align="center" justify="flex-end">
            <div className="save-button-website" style={{ position: "fixed" }}>
              <form
                onSubmit={handleSubmit((values) => {
                  const apiData = {};
                  apiData.about_company = values.about_company;
                  apiData.about_header = values.about_header;
                  apiData.team_header = values.team_header;
                  apiData.about_team = values.about_team;
                  apiData.show_team_section = showTeamSection;
                  if (team_photo?.file) {
                    apiData.team_photo = team_photo.preview;
                  }
                  if (about_us_image.file) {
                    apiData.about_us_image = about_us_image.preview;
                  }
                  dispatch(updateWebsiteDesign({ data: apiData }));
                })}
              >
                <Button
                  label="Save Changes"
                  type="submit"
                  loading={updateLoading}
                  loaderColor="#fff"
                />
              </form>
            </div>
          </Flex>
        </div>
      )}

      <div style={{maxWidth:'1440px',margin:'0 auto'}}>

      {showTeamSection && (
        <div className="standard-about-section2">
          <div style={{ position: "relative" }}>
            <input
              className="standard-about-sec2-head"
              style={{ textAlign: "left" }}
              {...register("team_header")}
              disabled={!isEditAccess}
            />
            {isEditAccess && (
              <Edit2
                style={{
                  position: "absolute",
                  left: "-10px",
                  top: "-10px",
                }}
                size={18}
                onClick={() => {
                  setFocus("team_header");
                }}
                color="#000"
              />
            )}
          </div>
          <div className="standard-about-sec2-details">
            <div
              className="standard-about-sec2-details-left"
              style={{ position: "relative" }}
            >
              <textarea
                className="standard-about-sec2-details-para1"
                {...register("about_team")}
                readOnly={!isEditAccess}
              ></textarea>
              {isEditAccess && (
                <Edit2
                  style={{
                    position: "absolute",
                    left: "-10px",
                    top: "-15px",
                  }}
                  size={18}
                  onClick={() => {
                    setFocus("about_team");
                  }}
                  color="#000"
                />
              )}
            </div>
            <div className="standard-about-sec2-details-right">
              <img
                src={
                  team_photo?.file
                    ? team_photo.preview
                    : data?.team_photo
                    ? data?.team_photo
                    : "/clasicTemplate/about/Rectangle 34624252.png"
                }
                alt=""
              />
              {isEditAccess && (
                <div
                  className="update-banner-image-input"
                  style={{ zIndex: 2 }}
                >
                  <label htmlFor="team-image">
                    <div className="update-banner-image">Update Team Image</div>
                  </label>
                  <input
                    type="file"
                    id="team-image"
                    onChange={team_photo.changeHandler}
                  />
                </div>
              )}

              <div className="standard-team-overlay"></div>
            </div>
          </div>
        </div>
      )}

{data?.show_business_hours && <div className="business-timings-container">
        <h1>Business Hours</h1>
        <table>
          <thead>
            <tr>
              <th>Day</th>
              <th>Timing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Monday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) => d?.day === "Monday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Monday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Monday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) =>
                    d?.day === "Tuesday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Tuesday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Tuesday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) =>
                    d?.day === "Wednesday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Wednesday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Wednesday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) =>
                    d?.day === "Thursday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Thursday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Thursday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) => d?.day === "Friday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Friday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Friday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) =>
                    d?.day === "Saturday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Saturday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Saturday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>
                {" "}
                {data?.business_hours?.find(
                  (d) => d?.day === "Sunday" && d?.opening_time && d?.close_time
                )
                  ? `${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Sunday")
                        ?.opening_time
                    )} - ${convertToAMPM(
                      data?.business_hours?.find((d) => d?.day === "Sunday")
                        ?.close_time
                    )}`
                  : "Closed"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>}

      

      <div className="booking-cancellation-policy">
        <h1>Booking Cencellation Policy</h1>
        <div style={{ position: "relative" }}>
          <textarea
            {...register("booking_cancellation_policy")}
            maxLength={1000}
            readOnly={!isEditAccess}
          ></textarea>
          {isEditAccess && (
            <Edit2
              style={{
                position: "absolute",
                left: "-15px",
                top: "-15px",
              }}
              size={18}
              cursor="pointer"
              onClick={() => {
                setFocus("booking_cancellation_policy");
              }}
            />
          )}
        </div>
      </div>
      </div>

    </>
  );
};

export default About;
