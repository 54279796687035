import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { BASE_URL } from "../config";
import { createWebsiteDesign } from "../auth/authSlice";

const initialState = {
  loading: false,
  error: null,
  success: false,
  uploadLoading: false,
  uploadError: null,
  uploadSuccess: false,
  updateLoading: false,
  updateSuccess: false,
  updateError: null,
  websiteData:null
};

export const websiteDesignSlice = createSlice({
  name: "websiteDesignSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.uploadError = null;
      state.uploadSuccess = false;
      state.updateError = null;
      state.updateSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebsiteDesign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWebsiteDesign.fulfilled, (state,action) => {
      state.loading = false;
      state.websiteData = action.payload;
    });
    builder.addCase(getWebsiteDesign.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(websiteUpload.pending, (state) => {
      state.uploadLoading = true;
    });
    builder.addCase(websiteUpload.fulfilled, (state) => {
      state.uploadLoading = false;
      state.uploadSuccess = true;
    });
    builder.addCase(websiteUpload.rejected, (state, action) => {
      state.uploadLoading = false;
      state.uploadError = action.payload;
    });
    builder.addCase(updateWebsiteDesign.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateWebsiteDesign.fulfilled, (state) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateWebsiteDesign.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
  },
});

export const checkWebsiteUrl = createAsyncThunk(
  "checkUrl",
  async (apiData, { rejectWithValue, dispatch }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaouthorized - please provide token");
      }
      if (!apiData.data.business_url) {
        return rejectWithValue("Please Provide URL");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/website_design/check_url`,
        { business_url: apiData.data.business_url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      if (data.statusCode === 404) {
        return rejectWithValue(data.message);
      }
      dispatch(createWebsiteDesign(apiData.data));
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const websiteUpload = createAsyncThunk(
  "websiteUpload",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/website_design/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getWebsiteDesign = createAsyncThunk(
  "getWebsiteDesign",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/website_design/view`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if(error?.response.status === 404){
        return 
      }
      else if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateWebsiteDesign = createAsyncThunk("updateWebsiteDesign", async (apiData,{rejectWithValue,getState})=>{
  try {
    const token = getState()?.auth?.token
    if(!token){
      return rejectWithValue("Please provide token")
    }
    const { data } = await axios.patch(
      `${BASE_URL}/v1/website_design/update`,
      apiData.data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.result;
  } catch (error) {
    if (error?.response?.data?.message) {
      return rejectWithValue(error?.response?.data?.message);
    } else {
      return rejectWithValue("Some Error occured Please Try Again Later");
    }
  }
})
export const { clearState } = websiteDesignSlice.actions;
