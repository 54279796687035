import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { BASE_URL } from "../../../config/config";
import { decryptObject } from "../../../utills/encryptionDecription";

const WebsitePayment = () => {
  const query = queryString.parse(window.location.search);
  const { data } = useSelector((state) => state.websiteStyles);
  const [stripeError, setStripeError] = useState(null);
  const navigate = useNavigate();
  const stripePromise = useMemo(() => {
    return loadStripe(
      "pk_test_51OiDOiBW9V1jae3P0soFdWu1GBooB75ob6hbmGDoIrMHRgQupgIJjWxmNDAcgA3E6YeZ3RZvQpIcfCOq9jXIJjvz00kwKJWM0M"
    );
  }, [data?.business_id?.public_key]);

  useEffect(() => {
    if (!query?.cost || !query?.data) {
      navigate(-1);
    } else if (query?.cost) {
      if (!clientSecret.length > 0) {
        const decryptedData = decryptObject(decodeURIComponent(query.data));
        try {
          fetch(`${BASE_URL}/payment/create-payment-intent`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              amount: Number(query.cost),
              business_id: data?.business_id?.id,
              services: decryptedData.services,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              setClientSecret(data.client_secret);
            })
            .catch((err) => {
              return err.json();
            })
            .then((err) => setStripeError(err));
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, []);

  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div style={{ padding: "10px 20px" }}>
      {clientSecret &&
        (!stripeError ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm data={data} dataToken={query?.data} />
          </Elements>
        ) : (
          <h1>{stripeError.error.message}</h1>
        ))}
    </div>
  );
};

export default WebsitePayment;
