import React, { useEffect, useState } from "react";
import "./About.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useFileHandler } from "6pp";
import {
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import Flex from "../../../../components/styled/Flex/Flex";
import { Switch } from "antd";
import { Edit2 } from "react-feather";
import { convertToAMPM } from "../../../../utills/prettifyDate";
const About = ({ data, isEditAccess }) => {
  const { updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website Updated Successfully");
      dispatch(clearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  const { register, setFocus } = useForm({
    defaultValues: {
      about_header: data?.about_header ? data?.about_header : "About Us",
      about_company: data?.about_company
        ? data?.about_company
        : `${data.business_id.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      about_team: data?.about_team
        ? data?.about_team
        : `At ${data.business_id.business_name}, our team is our greatest asset. Comprising passionate and talented professionals, each member brings unique expertise and a shared commitment to excellence. our diverse team collaborates to deliver exceptional results for our clients. We believe in fostering a supportive and dynamic work environment where creativity and dedication thrive, driving our success and ensuring we consistently exceed expectations.`,
      team_header: data?.team_header ? data?.team_header : "About Our Team",
      booking_cancellation_policy:
        data?.booking_cancellation_policy || "Booking Cancellation Policy",
    },
  });
  const team_photo = useFileHandler("single");
  const about_us_image = useFileHandler("single");
  const [showTeamSection, setShowTeamSection] = useState(
    data?.show_team_section === "false" ? false : true
  );
  return (
    <div className="Professional-about-page">
      <div className="Prof-about-main">
        {isEditAccess && (
          <div
            className="update-banner-image-input"
            style={{ zIndex: 1, top: "100px" }}
          >
            <label htmlFor="about-image">
              <div className="update-banner-image">Update About image</div>
            </label>
            <input
              type="file"
              id="about-image"
              onChange={about_us_image.changeHandler}
            />
          </div>
        )}

        <img
          src={
            about_us_image.file
              ? about_us_image?.preview
              : data?.about_us_image
              ? data?.about_us_image
              : "/clasicTemplate/about/Rectangle 1457.png"
          }
          alt=""
        />
        <div
          className="pro-about-detail"
          style={{ maxWidth: "1440px", margin: "0 auto" }}
        >
          <div className="heading-para" style={{ position: "relative" }}>
            <input {...register("about_header")} disabled={!isEditAccess} />
            <textarea
              {...register("about_company")}
              readOnly={!isEditAccess}
            ></textarea>
            {isEditAccess && (
              <>
                <Edit2
                  className="professional-heading-para-head-edit"
                  // style={{
                  //   position: "absolute",
                  //   left: "-15px",
                  //   top: "0px",
                  // }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("about_header");
                  }}
                  color="#fff"
                />
                <Edit2
                  className="professional-heading-para-para-edit"
                  // style={{
                  //   position: "absolute",
                  //   left: "-20px",
                  //   top: "60px",
                  // }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("about_company");
                  }}
                  color="#fff"
                />
              </>
            )}
          </div>
        </div>
        <div className="global-overlay"></div>
        {isEditAccess && (
          <div className="standard-template-switch">
            <Flex align="center" gap={5} className="mb-30">
              <label htmlFor="team" style={{ color: "#fff" }}>
                Show Team Section
              </label>
              <Switch
                defaultChecked={showTeamSection}
                onChange={(checked) => {
                  setShowTeamSection(checked);
                }}
              />
            </Flex>
          </div>
        )}
      </div>

      {showTeamSection && (
        <>
          <div className="Pro-About-second">
            <div style={{ position: "relative" }}>
              <input {...register("team_header")} disabled={!isEditAccess} />
              {isEditAccess && (
                <Edit2
                  style={{
                    position: "absolute",
                    left: "-15px",
                    top: "0px",
                  }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("about_header");
                  }}
                  color="#000"
                />
              )}
            </div>

            <div style={{ position: "relative" }}>
              <textarea
                {...register("about_team")}
                readOnly={!isEditAccess}
              ></textarea>
              {isEditAccess && (
                <Edit2
                  style={{
                    position: "absolute",
                    left: "-15px",
                    top: "0px",
                  }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("about_company");
                  }}
                  color="#000"
                />
              )}
            </div>
          </div>

          <div className="About-Pro-third">
            <div className="Pro-about-fimg1">
              <img src={"/professionaltemplate/about/cross1.png"} alt="" />
            </div>
            <div className="Pro-about-fimg2">
              <img src={"/professionaltemplate/about/cross2.png"} alt="" />
            </div>
            <div
              className="Pro-about-thirdimg"
              style={{ position: "relative" }}
            >
              <div
                className="professional-team-image-container"
                style={{ position: "relative" }}
              >
                <img
                  src={
                    team_photo.file
                      ? team_photo.preview
                      : data?.team_photo
                      ? data?.team_photo
                      : "/clasicTemplate/about/Rectangle 1457.png"
                  }
                  alt=""
                />
                {isEditAccess && (
                  <div
                    className="update-banner-image-input"
                    style={{ zIndex: 1, top: "20px", right: "20px" }}
                  >
                    <label htmlFor="team-image">
                      <div className="update-banner-image">
                        Update Team image
                      </div>
                    </label>
                    <input
                      type="file"
                      id="team-image"
                      onChange={team_photo.changeHandler}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <div style={{maxWidth:'1280px',margin:'0 auto'}}>

      {data?.show_business_hours && (
        <div
          className="business-timings-container"
          style={{ marginBottom: "40px" }}
        >
          <h1 style={{ textAlign: "center" }}>Business Hours</h1>
          <table style={{ margin: "0 auto" }}>
            <thead>
              <tr>
                <th>Day</th>
                <th>Timing</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Monday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Monday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Monday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Tuesday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Tuesday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Tuesday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Wednesday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find(
                          (d) => d?.day === "Wednesday"
                        )?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find(
                          (d) => d?.day === "Wednesday"
                        )?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Thursday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Thursday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Thursday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Friday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Friday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Friday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Saturday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Saturday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Saturday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>
                  {" "}
                  {data?.business_hours?.find(
                    (d) =>
                      d?.day === "Sunday" && d?.opening_time && d?.close_time
                  )
                    ? `${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Sunday")
                          ?.opening_time
                      )} - ${convertToAMPM(
                        data?.business_hours?.find((d) => d?.day === "Sunday")
                          ?.close_time
                      )}`
                    : "Closed"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      </div>

      <div className="booking-cancellation-policy">
        <h1 style={{ textAlign: "center" }}>Booking Cencellation Policy</h1>
        <div style={{ position: "relative" }}>
          <textarea
            {...register("booking_cancellation_policy")}
            maxLength={1000}
            readOnly={!isEditAccess}
            style={{ textAlign: "center" }}
          ></textarea>
          {isEditAccess && (
            <Edit2
              style={{
                position: "absolute",
                left: "50%",
                top: "-18px",
              }}
              size={18}
              cursor="pointer"
              onClick={() => {
                setFocus("booking_cancellation_policy");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
