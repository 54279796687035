import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ClassicTemplateAbout from "../ClassicTemplate/About/About";
import StandardTemplateAbout from "../StandardTemplate/About/About";
import ProfessioalTemplateAbout from "../ProfessionalTemplate/About/About";
import { BUSINESS_ADMIN, authorizeRole } from "../../../utills/authorizeRole";

const WebsiteAbout = () => {
  const { loading, data } = useSelector((state) => state.websiteStyles);
  const {token,userData} = useSelector(state => state.auth)

  const isEditAccess = useMemo(()=>{
    if(token && data.business_id.id === userData.business_id.id && authorizeRole(userData.roles,BUSINESS_ADMIN)){
      return true
    }else{
      return false
    }
  },[token,data?.business_id,userData?.business_id,userData?.roles])
  if (loading) {
    return <PageLoader />;
  }
  if (data?.template === 1) {
    return <ClassicTemplateAbout data={data} isEditAccess={isEditAccess}/>;
  }
  if (data?.template === 2) {
    return <StandardTemplateAbout data={data} isEditAccess={isEditAccess}/>;
  }
  if (data?.template === 3) {
    return <ProfessioalTemplateAbout data={data} isEditAccess={isEditAccess}/>;
  }
};

export default WebsiteAbout;
