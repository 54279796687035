import React, { useEffect, useState } from "react";
import "./AddReferral.css";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import {
  clearState,
  addClientReferral,
} from "../../../features/ServiceProviderPortal/referralSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { emailPattern, namePattern } from "../../../utills/Schems";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { toast } from "react-toastify";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";

const AddReferral = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [dropError, setDropError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { addLoading, addError, addSuccess } = useSelector((state) => state.referrals);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAddClientRef = (values) => {
    if(!selectedService){
      setDropError('Please select')
    }
    else{
      let apiData = {};
      apiData.status = 34;
      apiData.service_id =  selectedService.id;
      apiData.name = values.name;
      apiData.email = values.email
      dispatch(addClientReferral({ token, data: { ...apiData } }));
    }
  };

  useEffect(() => {
    if (addSuccess) {
      toast.success("Referral sent successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/client-portal/referrals");
    }
  }, [addSuccess, navigate, dispatch]);
  // const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  return (
    <div className="outer">
      <Link
        to={"/client-portal/referrals"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Referral</h1>
      <form onSubmit={handleSubmit(handleAddClientRef)}>
        <div className="add-appoinment-inputs" id="add-appoinment-inputs">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="">Name*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"text"}
                placeholder={"Full Name"}
                id="name"
                {...register("name", {
                  required: "Please enter name",
                  pattern: namePattern,
                })}
              />
              <img src="/icons/user.png" alt="icon" />
            </div>

            <p className="global-input-error">
              {errors?.name && errors?.name?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="email">Email*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"text"}
                placeholder={"Email@gmail.com"}
                {...register("email", {
                  required: "Please enter email",
                  pattern: emailPattern,
                })}
                id="email"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>

            <p className="global-input-error">
              {errors?.email && errors?.email?.message}
            </p>
          </Flex>
          {/* <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <ServiceProviderDropDown
              label="Service Provider"
              selectedServiceProvider={selectedServiceProvider}
              setSelectedServiceProvider={setSelectedServiceProvider}
              // defaultSelected
            />
          </Flex> */}
          <div className="add-team-member-input">
            <ServicesDropDown
              label="Select Service*"
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              handleChange={()=>{}}
            />
            <p className="global-input-error">
              {dropError && !selectedService && dropError}
            </p>
          </div>
        </div>
        <div className="edit-client-btns">
          <Button
            variant="primary"
            label={"Add Referral"}
            type="submit"
            loading={addLoading}
            loaderColor="#fff"
          />
        </div>
      </form>
      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddReferral;
