import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  notifications: null,
  error: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/notifications/manage?page=1&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);
export const readNotifications = createAsyncThunk(
  "readNotifications",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }

      await Promise.all(
        apiData.notifications.map(async (notification) => {
          await axios.patch(
            `${BASE_URL}/v1/notifications/seen/${notification.id}`,
            { seen: 1 },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiData.token}`,
              },
            }
          );
        })
      );

      return "Operation Successfull";
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);
