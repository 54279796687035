import React from "react";
import "./style.css";
import LandingPageLayout from "../Layout/LandingPageLayout";

const PrivacyPolicy = () => {
  return (
    <LandingPageLayout>
      <div className="privacy-policy-container">
        <div className="privacy-policy-inner">
          <h1>Privacy Policy for BizOps Pro</h1>
          <p class="effective-date">Effective Date: [Date]</p>

          <p>
            At BizOps Pro ("we," "our," or "us"), we are committed to protecting
            your privacy. This Privacy Policy explains how we collect, use,
            store, and share your personal information when you use our website
            and scheduling and business software services (the "Service"). By
            accessing or using our Service, you agree to the terms of this
            Privacy Policy.
          </p>

          <p>
            Please read this policy carefully to understand how we treat your
            information. If you do not agree with the terms of this policy,
            please do not use our Service.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We collect several types of information to provide and improve our
            Service:
          </p>

          <h3>a. Personal Information</h3>
          <p>
            When you sign up for an account or use our Service, we may collect
            personal details, such as:
          </p>
          <ul style={{listStyleType:'disc'}}>
            <li>Name <strong></strong>(first and last)</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Business name</li>
            <li>
              Billing information (e.g., credit card information, billing
              address)
            </li>
            <li>Profile details (e.g., avatar, preferences)</li>
          </ul>

          <h3>b. Usage Data</h3>
          <p>
            We automatically collect data about your interactions with the
            Service, including:
          </p>
          <ul>
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Device type and operating system</li>
            <li>Pages visited within our website or app</li>
            <li>Time spent on various sections of the website or app</li>
            <li>
              Interaction details (e.g., clicks, scrolls, form submissions)
            </li>
          </ul>

          <h3>c. Cookies and Tracking Technologies</h3>
          <p>
            We use cookies and similar tracking technologies to personalize your
            experience and analyze how you use our Service. Cookies are small
            files stored on your device. You can manage or disable cookies
            through your browser settings, though this may affect your
            experience with certain features of the Service.
          </p>

          <h3>d. Third-Party Integrations</h3>
          <p>
            Our Service may integrate with third-party applications, such as
            payment processors, calendar apps, or customer management tools.
            When you use these integrations, we may collect information provided
            by these third parties. Please review the privacy policies of these
            third-party services for more information.
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>
            We use the information we collect to provide, improve, and customize
            the Service, including for the following purposes:
          </p>
          <ul>
            <li>
              To provide and manage your account (e.g., create accounts, process
              payments, send transactional emails)
            </li>
            <li>
              To deliver the Service features you requested (e.g., scheduling,
              reminders, reporting)
            </li>
            <li>
              To send important communications (e.g., billing information,
              service updates, legal notices)
            </li>
            <li>
              To improve the Service by analyzing usage patterns and user
              feedback
            </li>
            <li>
              To personalize your experience by offering features, content, or
              settings tailored to your needs
            </li>
            <li>
              To comply with legal obligations or respond to legal requests
              (e.g., subpoenas, court orders)
            </li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>
            We do not sell, rent, or trade your personal information. However,
            we may share your information in the following circumstances:
          </p>

          <h3>a. With service providers</h3>
          <p>
            We may share your information with third-party vendors who assist us
            in delivering and supporting the Service. These vendors may include:
          </p>
          <ul>
            <li>Payment processors (for billing purposes)</li>
            <li>Cloud storage providers</li>
            <li>Email service providers</li>
            <li>Analytics platforms</li>
          </ul>
          <p>
            These third-party service providers are obligated to protect your
            information and are not allowed to use it for other purposes.
          </p>

          <h3>b. For legal and regulatory compliance</h3>
          <p>
            We may disclose your information if required to do so by law,
            regulation, or legal process, such as in response to a subpoena,
            court order, or government request.
          </p>

          <h3>c. In connection with business transfers</h3>
          <p>
            In the event of a merger, acquisition, or sale of assets, your
            information may be transferred as part of that transaction. We will
            notify you of any change in ownership or control of your personal
            information.
          </p>

          <h3>d. With your consent</h3>
          <p>
            We may share your information with third parties if you have
            provided explicit consent to do so.
          </p>

          <h2>4. Data Retention</h2>
          <p>
            We retain your personal information for as long as necessary to
            provide the Service to you and to fulfill the purposes described in
            this Privacy Policy. We may retain and use your information for a
            longer period if required by law or for legitimate business
            purposes, such as compliance with legal obligations, resolving
            disputes, and enforcing agreements.
          </p>
          <p>
            When your information is no longer needed for these purposes, we
            will delete or anonymize it in accordance with applicable laws.
          </p>

          <h2>5. Data Security</h2>
          <p>
            We take reasonable precautions to protect your information from
            unauthorized access, use, alteration, and disclosure. We use a
            variety of physical, technical, and administrative security measures
            to safeguard your data. However, no data transmission over the
            internet or electronic storage method is completely secure, and we
            cannot guarantee absolute security.
          </p>
          <p>
            If you suspect any unauthorized activity on your account, please
            contact us immediately.
          </p>

          <h2>6. Your Data Protection Rights</h2>
          <p>
            Depending on your location, you may have the following rights with
            respect to your personal information:
          </p>
          <ul>
            <li>
              Right to access: You can request a copy of the personal data we
              hold about you.
            </li>
            <li>
              Right to rectification: You can ask us to correct any inaccuracies
              in your personal information.
            </li>
            <li>
              Right to erasure: You can request that we delete your personal
              information, subject to certain conditions.
            </li>
            <li>
              Right to restrict processing: You can request that we limit the
              processing of your personal information in certain situations.
            </li>
            <li>
              Right to object to processing: You can object to the processing of
              your personal data, particularly if it is used for direct
              marketing purposes.
            </li>
            <li>
              Right to data portability: You can request to receive your
              personal information in a structured, commonly used format or have
              it transferred to another service provider.
            </li>
          </ul>
          <p>To exercise these rights, please contact us at [Contact Email].</p>

          <h2>7. Children's Privacy</h2>
          <p>
            Our Service is not intended for children under the age of 13. We do
            not knowingly collect personal information from children. If we
            learn that we have collected personal information from a child under
            13 without verifiable parental consent, we will delete that
            information as soon as possible.
          </p>

          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices, technologies, or legal obligations. If we
            make significant changes, we will notify you via email or by posting
            a notice on our website. We encourage you to review this Privacy
            Policy periodically to stay informed about how we are protecting
            your information.
          </p>

          <h2>9. Contact Us</h2>
          <div class="contact-info">
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </p>
            <p>
              BIZOPS PRO LTD,
              <br />
              124 City Road,
              <br />
              London
              <br />
              EC1V2NX
            </p>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default PrivacyPolicy;
