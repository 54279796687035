import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  success: false,
  feedBacks: null,
  feedback: null,
  error: null,
  delLoading: false,
  delSuccess: false,
  delError: null,
  addLoading: false,
  addError: null,
  addSuccess: false,
  updateLoading: false,
  updateError: null,
  updateSuccess: false,
  getLoading:false,
  getError:null
};

export const feedbackSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = null;
      state.delError = null;
      state.delSuccess = null;
      state.addSuccess = false;
      state.addError = null;
      state.updateError = null;
      state.updateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // Get All feedbacks

    builder.addCase(getAllFeedBacks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllFeedBacks.fulfilled, (state, action) => {
      state.loading = false;
      state.feedBacks = action.payload;
    });
    builder.addCase(getAllFeedBacks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addFeedBack.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addFeedBack.fulfilled, (state, action) => {
      state.addLoading = false;
      state.addSuccess = true;
    });
    builder.addCase(addFeedBack.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });
    builder.addCase(updateFeedback.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateFeedback.fulfilled, (state, action) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateFeedback.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
    builder.addCase(deleteFeedback.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteFeedback.fulfilled, (state, action) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteFeedback.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
    builder.addCase(getSingleFeedBack.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getSingleFeedBack.fulfilled, (state, action) => {
      state.getLoading = false;
      state.feedback = action.payload;
    });
    builder.addCase(getSingleFeedBack.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
  },
});

export const getAllFeedBacks = createAsyncThunk(
  "getAllFeedBacks",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/feedbacks/manage?page=1&limit=50`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleFeedBack = createAsyncThunk(
  "getSingleFeedBack",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        rejectWithValue("Unauthorized - Please provide id");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/feedbacks/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const addFeedBack = createAsyncThunk(
  "addFeedBack",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/feedbacks/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const updateFeedback = createAsyncThunk(
  "updateFeedback",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        rejectWithValue("Please provide id");
      }

      const { data } = await axios.patch(
        `${BASE_URL}/v1/feedbacks/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const deleteFeedback = createAsyncThunk(
  "deleteFeedback",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        rejectWithValue("Please provide id");
      }

      const { data } = await axios.delete(
        `${BASE_URL}/v1/feedbacks/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = feedbackSlice.actions;
