import React from "react";

const Notifications = () => {
  return (
    <div className="outer">
      <h1 className="edit-client-heading">Notifications</h1>
      <div className="notification-date-details">
        <span className="notification-date">12 Aug 2023</span>
        <div className="notifications-container">
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
            <NotificationBadge name='Charles Markman' message='has cancelled a new appointment' />
            <NotificationBadge name='Charles Markman' message='appointment is coming up' />
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
        </div>
      </div>
      <div className="notification-date-details">
        <span className="notification-date">14 Aug 2023</span>
        <div className="notifications-container">
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
            <NotificationBadge name='Charles Markman' message='has cancelled a new appointment' />
            <NotificationBadge name='Charles Markman' message='appointment is coming up' />
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
            <NotificationBadge name='Charles Markman' message='has scheduled a new appointment' />
        </div>
      </div>
    </div>
  );
};

const NotificationBadge = ({name, message}) => {
  return (
  <div className="notification-badge">
    <h2 className="notifiaction-sender-name">{name}</h2>
    <span className="notification-message">{message}</span>
  </div>
  );
};

export default Notifications;
