import React, { useEffect, useState } from "react";
import { ArrowLeft, ChevronDown } from "react-feather";
import { Link } from "react-router-dom";
import { ClientDetailsBadge } from "../ClientDetails/ClientDetails";
import "./style.css";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Badge from "../../../components/styled/Badge/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteVoucher,
  resetState,
} from "../../../features/ServiceProviderPortal/vouchersSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { concatNames } from "../../../utills/concatNames";
import { getClient } from "../../../features/ServiceProviderPortal/getClientsSlice";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { getTotal } from "../../../utills/totalFromArrays";
import Popup from "../../../components/styled/Popup/Popup";
import Button from "../../../components/styled/Button/Button";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
const VoucherDetails = () => {
  const [delId, setDelId] = useState(null);
  const [voucherPopup, setVoucherPopup] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const { deleteVoucherLoading, deleteVoucherError, deleteVoucherSuccess } =
    useSelector((state) => state.vouchers);
  const dispatch = useDispatch();
  const { loading, error, client } = useSelector((state) => state.clients);
  const { id } = useParams();
  useEffect(() => {
    if (token && id) {
      dispatch(getClient({ token, id }));
    }
  }, [token, id, dispatch]);
  useEffect(() => {
    if (deleteVoucherSuccess) {
      setVoucherPopup(false);
      dispatch(resetState());
      dispatch(getClient({ token, id }));
    }
  }, [deleteVoucherSuccess, dispatch, token, id]);

  const handleDeleteVoucher = () => {
    const apiData = {
      token,
      id: delId,
    };
    dispatch(deleteVoucher(apiData));
  };
  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div className="voucher-details-container" style={{ position: "relative" }}>
      <Link to={`/portal/client-details/${id}`} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <div className="mb-20">
            <h1 className="edit-client-heading">{`Vouchers- ${concatNames(
              client?.first_name,
              client?.last_name
            )}`}</h1>

            <div className="client-information mb-20">
              <ClientDetailsBadge label="Client Id" value={client?.id} />
              <ClientDetailsBadge
                label="Client Name"
                value={concatNames(client?.first_name, client?.last_name)}
              />
              <ClientDetailsBadge label="Email" value={client?.email} />
              <ClientDetailsBadge label="Phone No" value={client?.phone} />
              <ClientDetailsBadge
                label="Total Amount"
                value={`$${
                  client?.vouchers ? getTotal(client?.vouchers) : "-"
                }`}
              />
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Voucher Amount</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                client?.vouchers.length > 0,
                <TableBody>
                  {client?.vouchers.map((voucher, index) => {
                    return (
                      <TableRow key={voucher?.id}>
                        <TableBodyData>
                          <TableText variant="link">{voucher?.id}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{`$${voucher?.total_amount}`}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                voucher?.status === 26
                                  ? "info"
                                  : voucher?.status === 27
                                  ? "error"
                                  : "success"
                              }
                            >
                              {voucher?.status === 26
                                ? "Sent"
                                : voucher?.status === 27
                                ? "Expired"
                                : "Redeemed"}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" gap={10} justify="center">
                            <Link to={`/portal/edit-voucher/${voucher.id}`}>
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                            <img
                              src="/icons/delete.png"
                              alt="delete"
                              onClick={() => {
                                setDelId(voucher.id);
                                setVoucherPopup(true);
                              }}
                            />
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler
                  label={`No Vouchers Found For ${concatNames(
                    client?.first_name,
                    client?.last_name
                  )}`}
                />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                client?.vouchers?.length > 0,
                client?.vouchers?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Voucher Amount
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>{`$${item?.total_amount}`}</PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                <Flex align="center" justify="flex-start">
                                  <Badge
                                    tone={
                                      item?.status === 26
                                        ? "info"
                                        : item?.status === 27
                                        ? "error"
                                        : "success"
                                    }
                                  >
                                    {item?.status === 26
                                      ? "Sent"
                                      : item?.status === 27
                                      ? "Expired"
                                      : "Redeemed"}
                                  </Badge>
                                </Flex>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex
                                align="center"
                                gap={10}
                                justify="flex-start"
                              >
                                <Link to={`/portal/edit-voucher/${item.id}`}>
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelId(item.id);
                                    setVoucherPopup(true);
                                  }}
                                />
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Appointments to show"} />
              )}
            </ResponsiveContainer>
          </div>,
          <h1>{error}</h1>
        )
      )}

      {voucherPopup && (
        <Popup
          className="client-popup"
          popUp={voucherPopup}
          setPopUp={setVoucherPopup}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteVoucher}
                loading={deleteVoucherLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setVoucherPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {deleteVoucherError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={deleteVoucherError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setVoucherPopup(false);
              dispatch(resetState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default VoucherDetails;
