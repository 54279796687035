import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  subscriptions: null,
  subscription: null,
  dropDown: null,
  addLoading: false,
  addError: null,
  delLoading: false,
  delError: null,
  delSuccess: false,
  getLoading:false,
  getError:null
};

export const subscriptionsSlice = createSlice({
  name: "subscriptionsSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addError = null;
      state.delSuccess = false;
      state.delError = null;
      state.getError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSubscriptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSubscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.subscriptions = action.payload;
    });
    builder.addCase(getAllSubscriptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSubscriptionDrop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubscriptionDrop.fulfilled, (state, action) => {
      state.loading = false;
      state.dropDown = action.payload;
    });
    builder.addCase(getSubscriptionDrop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addSubscription.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addSubscription.fulfilled, (state) => {
      state.success = true;
      state.addLoading = false;
    });
    builder.addCase(addSubscription.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });
    builder.addCase(updateSubscription.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(updateSubscription.fulfilled, (state) => {
      state.success = true;
      state.addLoading = false;
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });

    builder.addCase(deleteSubscription.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteSubscription.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteSubscription.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });

    builder.addCase(getSingleSubscription.pending,(state)=>{
      state.getLoading = true;
    })
    builder.addCase(getSingleSubscription.fulfilled,(state,action)=>{
      state.getLoading = false;
      state.subscription = action.payload
    })
    builder.addCase(getSingleSubscription.rejected,(state,action)=>{
      state.getLoading = false;
      state.getError = action.payload
    })
  },
});

export const getAllSubscriptions = createAsyncThunk(
  "getAllSubscriptions",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/subscriptions/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);
export const getSingleSubscription = createAsyncThunk(
  "getSingleSubscription",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/subscriptions/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);

export const getSubscriptionDrop = createAsyncThunk(
  "getSubscriptionDrop",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/3`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const addSubscription = createAsyncThunk(
  "addSubscription",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/subscriptions/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "deleteSubscription",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/subscriptions/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "updateSubscription",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/subscriptions/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = subscriptionsSlice.actions;
