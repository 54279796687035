import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import Popup from "../../../components/styled/Popup/Popup";
import { useForm } from "react-hook-form";
import Flex from "../../../components/styled/Flex/Flex";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  addFeedBack,
  getAllFeedBacks,
  clearState,
  deleteFeedback,
  getSingleFeedBack,
  updateFeedback,
} from "../../../features/ServiceProviderPortal/feedbackSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { prettifyDate } from "../../../utills/prettifyDate";
import { toast } from "react-toastify";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { handleUndefined } from "../../../utills/handleUndefined";
import { ChevronDown } from "react-feather";
import Pagination from "../../../components/styled/Pagination/Pagination";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";

const Feedback = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  const {
    loading,
    feedBacks,
    error,
    addLoading,
    addError,
    addSuccess,
    delLoading,
    delError,
    delSuccess,
    updateLoading,
    updateSuccess,
    updateError,
    getLoading,
    feedback,
  } = useSelector((state) => state.feedbacks);
  const dispatch = useDispatch();

  const [addPopup, setAddPopup] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const [activeResRow, setActiveResRow] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (token) {
      dispatch(getAllFeedBacks({ token }));
    }
    if (addSuccess) {
      toast.success("Feedback sent successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setAddPopup(false);
    }
    if (delSuccess) {
      toast.success("Feedback deleted successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setDelPopup(false);
    }
    if (updateSuccess) {
      toast.success("Feedback updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setUpdatePopup(false);
    }
  }, [token, dispatch, addSuccess, delSuccess, updateSuccess]);

  useEffect(() => {
    if (feedback) {
      reset1({
        feedback: feedback?.feedback,
      });
    }
  }, [reset1, feedback]);

  const handleAddFeedback = (values) => {
    dispatch(addFeedBack({ token, data: { ...values } }));
  };

  const handleDeleteFeedback = () => {
    dispatch(deleteFeedback({ token, id: delId }));
  };

  const handleUpdateFeedback = (values) => {
    dispatch(updateFeedback({ token, id: updateId, data: { ...values } }));
  };
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="edit-client-heading">Feedback</h1>
        {checkIfPermittedToSingleTab(permissions, 59, userData?.roles) && (
          <Button label="Add feedback" handleClick={() => setAddPopup(true)} />
        )}
      </Flex>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Feedback</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>

              <TableBody>
                {conditionalRender(
                  feedBacks?.items?.length > 0,

                  feedBacks?.items?.map((item, index) => {
                    return (
                      <TableRow>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.id)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>
                            {handleUndefined(item?.feedback)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-actions">
                          <Flex align="center" justify="center" gap={10}>
                            {checkIfPermittedToSingleTab(
                              permissions,
                              60,
                              userData?.roles
                            ) && (
                              <img
                                src="/icons/edit.png"
                                alt="edit"
                                onClick={() => {
                                  dispatch(
                                    getSingleFeedBack({ token, id: item.id })
                                  );
                                  setUpdatePopup(true);
                                  setUpdateId(item.id);
                                }}
                              />
                            )}

                            {checkIfPermittedToSingleTab(
                              permissions,
                              61,
                              userData?.roles
                            ) && (
                              <img
                                src="/icons/delete.png"
                                alt="delete"
                                onClick={() => {
                                  setDelId(item?.id);
                                  setDelPopup(true);
                                }}
                              />
                            )}

                            <Button
                              label="view"
                              variant="outline-sm"
                              handleClick={() => {
                                dispatch(
                                  getSingleFeedBack({ token, id: item.id })
                                );
                                setViewPopup(true);
                              }}
                            />
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  }),
                  <GlobalEmptyDataHandler label="No feedbacks to show" />
                )}
              </TableBody>
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                feedBacks?.items?.length > 0,
                feedBacks?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>ID</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {handleUndefined(item?.id)}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.created_at)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Feedback</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {handleUndefined(item?.feedback)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                <Flex align="center" justify="center" gap={10}>
                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    60,
                                    userData?.roles
                                  ) && (
                                    <img
                                      src="/icons/edit.png"
                                      alt="edit"
                                      onClick={() => {
                                        dispatch(
                                          getSingleFeedBack({
                                            token,
                                            id: item.id,
                                          })
                                        );
                                        setUpdatePopup(true);
                                        setUpdateId(item.id);
                                      }}
                                    />
                                  )}

                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    61,
                                    userData?.roles
                                  ) && (
                                    <img
                                      src="/icons/delete.png"
                                      alt="delete"
                                      onClick={() => {
                                        setDelId(item?.id);
                                        setDelPopup(true);
                                      }}
                                    />
                                  )}
                                  <Button
                                    label="view"
                                    variant="outline-sm"
                                    handleClick={() => {
                                      dispatch(
                                        getSingleFeedBack({
                                          token,
                                          id: item.id,
                                        })
                                      );
                                      setViewPopup(true);
                                    }}
                                  />
                                </Flex>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No feedbacks to show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}
      {feedBacks?.meta?.totalItems > feedBacks?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={feedBacks?.meta?.totalPages}
          />
        )}

      {addPopup && (
        <Popup
          className="add-feedback-popup"
          popUp={addPopup}
          setPopUp={setAddPopup}
        >
          <form
            className="edit-client-lables-inputs"
            id="edit-voucher-lables-inputs"
            onSubmit={handleSubmit(handleAddFeedback)}
          >
            <div className="lable-textarea-group lable-input-group">
              <label htmlFor="notes">Add Feedback</label>
              <div className="edit-client-icon-textarea">
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="Message"
                  {...register("feedback", {
                    required: "Please Enter feedback",
                    minLength: {
                      value: 20,
                      message: "Feedback should not be less then 20 characters",
                    },
                    maxLength: {
                      value: 200,
                      message:
                        "Feedback should not be more then 200 characters",
                    },
                  })}
                ></textarea>
              </div>
              <p className="global-input-error">
                {errors?.feedback && errors?.feedback?.message}
              </p>
            </div>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                variant="outline"
                label={"Cancel"}
                handleClick={() => setAddPopup(false)}
              />
              <Button
                variant="primary"
                label={"Send Feedback"}
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
      {updatePopup && (
        <Popup
          className="add-feedback-popup"
          popUp={updatePopup}
          setPopUp={setUpdatePopup}
        >
          {conditionalRender(
            getLoading,
            <PageLoader />,
            <form
              className="edit-client-lables-inputs"
              id="edit-voucher-lables-inputs"
              onSubmit={handleSubmit1(handleUpdateFeedback)}
            >
              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">Update Feedback</label>
                <div className="edit-client-icon-textarea">
                  <textarea
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Message"
                    {...register1("feedback", {
                      required: "Please Enter feedback",
                      minLength: {
                        value: 20,
                        message:
                          "Feedback should not be less then 20 characters",
                      },
                      maxLength: {
                        value: 200,
                        message:
                          "Feedback should not be more then 200 characters",
                      },
                    })}
                  ></textarea>
                </div>
                <p className="global-input-error">
                  {errors1?.feedback && errors1?.feedback?.message}
                </p>
              </div>
              <Flex align="center" justify="flex-end" gap={10}>
                <Button
                  variant="outline"
                  label={"Cancel"}
                  handleClick={() => setUpdatePopup(false)}
                />
                <Button
                  variant="primary"
                  label={"Update Feedback"}
                  type="submit"
                  loading={updateLoading}
                  loaderColor="#fff"
                />
              </Flex>
            </form>
          )}
        </Popup>
      )}
      {viewPopup && (
        <Popup
          className="add-feedback-popup"
          popUp={viewPopup}
          setPopUp={setViewPopup}
        >
          {conditionalRender(
            getLoading,
            <PageLoader />,
            <form
              className="edit-client-lables-inputs"
              id="edit-voucher-lables-inputs"
              onSubmit={handleSubmit1(handleUpdateFeedback)}
            >
              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">Feedback</label>
                <div className="edit-client-icon-textarea">
                  <textarea
                    readOnly
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Message"
                    {...register1("feedback", {
                      required: "Please Enter feedback",
                      minLength: {
                        value: 20,
                        message:
                          "Feedback should not be less then 20 characters",
                      },
                      maxLength: {
                        value: 200,
                        message:
                          "Feedback should not be more then 200 characters",
                      },
                    })}
                  ></textarea>
                </div>
              </div>
              <Flex align="center" justify="flex-end" gap={10}>
                <Button
                  variant="outline"
                  label={"Close"}
                  handleClick={() => setViewPopup(false)}
                />
              </Flex>
            </form>
          )}
        </Popup>
      )}
      {delPopup && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteFeedback}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {(delError || updateError || addError) && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup
            error={delError ? delError : updateError ? updateError : addError}
          />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Feedback;
