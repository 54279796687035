import React, { useEffect } from "react";
import { ArrowLeft } from "react-feather";
import { Link, useNavigate, useParams } from "react-router-dom";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateTestimonial, clearState, getSingleTestimonial } from "../../../features/SuperAdminPortal/testimonialsSlice";
import { toast } from "react-toastify";

const EditReview = () => {
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const { updateLoading, updateSuccess, testimonial } = useSelector(
    (state) => state.testimonials
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const handleUpdateClientReview = (values) => {
    
      dispatch(updateTestimonial({ token, data: { ...values }, id }));
  };

  useEffect(()=>{
    if(token){
      dispatch(getSingleTestimonial({token, id}))
    }
  },[dispatch,token, id])

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Review updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/client-portal/reviews");
    }
  }, [updateSuccess, navigate, dispatch]);
  useEffect(()=>{
    if(testimonial){
      reset({
        review: testimonial.review,
      })
    }
  },[reset, testimonial])
  return (
    <div className="outer">
      <Link to={"/client-portal/reviews"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Edit Review</h1>
      <form onSubmit={handleSubmit(handleUpdateClientReview)}>
        <div className="lable-textarea-group lable-input-group">
          <label htmlFor="notes">Review*</label>
          <div className="edit-client-icon-textarea">
            <textarea
              name=""
              id="notes"
              cols="135"
              rows="6"
              placeholder="Enter Review"
              {...register("review", {
                required: "Please enter review",
              })}
            ></textarea>
          </div>
            <p className="global-input-error">{errors?.review && errors?.review?.message}</p>
        </div>
        <Flex className="mt-20" gap={20}>
          <Button
            variant="primary"
            label={"Save"}
            type="submit"
            loaderColor="#fff"
            loading={updateLoading}
          />
          <Link to='/client-portal/reviews' onClick={()=> dispatch(clearState())}>
            <Button variant="outline" label={"Cancel"} />
          </Link>
        </Flex>
      </form>
    </div>
  );
};

export default EditReview;
