import React, { useEffect, useState } from "react";
import "./Services.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublicServices,
} from "../../../../features/ServiceProviderPortal/servicesSlice";
import { useNavigate, useParams } from "react-router-dom";
import Flex from "../../../../components/styled/Flex/Flex";
import { transformDuration } from "../../../../utills/prettifyDate";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import { ArrowRight } from "react-feather";
import { encryptObject } from "../../../../utills/encryptionDecription";
const Services = ({ data }) => {
  const { services } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getPublicServices({ id: data.business_id.id }));
  }, [dispatch]);

  const [selectedServices, setSelectedServices] = useState([]);
  const {url} = useParams()
  return (
    // <ClassicTemplateLayout>
    <div className="hero-section" style={{marginTop:'20px'}}>
      <Flex align="flex-end" justify="space-between" className='website-services-top-section'>
        <p className="hero-head mb-20">Services</p>
        {selectedServices.length > 0 && (
          <div
            className="website-selected-services"
            style={{
              background: data?.color || "#3E0FFE",
              padding: "10px 20px",
              borderRadius: "10px",
              cursor:'pointer'
            }}
            onClick={()=>{
              const encryptedToken = encryptObject(selectedServices)
              navigate(`/websites/${url}/book?data=${encryptedToken}`)
            }}
          >
            <Flex align='center' gap={10}>

            <p
              style={{
                color: getTextColorBasedOnBackground(data?.color || "#3E0FFE"),
              }}
            >
              {" "}
              Book {selectedServices.length}{" "}
              {selectedServices.length === 1 ? "Service" : "Services"}
            </p>
            <ArrowRight
              color={getTextColorBasedOnBackground(data?.color || "#3E0FFE")}
              size={20}
            />
            </Flex>
          </div>
        )}
      </Flex>
      {/* <p className="card-heading">Hair stylist </p> */}
      <div className="classic-template-services-container">
        {services?.map((service, index) => {
          return (
            <div className="card1" style={{ position: "relative" }}>
              <img
                src={
                  service?.base_url
                    ? service?.base_url
                    : "/clasicTemplate/services/Rectangle 34624244.png"
                }
                alt=""
              />
              <div className="service-card-inner">
                <Flex
                  align="center"
                  justify="space-between"
                  className="service-info"
                >
                  <p className="services-card-p">{service?.name}</p>
                  {service?.service_category_id?.name && (
                    <div
                      className="service-category-badge"
                      style={{
                        background: data?.color || "#3E0FFE",
                        color: getTextColorBasedOnBackground(
                          data?.color || "#3E0FFE"
                        ),
                      }}
                    >
                      <p>{service?.service_category_id?.name}</p>
                    </div>
                  )}
                  <p>£{service?.cost}</p>
                </Flex>
                <Flex className="service-info" align="center">
                  <p>{transformDuration(service?.duration)}</p>
                </Flex>
                <p style={{fontSize:'14px',color:'#666'}}>{service?.notes}</p>
                <button
                  className="book-now-btn"
                  style={{
                    borderColor: data?.color || "#3E0FFE",
                    color: data?.color || "#3E0FFE",
                  }}
                  onClick={() => {
                    if (selectedServices.find((s) => s.id === service.id)) {
                      const updatedServices = selectedServices.filter(
                        (s) => s.id !== service.id
                      );
                      setSelectedServices(updatedServices);
                    } else {
                      setSelectedServices([...selectedServices, service]);
                    }
                  }}
                >
                  {selectedServices.find((s) => s.id === service.id)
                    ? "Unselect"
                    : "Select"}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    // </ClassicTemplateLayout>
  );
};

export default Services;
