import React, { useEffect, useState } from "react";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { ChevronLeft, ChevronRight, Edit2 } from "react-feather";
import { useForm } from "react-hook-form";
import Flex from "../../../../components/styled/Flex/Flex";
import { useFileHandler } from "6pp";
import { ColorPicker, Rate } from "antd";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import Button from "../../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateWebsiteDesign,
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import { toast } from "react-toastify";
import { concatNames } from "../../../../utills/concatNames";
import { Link } from "react-router-dom";
const Home = ({ data, color, setColor, isEditAccess }) => {
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      heading:
        data?.heading || `Welcome to ${data?.business_id?.business_name}`,
      sub_heading:
        data?.sub_heading ||
        `${data?.business_id?.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      review_text: data?.review_text || "Reviews By Our Clients",
    },
  });

  const banner_image = useFileHandler("single");
  const dispatch = useDispatch();
  const [bannerReset, setBannerReset] = useState(null);
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website update successfully");
      dispatch(clearState());
      window.location.reload();
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);

  // console.log(checkIfEditAccess(data.business_id.id))
  return (
    <div style={{ position: "relative" }}>
      {/* // <ClassicTemplateLayout color={websiteDesign?.color}> */}
      {/*------------- Mobile responsiveness------------- */}
      <div className="mobile-responsive">
        <div className="mobile-section-one" style={{ position: "relative" }}>
          <textarea
            style={{ maxWidth: "100%", minHeight: "130px" }}
            className="overlay-head"
            {...register("heading")}
            maxLength={35}
            readOnly={!isEditAccess}
          ></textarea>
          <textarea
            className="mobile-para"
            {...register("sub_heading")}
            maxLength={230}
            readOnly={!isEditAccess}
          ></textarea>
          {isEditAccess && (
            <>
              <Edit2
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "5px",
                }}
                size={18}
                onClick={() => {
                  setFocus("heading");
                }}
              />
              <Edit2
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "100px",
                }}
                size={17}
                cursor="pointer"
                onClick={() => {
                  setFocus("sub_heading");
                }}
              />
            </>
          )}
        </div>
        <Link to={`/websites/${data?.business_url}/book`}>
          <button
            className=" mobile-btn"
            style={{
              background: color,
              color: getTextColorBasedOnBackground(color),
            }}
          >
            BOOK APPOINTMENT
          </button>
        </Link>

        <div className="mobile-section-2 mb-20">
          <img
            className="mob-img"
            src={
              bannerReset
                ? bannerReset
                : banner_image.preview
                ? banner_image.preview
                : data?.main_banner_image
                ? data?.main_banner_image
                : "/ClasicTemplate/home/banner.png"
            }
            alt=""
          />
          {isEditAccess && (
            <div
              className="website-color-picker"
              style={{ top: "10px", right: "10px" }}
            >
              <Flex align="center" gap={7}>
                <label htmlFor="color">Choose Color</label>
                <ColorPicker
                  value={color}
                  onChangeComplete={(color) => {
                    setColor(color.toHexString());
                  }}
                />
              </Flex>
            </div>
          )}

          <div className="mob-overlay"></div>
        </div>
      </div>
      <div className="section-one">
        <img
         src={
          bannerReset
            ? bannerReset
            : banner_image.preview
            ? banner_image.preview
            : data?.main_banner_image
            ? data?.main_banner_image
            : "/ClasicTemplate/home/banner.png"
        }
          alt="home page banner"
          style={{ height: "100%" }}
        />
        <div className="home-overlay">
          <div style={{ position: "relative" }}>
            <textarea
              className="overlay-head"
              {...register("heading")}
              maxLength={35}
              readOnly={!isEditAccess}
            ></textarea>
            {isEditAccess && (
              <Edit2
                style={{
                  position: "absolute",
                  right: "0",
                  top: "120px",
                  zIndex: "1000",
                }}
                size={18}
                cursor="pointer"
                onClick={() => {
                  setFocus("heading");
                }}
              />
            )}
          </div>
          <div style={{ position: "relative" }}>
            <textarea
              className="overlay-para"
              {...register("sub_heading")}
              maxLength={230}
              readOnly={!isEditAccess}
            ></textarea>
            {isEditAccess && (
              <Edit2
                style={{
                  position: "absolute",
                  right: "-20px",
                  top: "20px",
                  zIndex: "1000",
                }}
                cursor="pointer"
                onClick={() => {
                  setFocus("sub_heading");
                }}
                size={18}
              />
            )}
          </div>
          <Link to={`/websites/${data?.business_url}/book`}>
            <button
              className="get-consultation-btn book-appointment-btn"
              style={{
                background: color,
                color: getTextColorBasedOnBackground(color),
              }}
            >
              BOOK APPOINTMENT
            </button>
          </Link>
        </div>
        {isEditAccess && (
          <div className="update-banner-image-input">
            <label htmlFor="banner-image">
              <div className="update-banner-image">Update banner Image</div>
            </label>
            <input
              type="file"
              id="banner-image"
              onChange={banner_image.changeHandler}
            />
          </div>
        )}

        {isEditAccess && (
          <div className="website-color-picker" style={{ zIndex: "1000" }}>
            <Flex align="center" gap={7}>
              <label htmlFor="color">Choose Color</label>
              <ColorPicker
                value={color}
                onChangeComplete={(color) => {
                  setColor(color.toHexString());
                }}
              />
            </Flex>
          </div>
        )}
        {isEditAccess && data?.main_banner_image && (
          <div className="use-default-button-container">
            <button
              onClick={() => setBannerReset("/ClasicTemplate/home/banner.png")}
            >
              Use Default
            </button>
          </div>
        )}
      </div>
      {data?.business_id?.testimonials &&
        data?.business_id?.testimonials.length > 1 && (
          <div className="section-two">
            <h2 className="review">Reviews</h2>
            <div style={{ position: "relative" }}>
              <Flex align="center" justify="center" className="relative">
                <textarea
                  className="para"
                  {...register("review_text")}
                  maxLength={170}
                  readOnly={!isEditAccess}
                ></textarea>
                {isEditAccess && (
                  <Edit2
                    style={{
                      position: "absolute",
                      right: "150px",
                      top: "20px",
                      zIndex: "1000",
                    }}
                    className="review-text-edit-btn"
                    cursor="pointer"
                    onClick={() => {
                      setFocus("review_text");
                    }}
                    size={18}
                  />
                )}
              </Flex>
            </div>

            <div className="Home-slider">
              <div className="home-slider-inner">
                <Swiper
                  slidesPerView={2}
                  spaceBetween={20}
                  breakpoints={{
                    200: { slidesPerView: 1 },
                    431: { slidesPerView: 2 },
                  }}
                  keyboard={{
                    enabled: true,
                  }}
                  // autoplay={{
                  //   delay: 3000,
                  //   disableOnInteraction: false,
                  // }}
                  navigation={{
                    nextEl: ".home-slider-next",
                    prevEl: ".home-slider-prev",
                  }}
                  modules={[Navigation, keyboard, Autoplay]}
                  className="mySwiper"
                  loop={true}
                >
                  {data?.business_id?.testimonials.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div className="card">
                          <p style={{ fontFamily: "Roboto", height: "150px" }}>
                            {item?.review}
                          </p>
                          <div className="image-rating">
                            {/* <img
                              src={"/clasicTemplate/home/Ellipse 5182.png"}
                              alt=""
                            /> */}
                            <div className="ratings">
                              <h3>
                                {concatNames(
                                  item?.client_id?.first_name,
                                  item?.client_id?.last_name
                                )}
                              </h3>
                              {/* <Rate disabled defaultValue={4} /> */}
                              <Rate value={parseInt(item?.rating)} disabled />
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              <button
                className="home-slider-prev"
                style={{ backgroundColor: color }}
              >
                <ChevronLeft color={getTextColorBasedOnBackground(color)} />
              </button>
              <button
                className="home-slider-next"
                style={{ backgroundColor: color }}
              >
                <ChevronRight color={getTextColorBasedOnBackground(color)} />
              </button>
            </div>
          </div>
        )}

      {isEditAccess && (
        <div className="save-button-website">
          <form
            onSubmit={handleSubmit((values) => {
              const apiData = {};
              apiData.heading = values.heading;
              apiData.sub_heading = values.sub_heading;
              apiData.review_text = values.review_text;
              apiData.color = color;
              if(bannerReset){
                apiData.main_banner_image = bannerReset
              }
              else if (banner_image.file) {
                apiData.main_banner_image = banner_image.preview;
              }else{}
              dispatch(updateWebsiteDesign({ data: apiData }));
            })}
          >
            <Button
              label="Save Changes"
              type="submit"
              loading={updateLoading}
              loaderColor="#fff"
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default Home;
