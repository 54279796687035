import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  referrals: null,
  success: false,
  referral: null,
  dropDownLoading: false,
  dropDownError: null,
  dropDown: null,
  delLoading:false,
  delSuccess:false,
  delError:null,
  addLoading: false,
  addSuccess: false,
  addError: null,
};

export const referralsSlice = createSlice({
  name: "clientReferrals",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addSuccess = false;
      state.addError = null;
      state.delError = null;
      state.delSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClientReferrals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllClientReferrals.fulfilled, (state, action) => {
      state.loading = false;
      state.referrals = action.payload;
    });
    builder.addCase(getAllClientReferrals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add client refferal
    builder.addCase(addClientReferral.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addClientReferral.fulfilled, (state) => {
      state.addLoading = false;
      state.addSuccess = true;
    });
    builder.addCase(addClientReferral.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });

    // Get Refferals dropdown
    builder.addCase(getClientRefferalsDropDown.pending, (state) => {
      state.dropDownLoading = true;
    });
    builder.addCase(getClientRefferalsDropDown.fulfilled, (state, action) => {
      state.dropDownLoading = false;
      state.dropDown = action.payload;
    });
    builder.addCase(getClientRefferalsDropDown.rejected, (state, action) => {
      state.dropDownLoading = false;
      state.dropDown = action.payload;
    });

    // Update client cases
    builder.addCase(updateClientRefferal.pending,(state)=>{
      state.loading = true
    });
    builder.addCase(updateClientRefferal.fulfilled,(state)=>{
      state.loading = false;
      state.success = true;
    })
    builder.addCase(updateClientRefferal.rejected,(state,action)=>{
      state.loading = false;
      state.error = action.payload;

    })
    builder.addCase(deleteRefferal.pending,(state)=>{
      state.delLoading = true
    });
    builder.addCase(deleteRefferal.fulfilled,(state)=>{
      state.delLoading = false;
      state.delSuccess = true;
    })
    builder.addCase(deleteRefferal.rejected,(state,action)=>{
      state.delLoading = false;
      state.delError = action.payload;
    })
  },
});

export const getAllClientReferrals = createAsyncThunk(
  "clientReferrals",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/client_refferals/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occurred please try again");
    }
  }
);

export const addClientReferral = createAsyncThunk(
  "AddClientReferral",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/client_refferals/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occurred please try again");
    }
  }
);
export const getClientRefferalsDropDown = createAsyncThunk(
  "getClientRefferalsDropDown",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/lookup/dropdown/10`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occurred please try again");
    }
  }
);

export const updateClientRefferal = createAsyncThunk(
  "updateClientRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }

      const { data } = await axios.patch(
        `${BASE_URL}/v1/client_refferals/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occurred please try again");
    }
  }
);
export const deleteRefferal = createAsyncThunk(
  "deleteRefferal",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/client_refferals/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const { clearState } = referralsSlice.actions;
