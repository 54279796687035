import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientSignup, login } from "./authActions";
import { signup } from "./authActions";
import axios from "axios";
import { BASE_URL } from "../config";
import { signUpOtpVerification } from "./forgetPasswordSlice";
const authInitialState = {
  userData: {},
  token: null,
  websiteDesign: null,
  forgetPassUser: null,
  signup: null,
};
const tempAuthData = {
  loading: false,
  error: null,
  success: false,
  validateLoading: false,
  validateError: null,
  validateSuccess: false,
  getUserLoading: false,
  getUserError: null,
  getUserSuccess: false,
  updateLoading: false,
  updateError: null,
  updateSuccess: false,
  clientSignupLoading: false,
  clientSignupError: null,
  clientSignupSuccess: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    clearState: (state) => {
      state.token = null;
      state.userData = null;
      state.forgetPassUser = null;
      state.websiteDesign = null;
    },
    createWebsiteDesign: (state, action) => {
      state.websiteDesign = action.payload;
    },
    clearWebsiteDesign: (state) => {
      state.websiteDesign = null;
    },
    createForgetPassUser: (state, action) => {
      state.forgetPassUser = action.payload;
    },
    clearForgetPassUser: (state) => {
      state.forgetPassUser = null;
    },
    updateUserData: (state, action) => {
      state.userData = action.payload;
    },
    updateStripeAccountPaymentStatus: (state, action) => {
      state.userData.is_payment = action.is_payment;
      state.userData.business_id.is_stripe_account = action.is_stripe_account;
    },
    saveSignUpData: (state, action) => {
      state.signup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userData = action.payload.userData;
      state.token = action.payload.token;
    });
    builder.addCase(signUpOtpVerification.fulfilled, (state, action) => {
      state.userData = action.payload.userData;
      state.token = action.payload.token;
    });
  },
});

export const authTemporarySlice = createSlice({
  name: "authTemp",
  initialState: tempAuthData,
  reducers: {
    clearStateTemp: (state) => {
      state.error = null;
      state.success = false;
      state.updateSuccess = false;
      state.updateError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
    builder.addCase(signup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
    builder.addCase(validateToken.pending, (state) => {
      state.validateLoading = true;
    });
    builder.addCase(validateToken.fulfilled, (state) => {
      state.validateLoading = false;
      state.validateSuccess = true;
    });
    builder.addCase(validateToken.rejected, (state, action) => {
      state.validateLoading = false;
      state.validateError = action.payload;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.getUserLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state) => {
      state.getUserLoading = false;
      state.getUserSuccess = true;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.getUserLoading = false;
      state.getUserError = action.payload;
    });

    builder.addCase(updateSignup.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateSignup.fulfilled, (state) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateSignup.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
    builder.addCase(clientSignup.pending, (state) => {
      state.clientSignupLoading = true;
    });
    builder.addCase(clientSignup.fulfilled, (state) => {
      state.clientSignupLoading = false;
      state.clientSignupSuccess = true;
    });
    builder.addCase(clientSignup.rejected, (state, action) => {
      state.clientSignupLoading = false;
      state.clientSignupError = action.payload;
    });
  },
});

export const validateToken = createAsyncThunk(
  "validateToken",
  async (token, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/validate-accesstoken`,
        { token }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.statusCode === 500) {
        dispatch(clearState());
      }
    }
  }
);

export const getUserData = createAsyncThunk(
  "getUserData",
  async (apiData, { rejectWithValue, dispatch, getState }) => {
    try {
      const token = getState().auth.token;
      if (!token) {
        return rejectWithValue("Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/users/login-userinfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(authSlice.actions.updateUserData(data.result));
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);


export const updateSignup = createAsyncThunk(
  "auth/updateSignup",
  async (apiData, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/update/signup/${apiData.id}`,
        apiData.data
      );
      if (data.statusCode === 409) {
        return rejectWithValue(data.message);
      }
      dispatch(getUserData());
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error?.response?.data?.statusCode === 409) {
          return rejectWithValue(error?.response?.data?.message);
        } else if (typeof error?.response?.data?.message === "object") {
          return rejectWithValue("Please fillout the form correctly");
        } else {
          return rejectWithValue(error?.response?.data?.message);
        }
      } else {
        return rejectWithValue("Some Error Occured During Login");
      }
    }
  }
);

export const signupUpdate = (apiData) => {
  return axios.patch(
    `${BASE_URL}/v1/users/update/signup/${apiData.id}`,
    apiData.data
  );
};

export const {
  clearState,
  createWebsiteDesign,
  clearForgetPassUser,
  clearWebsiteDesign,
  createForgetPassUser,
  saveSignUpData,
  updateUserData,
  updateStripeAccountPaymentStatus,
} = authSlice.actions;
export const { clearStateTemp } = authTemporarySlice.actions;
