export const businessType = [
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T11:28:12.534Z",
    updated_at: "2023-12-19T11:28:12.534Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 5,
    name: "Beauty and Wellness",
    alias: "",
    description: "Beauty and Wellness",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T11:29:24.675Z",
    updated_at: "2023-12-19T11:29:24.675Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 6,
    name: "Hairstylists And Salons",
    alias: "",
    description: "Hairstylists And Salons",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T11:30:19.951Z",
    updated_at: "2023-12-19T11:30:19.951Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 8,
    name: "Professional Services",
    alias: "",
    description: "Professional Services",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T11:30:08.744Z",
    updated_at: "2023-12-19T11:30:08.744Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 7,
    name: "Sports And Fitness",
    alias: "",
    description: "Sports And Fitness",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2024-03-28T08:30:39.169Z",
    updated_at: "2024-03-28T08:30:39.169Z",
    created_by: "info@bizopspro.com",
    updated_by: "info@bizopspro.com",
    id: 42,
    name: "Other",
    alias: "",
    description: "Other business type",
  },
];

export const subscriptionDrop = [
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:25:22.828Z",
    updated_at: "2023-12-19T15:25:22.828Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 9,
    name: "15 day free trial",
    alias: "",
    description: "15 day free trial",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:25:47.259Z",
    updated_at: "2023-12-19T15:25:47.259Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 10,
    name: "Scaling pro",
    alias: "",
    description: "Scaling pro",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:26:05.982Z",
    updated_at: "2023-12-19T15:26:05.982Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 11,
    name: "Super pro",
    alias: "",
    description: "Super pro",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:26:22.797Z",
    updated_at: "2023-12-19T15:26:22.797Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 12,
    name: "Supreme pro",
    alias: "",
    description: "Supreme pro",
  },
];
export const subscriptionDropWithoutFreeTrial = [
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:25:47.259Z",
    updated_at: "2023-12-19T15:25:47.259Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 10,
    name: "Scaling pro",
    alias: "",
    description: "Scaling pro",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:26:05.982Z",
    updated_at: "2023-12-19T15:26:05.982Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 11,
    name: "Super pro",
    alias: "",
    description: "Super pro",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-19T15:26:22.797Z",
    updated_at: "2023-12-19T15:26:22.797Z",
    created_by: "latifoomail@gmail.com",
    updated_by: "latifoomail@gmail.com",
    id: 12,
    name: "Supreme pro",
    alias: "",
    description: "Supreme pro",
  },
];

