import React from "react";
import "./style.css";
import { ArrowLeft, Check } from "react-feather";
import Footer from "../../../components/LandingPage/Footer/Footer";
import Flex from "../../../components/styled/Flex/Flex";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
const Layout = ({ children, step, steps = true, isFooter = true,heading='Sign Up' }) => {
  const navigate = useNavigate();
  return (
    <div className="max-width-1440 signup-main-container">
      <ScrollToTop />
      <div className="signup-main-container-inner">
        <div className="signup-left">
          <Link to="/">
            <img src="/logo.png" alt="Logo" className="signup-logo" />
          </Link>
          <img src="/signup/signup-banner.png" alt="SignUp Banner" />
        </div>
        <div className="signup-right">
          <div className="res-image">
            <Link to="/">
              <img src="/logo.png" alt="Logo" className="signup-logo" />
            </Link>
          </div>
          <Flex className="w-100" align="center" justify="space-between">
            <h3>{heading}</h3>

            {(step === "2" || step === "3") && (
              <Flex
                align="center"
                className="back signup-back-btn"
                handleClick={() => {
                  let converted = parseInt(step);
                  navigate(`/signup?step=${--converted}`);
                }}
              >
                <ArrowLeft size={24} color="#5459FF" /> Back
              </Flex>
            )}
          </Flex>
          {steps && (
            <div className="steps">
              <div className="step active">
                <Check className="check-icon" color="#fff" />
              </div>
              <div className="line active"></div>
              <div
                className={`step ${step === '2' || step === '3' ? "active" : ""}`}
              >
                <Check className="check-icon" color="#fff" />
              </div>
              <div className={`line ${step === '3' ? "active" : ""}`}></div>
              <div className={`step ${step === '3' ? "active" : ""}`}>
                <Check className="check-icon" color="#fff" />
              </div>
            </div>
          )}

          {children}
        </div>
      </div>
      {isFooter && <Footer />}
    </div>
  );
};

export default Layout;
