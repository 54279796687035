import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import Popup from "../../../components/styled/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  deleteTestimonial,
  getAllTestimonials,
  updateTestimonial,
} from "../../../features/SuperAdminPortal/testimonialsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { concatNames } from "../../../utills/concatNames";
import { prettifyDate } from "../../../utills/prettifyDate";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { handleUndefined } from "../../../utills/handleUndefined";
import { toast } from "react-toastify";

const Reviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { token } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    testimonials,
    delError,
    delLoading,
    updateLoading,
    updateSuccess,
    updateError,
  } = useSelector((state) => state.testimonials);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(
        getAllTestimonials({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (updateSuccess) {
      setDelPopup(false);
      dispatch(clearState());
      toast.success("Operation successfull")
    }
  }, [token, dispatch, updateSuccess, currentPage, itemsPerPage]);

  useEffect(()=>{
    if(updateError){
        toast.error(updateError)
        dispatch(clearState());
    }
  },[updateError,dispatch])

  const handleDeleteClientReview = () => {
    dispatch(deleteTestimonial({ token, id: delId }));
  };

  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const [activeResRow, setActiveResRow] = useState(1);

  return (
    <div
      className="outer client-portal-reviews"
      style={{ position: "relative" }}
    >
      <Flex align={"center"} justify={"space-between"} gap={10}>
        <h1 className="edit-client-heading">Reviews</h1>
      </Flex>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          error,
          <GlobalErrorHandler label={error} />,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Client Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Review</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                testimonials?.items?.length > 0,
                <TableBody>
                  {testimonials?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>
                            {concatNames(item?.client_id?.first_name,item?.client_id?.last_name)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>
                            {handleUndefined(item?.client_id?.email)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>
                            {item?.review}
                          </TableText>
                        </TableBodyData>
                        {/* <TableBodyData>
                          <TableText>{item?.from?.full_name}</TableText>
                        </TableBodyData> */}

                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>

                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            {item?.is_active ? (
                              <Button
                                variant="outline"
                                label="Remove From Website"
                                handleClick={() => {
                                  dispatch(
                                    updateTestimonial({
                                      token,
                                      data: { is_active: 0 },
                                      id: item.id,
                                    })
                                  );
                                }}
                                loaderColor="#3E0FFE"
                                loading={updateLoading}
                              />
                            ) : (
                              <Button
                                variant="outline"
                                label="Add To Website"
                                handleClick={() => {
                                  dispatch(
                                    updateTestimonial({
                                      token,
                                      data: { is_active: 1 },
                                      id: item.id,
                                    })
                                  );
                                }}
                                loaderColor="#3E0FFE"
                                loading={updateLoading}
                              />
                            )}
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Referrals to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                testimonials?.items?.length > 0,
                testimonials?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Client Name</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                              {concatNames(item?.client_id?.first_name,item?.client_id?.last_name)}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                <TableText>
                                {handleUndefined(item?.client_id?.email)}
                                </TableText>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          {/* <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Service</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {"Hair Dresser"}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow> */}
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Review</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {item?.review}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>

                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.created_at)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          {/* <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex
                                align="center"
                                justify="flex-start"
                                gap={10}
                              >
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                                <Link
                                  to={`/portal/edit-client-referrals/${item?.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow> */}
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>
        )
      )}
      {testimonials?.meta?.totalItems > testimonials?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={testimonials?.meta?.totalPages}
          />
        )}
      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClientReview}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Reviews;
