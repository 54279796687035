import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authSlice, authTemporarySlice } from "./auth/authSlice";
import signupDataReducer from "./signup/signupSlice";
import { getClientsSlice } from "./ServiceProviderPortal/getClientsSlice";
import { vouchersSlice } from "./ServiceProviderPortal/vouchersSlice";
import { invoicesSlice } from "./ServiceProviderPortal/invoiceSlice";
import { teamMemberSlice } from "./ServiceProviderPortal/teamMemberSlice";
import { appoinmentSlice } from "./ServiceProviderPortal/appoinmentSlice";
import { referralsSlice } from "./ServiceProviderPortal/referralSlice";
import { servicesSlice } from "./ServiceProviderPortal/servicesSlice";
import { businessReferralsSlice } from "./ServiceProviderPortal/businessReferralsSlice";
import { subscriptionsSlice } from "./ServiceProviderPortal/SubscriptionsSlice";
import { discountSlice } from "./ServiceProviderPortal/discountsSlice";
import { subscriptionInvoicesSlice } from "./ServiceProviderPortal/subscriptionInvoicesSlice";
import { appointmentSettingsSlice } from "./ServiceProviderPortal/appointmentSettingsSlice";
import { updateProfileSlice } from "./ServiceProviderPortal/updateProfileSlice";
// import { forgetPassSlice } from "./ServiceProviderPortal/forgetPassSlice";
import { forgetPassSlice } from "./auth/forgetPasswordSlice";
import { websiteDesignSlice } from "./ServiceProviderPortal/websiteDesignSlice";
import { websiteSLice } from "./Websites/websitesSlice";
import { intakeFormSlice } from "./ServiceProviderPortal/intakeFormSlice";
import { dashboardSlice } from "./ServiceProviderPortal/dashboardSlice";
import { feedbackSlice } from "./ServiceProviderPortal/feedbackSlice";
import { serviceProvidersSlice } from "./SuperAdminPortal/serviceProvidersSlice";
import { BusinessSlice } from "./SuperAdminPortal/businessesSlice";
import { adminClientsSlice } from "./SuperAdminPortal/clientsSlice";
import { testimonialSlice } from "./SuperAdminPortal/testimonialsSlice";
import {adminDashboardSlice} from "./SuperAdminPortal/adminDashboardSlice"
import { mainWebsiteSlice } from "./MainWebsite/mainWebsiteSlice";
import { pricingSlice } from "./SuperAdminPortal/pricingSlice";
import { accessLevelsSlice } from "./ServiceProviderPortal/accessLevelsSlice";
import { appointmentSlice } from "./ClientPortal/appointmentSlice";
import { serviceCategorySlice } from "./ServiceProviderPortal/serviceCategory";
import { jobTitleSlice } from "./ServiceProviderPortal/jobTitleSlice";
import { notificationsSlice } from "./ServiceProviderPortal/notificationsSlice";
const persistConfig = {
  key: "root",
  storage,
};
export const store = configureStore({
  reducer: {
    auth: persistReducer(persistConfig, authSlice.reducer),
    authTemp: authTemporarySlice.reducer,
    signUpData: signupDataReducer,
    clients: getClientsSlice.reducer,
    vouchers: vouchersSlice.reducer,
    invoices: invoicesSlice.reducer,
    team: teamMemberSlice.reducer,
    appoinments: appoinmentSlice.reducer,
    appointments: appointmentSlice.reducer,
    referrals: referralsSlice.reducer,
    services: servicesSlice.reducer,
    serviceCategory: serviceCategorySlice.reducer,
    jobTitle: jobTitleSlice.reducer,
    businessReferrals: businessReferralsSlice.reducer,
    subscriptions: subscriptionsSlice.reducer,
    discounts: discountSlice.reducer,
    subInvoices: subscriptionInvoicesSlice.reducer,
    appointmentSettings: appointmentSettingsSlice.reducer,
    updateProfile: updateProfileSlice.reducer,
    forgetPass: forgetPassSlice.reducer,
    webDesign: websiteDesignSlice.reducer,
    websiteStyles: websiteSLice.reducer,
    form: intakeFormSlice.reducer,
    dashboard: dashboardSlice.reducer,
    AdminDashboard: adminDashboardSlice.reducer,
    feedbacks: feedbackSlice.reducer,
    providers: serviceProvidersSlice.reducer,
    businesses: BusinessSlice.reducer,
    adminClients: adminClientsSlice.reducer,
    testimonials: testimonialSlice.reducer,
    mainWebsiteData: mainWebsiteSlice.reducer,
    pricing: pricingSlice.reducer,
    permissions: accessLevelsSlice.reducer,
    notifications: notificationsSlice.reducer
  },
});

export const logout = () => {
  persistor.purge();
};

export const persistor = persistStore(store);
