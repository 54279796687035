import React, { useEffect, useState } from "react";
import "./ServiceCategory.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { Link } from "react-router-dom";
import Popup from "../../../components/styled/Popup/Popup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addServiceCategory,
  deleteServiceCategory,
  getAllCategories,
  updateServiceCategory,
  clearState
} from "../../../features/ServiceProviderPortal/serviceCategory";
// import { clearState } from "../../../features/ServiceProviderPortal/serviceCategory";
import { toast } from "react-toastify";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { handleUndefined } from "../../../utills/handleUndefined";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";

const ServiceCategory = () => {
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const [addServiceCategoryPopup, setAddServiceCategoryPopup] = useState(false);
  const [updateServiceCategoryPopup, setUpdateServiceCategoryPopup] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeResRow, setActiveResRow] = useState(1);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    loading,
    error,
    addLoading,
    addSuccess,
    categories,
    updateLoading,
    updateSuccess,
    delLoading,
    delError,
    delSuccess,
  } = useSelector((state) => state.serviceCategory);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const {
    register: updateRegister,
    formState: { errors: updateErrors },
    handleSubmit: updateHandleSubmit,
    reset: updateReset,
  } = useForm();
  useEffect(() => {
    if (token) {
      dispatch(
        getAllCategories({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (delSuccess) {
      toast.success("Category deleted Successfully", {
        position: "top-right",
      });
      setDelPopup(false);
      dispatch(clearState());
      setDelId(null);
    }
    if (addSuccess) {
      toast.success("Category added successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setAddServiceCategoryPopup(false);
      reset(undefined);
    }
    if (updateSuccess) {
      toast.success("Category updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setUpdateServiceCategoryPopup(false);
      setDelId(null);
    }
  }, [
    token,
    dispatch,
    delSuccess,
    currentPage,
    itemsPerPage,
    addSuccess,
    reset,
    updateSuccess,
  ]);
  const handleDeleteService = () => {
    dispatch(deleteServiceCategory({ token, id: delId }));
  };
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex justify="space-between" gap={10} align="center">
        <h1 className="edit-client-heading">Service Category</h1>
        <Link onClick={() => setAddServiceCategoryPopup(true)}>
          <Button variant="primary" label={"Add Category"} />
        </Link>
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Category</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                categories?.items?.length > 0,
                <TableBody>
                  {categories?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.name)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            <img
                              src="/icons/delete.png"
                              alt="delete"
                              onClick={() => {
                                setDelId(item?.id);
                                setDelPopup(true);
                              }}
                            />
                            <Link>
                              <img
                                src="/icons/edit.png"
                                alt="edit"
                                onClick={() => {
                                  setUpdateServiceCategoryPopup(true);
                                  setDelId(item?.id);
                                  updateReset({ name: item?.name });
                                }}
                              />
                            </Link>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Service Categories To Show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                categories?.items?.length > 0,
                categories?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Category</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {handleUndefined(item?.name)}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                            <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                            <Flex align="center" justify="flex-start" gap={10}>
                            <img
                              src="/icons/delete.png"
                              alt="delete"
                              onClick={() => {
                                setDelId(item?.id);
                                setDelPopup(true);
                              }}
                            />
                            <Link>
                              <img
                                src="/icons/edit.png"
                                alt="edit"
                                onClick={() => {
                                  setUpdateServiceCategoryPopup(true);
                                  setDelId(item?.id);
                                  updateReset({ name: item?.name });
                                }}
                              />
                            </Link>
                          </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label="No Categories To Show" />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {categories?.meta?.totalItems > categories?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={categories?.meta?.totalPages}
          />
        )}

      {addServiceCategoryPopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={setAddServiceCategoryPopup}
          popUp={addServiceCategoryPopup}
        >
          <h3>Add Service</h3>
          <form
            onSubmit={handleSubmit((values) => {
              dispatch(addServiceCategory({ token, data: { ...values } }));
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="category">Category Name</label>
              <div className="global-input-container-input">
                <input
                  type={"text"}
                  placeholder="Category"
                  {...register("name", {
                    required: "Please Enter Category Name",
                    maxLength: {
                      value: 30,
                      message: "Name should not be more then 30 characters",
                    },
                    min: { value: 0, message: "Discount cannot be negative" },
                  })}
                  id="category"
                  // min={0}
                />
              </div>
              <p className="global-input-error mb-10">
                {errors?.name && errors?.name?.message}
              </p>
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => {
                  setAddServiceCategoryPopup(false);
                  reset(undefined);
                }}
              />
              <Button
                label="Create"
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
      {updateServiceCategoryPopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={setUpdateServiceCategoryPopup}
          popUp={updateServiceCategoryPopup}
        >
          <h3>Update Service Category</h3>
          <form
            onSubmit={updateHandleSubmit((values) => {
              dispatch(
                updateServiceCategory({
                  token,
                  data: { ...values },
                  id: delId,
                })
              );
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="category">Category Name</label>
              <div className="global-input-container-input">
                <input
                  type={"text"}
                  placeholder="Category"
                  {...updateRegister("name", {
                    required: "Please Enter Category Name",
                    maxLength: {
                      value: 30,
                      message: "Name should not be more then 30 characters",
                    },
                    min: { value: 0, message: "Discount cannot be negative" },
                  })}
                  id="category"
                  // min={0}
                />
              </div>
              <p className="global-input-error mb-10">
                {updateErrors?.name && updateErrors?.name?.message}
              </p>
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => {
                  setUpdateServiceCategoryPopup(false);
                  setDelId(null);
                }}
              />
              <Button
                label="Save"
                type="submit"
                loading={updateLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteService}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default ServiceCategory;
