import React from 'react'
import LandingPageLayout from '../../../components/LandingPage/Layout/LandingPageLayout'
import './Contact.css'
const Contact = () => {
  return (
    <LandingPageLayout>
      <div className="max-width-1440 contact">
      <div className="contact-inner">
        <div className="contact-left">
          <img src='/home/contactImg.png' alt="Contact" />
        </div>
        <div className="contact-form">
          <div className="logo">
            <img src={'/navbar/logo.png'} alt="Logo" />
          </div>
          
          <h2 className="getInTouch">Get in Touch with Us</h2>
          <form action="">
            <div className="label-input">
              <label htmlFor="firstName">First Name *</label>
              <div className="icon-input">
                <img src={'/home/profile.png'} alt="" />
                <input type="text" name="firstName" id="firstName" placeholder='Muhammad' />
              </div>
              
            </div>
            <div className="label-input">
              <label htmlFor="surname">Surname *</label>
              <div className="icon-input">
                <img src={'/home/profile.png'} alt="" />
                <input type="text" name="surname" id="surname" placeholder='Iqbal' />
              </div>
            </div>
            <div className="label-input">
              <label htmlFor="email">Email*</label>
              <div className="icon-input">
                <img src={'/home/call-outgoing.png'} alt="" />
                <input type="email" name="email" id="email" placeholder='m.iqbal@gmail.com' />
              </div>
            </div>
            <div className="label-input">
              <label htmlFor="phone">Phone *</label>
              <div className="icon-input">
                <img src={'/home/sms-edit.png'} alt="" />
                <input type='number' maxLength={11} name="phone" id="phone" placeholder='03000000000' />
              </div>
            </div>
            <div className="label-input">
              <label htmlFor="message">Message</label>
              <div className="icon-textarea">
                <img src={'/home/sms-edit.png'} alt="" />
                <textarea name="message" id="message" rows={3} cols={67} placeholder='m.iqbal@gmail.com'></textarea>
                {/* <input type='text' name="message" id="message" /> */}
              </div>
            </div>
            <button className='contactus-btn' type="submit">Continue</button>
          </form>
        </div>
      </div>
    </div>
    </LandingPageLayout>
  )
}

export default Contact
