import React, { useMemo } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style.css";

const ClassicTemplateLayout = ({data,color,setColor}) => {
  const {
    questions,
  } = useSelector((state) => state.websiteStyles);
  const is_consultation_form_enables = useMemo(()=>{
    return questions?.is_active === 1?true:false
  },[questions?.is_active])
  return (
    <div
      className="classic-template-layout max-width-1440"
      style={{ position: "relative" }}
    >
      <Navbar data={data} color={color} is_consultation_form_enables={is_consultation_form_enables}/> 
      <div style={{ minHeight: "90vh", position: "relative" }}>
        <Outlet />
      </div>
      <Footer data={data}/>
    </div>
  );
};

export default ClassicTemplateLayout;
