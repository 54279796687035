import React, { useEffect, useState } from "react";
import "./Subscriptions.css";
import Flex from "../../../components/styled/Flex/Flex";
import { Link } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";

import {
  getAllSubscriptions,
  clearState,
  deleteSubscription,
} from "../../../features/ServiceProviderPortal/SubscriptionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { handleUndefined } from "../../../utills/handleUndefined";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { prettifyDate } from "../../../utills/prettifyDate";
import Badge from "../../../components/styled/Badge/Badge";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";

const Subscriptions = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, subscriptions, delLoading, delError, delSuccess } =
    useSelector((state) => state.subscriptions);

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  useEffect(() => {
    if (token) {
      dispatch(
        getAllSubscriptions({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      setDelId(null);
      dispatch(clearState());
    }
  }, [dispatch, token, delSuccess, currentPage, itemsPerPage]);

  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteSubscription = () => {
    dispatch(deleteSubscription({ token, id: delId }));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head">Subscriptions</h1>
        {/* <Flex align="center">
          <Link to="/admin-portal/add-subscription">
            <Button label="Add Subscription" />
          </Link>
        </Flex> */}
      </Flex>
      <div className="mt-30">
        {conditionalRender(
          loading,
          <PageLoader />,
          conditionalRender(
            !error,
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadData>ID</TableHeadData>
                    <TableHeadData>Business Name</TableHeadData>
                    <TableHeadData>Business Type</TableHeadData>
                    <TableHeadData>Subscription Type</TableHeadData>
                    <TableHeadData>Purchase Date</TableHeadData>
                    <TableHeadData>Status</TableHeadData>
                    <TableHeadData>Actions</TableHeadData>
                  </TableRow>
                </TableHead>
                {conditionalRender(
                  subscriptions?.items?.length > 0,
                  <TableBody>
                    {subscriptions?.items?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableBodyData>
                            <TableText>{handleUndefined(item?.id)}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              {handleUndefined(
                                item?.business_id?.business_name
                              )}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              <BusinessTypeText
                                type={item?.business_id?.business_type}
                              />
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{item?.subscription_type}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              {prettifyDate(item?.purchase_date)}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <Flex align="center" justify="center">
                              <TableText>
                                <Badge tone="success">{item?.status}</Badge>
                              </TableText>
                            </Flex>
                          </TableBodyData>
                          <TableBodyData>
                            <Flex align="center" justify="center" gap={5}>
                              <Link
                                to={`/admin-portal/edit-subscription/${item?.id}`}
                              >
                                <img src="/icons/edit.png" alt="edit" />
                              </Link>
                              <Link>
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                              </Link>
                            </Flex>
                          </TableBodyData>
                        </TableRow>
                      );
                    })}
                  </TableBody>,
                  <GlobalEmptyDataHandler label="No subscriptions to show" />
                )}
              </Table>
              <ResponsiveContainer>
                {conditionalRender(
                  subscriptions?.items?.length > 0,
                  subscriptions?.items?.map((item, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <PhoneTable>
                          <PhoneTableHead
                            onClick={() => setActiveResRow(index + 1)}
                          >
                            <PhoneTableRow>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>ID</PhoneTableBoldText>
                              </PhoneTableHeadData>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>
                                  {handleUndefined(item?.id)}
                                </PhoneTableBoldText>
                              </PhoneTableHeadData>
                            </PhoneTableRow>
                          </PhoneTableHead>
                          <PhoneTableBody
                            className={`${
                              index + 1 === activeResRow
                                ? "responsive-table-body-show"
                                : "responsive-table-body-hide"
                            }`}
                          >
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Business Name
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(
                                    item?.business_id?.business_name
                                  )}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Business Type
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  <BusinessTypeText
                                    type={item?.business_id?.business_type}
                                  />
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Subscription Type
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(item?.subscription_type)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Purchase Date</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {prettifyDate(item?.purchase_date)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Status</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                <Badge tone="success">{item?.status}</Badge>
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                              <Flex align="center" justify="center" gap={5}>
                              <Link
                                to={`/admin-portal/edit-subscription/${item?.id}`}
                              >
                                <img src="/icons/edit.png" alt="edit" />
                              </Link>
                              <Link>
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                              </Link>
                            </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          </PhoneTableBody>
                        </PhoneTable>
                        <div className="global-responsive-table-chevron">
                          <ChevronDown
                            className={`${
                              index + 1 === activeResRow
                                ? "table-chevron-rotate"
                                : "table-chevron-rerotate"
                            }`}
                          />
                        </div>
                      </div>
                    );
                  }),
                  <GlobalEmptyDataHandler label={"No referrals To Show"} />
                )}
              </ResponsiveContainer>
            </>,
            <GlobalErrorHandler label={error} />
          )
        )}
        {subscriptions?.meta?.totalItems > subscriptions?.meta?.itemsPerPage &&
          !error && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={subscriptions?.meta?.totalPages}
            />
          )}
      </div>

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteSubscription}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

function BusinessTypeText({ type }) {
  if (type === 5) {
    return "Beauty and Wellness";
  }
  if (type === 6) {
    return "Hairstylists And Salons";
  }
  if (type === 8) {
    return "Professional Services";
  }
  if (type === 7) {
    return "Sports And Fitness";
  }
  if (type === 42) {
    return "Other";
  }
}

export default Subscriptions;
