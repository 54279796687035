import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import Button from "../../../components/styled/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  addSubscriptionInvoice,
  clearState,
} from "../../../features/ServiceProviderPortal/subscriptionInvoicesSlice";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
const AddSubscriptionInvoice = () => {
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientError, setSelectedClientError] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceError, setSelectedServiceError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { addLoading, addError, success } = useSelector(
    (state) => state.subInvoices
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddSubscriptionInvoice = (values) => {
    if (!selectedClient || !selectedService) {
      setSelectedClientError("Please Select");
      setSelectedServiceError("Please Select");
    } else {
      values.client_id = selectedClient.id;
      values.service_id = selectedService.id;

      dispatch(addSubscriptionInvoice({ token, data: { ...values } }));
    }
  };

  useEffect(() => {
    if (success) {
      navigate("/portal/subscription-invoices");
      dispatch(clearState());
    }
  }, [success, navigate, dispatch]);
  return (
    <div className="add-subscription-invice-container outer">
      <Link
        to={"/portal/subscription-invoices"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Subscription Invoice</h1>

      <form onSubmit={handleSubmit(handleAddSubscriptionInvoice)}>
        <div className="add-subscription-invice-inputs">
          <div className="add-subscription-invice-input">
            <ClientsDropDown
              label="Select Client*"
              selectedClient={selectedClient}
              setSelectedCLient={setSelectedClient}
            />
            <p className="global-input-error">
              {selectedClientError && !selectedClient && selectedClientError}
            </p>
          </div>
          <div className="add-subscription-invice-input">
            <ServicesDropDown
              label="Select Service*"
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              handleChange={()=>{}}
            />
            <p className="global-input-error">
              {selectedServiceError && !selectedService && selectedServiceError}
            </p>
          </div>

          <Flex
            className={`global-input-container add-subscription-invice-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="appointment_date">Appointment Date*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"date"}
                {...register("appointment_date", {
                  required: "Please Select Appointment Date",
                })}
                id="appointment_date"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            {errors?.appointment_date && (
              <p className="global-input-error">
                {errors?.appointment_date?.message}
              </p>
            )}
          </Flex>
          <Flex
            className={`global-input-container add-subscription-invice-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="total_amount">Total Amount*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"number"}
                {...register("total_amount", {
                  required: "Please Enter Total Amount",
                })}
                id="total_amount"
                maxLength={5}
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            {errors?.total_amount && (
              <p className="global-input-error">
                {errors?.total_amount?.message}
              </p>
            )}
          </Flex>

          <div className="lable-textarea-group lable-input-group mt-20 mb-20">
            <label htmlFor="notes">Notes</label>
            <div className="edit-client-icon-textarea">
              {/* <input type="number" placeholder="sent" id="id" /> */}
              <img src="/icons/profile-2user.png" alt="" />
              <textarea
                name=""
                id="notes"
                cols="135"
                rows="3"
                placeholder="Notes"
                {...register("notes", {
                  required: "Please Add Some Notes",
                  maxLength: {
                    value: 100,
                    message: "Message should not be more then 100 characters",
                  },
                })}
              ></textarea>
            </div>
          </div>
        </div>

        <Button
          label="Add Invoive"
          type="submit"
          loading={addLoading}
          loaderColor="#fff"
        />
      </form>

      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddSubscriptionInvoice;
