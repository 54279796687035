import React, { useEffect, useState } from "react";
import "./ServiceProviders.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { getAllBusinesses } from "../../../features/SuperAdminPortal/businessesSlice";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { handleUndefined } from "../../../utills/handleUndefined";
import { ChevronDown } from "react-feather";
import Pagination from "../../../components/styled/Pagination/Pagination";
const ServiceProviders = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, businesses, error } = useSelector(
    (state) => state.businesses
  );
  const dispatch = useDispatch();
  const [activeResRow, setActiveResRow] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    if (token) {
      dispatch(getAllBusinesses({ token, query:{currentPage, itemsPerPage} }));
    }
  }, [token, dispatch, currentPage, itemsPerPage]);
  return (
    <div className="outer">
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head">Businesses</h1>
        <Flex align="center">
          <Link to="/admin-portal/add-service-provider">
            <Button label="Add Business" />
          </Link>
        </Flex>
      </Flex>
      <div className="mt-30">
        {conditionalRender(
          loading,
          <PageLoader />,
          conditionalRender(
            !error,
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadData>Business ID</TableHeadData>
                    <TableHeadData>Business Name</TableHeadData>
                    <TableHeadData>Location</TableHeadData>
                    {/* <TableHeadData>Number</TableHeadData> */}
                    <TableHeadData>Subscription Type</TableHeadData>
                    <TableHeadData>Name</TableHeadData>
                    <TableHeadData>Actions</TableHeadData>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conditionalRender(
                    businesses?.items?.length > 0,

                    businesses?.items?.map((item) => {
                      return (
                        <TableRow>
                          <TableBodyData>
                            <TableText>{handleUndefined(item?.id)}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              {handleUndefined(item?.business_name)}
                            </TableText>
                          </TableBodyData>

                          <TableBodyData>
                            <TableText>
                              {handleUndefined(item?.location_id)}
                            </TableText>
                          </TableBodyData>
                          {/* <TableBodyData>
                            <TableText>1</TableText>
                          </TableBodyData> */}
                          <TableBodyData>
                            <TableText>
                              {handleUndefined(item?.subscription_type)}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{item?.users?.full_name}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <Flex align="center" justify="center" gap={5}>
                              <Link to="/admin-portal/edit-service-provider">
                                <img src="/icons/edit.png" alt="edit" />
                              </Link>
                              <img src="/icons/delete.png" alt="delete" />
                            </Flex>
                          </TableBodyData>
                        </TableRow>
                      );
                    }),
                    <GlobalEmptyDataHandler label="No providers to show" />
                  )}
                </TableBody>
              </Table>
              <ResponsiveContainer>
                {conditionalRender(
                  businesses?.items?.length > 0,
                  businesses?.items.map((item, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <PhoneTable>
                          <PhoneTableHead
                            onClick={() => setActiveResRow(index + 1)}
                          >
                            <PhoneTableRow>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>
                                  Business ID
                                </PhoneTableBoldText>
                              </PhoneTableHeadData>
                              <PhoneTableHeadData>
                                {handleUndefined(item?.id)}
                              </PhoneTableHeadData>
                            </PhoneTableRow>
                          </PhoneTableHead>
                          <PhoneTableBody
                            className={`${
                              index + 1 === activeResRow
                                ? "responsive-table-body-show"
                                : "responsive-table-body-hide"
                            }`}
                          >
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Business Name
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(item?.business_name)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Location
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(item?.location_id)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            {/* <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Number</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>1</PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow> */}
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Subscription Type
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(item?.subscription_type)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Appointment
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  Haircut
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <Flex align="center" gap={10}>
                                  <Link to="/admin-portal/edit-service-provider">
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                  <Link>
                                    <img src="/icons/delete.png" alt="delete" />
                                  </Link>
                                </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          </PhoneTableBody>
                        </PhoneTable>
                        <div className="global-responsive-table-chevron">
                          <ChevronDown
                            className={`${
                              index + 1 === activeResRow
                                ? "table-chevron-rotate"
                                : "table-chevron-rerotate"
                            }`}
                          />
                        </div>
                      </div>
                    );
                  }),
                  <GlobalEmptyDataHandler label="No service providers to show" />
                )}
              </ResponsiveContainer>
              {businesses?.meta?.totalItems > businesses?.meta?.itemsPerPage &&
                !error && (
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={businesses?.meta?.totalPages}
                  />
                )}
            </>,
            <GlobalErrorHandler label={error} />
          )
        )}
      </div>
    </div>
  );
};

export default ServiceProviders;
