import React, { useEffect, useState } from "react";
import "./Services.css";
import StandardTemplateLayout from "../../../../components/Templates/StandardTemplateLayout/StandardTemplateLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getPublicServices,
} from "../../../../features/ServiceProviderPortal/servicesSlice";
import { conditionalRender } from "../../../../utills/conditionalRender";
import PageLoader from "../../../../components/styled/PageLoader/PageLoader";
import { transformDuration } from "../../../../utills/prettifyDate";
import Flex from "../../../../components/styled/Flex/Flex";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import { useNavigate, useParams } from "react-router-dom";
import { encryptObject } from "../../../../utills/encryptionDecription";
import { ArrowRight } from "react-feather";

const Services = ({ data }) => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, services } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const websiteDesign = useSelector((state) => state.auth.websiteDesign);
  const [selectedServices, setSelectedServices] = useState([]);
  const { url } = useParams();
  useEffect(() => {
    dispatch(getPublicServices({ id: data.business_id.id }));
  }, [dispatch]);
  const navigate = useNavigate();
  return (
    <div
      className="standard-services-section1"
      style={{
        // background: `url('/templates/standardTemplate/home/home.png')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "91vh",
        position: "relative",
        paddingTop: "150px",
      }}
    >
      {/* <img className="std-services-home-img" src="/templates/standardTemplate/home/home.png" alt="" /> */}
      <div
        className="standard-services-sec1-overlay"
        // style={{ height: "100%",paddingTop:'150px' }}
      ></div>

      <Flex
        align="flex-end"
        justify="space-between"
        className="website-services-top-section-standard"
      >
        {/* <p className="hero-head mb-20">Services</p> */}
        {selectedServices.length > 0 && (
          <div
            className="website-selected-services"
            style={{
              background: data?.color || "#3E0FFE",
              padding: "10px 20px",
              borderRadius: "10px",
              cursor: "pointer",
              zIndex:1000
            }}
            onClick={() => {
              const encryptedToken = encryptObject(selectedServices);
              navigate(`/websites/${url}/book?data=${encryptedToken}`);
            }}
          >
            <Flex align="center" gap={10}>
              <span
                style={{
                  color: getTextColorBasedOnBackground(
                    data?.color || "#3E0FFE"
                  ),
                }}
              >
                {" "}
                Book {selectedServices.length}{" "}
                {selectedServices.length === 1 ? "Service" : "Services"}
              </span>
              <ArrowRight
                color={getTextColorBasedOnBackground(data?.color || "#3E0FFE")}
                size={20}
              />
            </Flex>
          </div>
        )}
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        <div className="standard-services-cards-grid">
          {services?.map((item, index) => {
            return (
              <div
                className="standard-service-card"
                style={{ position: "relative" }}
              >
                <div className="standard-service-card-img">
                  <img
                    width="100%"
                    src={
                      item?.base_url
                        ? item?.base_url
                        : "/clasicTemplate/services/Rectangle 34624244.png"
                    }
                    alt=""
                  />
                </div>
                <div className="standard-service-name-book-btn">
                  <p style={{ color: "#000" }}>{item?.name}</p>
                  <p style={{ color: "#000" }}>£{item?.cost}</p>
                </div>
                <div style={{ padding: "15px", paddingTop: "0" }}>
                  <p style={{ color: "#000", marginBottom: "10px" }}>
                    {transformDuration(item?.duration)}
                  </p>
                  <p style={{ color: "#000", marginBottom: "10px" }}>
                    {item?.notes}
                  </p>
                  <Flex align="center" justify="flex-end">
                    <button
                      className="standard-service-book-btn"
                      style={{
                        color: data?.color,
                        background: "transparent",
                        borderColor: data?.color,
                      }}
                      onClick={() => {
                        if (selectedServices.find((s) => s.id === item.id)) {
                          const updatedServices = selectedServices.filter(
                            (s) => s.id !== item.id
                          );
                          setSelectedServices(updatedServices);
                        } else {
                          setSelectedServices([...selectedServices, item]);
                        }
                      }}
                    >
                      {selectedServices.find((s) => s.id === item.id)
                        ? "Unselect"
                        : "Select"}
                    </button>
                  </Flex>
                </div>
                {item?.service_category_id?.name && (
                  <div
                    className="service-category-badge"
                    style={{
                      background: data?.color || "#3E0FFE",
                      color: getTextColorBasedOnBackground(
                        data?.color || "#3E0FFE"
                      ),
                    }}
                  >
                    <p>{item?.service_category_id?.name}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Services;
