import React, { useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import Flex from "../../../../../components/styled/Flex/Flex";
import Button from "../../../../../components/styled/Button/Button";
import GlobalDropDown from "../../../../../components/styled/Form/GlobalDropDown/GlobalDropDown";

const AddTestimonial = () => {
    const [selectedBinaryImage, setSelectedBinaryImage] = useState(null);
  const [businessImage, setBusinessImage] = useState(null);
  const [businessBinaryImage, setBusinessBinaryImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dropOptions = [{ name: "XYZ" }, { name: "ABC" }];
  const [selectedOrganization, setSelectedOrganization] = useState(null);


  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();

        image.onload = () => {
          // Get the width and height of the image
          const { width, height } = image;
          // console.log(image)
          // console.log(width,height)
          // Define the minimum and maximum allowed sizes
          const minWidth = 20;
          const minHeight = 20;
          const maxWidth = 6600;
          const maxHeight = 6600;

          // Perform size validation here
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            // Image size is valid, set the selected image and show success message
            if (event.target.name === "image") {
              setBusinessBinaryImage(file);
              setBusinessImage(reader.result);
            }

            setErrorMessage("");
          } else {
            // Image size is not valid, show an error message
            setErrorMessage(
              "Image size is not within the allowed limits(100-701 x 100-445). Please choose an image with appropriate dimensions."
            );
            setSelectedBinaryImage(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };
  return (
    <div className="outer">
      <Link to={"/admin-portal/testimonials"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Testimonial</h1>
      <form>
        <h2 className="website-design-head mt-20">Add Testimonial </h2>
        <div className="add-team-member-inputs add-blog-form-top-inputs mt-20">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="fullName">Full Name*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input type={"text"} placeholder={""} id="fullName" />
              <img src="/icons/user.png" alt="icon" />
            </div>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="email">Email*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input type={"text"} placeholder={""} id="email" />
              <img src="/icons/sms.png" alt="icon" />
            </div>
          </Flex>
        </div>
        <div className="mt-30">
          <div className="lable-textarea-group lable-input-group">
            <label htmlFor="notes">Review</label>
            <div className="edit-client-icon-textarea">
              {/* <input type="number" placeholder="sent" id="id" /> */}
              <textarea
                name=""
                id="about-company"
                cols="135"
                rows="4"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="add-team-member-inputs add-blog-form-top-inputs mt-30">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="title">Title</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input type={"text"} placeholder={""} id="title" />
              <img src="/icons/user.png" alt="icon" />
            </div>
          </Flex>
          <Flex className="add-team-member-input" direction="column" gap={10}>
              <GlobalDropDown
                options={dropOptions}
                label="Organization"
                // icon="/icons/appointments.png"
                stateHandler={selectedOrganization}
                setStateHandler={setSelectedOrganization}
              />
            </Flex>
        </div>
        <div className="lable-input-group mt-20">
          <label htmlFor="address">Add Image</label>
          <div
            className="upload-content-image-section width-343 "
            style={{ width: "343px" }}
            id="img-box"
          >
            <label htmlFor="file">
              <div>
                {businessImage ? (
                  <div className="upload-img" style={{ width: "182px" }}>
                    {/* <img src={selectedImage} alt="Preview" /> */}
                    {businessImage && (
                      <img src={businessImage} alt="Selected" />
                    )}
                  </div>
                ) : (
                  // <img src={uploadimg} alt="Preview" />
                  <>
                    <div
                      className="upload-photo"
                      style={{ width: "182px", borderRadius: "0px" }}
                    >
                      <div className="up-img">
                        {/* <img src={uploadimg} alt="" /> */}
                        <span className="drag-drop">Select Image</span>
                      </div>
                    </div>
                    <div className="error-message">{errorMessage}</div>
                  </>
                )}
              </div>
            </label>
            <input
              className="upload-content-image"
              type="file"
              accept="image/*"
              name="image"
              id="file"
              onChange={handleImageUpload}
            />
          </div>
        </div>
        <div className="mt-30"></div>
        <Button label='Add Testimonial' />
      </form>
      <div className="mt-30"></div>
    </div>
  );
};

export default AddTestimonial;
