import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Flex from "../../styled/Flex/Flex";
import "./style.css";
import { ChevronUp, ChevronDown, Check } from "react-feather";
import { conditionalRender } from "../../../utills/conditionalRender";
import { getPricingsForDropDown } from "../../../features/SuperAdminPortal/pricingSlice";
const FeaturesDropDown = ({
  label,
  selectedFeature,
  setSelectedFeature,
  handleChange,
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { dropLoading, features } = useSelector((state) => state.pricing);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPricingsForDropDown({ token }));
  }, [dispatch, token]);
  return (
    <div className="global-client-dropdown">
      <div
        className="global-client-drop-head"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <h2>{label}</h2>
        <Flex
          className="global-client-drop-input"
          align="center"
          justify="space-between"
          gap={10}
        >
          <Flex className="global-client-drop-input-left" gap={15}>
            {/* <img src="/icons/user.png" alt="user" /> */}
            <h3>
              {conditionalRender(
                dropLoading,
                "Loading...",
                conditionalRender(
                  selectedFeature,
                  selectedFeature?.name,
                  "Select Feature"
                )
              )}
            </h3>
          </Flex>
          {conditionalRender(
            open,
            <ChevronUp color="#666666" />,
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>

      <div
        className="global-client-drop-options"
        style={{
          maxHeight: `${open ? "300px" : "0px"}`,
          overflow: `${open ? "auto" : "hidden"}`,
        }}
      >
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Flex
            direction="column"
            gap={10}
            className="global-client-drop-options-inner"
          >
            {features && features?.length > 0 ? (
              features?.map((item, index) => {
                return (
                  conditionalRender(item?.name, <div
                    style={{
                      display: `${
                        query
                          ? item?.name?.includes(query.toLowerCase())
                            ? "block"
                            : "none"
                          : "block"
                      }`,
                    }}
                    >
                      <Flex
                        className="global-client-drop-option"
                        gap={15}
                        align="center"
                        key={index}
                      >
                        <Flex
                          align="center"
                          justify="center"
                          className="global-clients-drop-user"
                        >

                         <Check size={15}/>
                          
                          
                        </Flex>
                        <span
                          onClick={() => {
                            setSelectedFeature(item);
                            handleChange(item)
                            setOpen(false);
                          }}
                          className={`${
                            item?.id === selectedFeature?.id
                              ? "client-drop-option-active"
                              : ""
                          }`}
                        >
                          {item?.name}
                        </span>
                      </Flex>
                    </div>, '')
                );
              })
            ) : (
              <p className="global-empty-para">No Features to show</p>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default FeaturesDropDown;
