import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
import { createForgetPassUser, saveSignUpData } from "../auth/authSlice";

const initialState = {
  loading: false,
  success: false,
  error: null,
  verifyLoading: false,
  verifySuccess: false,
  verifyError: null,
  updateLoading: false,
  updateSuccess: false,
  updateError: null,
};

export const forgetPassSlice = createSlice({
  name: "forgetPassSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.verifySuccess = false;
      state.verifyError = null;
      state.updateSuccess = false;
      state.updateError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forgetPassRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgetPassRequest.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(forgetPassRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(tokenVerify.pending, (state) => {
      state.verifyLoading = true;
    });
    builder.addCase(tokenVerify.fulfilled, (state) => {
      state.verifyLoading = false;
      state.verifySuccess = true;
    });
    builder.addCase(tokenVerify.rejected, (state, action) => {
      state.verifyLoading = false;
      state.verifyError = action.payload;
    });
    builder.addCase(signUpOtpVerification.pending, (state) => {
      state.verifyLoading = true;
    });
    builder.addCase(signUpOtpVerification.fulfilled, (state) => {
      state.verifyLoading = false;
      state.verifySuccess = true;
    });
    builder.addCase(signUpOtpVerification.rejected, (state, action) => {
      state.verifyLoading = false;
      state.verifyError = action.payload;
    });
    builder.addCase(updateForgetPass.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateForgetPass.fulfilled, (state) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateForgetPass.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
  },
});
export const forgetPassRequest = createAsyncThunk(
  "forgetPassRequest",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.email) {
        return rejectWithValue("Please Provide Email");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/forgot_password`,
        apiData
      );

      return data.result;
    } catch (error) {
      if (error?.response?.status === 404) {
        return rejectWithValue("No user found with this email");
      }
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const tokenVerify = createAsyncThunk(
  "tokenVerify",
  async (apiData, { rejectWithValue, dispatch }) => {
    try {
      if (!apiData.verification_code) {
        return rejectWithValue("Please Provide Verification Token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/verify_otp`,
        apiData
      );
        if(apiData.mode === 'forget'){

          dispatch(createForgetPassUser(data.result));
        }
        if(apiData.mode === 'email'){
          dispatch(saveSignUpData(data.result))
        }
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);
export const signUpOtpVerification = createAsyncThunk(
  "signUpOtpVerification",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.verification_code) {
        return rejectWithValue("Please Provide Verification Token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/verify_otp`,
        apiData
      );
      return {
        userData: data.result,
        token: data.result.accessToken
      };
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const updateForgetPass = createAsyncThunk(
  "updateForgetPass",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide token");
      }

      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/reset_password`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const { clearState } = forgetPassSlice.actions;
