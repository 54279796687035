import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../config";
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const mainWebsiteSlice = createSlice({
  name: "mainWebsiteSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWebsiteData.pending, (state)=>{
        state.loading=true;
    });
    builder.addCase(getWebsiteData.fulfilled, (state,action)=>{
        state.loading=false;
        state.data = action.payload;
    });
    builder.addCase(getWebsiteData.rejected, (state,action)=>{
        state.loading = false;
        state.error = action.payload;
    })
  },
});

export const getWebsiteData = createAsyncThunk(
  "getWebsiteData",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/v1/feedbacks/all`);
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
