import React, { useRef, useState } from "react";
import './ViewBlog.css'
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";

const ViewBlog = () => {
    const heightRef = useRef()
    const [height, setHeight] = useState(false)
    const handleShowMore = ()=>{
        if(heightRef.current.style.maxHeight.length === 0 || heightRef.current.style.maxHeight === '0px'){
            setHeight(true);
            heightRef.current.style.maxHeight = '1000px'
        }
        else{
            setHeight(false);
            heightRef.current.style.maxHeight = '0px'
        }
    }
  return (
    <div className="outer">
      <Link to={"/admin-portal/blogs"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <div className="view-blog-inner">
          <h1 className="headings view-blog-heading">Welcome To Our Blogs</h1>
          <p className="blogs-para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            since the 1500s
          </p>
          <div className="view-blog-posts">
            <div className="view-blog">
              <h2 className="view-blog-title headings">Blog 1</h2>
              <div className="view-blog-details">
                <div className="view-blog-paras">
                  <p className="view-blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p className="view-blog-post-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
                    
                <div className="view-blog-image">
                  <img
                    src={"/blogs/blogImg1.png"}
                    alt="Blog"
                    className="blog-img"
                  />
                </div>
              </div>
              <div className="view-blog-extra-paras" ref={heightRef}>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum repellendus? Fuga nisi aspernatur voluptatum quia praesentium sint et, numquam iste error, dolorem ex? Vitae nesciunt corporis consequuntur deserunt ducimus aspernatur, veritatis pariatur quia animi velit, exercitationem iure unde eligendi magni explicabo beatae dolore temporibus facilis eveniet placeat ullam dolorem delectus dolor? Maiores cupiditate nesciunt natus sunt, temporibus sint quas dignissimos, nobis quibusdam delectus illum aliquid iste vitae quia unde beatae eligendi atque accusantium incidunt accusamus magnam non fugiat? Eius, quaerat. Ab illum ipsa, voluptatibus ipsam fugit distinctio aliquid ducimus facere voluptatem eveniet, ut, quo quidem? Nesciunt ipsum eum esse.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, illum repellendus? Fuga nisi aspernatur voluptatum quia praesentium sint et, numquam iste error, dolorem ex? Vitae nesciunt corporis consequuntur deserunt ducimus aspernatur, veritatis pariatur quia animi velit, exercitationem iure unde eligendi magni explicabo beatae dolore temporibus facilis eveniet placeat ullam dolorem delectus dolor? Maiores cupiditate nesciunt natus sunt, temporibus sint quas dignissimos, nobis quibusdam delectus illum aliquid iste vitae quia unde beatae eligendi atque accusantium incidunt accusamus magnam non fugiat? Eius, quaerat. Ab illum ipsa, voluptatibus ipsam fugit distinctio aliquid ducimus facere voluptatem eveniet, ut, quo quidem? Nesciunt ipsum eum esse.</p>
              </div>
                <button className="read-more" onClick={handleShowMore}>{height? 'See Less': 'Read More'}</button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ViewBlog;
