import React, { useEffect } from "react";
import StandardTemplateLayout from "../../../../components/Templates/StandardTemplateLayout/StandardTemplateLayout";
import "./style.css";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useFileHandler } from "6pp";
import Flex from "../../../../components/styled/Flex/Flex";
import { ColorPicker, Rate } from "antd";
import Button from "../../../../components/styled/Button/Button";
import {
  updateWebsiteDesign,
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import { toast } from "react-toastify";
import { Edit2 } from "react-feather";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import { concatNames } from "../../../../utills/concatNames";
const Home = ({ data, color, setColor, isEditAccess }) => {
  const websiteDesign = useSelector((state) => state.auth.websiteDesign);

  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      heading:
        data?.heading || `Welcome to ${data?.business_id?.business_name}`,
      sub_heading:
        data?.sub_heading ||
        `${data?.business_id?.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      review_text: data?.review_text || "Reviews By Our Clients",
    },
  });
  const banner_image = useFileHandler("single");
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website update successfully");
      dispatch(clearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  return (
    <>
      <div className="standard-template-main-banner-top">
        <img
          src={
            banner_image.file
              ? banner_image.preview
              : data?.main_banner_image
              ? data?.main_banner_image
              : "/templates/standardTemplate/home/home.png"
          }
          alt=""
        />
        <div className="standard-template-main-banner-content">
          <div className="standard-home-section">
            <div className="standard-home-overlay">
              <div className="standard-home-content">
                <div
                  className="standard-home-content2"
                  style={{ position: "relative" }}
                >
                  <textarea
                    className="standard-home-heading"
                    {...register("heading")}
                    maxLength={40}
                    readOnly={!isEditAccess}
                  ></textarea>
                  <textarea
                    className="standard-home-para"
                    {...register("sub_heading")}
                    maxLength={150}
                    readOnly={!isEditAccess}
                  ></textarea>
                  {isEditAccess && (
                    <>
                      <Edit2
                        className="standard-home-heading-edit"
                        size={18}
                        onClick={() => {
                          setFocus("heading");
                        }}
                        color="#fff"
                      />
                      <Edit2
                        className="standard-home-para-edit"
                        size={17}
                        cursor="pointer"
                        onClick={() => {
                          setFocus("sub_heading");
                        }}
                        color="#fff"
                      />
                    </>
                  )}
                </div>
                <Link to={"/templates/standard-template/consultation"}>
                  <button
                    className="get-consultation-btn"
                    style={{
                      background: color,
                      color: getTextColorBasedOnBackground(color),
                      border: "none",
                    }}
                  >
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
            {isEditAccess && (
              <div className="update-banner-image-input">
                <label htmlFor="banner-image">
                  <div className="update-banner-image">Update banner Image</div>
                </label>
                <input
                  type="file"
                  id="banner-image"
                  onChange={banner_image.changeHandler}
                />
              </div>
            )}

            {isEditAccess && (
              <div className="website-color-picker" style={{ zIndex: "1000" }}>
                <Flex align="center" gap={7}>
                  <label htmlFor="color">Choose Color</label>
                  <ColorPicker
                    value={color}
                    onChangeComplete={(color) => {
                      setColor(color.toHexString());
                    }}
                  />
                </Flex>
              </div>
            )}
          </div>
        </div>
      </div>
      {isEditAccess && (
        <div className="max-width-1440" style={{ padding: "20px 0" }}>
          <Flex align="center" justify="flex-end">
            <div className="save-button-website">
              <form
                onSubmit={handleSubmit((values) => {
                  const apiData = {};
                  apiData.heading = values.heading;
                  apiData.sub_heading = values.sub_heading;
                  // apiData.review_text = values.review_text;
                  apiData.color = color;
                  if (banner_image.file) {
                    apiData.main_banner_image = banner_image.preview;
                  }
                  dispatch(updateWebsiteDesign({ data: apiData }));
                })}
              >
                <Button
                  label="Save Changes"
                  type="submit"
                  loading={updateLoading}
                  loaderColor="#fff"
                />
              </form>
            </div>
          </Flex>
        </div>
      )}
      {data?.business_id?.testimonials &&
        data?.business_id?.testimonials.length > 0 && (
          <div className="standard-reviews-section">
            <h1 className="standard-reviews-head">Reviews</h1>
            <div className="standard-reviews-slider">
              <div className="standard-reviews-slider-inner">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={80}
                  keyboard={{
                    enabled: true,
                  }}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                  modules={[Navigation, keyboard, Autoplay]}
                  className="mySwiper"
                  loop={true}
                >
                  {data?.business_id?.testimonials.map((item) => {
                    return (
                      <SwiperSlide>
                        <div className="standard-home-review">
                          <img
                            className="standard-home-review-comas"
                            src="/templates/standardTemplate/home/comas.png"
                            alt=""
                          />
                          <p
                            className="standard-home-review-para"
                            style={{ height: "150px" }}
                          >
                            {item?.review}
                          </p>
                          <span className="standard-home-reviewer-name">
                            {concatNames(
                              item?.client_id?.first_name,
                              item?.client_id?.last_name
                            )}
                          </span>
                          <Rate value={parseInt(item?.rating)} disabled />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <button
                  className="swiper-button-prev"
                  style={{ borderColor: data?.color, color: data?.color }}
                ></button>
                <button
                  className="swiper-button-next"
                  style={{ borderColor: data?.color, color: data?.color }}
                ></button>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Home;
