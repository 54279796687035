import React, { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";

import { useForm } from "react-hook-form";
import Input from "../../../components/styled/Form/Input/Input";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import {
  clearState,
  createTeamMember,
} from "../../../features/ServiceProviderPortal/teamMemberSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  nameMaxLength,
  nameMinLength,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import JobTitlesDropdown from "../../../components/Portal/JobTitlesDropdown/JobTitlesDropdown";
import {
  addJobTitle,
  clearState as addJobTitleClearState,
} from "../../../features/ServiceProviderPortal/jobTitleSlice";
import { toast } from "react-toastify";
import TimePicker from "../../../components/styled/Form/DateTimePickers/TimePicker";
const AddTeamMember = () => {
  const { token } = useSelector((state) => state.auth);
  const {
    teamMembersDropLoading,
    teamMembersDropError,
    loading,
    error,
    success,
  } = useSelector((state) => state.team);
  const { addLoading, addError, addSuccess } = useSelector(
    (state) => state.jobTitle
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const {
    register: addJobTitleRegister,
    formState: { errors: addJobTitleErrors },
    handleSubmit: addJobTitleSubmit,
    reset: addJobTitleReset,
  } = useForm();

  useEffect(() => {
    if (addSuccess) {
      toast.success("Job title added successfully");
      setAddJobTitlePopup(false);
      dispatch(addJobTitleClearState());
    }
  }, [dispatch, addSuccess]);
  const [jobError, setJobError] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [addJobTitlePopup, setAddJobTitlePopup] = useState(false);
  const handleAddTeamMember = (values) => {
    if (!values.job_title) {
      setJobError("Please Select Job");
    } else {
      let apiData = {};
      let timings = [];
      apiData.first_name = values.first_name;
      apiData.last_name = values.last_name;
      apiData.mobile = values.mobile;
      apiData.email = values.email;
      apiData.job_title = values.job_title.id;
      if (values?.monday_opening_time?.length > 0) {
        const timingData = {
          day: "Monday",
          opening_time: values.monday_opening_time,
          close_time: values.monday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.tuesday_opening_time?.length > 0) {
        const timingData = {
          day: "Tuesday",
          opening_time: values.tuesday_opening_time,
          close_time: values.tuesday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.wednesday_opening_time?.length > 0) {
        const timingData = {
          day: "Wednesday",
          opening_time: values.wednesday_opening_time,
          close_time: values.wednesday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.thursday_opening_time?.length > 0) {
        const timingData = {
          day: "Thursday",
          opening_time: values.thursday_opening_time,
          close_time: values.thursday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.friday_opening_time?.length > 0) {
        const timingData = {
          day: "Friday",
          opening_time: values.friday_opening_time,
          close_time: values.friday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.saturday_opening_time?.length > 0) {
        const timingData = {
          day: "Saturday",
          opening_time: values.saturday_opening_time,
          close_time: values.saturday_closing_time,
        };
        timings.push(timingData);
      }
      if (values?.sunday_opening_time?.length > 0) {
        const timingData = {
          day: "Sunday",
          opening_time: values.sunday_opening_time,
          close_time: values.sunday_closing_time,
        };
        timings.push(timingData);
      }

      apiData.working_hours = timings;
      dispatch(createTeamMember({ token, data: apiData }));
    }
  };

  const monday_opening_time = watch("monday_opening_time");
  const tuesday_opening_time = watch("tuesday_opening_time");
  const wednesday_opening_time = watch("wednesday_opening_time");
  const thursday_opening_time = watch("thursday_opening_time");
  const friday_opening_time = watch("friday_opening_time");
  const saturday_opening_time = watch("saturday_opening_time");
  const sunday_opening_time = watch("sunday_opening_time");
  const monday_closing_time = watch("monday_closing_time");
  const tuesday_closing_time = watch("tuesday_closing_time");
  const wednesday_closing_time = watch("wednesday_closing_time");
  const thursday_closing_time = watch("thursday_closing_time");
  const friday_closing_time = watch("friday_closing_time");
  const saturday_closing_time = watch("saturday_closing_time");
  const sunday_closing_time = watch("sunday_closing_time");
  const job_title = watch("job_title");
  return (
    <div className="outer">
      <Link to={"/portal/team"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Team Member</h1>
      {conditionalRender(
        teamMembersDropLoading,
        <PageLoader />,
        conditionalRender(
          !teamMembersDropError,
          <form onSubmit={handleSubmit(handleAddTeamMember)}>
            <div className="add-team-member-inputs">
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="firstName">First Name*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"First Name"}
                    {...register("first_name", {
                      required: "Please Enter First Name",
                      pattern: namePattern,
                      maxLength: nameMaxLength,
                      minLength: nameMinLength,
                    })}
                    id="firstName"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.first_name && errors?.first_name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="last_name">Last Name*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Last Name"}
                    {...register("last_name", {
                      required: "Please Enter Last Name",
                      pattern: namePattern,
                      maxLength: nameMaxLength,
                      minLength: nameMinLength,
                    })}
                    id="last_name"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.last_name && errors?.last_name?.message}
                </p>
              </Flex>
              <Input
                label="Email"
                register={register}
                name="email"
                isRequired={true}
                icon="/icons/mail.png"
                htmlFor="email"
                type="email"
                error={errors?.email}
                placeholder="Enter Your Email"
                className="add-team-member-input"
                errorMessage="Please Enter Email"
              />
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="mobile">Phone Number*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    placeholder={"Phone Number"}
                    {...register("mobile", {
                      required: "Please Enter Phone Number",
                      pattern: phonePattern,
                      min: {
                        value: 0,
                        message: "Phone number cannot be negative",
                      },
                    })}
                    id="mobile"
                  />
                  <img src="/icons/call.png" alt="icon" />
                </div>

                <p className="global-input-error">
                  {errors?.mobile && errors?.mobile?.message}
                </p>
              </Flex>
              <Flex
                className="add-team-member-input select-job-title-input"
                direction="column"
                gap={10}
              >
                <JobTitlesDropdown
                  label="Select Job Title"
                  value={job_title}
                  onChange={(option) => {
                    setValue("job_title", option);
                  }}
                  refetch={addSuccess}
                />
                <p className="global-input-error">
                  {jobError && !job_title && jobError}
                </p>
                <button
                  className="fill-previous-btn"
                  onClick={() => setAddJobTitlePopup(true)}
                  type="button"
                >
                  Add New Job Title
                </button>
              </Flex>
            </div>
            <h2 className="website-design-head mt-30">Working Hours</h2>
            <div className="working-timings mt-20">
              <div className="working-time-open-close">
                <label htmlFor="monday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="monday-timing"
                    value={"Nail Art"}
                    checked
                  />
                  <span className="business-service-label">Monday</span>
                </label>
                <div className="open-close-time">
                  <div className="open-time-label-input">
                    <label htmlFor="monday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={monday_opening_time}
                        onChange={(time) => {
                          setValue("monday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="monday-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={monday_closing_time}
                        onChange={(time) => {
                          setValue("monday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>

                  <p className="website-design-date-error global-input-error">
                    {errors?.monday_closing_time &&
                      errors?.monday_closing_time?.message}
                  </p>
                </div>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="tuesday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="tuesday-timing"
                    value={"Tuesday"}
                    checked
                  />
                  <span className="business-service-label">Tuesday</span>
                </label>
                <div className="open-close-time">
                  {monday_opening_time?.length > 0 &&
                    monday_closing_time?.length > 0 &&
                    (!tuesday_opening_time?.length > 0 ||
                      !tuesday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            tuesday_opening_time: monday_opening_time,
                            tuesday_closing_time: monday_closing_time,
                            monday_opening_time,
                            monday_closing_time,
                          })
                        }
                      >
                        Copy Previous Day
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="tuesday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={tuesday_opening_time}
                        onChange={(time) => {
                          setValue("tuesday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="tuesday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={tuesday_closing_time}
                        onChange={(time) => {
                          setValue("tuesday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.tuesday_closing_time &&
                    errors?.tuesday_closing_time?.message}
                </p>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="wednesday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="wednesday-timing"
                    value={"Wednesday"}
                    checked
                  />
                  <span className="business-service-label">Wednesday</span>
                </label>
                <div className="open-close-time">
                  {tuesday_opening_time?.length > 0 &&
                    tuesday_closing_time?.length > 0 &&
                    (!wednesday_opening_time?.length > 0 ||
                      !wednesday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            wednesday_opening_time: tuesday_opening_time,
                            wednesday_closing_time: tuesday_closing_time,
                            tuesday_closing_time,
                            tuesday_opening_time,
                            monday_closing_time,
                            monday_opening_time,
                          })
                        }
                      >
                        Copy Previous Day
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="wednesday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={wednesday_opening_time}
                        onChange={(time) => {
                          setValue("wednesday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="wednesday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={wednesday_closing_time}
                        onChange={(time) => {
                          setValue("wednesday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.wednesday_closing_time &&
                    errors?.wednesday_closing_time?.message}
                </p>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="thursday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="thursday-timing"
                    value={"Thursday"}
                    checked
                  />
                  <span className="business-service-label">Thursday</span>
                </label>
                <div className="open-close-time">
                  {wednesday_opening_time?.length > 0 &&
                    wednesday_closing_time?.length > 0 &&
                    (!thursday_opening_time?.length > 0 ||
                      !thursday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            thursday_opening_time: wednesday_opening_time,
                            thursday_closing_time: wednesday_closing_time,
                            monday_closing_time,
                            monday_opening_time,
                            tuesday_opening_time,
                            tuesday_closing_time,
                            wednesday_opening_time,
                            wednesday_closing_time,
                          })
                        }
                      >
                        Copy Previous Day
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="thursday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={thursday_opening_time}
                        onChange={(time) => {
                          setValue("thursday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="thursday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={thursday_closing_time}
                        onChange={(time) => {
                          setValue("thursday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.thursday_closing_time &&
                    errors?.thursday_closing_time?.message}
                </p>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="friday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="friday-timing"
                    value={"Friday"}
                    checked
                  />
                  <span className="business-service-label">Friday</span>
                </label>
                <div className="open-close-time">
                  {thursday_opening_time?.length > 0 &&
                    thursday_closing_time?.length > 0 &&
                    (!friday_opening_time?.length > 0 ||
                      !friday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            friday_opening_time: thursday_opening_time,
                            friday_closing_time: thursday_closing_time,
                            monday_opening_time,
                            monday_closing_time,
                            tuesday_closing_time,
                            tuesday_opening_time,
                            wednesday_closing_time,
                            wednesday_opening_time,
                            thursday_closing_time,
                            thursday_opening_time,
                          })
                        }
                      >
                        Copy Previous Day
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="friday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={friday_opening_time}
                        onChange={(time) => {
                          setValue("friday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="friday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={friday_closing_time}
                        onChange={(time) => {
                          setValue("friday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.friday_closing_time &&
                    errors?.friday_closing_time?.message}
                </p>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="saturday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="saturday-timing"
                    value={"Saturday"}
                    checked
                  />
                  <span className="business-service-label">Saturday</span>
                </label>
                <div className="open-close-time">
                  {friday_opening_time?.length > 0 &&
                    friday_closing_time?.length > 0 &&
                    (!saturday_opening_time?.length > 0 ||
                      !saturday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            saturday_opening_time: friday_opening_time,
                            saturday_closing_time: friday_closing_time,
                            monday_opening_time,
                            monday_closing_time,
                            tuesday_closing_time,
                            tuesday_opening_time,
                            wednesday_closing_time,
                            wednesday_opening_time,
                            thursday_closing_time,
                            thursday_opening_time,
                            friday_opening_time,
                            friday_closing_time,
                          })
                        }
                      >
                        Copy Previous Day
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="saturday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={saturday_opening_time}
                        onChange={(time) => {
                          setValue("saturday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="saturday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={saturday_closing_time}
                        onChange={(time) => {
                          setValue("saturday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.saturday_closing_time &&
                    errors?.saturday_closing_time?.message}
                </p>
              </div>
              <div className="working-time-open-close">
                <label htmlFor="sunday-timing" className="business-service">
                  <input
                    type="checkbox"
                    name=""
                    id="sunday-timing"
                    value={"Sunday"}
                    checked
                  />
                  <span className="business-service-label">Sunday</span>
                </label>
                <div className="open-close-time">
                  {saturday_opening_time?.length > 0 &&
                    saturday_closing_time?.length > 0 &&
                    (!sunday_opening_time?.length > 0 ||
                      !sunday_closing_time?.length > 0) && (
                      <button
                        className="fill-previous-btn"
                        onClick={() =>
                          reset({
                            sunday_opening_time: saturday_opening_time,
                            sunday_closing_time: saturday_closing_time,
                            monday_closing_time,
                            monday_opening_time,
                            tuesday_closing_time,
                            tuesday_opening_time,
                            wednesday_closing_time,
                            wednesday_opening_time,
                            thursday_closing_time,
                            thursday_opening_time,
                            friday_opening_time,
                            friday_closing_time,
                            saturday_closing_time,
                            saturday_opening_time,
                          })
                        }
                      >
                        Fill Previous Timing
                      </button>
                    )}
                  <div className="open-time-label-input">
                    <label htmlFor="sunday-open-timing">Opening Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={sunday_opening_time}
                        onChange={(time) => {
                          setValue("sunday_opening_time", time);
                        }}
                      />
                    </div>
                  </div>
                  <div className="close-time-label-input">
                    <label htmlFor="sunday-close-timing">Closing Time</label>
                    <div id="width-118">
                      <TimePicker
                        value={sunday_closing_time}
                        onChange={(time) => {
                          setValue("sunday_closing_time", time);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="website-design-date-error global-input-error">
                  {errors?.sunday_closing_time &&
                    errors?.sunday_closing_time?.message}
                </p>
              </div>
            </div>

            <div className="edit-client-btns">
              <Button
                variant="primary"
                label={"Add Team Member"}
                type="submit"
                loading={loading}
                loaderColor="#fff"
              />
            </div>
          </form>,
          <h1>{teamMembersDropError}</h1>
        )
      )}

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
      {success && (
        <Popup
          popUp={successPopup}
          setPopUp={setSuccessPopup}
          className="signup-success-popup"
        >
          <img src="/icons/success.png" alt="success" />
          <h3>Team Member invited successfully</h3>
          <Button
            label="Ok"
            variant="rounded-outline"
            handleClick={() => {
              setSuccessPopup(false);
              dispatch(clearState());
              navigate("/portal/team");
            }}
          />
        </Popup>
      )}

      {addJobTitlePopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={setAddJobTitlePopup}
          popUp={addJobTitlePopup}
        >
          <h3>Add Job Title</h3>
          <form
            onSubmit={addJobTitleSubmit((values) => {
              dispatch(addJobTitle({ token, data: { ...values } }));
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="title">Job Title</label>
              <div className="global-input-container-input">
                <input
                  type={"text"}
                  placeholder="Job Title"
                  {...addJobTitleRegister("name", {
                    required: "Please Enter Job Title",
                    maxLength: {
                      value: 30,
                      message: "Title should not be more then 30 characters",
                    },
                  })}
                  id="title"
                  // min={0}
                />
              </div>
              {
                <Flex align="center" justify="space-between">
                  <p className="global-input-error mb-10">
                    {addJobTitleErrors?.name &&
                      addJobTitleErrors?.name?.message}
                  </p>
                  <p className="global-input-error">{addError && addError}</p>
                </Flex>
              }
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => {
                  setAddJobTitlePopup(false);
                  addJobTitleReset(undefined);
                  dispatch(addJobTitleClearState());
                }}
              />
              <Button
                label="Add Title"
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default AddTeamMember;
