import React from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ClassicTemplateBook from "../ClassicTemplate/Book/Book";
import StandardTemplateBook from "../StandardTemplate/Book/Book";
import ProfessionalTemplateBook from "../ProfessionalTemplate/Book/Book";

const WebsiteBook = () => {
  const { loading, error, data } = useSelector((state) => state.websiteStyles);

  if (loading) {
    return <PageLoader />;
  }
  if (data?.template === 1) {
    return <ClassicTemplateBook data={data}/>;
  }
  if (data?.template === 2) {
    return <StandardTemplateBook data={data}/>;
  }
  if (data?.template === 3) {
    return <ProfessionalTemplateBook data={data}/>;
  }
};

export default WebsiteBook;
