import React from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ClassicTemplateServices from "../ClassicTemplate/Services/Services";
import StandardTemplateServices from "../StandardTemplate/Services/Services";
import ProfessioalTemplateServices from "../ProfessionalTemplate/Services/Services";

const WebsiteServices = () => {
  const { loading, data } = useSelector((state) => state.websiteStyles);
  console.log('from website services ',data)
  if (loading) {
    return <PageLoader />;
  }
  if (data?.template === 1) {
    return <ClassicTemplateServices data={data}/>;
  }
  if (data?.template === 2) {
    return <StandardTemplateServices data={data}/>;
  } 
  if (data?.template === 3) {
    return <ProfessioalTemplateServices data={data}/>;
  }
};

export default WebsiteServices;
