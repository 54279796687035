import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Button from "../../../components/styled/Button/Button";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import {
  updateSubscription,
  clearState,
  getSubscriptionDrop,
  getSingleSubscription,
} from "../../../features/ServiceProviderPortal/SubscriptionsSlice";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";

const EditSubscription = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const { token } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    dropDown,
    addLoading,
    addError,
    success,
    subscription,
    getLoading,
    getError,
  } = useSelector((state) => state.subscriptions);

  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(getSubscriptionDrop({ token }));
      dispatch(getSingleSubscription({ token, id }));
    }
  }, [token, dispatch, id]);

  useEffect(() => {
    if (subscription) {
      reset({
        name: subscription?.name,
        purchase_date: subscription?.purchase_date,
        expiry_date: subscription?.expiry_date,
        amount: subscription?.amount,
        notes: subscription?.notes,
      });
    }
  }, [subscription, reset]);

  const handleAddSubscription = (values) => {
    if (!selectedSubscription) {
      setSubscriptionError("Please Select Subscription");
    } else {
      values.type = selectedSubscription.id;
      values.status = 38;
      dispatch(updateSubscription({ token, data: { ...values }, id }));
    }
  };
  useEffect(() => {
    if (success) {
      navigate("/portal/subscriptions");
      dispatch(clearState());
    }
  }, [success, navigate, dispatch]);
  return (
    <div className="outer">
      <Link
        to={"/portal/subscriptions"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Update Subscription</h1>
      {conditionalRender(
        loading || getLoading,
        <PageLoader />,
        conditionalRender(
          !error && !getError,
          <form onSubmit={handleSubmit(handleAddSubscription)}>
            <div className="add-subscription-inputs">
              <Flex
                className={`global-input-container add-subscription-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="name">Name*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Name"}
                    {...register("name", {
                      required: "Please Enter Name",
                      maxLength: {
                        value: 20,
                        message: "Should not be greater then twenty characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Should not be less then three characters",
                      },
                    })}
                    id="name"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.name && errors?.name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-subscription-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="purchase_date">Purchase Date*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"date"}
                    {...register("purchase_date", {
                      required: "Please select purchase date",
                    })}
                    id="purchase_date"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.purchase_date && errors?.purchase_date?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-subscription-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="expiry_date">Expiry Date*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"date"}
                    {...register("expiry_date", {
                      required: "Please select Expiry date",
                    })}
                    id="expiry_date"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.expiry_date && errors?.expiry_date?.message}
                </p>
              </Flex>

              <Flex
                className={`global-input-container add-subscription-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="amount">Amount*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    {...register("amount", {
                      required: "Please select purchase date",
                      maxLength: {
                        value: 4,
                        message: "Should not be greater then four digits",
                      },
                    })}
                    id="amount"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.amount && errors?.amount?.message}
                </p>
              </Flex>
              <div className="add-subscription-input">
                <GlobalDropDown
                  label="Select Subscription*"
                  options={dropDown}
                  stateHandler={selectedSubscription}
                  setStateHandler={setSelectedSubscription}
                  icon="/icons/user.png"
                />
                <p className="global-input-error">
                  {subscriptionError &&
                    !selectedSubscription &&
                    subscriptionError}
                </p>
              </div>
            </div>
            <div className="lable-textarea-group lable-input-group">
              <label htmlFor="notes">Add Notes*</label>
              <div className="edit-client-icon-textarea">
                <img src="/icons/profile-2user.png" alt="" />
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="Notes"
                  {...register("notes", {
                    required: "Please Enter Notes",
                    maxLength: {
                      value: 100,
                      message: "Not Should be more then 100 characters",
                    },
                  })}
                ></textarea>
              </div>
              <p className="global-input-error">
                {error?.notes && errors?.notes?.message}
              </p>
            </div>

            <Button
              label="Save"
              type="submit"
              loading={addLoading}
              loaderColor="#fff"
            />
          </form>,
          <GlobalErrorHandler label={error ? error : getError} />
        )
      )}

      {addError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default EditSubscription;
