import React, { useEffect, useState } from "react";
import "./style.css";
import Tabs from "../../../components/styled/Tabs/Tabs";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import { namePattern } from "../../../utills/Schems";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  clearState,
  updateBusinessProfile,
  updateUserProfile,
  updatePassword,
} from "../../../features/ServiceProviderPortal/updateProfileSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import { getUserData } from "../../../features/auth/authSlice";
import CheckBox from "../../../components/Checkbox/Checkbox";
import { convBoolToNumber } from "../../../utills/conversion";
import { toast } from "react-toastify";
import { authorizeRole } from "../../../utills/authorizeRole";
import { Check, Eye, EyeOff, X } from "react-feather";
const ProfileSettings = () => {
  const [activeOption, setActiveOption] = useState("Personal Profile");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBinaryImage, setSelectedBinaryImage] = useState(null);
  const [businessImage, setBusinessImage] = useState(null);
  const [businessBinaryImage, setBusinessBinaryImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          const { width, height } = image;
          const minWidth = 20;
          const minHeight = 20;
          const maxWidth = 1600;
          const maxHeight = 1600;
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            if (event.target.name === "image") {
              setBusinessBinaryImage(file);
              setBusinessImage(reader.result);
            }
            if (event.target.name === "image1") {
              setSelectedImage(reader.result);
              setSelectedBinaryImage(file);
            }

            setErrorMessage("");
          } else {
            setErrorMessage(
              "Image size is not within the allowed limits(100-1600 x 100-1600). Please choose an image with appropriate dimensions."
            );
            setSelectedImage(null);
            setSelectedBinaryImage(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };

  const { token, userData } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    success,
    updatePassLoading,
    updatePassSuccess,
    updatePassError,
  } = useSelector((state) => state.updateProfile);
  const { getUserLoading, getUserError } = useSelector(
    (state) => state.authTemp
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [seletedCurrency, setSelectedCurrency] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      last_name: userData?.last_name,
      first_name: userData?.first_name,
    },
  });
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: userData?.business_id?.business_name,
      address: userData?.business_id?.address,
    },
  });

  const {
    register: updatePassRegister,
    formState: { errors: updatePassErrors },
    handleSubmit: updatePassHandleSubmit,
    watch,
  } = useForm();
  const handleUpdateUserProfile = (values) => {
    const formData = new FormData();
    if (selectedTimeZone) {
      values.timezone = selectedTimeZone.name;
      formData.append("timezone", selectedTimeZone.name);
    }
    if (selectedLocation) {
      formData.append("location", selectedLocation.name);
    }
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("file_name", selectedBinaryImage);
    formData.append("address", values.address);
    dispatch(updateUserProfile({ token, data: formData }));
  };

  const handleUpdateBusinessProfile = (values) => {
    const formData = new FormData();
    if (selectedPaymentMethod) {
      formData.append("payment_method", selectedPaymentMethod.name);
    }
    if (seletedCurrency) {
      formData.append("currency", seletedCurrency.name);
    }

    formData.append("business_name", values.name);
    formData.append("address", values.address);
    formData.append("file_name", businessBinaryImage);
    formData.append("show_address", convBoolToNumber(values.show_address));
    dispatch(updateBusinessProfile({ token, data: formData }));
  };

  useEffect(() => {
    if (token) {
      dispatch(getUserData(token));
    }
    if (success) {
      toast.success("Profile Updated Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
    }
    if (updatePassSuccess) {
      toast.success("Password updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setChangePassPopup(false);
    }
  }, [token, dispatch, success, updatePassSuccess]);

  const [changePassPopup, setChangePassPopup] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);

  const password = watch("password");

  const [passwordConstraints, setPasswordConstraints] = useState({
    length: false,
    uppercase: false,
    specialCharacter: false,
    number: false,
  });

  const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/;

  const handlePasswordChange = (e) => {
    setPasswordConstraints({
      length: e.target.value.length >= 8,
      uppercase: /[A-Z]/.test(e.target.value),
      specialCharacter: /[!@#$&*]/.test(e.target.value),
      number: /[0-9]/.test(e.target.value),
    });
  };

  return (
    <div className="outer">
      <h1 className="profiles-head">Profile</h1>
      <Tabs
        width={346}
        options={
          authorizeRole(userData.roles, "Business Admin")
            ? ["Personal Profile", "Business Profile"]
            : ["Personal Profile"]
        }
        activeOption={activeOption}
        setActiveOption={setActiveOption}
      />

      {conditionalRender(
        getUserLoading,
        <PageLoader />,
        conditionalRender(
          !getUserError,
          <>
            {activeOption === "Personal Profile" ? (
              <form onSubmit={handleSubmit(handleUpdateUserProfile)}>
                <div className="edit-profile-inputs">
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <label htmlFor="email">Email</label>
                    <div
                      className="global-input-container-input global-input-container-input-with-image"
                      style={{
                        cursor: "not-allowed",
                        background: `rgba(84, 89, 255, 0.10)`,
                        borderRadius: "8px",
                      }}
                    >
                      <input
                        style={{
                          cursor: "not-allowed",
                          background: `rgba(84, 89, 255, 0.10)`,
                        }}
                        disabled
                        type={"text"}
                        placeholder={userData.email}
                        id="email"
                      />
                      <img src="/icons/mail.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.email && errors?.email?.message}
                    </p>
                  </Flex>
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <label htmlFor="user-name">User Name</label>
                    <div
                      className="global-input-container-input global-input-container-input-with-image"
                      style={{
                        cursor: "not-allowed",
                        background: `rgba(84, 89, 255, 0.10)`,
                        borderRadius: "8px",
                      }}
                    >
                      <input
                        style={{
                          cursor: "not-allowed",
                          background: `rgba(84, 89, 255, 0.10)`,
                        }}
                        disabled
                        type={"text"}
                        value={userData?.username}
                        id="user-name"
                      />
                      <img src="/icons/user.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.email && errors?.email?.message}
                    </p>
                  </Flex>
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <Flex
                      align="center"
                      justify="space-between"
                      className="profile-settings-password-container"
                    >
                      <label htmlFor="user-name">Password</label>
                      <p onClick={() => setChangePassPopup(true)}>Change</p>
                    </Flex>
                    <div
                      className="global-input-container-input global-input-container-input-with-image"
                      style={{
                        cursor: "not-allowed",
                        background: `rgba(84, 89, 255, 0.10)`,
                        borderRadius: "8px",
                      }}
                    >
                      <input
                        style={{
                          cursor: "not-allowed",
                          background: `rgba(84, 89, 255, 0.10)`,
                        }}
                        disabled
                        type={"text"}
                        placeholder="********"
                        id="user-name"
                      />
                      <img src="/icons/user.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.email && errors?.email?.message}
                    </p>
                  </Flex>
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <label htmlFor="first_name">First Name*</label>
                    <div className="global-input-container-input global-input-container-input-with-image">
                      <input
                        type={"text"}
                        placeholder={"First Name"}
                        {...register("first_name", {
                          required: false,
                          minLength: {
                            value: 3,
                            message: "Should not be less then 3 characters",
                          },
                          maxLength: {
                            value: 20,
                            message: "Should not be greater then 20 characters",
                          },
                          pattern: namePattern,
                        })}
                        id="first_name"
                      />
                      <img src="/icons/user.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.first_name && errors?.first_name?.message}
                    </p>
                  </Flex>
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <label htmlFor="last_name">Last Name*</label>
                    <div className="global-input-container-input global-input-container-input-with-image">
                      <input
                        type={"text"}
                        placeholder={"Last Name"}
                        {...register("last_name", {
                          required: "Please Enter Last Name",
                          minLength: {
                            value: 3,
                            message: "Should not be less then 3 characters",
                          },
                          maxLength: {
                            value: 20,
                            message: "Should not be greater then 20 characters",
                          },
                          pattern: namePattern,
                        })}
                        id="last_name"
                      />
                      <img src="/icons/user.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.last_name && errors?.last_name?.message}
                    </p>
                  </Flex>

                  {/* <div className="edit-profile-input">
                    <GlobalDropDown
                      label="Please Select Timezone"
                      stateHandler={selectedTimeZone}
                      setStateHandler={setSelectedTimeZone}
                      icon="/icons/appointments.png"
                      options={timeZones}
                    />
                  </div>
                  <div className="edit-profile-input">
                    <GlobalDropDown
                      label="Location"
                      stateHandler={selectedLocation}
                      setStateHandler={setSelectedLocation}
                      defaultName={userData?.location}
                      icon="/icons/user.png"
                      options={[
                        {
                          name: "Virtual",
                        },
                        {
                          name: "On Site",
                        },
                      ]}
                    />
                  </div>
                  <Flex
                    className={`global-input-container edit-profile-input`}
                    direction="column"
                    gap={10}
                  >
                    <label htmlFor="address">Address*</label>
                    <div className="global-input-container-input global-input-container-input-with-image">
                      <input
                        type={"text"}
                        placeholder={"Address"}
                        {...register("address", {
                          required: false,
                          maxLength: {
                            value: 20,
                            message: "Should not be greater then 20 characters",
                          },
                        })}
                        id="address"
                      />
                      <img src="/icons/user.png" alt="icon" />
                    </div>
                    <p className="global-input-error">
                      {errors?.address && errors?.address?.message}
                    </p>
                  </Flex> */}
                </div>

                <div className="lable-input-group">
                  <label htmlFor="address">Profile-picture</label>
                  <p className="image-size-constraints">Please upload an image with a minimum size of 20x20 pixels and a maximum size of 1600x1600 pixels</p>
                  <div className="upload-content-image-section " id="img-box">
                    <label htmlFor="file">
                      <div>
                        {selectedImage || userData?.image_url ? (
                          <div className="upload-img">
                            {/* <img src={selectedImage} alt="Preview" /> */}
                            {(selectedImage || userData?.image_url) && (
                              <img
                                src={
                                  selectedImage
                                    ? selectedImage
                                    : userData?.image_url
                                }
                                alt="Selected"
                              />
                            )}
                          </div>
                        ) : (
                          // <img src={uploadimg} alt="Preview" />
                          <>
                            <div className="upload-photo">
                              <div className="up-img">
                                <span className="drag-drop">
                                  Please Select Image
                                </span>
                              </div>
                            </div>
                            {errorMessage && (
                              <div className="error-message">
                                {errorMessage}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </label>
                    <input
                      className="upload-content-image"
                      type="file"
                      accept="image/*"
                      name="image1"
                      id="file"
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="file" className="upload-content-label">
                      Choose image
                    </label>
                  </div>
                </div>
                <div className="edit-client-btns">
                  {/* <button className="edit-client-save">Save</button> */}
                  <Button
                    variant="primary"
                    label={"Save"}
                    loading={loading}
                    loaderColor="#fff"
                    type="submit"
                  />
                  {/* <button className="edit-client-cancel">Cancel</button> */}
                </div>
              </form>
            ) : authorizeRole(userData.roles, "Business Admin") ? (
              <>
                <h1 className="business-profile-head">
                  Note: Business Name' will be shown in various areas such as
                  booking site, invoices, vouchers, notifications and messages
                  sent to clients.
                </h1>
                <form onSubmit={handleSubmit2(handleUpdateBusinessProfile)}>
                  <div className="edit-profile-inputs">
                    <Flex
                      className={`global-input-container edit-profile-input`}
                      direction="column"
                      gap={10}
                    >
                      <label htmlFor="name">Business Name*</label>
                      <div className="global-input-container-input global-input-container-input-with-image">
                        <input
                          type={"text"}
                          placeholder={"Business Name"}
                          {...register2("name", {
                            required: "Please Enter Business Name",
                            minLength: {
                              value: 3,
                              message: "Should not be less then 3 characters",
                            },
                            maxLength: {
                              value: 20,
                              message:
                                "Should not be greater then 20 characters",
                            },
                          })}
                          id="name"
                        />
                        <img src="/icons/user.png" alt="icon" />
                      </div>

                      <p className="global-input-error">
                        {errors2?.name && errors2?.name?.message}
                      </p>
                    </Flex>
                    <Flex
                      className={`global-input-container edit-profile-input`}
                      direction="column"
                      gap={10}
                    >
                      <label htmlFor="address">Billing Address</label>
                      <div className="global-input-container-input global-input-container-input-with-image">
                        <input
                          type={"text"}
                          placeholder={"Address"}
                          {...register2("address", {
                            required: "Please Enter Address",
                            minLength: {
                              value: 3,
                              message: "Should not be less then 3 characters",
                            },
                            maxLength: {
                              value: 20,
                              message:
                                "Should not be greater then 20 characters",
                            },
                          })}
                          id="address"
                        />
                        <img src="/icons/user.png" alt="icon" />
                      </div>
                      <p className="global-input-error">
                        {errors2?.address && errors2?.address?.message}
                      </p>
                    </Flex>

                    {/* <div className="edit-profile-input" style={{zIndex:2}}>
                      <GlobalDropDown
                        label="Currency"
                        stateHandler={seletedCurrency}
                        setStateHandler={setSelectedCurrency}
                        defaultName={userData?.business_id?.currency}
                        options={[
                          {
                            name: "US Dollars",
                          },
                          {
                            name: "Euros",
                          },
                          {
                            name: "PKR",
                          },
                          {
                            name: "INR",
                          },
                        ]}
                      />
                      <p className="global-input-error"></p>
                    </div> */}
                  </div>
                  <div className="edit-profile-input" style={{zIndex:1}}>
                    <GlobalDropDown
                      label="Select Payment Method"
                      stateHandler={selectedPaymentMethod}
                      setStateHandler={setSelectedPaymentMethod}
                      defaultName={userData?.business_id?.payment_method}
                      options={[
                        {
                          name: "PayPal",
                        },
                        {
                          name: "Stripe",
                        },
                        {
                          name: "Bank Transfer",
                        },
                        {
                          name: "Debit / Credit Card",
                        },
                      ]}
                    />
                    <p className="global-input-error"></p>
                  </div>
                  <div className="lable-input-group">
                    <label htmlFor="address">Business Logo</label>
                    <p className="image-size-constraints">Please upload an image with a minimum size of 20x20 pixels and a maximum size of 1600x1600 pixels</p>
                    <div
                      className="upload-content-image-section width-343 "
                      style={{ width: "343px" }}
                      id="img-box"
                    >
                      <label htmlFor="file">
                        <div>
                          {selectedImage || userData?.business_id?.image_url ? (
                            <div className="upload-img">
                              {businessImage ? (
                                <img src={businessImage} alt="Selected" />
                              ) : (
                                <img
                                  src={userData?.business_id?.image_url}
                                  alt="Selected"
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              <div className="upload-photo">
                                <div className="up-img">
                                  <span className="drag-drop">
                                    Select Image
                                  </span>
                                </div>
                              </div>
                              {errorMessage && (
                                <div className="error-message">
                                  {errorMessage}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </label>
                      <input
                        className="upload-content-image"
                        type="file"
                        accept="image/*"
                        name="image"
                        id="file"
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="file" className="upload-content-label">
                        Choose image
                      </label>
                    </div>
                    <div className="mb-20"></div>
                    <CheckBox
                      register={register2}
                      label="Display address on website"
                      name="show_address"
                      checked={userData?.business_id?.show_address}
                    />
                  </div>
                  <div className="edit-client-btns">
                    <Button
                      variant="primary"
                      label={"Save"}
                      type="submit"
                      loading={loading}
                      loaderColor="#fff"
                    />
                    {/* <button className="edit-client-cancel">Cancel</button> */}
                  </div>
                </form>
              </>
            ) : null}
          </>,
          <h1>{getUserError}</h1>
        )
      )}

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
      {updatePassError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={updatePassError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}

      {changePassPopup && !updatePassError && (
        <Popup
          className="change-password-popup"
          popUp={changePassPopup}
          setPopUp={setChangePassPopup}
        >
          <form
            onSubmit={updatePassHandleSubmit((values) => {
              if (values.password === values.old_password) {
                toast.error("Old passord cannot be used as new password");
              } else {
                dispatch(
                  updatePassword({
                    token,
                    data: {
                      old_password: values.old_password,
                      new_password: values.password,
                    },
                  })
                );
              }
            })}
          >
            <h1>Update Your password</h1>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="old_password">Old Password*</label>
              <div className="global-input-container-input global-input-container-input-with-image update-pass-input-container">
                <input
                  type={`${showOldPass ? "text" : "password"}`}
                  id="old_password"
                  {...updatePassRegister("old_password", {
                    required: "Please Enter old password",
                    pattern: passwordPattern,
                  })}
                />
                <div className="update-password-view-icons">
                  {showOldPass ? (
                    <Eye
                      onClick={() => setShowOldPass(false)}
                      className="eye"
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  ) : (
                    <EyeOff
                      className="eye-off"
                      onClick={() => setShowOldPass(true)}
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  )}
                </div>
              </div>
              <p className="global-input-error mb-10">
                {updatePassErrors?.old_password &&
                  updatePassErrors?.old_password?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="password">New Password*</label>
              <div className="global-input-container-input global-input-container-input-with-image update-pass-input-container">
                <input
                  type={`${showPass ? "text" : "password"}`}
                  id="password"
                  {...updatePassRegister("password", {
                    required: "Please Enter your password",
                    pattern: passwordPattern,
                  })}
                  onChange={handlePasswordChange}
                />
                <div className="update-password-view-icons">
                  {showPass ? (
                    <Eye
                      onClick={() => setShowPass(false)}
                      className="eye"
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  ) : (
                    <EyeOff
                      className="eye-off"
                      onClick={() => setShowPass(true)}
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  )}
                </div>
              </div>
              {updatePassErrors?.password && (
                <div className="global-password-constraints">
                  <Flex align="center" gap={5}>
                    {passwordConstraints.length ? (
                      <Check size={15} color={"#14A800"} />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.length ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      At least 8 characters
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.uppercase ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.uppercase ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Capital letter(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.specialCharacter ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.specialCharacter
                            ? "#14A800"
                            : "#FF0000"
                        }`,
                      }}
                    >
                      Special character(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.number ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.number ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Number(s)
                    </p>
                  </Flex>
                </div>
              )}
              <p className="global-input-error"></p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="cPass">Confirm Password*</label>
              <div className="global-input-container-input global-input-container-input-with-image update-pass-input-container">
                <input
                  type={`${showCPass ? "text" : "password"}`}
                  id="cPass"
                  {...updatePassRegister("cPass", {
                    validate: (value) =>
                      value === password || "password doesn't match",
                    required: "confirm password is required",
                  })}
                />
                <div className="update-password-view-icons">
                  {showCPass ? (
                    <Eye
                      onClick={() => setShowCPass(false)}
                      className="eye"
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  ) : (
                    <EyeOff
                      className="eye-off"
                      onClick={() => setShowCPass(true)}
                      size={20}
                      color="#666"
                      cursor="pointer"
                    />
                  )}
                </div>
              </div>
              <p className="global-input-error mb-10">
                {updatePassErrors?.cPass && updatePassErrors?.cPass?.message}
              </p>
            </Flex>

            <Flex align="center" justify="center" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => setChangePassPopup(false)}
              />
              <Button
                label="Save"
                type="submit"
                loading={updatePassLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default ProfileSettings;
