import React, { useEffect, useState } from "react";
import "./Pricing.css";
import Flex from "../../../../components/styled/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPricing,
  getSinglePricing,
} from "../../../../features/SuperAdminPortal/pricingSlice";
import { conditionalRender } from "../../../../utills/conditionalRender";
import PageLoader from "../../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { handleUndefined } from "../../../../utills/handleUndefined";
import Popup from "../../../../components/styled/Popup/Popup";
import FeaturesDropDown from "../../../../components/Portal/featuresDropdown/FeaturesDropDown";
import { useForm } from "react-hook-form";
import { X } from "react-feather";

const Pricing = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, pricings, pricing, getLoading, getError } =
    useSelector((state) => state.pricing);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getAllPricing({ token }));
    }
  }, [dispatch, token]);

  const {
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    if (pricing) {
      setValue("features", pricing.features);
    }
  }, [pricing]);
  const features = watch("features");
  useEffect(() => {
    console.log(features);
  }, [features]);
  const [popup, setPopup] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  return (
    <div className="outer" style={{ position: "relative" }}>
      <div className="pt-10">
        <Flex justify="space-between">
          <h1 className="team-member-details-head">Pricing</h1>
        </Flex>
      </div>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          conditionalRender(
            pricings?.length > 0,
            <>
              {pricings?.map((pricing) => {
                return (
                  <div className="admin-pricing mt-20" key={pricing?.id}>
                    <div className="admin-pricing-head">
                      <Flex align="center" justify="space-between">
                        <span className="admin-pricing-head-title">
                          {pricing?.subscription_type}
                        </span>
                        <span className="admin-pricing-head-title">
                          $150 Per Month
                        </span>
                        <Flex gap={8} align="center">
                          <img
                            src="/icons/edit.png"
                            alt="edit"
                            onClick={() => {
                              setPopup(true);
                              dispatch(
                                getSinglePricing({ token, id: pricing.id })
                              );
                            }}
                          />
                        </Flex>
                      </Flex>
                    </div>
                    <div className="admin-pricing-body">
                      <ul className="admin-pricing-ul">
                        {pricing?.features?.map((item) => {
                          return (
                            <li key={item?.id}>
                              {handleUndefined(item?.name)}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </>,
            <GlobalEmptyDataHandler label="No Pricings to show" />
          ),
          <GlobalErrorHandler label={error} />
        )
      )}

      {popup && (
        <Popup
          popUp={popup}
          setPopUp={setPopup}
          className="update-pricing-popup"
        >
          <h1>Update pricing</h1>
          {conditionalRender(
            getLoading,
            <PageLoader />,
            conditionalRender(
              !getError,
              <>
                <form>
                  <div className="update-pricing-inputs">
                    <div
                      className="update-pricing-input"
                      style={{ transform: "translateY(5px)" }}
                    >
                      <FeaturesDropDown
                        label="Select feature"
                        selectedFeature={selectedFeature}
                        setSelectedFeature={setSelectedFeature}
                        handleChange={() => {}}
                      />
                    </div>
                    <Flex
                      className={`global-input-container update-pricing-input`}
                      direction="column"
                      gap={10}
                    >
                      <label htmlFor="price">Price*</label>
                      <div className="global-input-container-input global-input-container-input-with-image">
                        <input
                          type={"number"}
                          placeholder={"0300000000"}
                          id="price"
                          min={0}
                        />
                        <img src="/icons/amount.png" alt="icon" />
                      </div>
                    </Flex>
                  </div>
                </form>
                <div className="admin-pricing-body">
                  <ul className="admin-pricing-ul">
                    {features?.map((item) => {
                      return (
                        <Flex key={item?.id} align='flex-start' gap={10}>
                          <X size={15}/>
                          <li style={{ listStyle: "none" ,marginLeft:'0',padding:'0'}}>
                            {handleUndefined(item?.name)}
                          </li>
                        </Flex>
                      );
                    })}
                  </ul>
                </div>
              </>,
              <GlobalErrorHandler label={getError} />
            )
          )}
        </Popup>
      )}
    </div>
  );
};

export default Pricing;
