import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Flex from "../../styled/Flex/Flex";
import "./style.css";
import { ChevronUp, ChevronDown } from "react-feather";
import { conditionalRender } from "../../../utills/conditionalRender";
import { getPublicServices } from "../../../features/ServiceProviderPortal/servicesSlice";

const ServicesPublicDropdown = ({ label, selectedService, setSelectedService, handleChange, defaultSelected, labelColor,id }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const { loading, error, services } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    dispatch(getPublicServices({ id }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedService(defaultSelected);
    }
  }, [defaultSelected, setSelectedService]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="global-client-dropdown" ref={dropdownRef}>
      <div
        className="global-client-drop-head"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <h2 style={{color: `${conditionalRender(labelColor, labelColor, '#000')}`}}>{label}</h2>
        <Flex
          className="global-client-drop-input"
          align="center"
          justify="space-between"
          gap={10}
        >
          <Flex className="global-client-drop-input-left" gap={15}>
            <img src="/icons/user.png" alt="user" />
            <h3>
              {conditionalRender(
                loading,
                "Loading...",
                conditionalRender(
                  selectedService,
                  selectedService?.name,
                  "Select Service"
                )
              )}
            </h3>
          </Flex>
          {conditionalRender(
            open,
            <ChevronUp color="#666666" />,
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>

      <div
        className="global-client-drop-options"
        style={{
          maxHeight: `${open ? "300px" : "0px"}`,
          overflow: `${open ? "auto" : "hidden"}`,
        }}
      >
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Flex
            direction="column"
            gap={10}
            className="global-client-drop-options-inner"
          >
            {!error ? (
              services && services?.length > 0 ? (
                services.map((service, index) => {
                  const name = service.name.toLowerCase();
                  return (
                    <div
                      key={index}
                      style={{
                        display: `${
                          query
                            ? name.includes(query?.toLowerCase())
                              ? "block"
                              : "none"
                            : "block"
                        }`,
                      }}
                    >
                      <Flex
                        className="global-client-drop-option"
                        gap={15}
                        align="center"
                      >
                        <Flex
                          align="center"
                          justify="center"
                          className="global-services-drop-image"
                        >
                          <img src={service?.base_url} alt="service" />
                        </Flex>
                        <span
                          onClick={() => {
                            setSelectedService(service);
                            setOpen(false);
                            handleChange(service);
                          }}
                          className={`${
                            service?.id === selectedService?.id
                              ? "client-drop-option-active"
                              : ""
                          }`}
                        >
                          {service?.name}
                        </span>
                      </Flex>
                    </div>
                  );
                })
              ) : (
                <p className="global-empty-para">
                  No Services to show
                </p>
              )
            ) : (
              <p className="dropdown-empty">{error}</p>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default ServicesPublicDropdown;
