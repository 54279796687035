import React, { useState } from 'react'
import Flex from '../../../components/styled/Flex/Flex'
import GlobalDropDown from '../../../components/styled/Form/GlobalDropDown/GlobalDropDown'
import Button from '../../../components/styled/Button/Button'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'

const EditServiceProvider = () => {
  const dropOptions = [{name: "XYZ"},{name: "ABC"}]
  const [selectedBusinessType, setSelectedBusinessType] = useState(null)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  return (
    <div className="outer">
      <Link to={"/admin-portal/service-providers"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20">Edit Service Provider</h1>
      <div className="mt-20">
      <form>
        <div className="add-team-member-inputs">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="businessName">Business Name*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"text"}
                placeholder={"Business Name"}
                id="businessName"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="last_name">Email*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"email"}
                placeholder={"m.iqbal@gmail.com"}
                id="email"
              />
              <img src="/icons/sms.png" alt="icon" />
            </div>
            
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="username">Phone*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"number"}
                placeholder={"0300000000"}
                id="number"
                min={0}
              />
              <img src="/icons/call-1.png" alt="icon" />
            </div>
            
          </Flex>
          {/* <Input
            label="Email"
            name="email"
            // isRequired={true}
            icon="/icons/mail.png"
            htmlFor="email"
            type="email"
            placeholder="Enter Your Email"
            className="add-team-member-input"
            errorMessage="Please Enter Email"
          /> */}
          {/* <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="mobile">Phone Number*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"number"}
                placeholder={"Phone Number"}
 
                id="mobile"
              />
              <img src="/icons/call.png" alt="icon" />
            </div>
           
          </Flex> */}
          {/* <Input
                label="Password"
                register={register}
                name="password"
                icon="/icons/user.png"
                htmlFor="password"
                type="password"
                error={errors?.password}
                placeholder="Enter Your Password"
                className="add-team-member-input"
              />
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="confirm_password">Confirm Password*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"password"}
                    placeholder={"Confirm Your Password"}
                    {...register("confirm_password", {
                      validate: (value) =>
                       !password || (password === value || "Passwords didn't matched"),
                    })}
                    id="confirm_password"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                {errors?.confirm_password && (
                  <p className="global-input-error">
                    {errors?.confirm_password?.message}
                  </p>
                )}
              </Flex> */}

          <Flex className="add-team-member-input" direction="column" gap={10}>
            <GlobalDropDown
              options={dropOptions}
              label="Business Type*"
              icon="/icons/appointments.png"
              stateHandler={selectedBusinessType}
              setStateHandler={setSelectedBusinessType}
            />
            
          </Flex>
          <Flex className="add-team-member-input" direction="column" gap={10}>
            <GlobalDropDown
              options={dropOptions}
              label="Subscription Type*"
              icon="/icons/appointments.png"
              stateHandler={selectedSubscription}
              setStateHandler={setSelectedSubscription}
            />
            
          </Flex>
          {/* <Flex className="add-team-member-input" direction="column" gap={10}>
            <GlobalDropDown
              options={teamMembersDrop?.systemRole}
              label="System Role"
              setStateHandler={setSystemRole}
              stateHandler={systemRole}
              icon="/icons/user.png"
              defaultId={teamMember?.roles[0]?.id}
            />
            {roleError && !systemRole && (
              <p className="global-input-error">{roleError}</p>
            )}
          </Flex> */}
        </div>


        <div className="edit-client-btns">
            <Flex align='center' gap={20}>

          <Button
            variant="primary"
            label={"Save"}
            type="submit"
            loaderColor="#fff"
          />
          <Link to='/admin-portal/service-providers'>
          <Button variant="outline" label={'Cancel'} />
          </Link>
            </Flex>
        </div>
      </form>

      </div>
    </div>
  );
}

export default EditServiceProvider