import React from 'react'
import Button from '../../../components/styled/Button/Button'
import { ArrowLeft } from 'react-feather'
import { Link } from 'react-router-dom'

const AssignAppointment = () => {
  return (
    <div className="outer">
      <Link to={"/portal/dashboard"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Edit Appointment</h1>
      <div
        className="edit-client-lables-inputs"
        id="edit-voucher-lables-inputs"
      >
        <div className="lable-input-group">
          <label htmlFor="id">ID*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/user.png" alt="" />
            <input type="text" placeholder="12233" id="id" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="name">Name*</label>
          <div className="edit-client-icon-input width-343" style={{width: "343px"}}>
            <img src="/icons/user.png" alt="" />
            <input type="text" placeholder="Muhammad" id="name" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="email">Email*</label>
          <div className="edit-client-icon-input width-343" style={{width: "343px"}}>
            <img src="/icons/sms.png" alt="" />
            <input type="email" placeholder="m.iqbal@gmail.com" id="email" />
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="services">Services*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/profile-2user.png" alt="" />
            {/* <input type="text" placeholder="1234" id="services" /> */}
            <select name="" id="services">
              <option value="">Hair Dresser</option>
              <option value="">Hair Dresser</option>
              <option value="">Hair Dresser</option>
              <option value="">Hair Dresser</option>
            </select>
          </div>
        </div>
        <div className="lable-input-group">
          <label htmlFor="assign">Assign*</label>
          <div className="edit-client-icon-input">
            <img src="/icons/profile-2user.png" alt="" />
            {/* <input type="text" placeholder="1234" id="services" /> */}
            <select name="" id="services">
              <option value="">Xyz</option>
              <option value="">Abc</option>
              <option value="">Ali</option>
              <option value="">Iqbal</option>
            </select>
          </div>
        </div>

        <div className="lable-textarea-group lable-input-group">
          <label htmlFor="notes">Add Notes*</label>
          <div className="edit-client-icon-textarea">
            {/* <input type="number" placeholder="sent" id="id" /> */}
            <img src="/icons/profile-2user.png" alt="" />
            <textarea
              name=""
              id="notes"
              cols="135"
              rows="3"
              placeholder="Notes"
            ></textarea>
          </div>
        </div>
      </div>

      <div className="edit-client-btns">
        {/* <button className="edit-client-save">Save</button> */}
        <Button variant="primary" label={"Save"} />
        {/* <button className="edit-client-cancel">Cancel</button> */}
      </div>
    </div>
  )
}

export default AssignAppointment