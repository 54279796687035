import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  clearState,
  deleteCLient,
  getClients,
  importClients,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import { concatNames } from "../../../utills/concatNames";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { getCalendarData } from "../../../utills/getCalender";
import { toast } from "react-toastify";
import qs from "query-string";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";
const Clients = () => {
  const { mode } = qs.parse(window.location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    error,
    clients,
    deleteClientLoading,
    deleteClientSuccess,
    importLoading,
    importSuccess,
    addSuccess,
    importError,
  } = useSelector((state) => state.clients);
  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  useEffect(() => {
    if (token) {
      dispatch(getClients({ token, query: { currentPage, itemsPerPage } }));
    }
    if (deleteClientSuccess) {
      setClientPopUp(false);
      dispatch(clearState());
      toast.success("Client Deleted Successfully", {
        position: "top-right",
      });
    }
    if (importSuccess) {
      setImportPopup(false);
      dispatch(clearState());
      toast.success("Clients imported successfully");
    }
  }, [
    dispatch,
    token,
    currentPage,
    itemsPerPage,
    deleteClientSuccess,
    importSuccess,
  ]);
  // useEffect(() => {
  //   if (mode) {
  //     if (mode === "add") {
  //       toast.success("Client added successfully", {
  //         position: "top-right",
  //       });
  //       navigate("/portal/clients");
  //     }
  //   }
  // }, [mode, navigate]);

  const [clientPopUp, setClientPopUp] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteClient = () => {
    const apiData = { token, id: delId };
    dispatch(deleteCLient(apiData));
    setDelId(null);
  };

  const [activeResRow, setActiveResRow] = useState(1);
  const [importPopup, setImportPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState(null);

  return (
    <div className="clients-container" style={{ position: "relative" }}>
      <div className="clients-container-top clients-container-inner">
        <h1 className="edit-client-heading">Clients</h1>
        <div className="clients-container-top-buttons">
          <Button
            label="Import Client list"
            variant="outline"
            handleClick={() => setImportPopup(true)}
          />
          {checkIfPermittedToSingleTab(permissions, 10, userData?.roles) && (
            <Link to="/portal/add-client">
              <Button label="Add Client" />
            </Link>
          )}
        </div>
      </div>
      {conditionalRender(
        loading,
        <PageLoader />,
        <>
          <Table id="clients-table">
            <TableHead>
              <TableRow>
                <TableHeadData>ID</TableHeadData>
                <TableHeadData>Client Name</TableHeadData>
                <TableHeadData>Email</TableHeadData>
                <TableHeadData>Cell No</TableHeadData>
                {!(
                  !checkIfPermittedToSingleTab(
                    permissions,
                    11,
                    userData?.roles
                  ) &&
                  !checkIfPermittedToSingleTab(permissions, 12, userData?.roles)
                ) ? (
                  <TableHeadData>Action</TableHeadData>
                ) : null}
              </TableRow>
            </TableHead>
            {conditionalRender(
              !error,
              conditionalRender(
                clients?.items?.length > 0,
                <TableBody>
                  {clients?.items &&
                    clients?.items.map((client, index) => {
                      return (
                        <TableRow key={index}>
                          <TableBodyData>
                            <TableText>
                              {conditionalRender(
                                client.client_no,
                                client.client_no,
                                "-"
                              )}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <Link to={`/portal/client-details/${client.id}`}>
                              <TableText variant="link">
                                {`${client.first_name} ${client.last_name}`}
                              </TableText>
                            </Link>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{client.email}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{client.phone}</TableText>
                          </TableBodyData>

                          {!(
                            !checkIfPermittedToSingleTab(
                              permissions,
                              11,
                              userData?.roles
                            ) &&
                            !checkIfPermittedToSingleTab(
                              permissions,
                              12,
                              userData?.roles
                            )
                          ) ? (
                            <TableBodyData>
                              <Flex align="center" gap={10} justify="center">
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  11,
                                  userData?.roles
                                ) && (
                                  <Link to={`/portal/edit-client/${client.id}`}>
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                )}
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  12,
                                  userData?.roles
                                ) && (
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setClientPopUp(true);
                                      setDelId(client.id);
                                    }}
                                  />
                                )}
                              </Flex>
                            </TableBodyData>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No clients to show" />
              ),
              <GlobalErrorHandler label={error} />
            )}
          </Table>
          <ResponsiveContainer>
            {conditionalRender(
              clients?.items?.length > 0,
              clients?.items?.map((client, index) => {
                return (
                  <div key={index} style={{ position: "relative" }}>
                    <PhoneTable>
                      <PhoneTableHead
                        onClick={() => setActiveResRow(index + 1)}
                      >
                        <PhoneTableRow>
                          <PhoneTableHeadData>
                            <PhoneTableBoldText>Id</PhoneTableBoldText>
                          </PhoneTableHeadData>
                          <PhoneTableHeadData>
                            <PhoneTableBoldText>
                              {conditionalRender(
                                client.client_no,
                                client.client_no,
                                "-"
                              )}
                            </PhoneTableBoldText>
                          </PhoneTableHeadData>
                          {/* <PhoneTableHeadData>
                            <PhoneTableBoldText>
                              <ChevronDown />
                            </PhoneTableBoldText>
                          </PhoneTableHeadData> */}
                        </PhoneTableRow>
                      </PhoneTableHead>
                      <PhoneTableBody
                        className={`${
                          index + 1 === activeResRow
                            ? "responsive-table-body-show"
                            : "responsive-table-body-hide"
                        }`}
                      >
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Client Name</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <Link to={`/portal/client-details/${client.id}`}>
                              <PhoneTableSimpleText variant="link">
                                {conditionalRender(
                                  client,
                                  concatNames(
                                    client?.first_name,
                                    client?.last_name
                                  ),
                                  "-"
                                )}
                              </PhoneTableSimpleText>
                            </Link>
                          </PhoneTableBodyData>
                          {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                        </PhoneTableRow>

                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Email</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData isEmail={true}>
                            <PhoneTableSimpleText>
                              {conditionalRender(client, client?.email, "-")}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                          {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>
                              Phone Number
                            </PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <PhoneTableSimpleText>
                              {conditionalRender(client, client?.phone, "-")}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                          {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>
                              Total Appointments
                            </PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <PhoneTableSimpleText>
                              {client?.total_appointment}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                          {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                        </PhoneTableRow>
                        {!(
                          !checkIfPermittedToSingleTab(
                            permissions,
                            11,
                            userData?.roles
                          ) &&
                          !checkIfPermittedToSingleTab(
                            permissions,
                            12,
                            userData?.roles
                          )
                        ) ? (
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" gap={10}>
                              {checkIfPermittedToSingleTab(
                                  permissions,
                                  11,
                                  userData?.roles
                                ) && (
                                  <Link to={`/portal/edit-client/${client.id}`}>
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                )}
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  12,
                                  userData?.roles
                                ) && (
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setClientPopUp(true);
                                      setDelId(client.id);
                                    }}
                                  />
                                )}
                              </Flex>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                        ) : null}
                      </PhoneTableBody>
                    </PhoneTable>
                    <div className="global-responsive-table-chevron">
                      <ChevronDown
                        className={`${
                          index + 1 === activeResRow
                            ? "table-chevron-rotate"
                            : "table-chevron-rerotate"
                        }`}
                      />
                    </div>
                  </div>
                );
              }),
              <GlobalEmptyDataHandler label={"No Clients To Show"} />
            )}
          </ResponsiveContainer>
          {clients?.meta?.totalItems > clients?.meta?.itemsPerPage &&
            !error && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={clients?.meta?.totalPages}
              />
            )}
        </>
      )}

      {clientPopUp && (
        <Popup
          className="client-popup"
          popUp={clientPopUp}
          setPopUp={setClientPopUp}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClient}
                loading={deleteClientLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setClientPopUp(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {importPopup && (
        <Popup
          popUp={importPopup}
          setPopUp={setImportPopup}
          className="import-clients-popup"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!selectedFile) {
                setSelectedFileError("Please Select File");
              } else {
                const apiData = new FormData();
                apiData.append("file_name", selectedFile);

                dispatch(importClients({ token, file: apiData }));
              }
            }}
          >
            <input
              type="file"
              id="file"
              onChange={(e) => {
                setSelectedFile(e.target.files[0]);
              }}
            />
            <Flex align="center" justify="flex-end">
              <p className="global-input-error mb-20">
                {selectedFileError && !selectedFile && selectedFileError}
              </p>
              <p className="global-input-error">
                {importError ? importError : null}
              </p>
            </Flex>
            <Flex align="center" justify="space-between" gap={10}>
              <Button
                type="submit"
                label="Import"
                loading={importLoading}
                loaderColor="#fff"
              />
              <Button
                label="Download Template "
                variant="outline"
                handleClick={() => {
                  const fileName = "format.xlsx";
                  const fileUrl = process.env.PUBLIC_URL + "/" + fileName;
                  const link = document.createElement("a");
                  link.href = fileUrl;
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                }}
              />
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default Clients;
