import React, { useEffect, useState } from "react";
import "./CreateInvoice.css";
import Button from "../../../components/styled/Button/Button";
import { ArrowLeft, X } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import ServiceDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createInvoice,
  clearState,
  getInvoiceDrop,
} from "../../../features/ServiceProviderPortal/invoiceSlice";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { getCurrentDate, prettifyDate } from "../../../utills/prettifyDate";
import { toast } from "react-toastify";
import Tag from "../../../components/styled/Tag/Tag";
import ReactDatePicker from "react-datepicker";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import {
  InvoiceBadge,
  InvoiceHeading,
  InvoiceStatusBadge,
} from "../InvoiceDetails/InvoiceDetails";
import { concatNames } from "../../../utills/concatNames";
const CreateInvoice = () => {
  const [selectedClient, setSelectedCLient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const { token, userData } = useSelector((state) => state.auth);
  const {
    addInvoiveLoading,
    success,
    addInvoiceError,
    loading,
    error,
  } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropError, setDropError] = useState(null);
  const {
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });
  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (success) {
      toast.success("Invoice created Successfully", {
        position: "top-right",
      });
      toast.success("Invoice sent successfully");
      navigate("/portal/invoices");
      dispatch(clearState());
    }
  }, [success, navigate, dispatch]);
  useEffect(() => {
    dispatch(getInvoiceDrop({ token }));
  }, [token, dispatch]);

  const handleAddInvoice = (values) => {
    if (!selectedClient || !selectedServices.length > 0) {
      setDropError("Please Select");
    } else {
      if (selectedStatus) {
        values.status = selectedStatus.id;
      } else {
        values.status = 31;
      }
      values.client = selectedClient.id;
      let services = [];
      selectedServices.forEach((service) => {
        if (!service?.type) {
          services.push(service.id);
        }
      });
      values.services = services;
      let customServices = [];
      selectedServices.forEach((service) => {
        if (service?.type === "custom") {
          customServices.push({ name: service.name, cost: service.cost });
        }
      });
      values.custom_services = customServices;
      values.total_amount = calculateTotalBill();
    }
    dispatch(createInvoice({ token, data: values }));
  };

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceNameError, setServiceNameError] = useState(null);
  const [addServicePopup, setAddServicePopup] = useState(false);
  // const [serviceCost,setServiceCost]
  const appointment_date = watch("appointment_date");
  const due_date = watch("due_date");
  const addTag = (service) => {
    if (service?.id && selectedServices.find((m) => m?.id === service?.id)) {
      return;
    } else {
      setSelectedServices((prevData) => [...prevData, service]);
    }
  };

  const removeTag = (service) => {
    const updatedServices = selectedServices.filter((m) => m.id !== service.id);
    setSelectedServices(updatedServices);
  };

  const calculateTotalBill = () => {
    let total = 0;
    selectedServices.forEach((item) => {
      total += parseInt(item.cost);
    });
    return total;
  };
  const [viewPopup, setViewPopup] = useState(false);
  return (
    <div className="outer">
      <Link
        to={"/portal/invoices"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Invoices</h1>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <form onSubmit={handleSubmit(handleAddInvoice)}>
            <div className="add-invoice-inputs">
              <div className="add-invoice-input">
                <ClientsDropDown
                  onChange={() => {}}
                  label="Select Client*"
                  selectedClient={selectedClient}
                  setSelectedCLient={setSelectedCLient}
                />
                <p className="global-input-error">
                  {dropError && !selectedClient ? dropError : null}
                </p>
              </div>
              <Flex
                className={`global-input-container add-invoice-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="appointment_date">Appointment Date*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  {/* <input
                    type={"date"}
                    placeholder={"Name"}
                    {...register("appointment_date", {
                      required: "Please Select Date",
                    })}
                    id="appointment_date"
                  /> */}
                  <Controller
                    control={control}
                    name="appointment_date"
                    rules={{
                      required: "Please select appointment date",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={getCurrentDate()}
                        placeholderText="Please select date"
                      />
                    )}
                  />
                  <img src="/icons/appointments.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.appointment_date &&
                    errors?.appointment_date?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-invoice-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="due_date">Due Date*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  {/* <input
                    type={"date"}
                    placeholder={"Due Date"}
                    {...register("due_date", {
                      required: "Please Select Date",
                      validate: (value) =>
                        value > appointment_date ||
                        "Due date cannot be less then or equal to appointment date",
                    })}
                    id="due_date"
                    min={getCurrentDate()}
                  /> */}
                  <Controller
                    control={control}
                    name="due_date"
                    rules={{
                      required: "Please select due date",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        minDate={getCurrentDate()}
                        placeholderText="Please select date"
                      />
                    )}
                  />
                  <img src="/icons/appointments.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.due_date && errors?.due_date?.message}
                </p>
              </Flex>
              <div className="add-invoice-input service-input-invoice">
                <div>
                  <ServiceDropDown
                    handleChange={addTag}
                    label="Select Services*"
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                  />
                  {/* <div className="service-add-custom-btn"> */}

                  {/* </div> */}
                  <p className="global-input-error">
                    {dropError && !selectedService ? dropError : null}
                  </p>
                </div>
                <button
                  className="fill-previous-btn service-add-custom-btn"
                  onClick={() => setAddServicePopup(true)}
                  type="button"
                >
                  Add a custom service
                </button>
                <div className="selected-team-members mb-20">
                  {selectedServices && selectedServices.length > 0
                    ? selectedServices.map((service, index) => {
                        return (
                          <Tag onClick={() => removeTag(service)} key={index}>
                            {service?.name}
                          </Tag>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            <h3 className="invoice-total-amount">
              {selectedServices.length > 0 &&
                `Total Price : £${calculateTotalBill()}`}
            </h3>

            <div className="edit-client-btns">
              <Button
                variant="primary"
                label={"Preview Invoice"}
                handleClick={() => {
                  if (
                    !selectedClient ||
                    !appointment_date ||
                    !due_date ||
                    !selectedServices.length > 0
                  ) {
                    toast.error("Please fill out form correctly");
                  } else {
                    setViewPopup(true);
                  }
                }}
              />
              <Button
                variant="primary"
                label={"Send Invoice"}
                type="submit"
                loading={addInvoiveLoading}
                loaderColor="#fff"
              />
            </div>
          </form>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {addInvoiceError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addInvoiceError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
      {addServicePopup && (
        <Popup
          className="add-extra-invoice-service"
          popUp={addServicePopup}
          setPopUp={setAddServicePopup}
        >
          <form
            onSubmit={handleSubmit1((values) => {
              if (selectedServices.find((m) => m?.name === values.name)) {
                return;
              } else {
                setSelectedServices((prevData) => [
                  ...prevData,
                  { name: values.name, cost: values.cost, type: "custom" },
                ]);
                setAddServicePopup(false);
                setServiceNameError(null);
              }
            })}
          >
            <Flex
              className={`global-input-container add-extra-service-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="name">Service Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Service Name"}
                  id="name"
                  {...register1("name", {
                    required: "Please Enter Service Name",
                    minLength: {
                      value: 3,
                      message: "Should not be less then three characters",
                    },
                    maxLength: {
                      value: 20,
                      message: "Should not be greater then characters",
                    },
                  })}
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors1?.name && errors1?.name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container add-extra-service-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="cost">Service Cost*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"Cost"}
                  {...register1("cost", {
                    required: "Please Enter Service Cost",
                    maxLength: {
                      value: 5,
                      message: "Should not be greater then five characters",
                    },
                  })}
                  id="cost"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors1?.cost && errors1?.cost?.message}
              </p>
            </Flex>

            <Flex align="center" justify="flex-end">
              <Button label="Add Service" type="submit" />
            </Flex>
          </form>
        </Popup>
      )}
      {viewPopup && (
        <Popup
          className="view-voucher-popup"
          popUp={viewPopup}
          setPopUp={setViewPopup}
        >
          <Flex
            className="invoice-details-top-badge"
            justify="space-between"
            align="center"
          >
            {/* <span>Invoice No: #{invoice?.id}</span> */}
            <span>Appoinment Date# {prettifyDate(appointment_date)}</span>
            <X
              className="view-invoice-close"
              cursor="pointer"
              color="#fff"
              onClick={() => setViewPopup(false)}
            />
          </Flex>
          <Flex justify="space-between" gap={10} align="center">
            <InvoiceBadge
              label="From"
              name={userData?.business_id?.business_name}
              phone="023334455"
              email={userData?.business_id?.email}
            />
            <InvoiceBadge
              label="Send To"
              name={conditionalRender(
                selectedClient,
                concatNames(
                  selectedClient?.first_name,
                  selectedClient?.last_name
                ),
                "-"
              )}
              phone={conditionalRender(
                selectedClient,
                selectedClient?.phone,
                "-"
              )}
              email={conditionalRender(
                selectedClient,
                selectedClient?.email,
                "-"
              )}
            />
          </Flex>

          <div className="invoice-information">
            <div className="invoice-information-left">
              <div className="invoice-table">
                <table>
                  <tr className="mb-45">
                    <td className="mb-45">
                      <InvoiceHeading>Total Amount:</InvoiceHeading>
                    </td>
                    <td className="mb-45">
                      {" "}
                      <span>£{calculateTotalBill()}</span>
                    </td>
                  </tr>
                  <tr className="mb-45">
                    <td className="mb-45">
                      <InvoiceHeading>Due Date:</InvoiceHeading>
                    </td>
                    <td className="mb-45">
                      <span>{prettifyDate(due_date)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <InvoiceHeading>Services</InvoiceHeading>
                    </td>
                  </tr>
                  <tr>

                    <td>
                      <div className="selected-team-members mb-20">
                        {selectedServices && selectedServices.length > 0
                          ? selectedServices.map((service, index) => {
                              return (
                                <Tag
                                  key={index}
                                >
                                  {service?.name}
                                </Tag>
                              );
                            })
                          : null}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="invoice-information-right">
              <div>
                <table>
                  {/* <tr>
                    <td className="mb-45">
                      <InvoiceHeading>Transaction No:</InvoiceHeading>
                    </td>
                    <td className="mb-45">
                      <span>12345</span>
                    </td>
                  </tr> */}
                </table>
                <table>
                  <tr>
                    <td className="mb-45">
                      <InvoiceHeading>Status:</InvoiceHeading>
                    </td>
                    <td className="mb-45">
                      <InvoiceStatusBadge label={"Unpaid"} />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div className="invoice-details-bottom-table">
            <table>
              <tr>
                <td>
                  <InvoiceHeading>Appoinment Date: </InvoiceHeading>
                </td>
                <td>
                  <span>{prettifyDate(appointment_date)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <InvoiceHeading>Created By: </InvoiceHeading>
                </td>
                <td>
                  <span>{userData?.business_id?.business_name}</span>
                </td>
              </tr>
            </table>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default CreateInvoice;
