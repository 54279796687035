import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardData.pending,(state)=>{
        state.loading = true;
    })
    builder.addCase(getDashboardData.fulfilled,(state,action)=>{
        state.loading = false;
        state.data = action.payload;
    })
    builder.addCase(getDashboardData.rejected,(state,action)=>{
        state.loading = false;
        state.error = action.payload;
    })
  },
});

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please Provide Token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/dashboard?year=${apiData.query.year}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
