import React from "react";
import "./style.css";
import { ArrowLeft } from "react-feather";
import { ClientDetailsBadge } from "../ClientDetails/ClientDetails";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Badge from "../../../components/styled/Badge/Badge";
const AppoinmentDetails = () => {
  return (
    <div className="appoinment-details-container">
      <Link to={"/portal/aapoinments"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">{"Vouchers- <Jackson Smith>"}</h1>

      <div className="client-information">
        <ClientDetailsBadge label="Id" value="23121" />
        <ClientDetailsBadge label="Name" value="Jackson Smith" />
        <ClientDetailsBadge label="Date" value="15 Oct 2023" />
        <ClientDetailsBadge label="Phone No" value="+92 315 5687893" />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadData>Id</TableHeadData>
            <TableHeadData>Name</TableHeadData>
            <TableHeadData>Email</TableHeadData>
            <TableHeadData>Cell No</TableHeadData>
            <TableHeadData>Service</TableHeadData>
            <TableHeadData>Status</TableHeadData>
            <TableHeadData>Action</TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableBodyData>
              <TableText variant="link">23244</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>Muhammad Iqbal</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>M.Iqbal@Gmail.com</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>03445568654</TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>Nail Art</TableText>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center">
                <Badge label="Upcomming" tone="success" />
              </Flex>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center" gap={10}>
                <img src="/icons/edit.png" alt="edit" />
                <img src="/icons/delete.png" alt="delete" />
              </Flex>
            </TableBodyData>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default AppoinmentDetails;
