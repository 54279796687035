import React, { useEffect } from "react";
import "./EditClient.css";

import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  getClient,
  updateClient,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { useForm } from "react-hook-form";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import Flex from "../../../components/styled/Flex/Flex";
import { toast } from "react-toastify";
const EditClient = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, client, success, updateClientLoading } = useSelector(
    (state) => state.clients
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (token) {
      dispatch(getClient({ token, id }));
    }
  }, [dispatch, id, token]);

  useEffect(() => {
    if (client) {
      reset({
        first_name: client.first_name,
        last_name: client.last_name,
        email: client.email,
        total_appointment: client.total_appointment,
        phone: client.phone,
        notes:client.notes
      });
    }
  }, [client, reset]);
  useEffect(() => {
    if (success) {
      toast.success("Client updated successfully", {
        position: "top-right",
      });
      navigate("/portal/clients");
      dispatch(clearState());
    }
  }, [success, dispatch, navigate]);

  const handleEditClient = (values) => {
    dispatch(updateClient({ token, id, data: { ...values } }));
  };
  return (
    <>
      <div className="outer">
        <Link
          to={"/portal/clients"}
          className="back"
          onClick={() => dispatch(clearState())}
        >
          <ArrowLeft size={24} color="#5459FF" /> Back
        </Link>
        {conditionalRender(
          loading,
          <PageLoader />,
          conditionalRender(
            !error,
            <form onSubmit={handleSubmit(handleEditClient)}>
              <h1 className="edit-client-heading">Edit Client</h1>
              <div className="add-client-inputs">
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="first_name">First Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      {...register("first_name", {
                        required: "Please Enter First Name",
                        maxLength: {
                          value: 10,
                          message: "Not should be more then ten characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Not should be less then three characters",
                        },
                        pattern: namePattern,
                      })}
                      id="first_name"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors?.first_name && errors?.first_name?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="first_name">Last Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      {...register("last_name", {
                        required: "Please Enter Last Name",
                        maxLength: {
                          value: 10,
                          message: "Not should be more then ten characters",
                        },
                        minLength: {
                          value: 3,
                          message: "Not should be less then three characters",
                        },
                        pattern: namePattern,
                      })}
                      id="first_name"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors?.last_name && errors?.last_name?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="email">Email *</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    
                    <input
                      type={"email"}
                      placeholder={"Enter Email"}
                      {...register("email", {
                        required: "Please Enter Email",
                        pattern: emailPattern,
                      })}
                      id="email"
                    />
                    <img src="/icons/mail.png" alt="icon" />
                  </div>
                  <p className="global-input-error">
                    {errors?.email && errors?.email?.message}
                  </p>
                </Flex>
                <Flex
                  className={`global-input-container add-client-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="phone">Phone*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"number"}
                      placeholder={"Enter Email"}
                      {...register("phone", {
                        required: "Please Enter Phone Number",
                        pattern: phonePattern,
                        min:{value:0, message: "Phone number cannot be negative"},
                      })}
                      id="phone"
                    />
                    <img src="/icons/call-1.png" alt="icon" />
                  </div>
                  <p className="global-input-error mb-10">
                    {errors?.phone && errors?.phone?.message}
                  </p>
                </Flex>
              </div>

              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">
                  Internal Notes{" "}
                  <span>(This is only visible to internal users)</span>
                </label>
                <div className="edit-client-icon-textarea">
                  <textarea
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Enter Notes"
                    {...register("notes", { required: false })}
                  ></textarea>
                </div>
              </div>

              <div className="edit-client-btns">
                <Button variant="outline" label={"Cancel"} type="reset" />
                <Button
                  variant="primary"
                  label={"Save"}
                  loading={updateClientLoading}
                  loaderColor="#fff"
                  type="submit"
                />
              </div>
            </form>,
            <h1>{error}</h1>
          )
        )}
      </div>
      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </>
  );
};

export default EditClient;
