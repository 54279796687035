import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Flex from "../../styled/Flex/Flex";
import "./style.css";
import { ChevronUp, ChevronDown } from "react-feather";
import { conditionalRender } from "../../../utills/conditionalRender";
import { getServiceCategoriesDrop } from "../../../features/ServiceProviderPortal/serviceCategory";

const ServiceCategoriesDropdown = ({ label, onChange, value, refetch }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const { dropLoading, dropError, dropdown } = useSelector(
    (state) => state.serviceCategory
  );
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    dispatch(getServiceCategoriesDrop());
  }, [dispatch, refetch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="global-client-dropdown" ref={dropdownRef}>
      <div
        className="global-client-drop-head"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <h2>{label}</h2>
        <Flex
          className="global-client-drop-input"
          align="center"
          justify="space-between"
          gap={10}
        >
          <Flex className="global-client-drop-input-left" gap={15}>
            <img src="/icons/user.png" alt="user" />
            <h3>
              {conditionalRender(
                dropLoading,
                "Loading...",
                conditionalRender(value, value?.name, "Select Category")
              )}
            </h3>
          </Flex>
          {conditionalRender(
            open,
            <ChevronUp color="#666666" />,
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>

      <div
        className="global-client-drop-options"
        style={{
          maxHeight: `${open ? "300px" : "0px"}`,
          overflow: `${open ? "auto" : "hidden"}`,
        }}
      >
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Flex
            direction="column"
            gap={10}
            className="global-client-drop-options-inner"
          >
            {!dropError ? (
              dropdown && dropdown?.length > 0 ? (
                dropdown.map((service, index) => {
                  const name = service.name.toLowerCase();
                  return (
                    <div
                      key={index}
                      style={{
                        display: `${
                          query
                            ? name.includes(query?.toLowerCase())
                              ? "block"
                              : "none"
                            : "block"
                        }`,
                      }}
                    >
                      <Flex className="global-client-drop-option" gap={15} align="center">
                        <span
                          onClick={() => {
                            setOpen(false);
                            onChange(service);
                          }}
                          className={`${
                            service?.id === value?.id
                              ? "client-drop-option-active"
                              : ""
                          }`}
                        >
                          {service?.name}
                        </span>
                      </Flex>
                    </div>
                  );
                })
              ) : (
                <p className="global-empty-para">No Categories to show</p>
              )
            ) : (
              <p className="dropdown-empty">{dropError}</p>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default ServiceCategoriesDropdown;
