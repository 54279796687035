import React, { useEffect } from "react";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateSignup,
  updateStripeAccountPaymentStatus,
} from "../../../features/auth/authSlice";

const PaymentSuccess = () => {
  const { updateError, updateSuccess } = useSelector(
    (state) => state.authTemp
  );
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { token } = qs.parse(window.location.search);
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      dispatch(
        updateSignup({ id: userData.id, data: { access_token: token } })
      );
    }
  }, [token, navigate, dispatch]);

  // useEffect()

  useEffect(() => {
    if (updateSuccess) {
      dispatch(
        updateStripeAccountPaymentStatus({
          is_payment: true,
          is_stripe_account: userData.business_id.is_stripe_account,
        })
      );
      navigate("/portal/dashboard");
    }
  }, [updateSuccess, navigate]);

  if (updateError) {
    return <h1>{updateError}</h1>;
  }
  return <div></div>;
};

export default PaymentSuccess;
