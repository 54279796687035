import React, { useEffect, useState } from "react";
import "./ServiceProviderClientDetails.css";
import Flex from "../../../components/styled/Flex/Flex";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft, ChevronDown } from "react-feather";
import Button from "../../../components/styled/Button/Button";
import { ClientDetailsBadge } from "../../PortalSection/ClientDetails/ClientDetails";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getClient,
  clearState,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { prettifyDate } from "../../../utills/prettifyDate";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import { PhoneTable, PhoneTableBody, PhoneTableBodyData, PhoneTableBoldText, PhoneTableHead, PhoneTableHeadData, PhoneTableRow, PhoneTableSimpleText } from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { handleUndefined } from "../../../utills/handleUndefined";

const ServiceProviderClientDetails = () => {
  const dispatch = useDispatch();
  const { loading, error, client } = useSelector((state) => state.clients);
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [activeResRow, setActiveResRow] = useState(1);
  useEffect(() => {
    if (token && id) {
      dispatch(getClient({ token, id }));
    }
  }, [dispatch, token, id]);
  return (
    <div className="client-details-container" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <Link
          to={"/admin-portal/service-provider-clients"}
          className="back"
          onClick={() => dispatch(clearState)}
        >
          <ArrowLeft size={24} color="#5459FF" /> Back
        </Link>
        <Link to={`/admin-portal/voucher-details/`}>
          <Button label="View Vouchers" />
        </Link>
      </Flex>
      <div>
        <h1 className="edit-client-heading">{`Client Details`}</h1>
        {conditionalRender(
          loading,
          <PageLoader />,
          <>
            {conditionalRender(
              !error,
              <>
                <div className="client-information mb-20">
                  <ClientDetailsBadge
                    label="Client Id"
                    value={client?.client_no}
                  />
                  <ClientDetailsBadge
                    label="Full Name"
                    value={`${client?.first_name} ${client?.last_name}`}
                  />
                  <ClientDetailsBadge
                    label="Joining Date"
                    value={prettifyDate(client?.created_at)}
                  />
                  <ClientDetailsBadge
                    label="Mobile Number"
                    value={`${client?.phone}`}
                  />
                </div>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadData>Appointment ID</TableHeadData>
                      <TableHeadData>Service Type</TableHeadData>
                      <TableHeadData>Appointment Date</TableHeadData>
                      <TableHeadData>Service provider</TableHeadData>
                      {/* <TableHeadData>Voucher</TableHeadData> */}
                      <TableHeadData>Amount</TableHeadData>
                    </TableRow>
                  </TableHead>
                  {conditionalRender(
                    client?.appointments?.length > 0,
                    <TableBody>
                      {client?.appointments &&
                        client?.appointments?.map((appointment, index) => {
                          return (
                            <TableRow key={index}>
                              <TableBodyData>
                                <TableText variant="link">
                                  {appointment.id}
                                </TableText>
                              </TableBodyData>
                              <TableBodyData>
                                <TableText>Hair Styling</TableText>
                              </TableBodyData>
                              <TableBodyData>
                                <TableText>
                                  {prettifyDate(appointment.date)}
                                </TableText>
                              </TableBodyData>
                              <TableBodyData>
                                <TableText>New look Saloon</TableText>
                              </TableBodyData>
                              <TableBodyData>
                                <Link to="/admin-portal/service-provider-clients">
                                  <TableText variant="link">$120</TableText>
                                </Link>
                              </TableBodyData>
                            </TableRow>
                          );
                        })}
                    </TableBody>,
                    <GlobalEmptyDataHandler />
                  )}
                </Table>
                <ResponsiveContainer>
                {conditionalRender(
                  client?.appointments?.length > 0,
                  client?.appointments?.map((appointment, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <PhoneTable>
                          <PhoneTableHead
                            onClick={() => setActiveResRow(index + 1)}
                          >
                            <PhoneTableRow>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>Appointment ID</PhoneTableBoldText>
                              </PhoneTableHeadData>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>
                                  {handleUndefined(
                                    appointment.id
                                  )}
                                </PhoneTableBoldText>
                              </PhoneTableHeadData>
                            </PhoneTableRow>
                          </PhoneTableHead>
                          <PhoneTableBody
                            className={`${
                              index + 1 === activeResRow
                                ? "responsive-table-body-show"
                                : "responsive-table-body-hide"
                            }`}
                          >
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Service Type</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData >
                                <PhoneTableSimpleText>
                                  {"Hair Styling"}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                Appointment Date
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(prettifyDate(appointment.date))}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Service Provider
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {'New look Saloon'}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <Flex align="center" gap={10}>
                                  <img src="/icons/delete.png" alt="edit" />
                                  <Link to={`/admin-portal/edit-client-details`}>
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          </PhoneTableBody>
                        </PhoneTable>
                        <div className="global-responsive-table-chevron">
                          <ChevronDown
                            className={`${
                              index + 1 === activeResRow
                                ? "table-chevron-rotate"
                                : "table-chevron-rerotate"
                            }`}
                          />
                        </div>
                      </div>
                    );
                  }),
                  <GlobalEmptyDataHandler label={"No referrals To Show"} />
                )}
              </ResponsiveContainer>
              </>,
              <GlobalErrorHandler />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceProviderClientDetails;
