export function isAppointmentAllowed(prevent_booking, appointment_datetime) {
    const current_datetime = new Date();
    const minimum_allowed_datetime = new Date(current_datetime.getTime() + prevent_booking * 60 * 60 * 1000);
    if (appointment_datetime > minimum_allowed_datetime) {
        return true;
    } else {
        return false;
    }
}

export function isCancellationAllowed(minimum_days_before_cancellation, appointment_date_and_time) {
    const current_date = new Date();
    const minimum_allowed_cancellation_date = new Date(appointment_date_and_time);
    minimum_allowed_cancellation_date.setDate(minimum_allowed_cancellation_date.getDate() - minimum_days_before_cancellation);
    if (current_date < minimum_allowed_cancellation_date) {
        return true; 
    } else {
        return false; 
    }
}

export function getAppointmentDateTimeCombined(date,time){
    if(date && time){
        const appointment_date_str = date;
        const appointment_time_str = time;
        const appointment_date = new Date(appointment_date_str);
        const [hours, minutes] = appointment_time_str?.split(":").map(Number);
        appointment_date.setHours(hours);
        appointment_date.setMinutes(minutes);
        appointment_date.setSeconds(0);
        return appointment_date
    }
}