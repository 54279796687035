import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateStateStep1 } from "../../../../features/signup/signupSlice";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../../utills/Schems";
import Flex from "../../../../components/styled/Flex/Flex";
import { Check, X, Eye, EyeOff } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/styled/Button/Button";
import qs from 'query-string'
const Step1 = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const signupState = useSelector((state) => state.signUpData);
  const {loading,error} = useSelector(state => state.authTemp)
  const { accesstoken,is_refferal } = qs.parse(window.location.search);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      first_name: signupState?.first_name,
      last_name: signupState?.last_name,
      email: signupState?.email,
      username: signupState?.username,
      mobile: signupState?.mobile,
    },
  });
 
  const dispatch = useDispatch();

  const onSubmit = (values) => {
      dispatch(updateStateStep1({ ...values }));
      if(accesstoken && is_refferal){
        navigate(`/signup?step=2&accesstoken=${accesstoken}&is_refferal=${is_refferal}`)
      }else{
        navigate(`/signup?step=2`)
      }
  };

  const password = watch("password");

  const [passwordConstraints, setPasswordConstraints] = useState({
    length: false,
    uppercase: false,
    specialCharacter: false,
    number: false,
  });

  const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/;

  const handlePasswordChange = (e) => {
    setPasswordConstraints({
      length: e.target.value.length >= 8,
      uppercase: /[A-Z]/.test(e.target.value),
      specialCharacter: /[!@#$&*]/.test(e.target.value),
      number: /[0-9]/.test(e.target.value),
    });
  };

  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);
  return (
    <div>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="signup-forms-input">
          <div className="input-group">
            <label htmlFor="fName"> First Name *</label>
            <div className="input">
              <input
                type="text"
                id="fName"
                {...register("first_name", {
                  required: "Please Enter First Name",
                  maxLength: {
                    value: 20,
                    message: "Should not be more then 20 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Should not be less then 3 characters",
                  },

                  pattern: namePattern,
                })}
              />
              <img src="/icons/user.png" alt="user" />
              {/* {errors?.first_name && (
                <p className="error">{errors?.first_name?.message}</p>
              )} */}
              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="lName"> Last Name *</label>
            <div className="input">
              <input
                type="text"
                id="lName"
                {...register("last_name", {
                  required: "Please Enter Last Name",
                  maxLength: {
                    value: 20,
                    message: "Should not be more then 20 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Should not be less then 3 characters",
                  },
                  pattern: namePattern,
                })}
              />
              <img src="/icons/user.png" alt="" />
              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </div>
          </div>
          {/* <div className="input-group">
            <label htmlFor="surname"> User Name *</label>
            <div className="input">
              <input
                type="text"
                id="surname"
                {...register("username", {
                  required: "Please Enter Your username",
                  maxLength: {
                    value: 20,
                    message:
                      "username should not be more then twenty characters",
                  },
                  minLength: {
                    value: 5,
                    message: "Should not be less then five characters",
                  },
                  pattern: userNamePattern,
                })}
              />
              <img src="/icons/user.png" alt="user" />
              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.username && errors?.username?.message}
              </p>
            </div>
          </div> */}
          <div className="input-group">
            <label htmlFor="email"> Email*</label>
            <div className="input">
              <input
                type="text"
                id="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: emailPattern,
                })}
              />
              <img src="/icons/mail.png" alt="" />
              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.email && errors?.email?.message}
              </p>
            </div>
          </div>
          {/* <div className="input-group">
            <GlobalDropDown
              stateHandler={selectedBusinessType}
              setStateHandler={setSelectedBusinessType}
              options={businessType}
              defaultId={signupState?.business_type}
              label={"Business Type*"}
              background="#fff"
            />
            <p className="global-input-error">
              {selectedBusinessTypeError &&
                !selectedBusinessType &&
                selectedBusinessTypeError}
            </p>
          </div> */}
          <div className="input-group">
            <label htmlFor="phone1"> Phone Number*</label>
            <div className="input">
              <input
                type="text"
                id="phone1"
                {...register("mobile", {
                  required: "Please Enter your Phone Number",
                  pattern: phonePattern,
                })}
              />
              <img src="/icons/call.png" alt="call" />
              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.mobile && errors?.mobile?.message}
              </p>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="password"> Password*</label>
            <div className="input">
              <input
                type={`${showPass ? "text" : "password"}`}
                id="password"
                {...register("password", {
                  required: "Please Enter your password",
                  pattern: passwordPattern,
                })}
                onChange={handlePasswordChange}
              />
              <div className="password-view-icons">
                {showPass ? (
                  <Eye
                    onClick={() => setShowPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>
            </div>
            <div className="global-password-constraints">
              {errors?.password && (
                <>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.length ? (
                      <Check size={15} color={"#14A800"} />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.length ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      At least 8 characters
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.uppercase ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.uppercase ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Capital letter(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.specialCharacter ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.specialCharacter
                            ? "#14A800"
                            : "#FF0000"
                        }`,
                      }}
                    >
                      Special character(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.number ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.number ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Number(s)
                    </p>
                  </Flex>
                </>
              )}
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="password"> Confirm Password *</label>
            <div className="input">
              <input
                type={`${showCPass ? "text" : "password"}`}
                id="password"
                {...register("confirm_password", {
                  validate: (value) =>
                    value === password || "password doesn't match",
                  required: "confirm password is required",
                })}
              />
              <div className="password-view-icons">
                {showCPass ? (
                  <Eye
                    onClick={() => setShowCPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowCPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>

              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors?.confirm_password && errors?.confirm_password?.message}
              </p>
            </div>

          </div>
            <p className="signup-text">Already Have an account ? <Link to='/login'>Login</Link></p>
        </div>
        <Flex align="center" justify="flex-end">
        <p className="global-input-error">{error && error}</p>
        </Flex>

        <div className="submit-btn-outer">
          <Button
            label="Continue"
            type="submit"
            variant="rounded-outline"
            loading={loading}
            loaderColor="#3e0ffe"
          />
        </div>
      </form>
    </div>
  );
};

export default Step1;
