import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signupUpdate } from "../../../features/auth/authSlice";
import { loadStripe } from "@stripe/stripe-js";
import { BASE_URL } from "../../../features/config";
import Layout from "../../SignUp/Layout/Layout";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import {
  subscriptionDrop,
  subscriptionDropWithoutFreeTrial,
} from "../../SignUp/SignUp/dropDowns";
import Flex from "../../../components/styled/Flex/Flex";
import { conditionalRender } from "../../../utills/conditionalRender";
import BtnLoader from "../../../components/styled/BtnLoader/BtnLoader";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { handlePaymentAndConnectAccountRedirection } from "../../../utills/authorizeRole";
import qs from "query-string";
const Step3 = ({ formData, setFormData, handleSignUp }) => {
  const { reason } = qs.parse(window.location.search);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const signupState = useSelector((state) => state.authTemp);
  const { userData } = useSelector((state) => state.auth);
  const { updateLoading } = useSelector((state) => state.authTemp);
  const [isPaynmentLoading, setIsPaynmentLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  useEffect(() => {
    if (userData) {
      const response = handlePaymentAndConnectAccountRedirection(userData);
      if (response.ok) {
        navigate("/portal/dashboard");
      }
    }
  }, [navigate, userData]);
  const onSubmit = async (values) => {
    if (!selectedSubscription) {
      setSubscriptionError("Please select subscription type");
    } else {
      if (selectedSubscription.id === 9) {
        mutation.mutate({ data: { subscription_type: 9 }, id: userData.id });
      } else {
        handleStipePaynment();
      }
    }
  };
  const mutation = useMutation({
    mutationFn: signupUpdate,
    onError: (err) => {
      const message = err.response?.data?.message || err.message;
      toast.error(message);
    },
    onSuccess: () => {
      navigate("/portal/dashboard");
    },
  });

  const handleStipePaynment = async () => {
    setIsPaynmentLoading(true);
    const stripePromise = await loadStripe(
      "pk_test_51OiDOiBW9V1jae3P0soFdWu1GBooB75ob6hbmGDoIrMHRgQupgIJjWxmNDAcgA3E6YeZ3RZvQpIcfCOq9jXIJjvz00kwKJWM0M"
    );
    const response = await fetch(
      `${BASE_URL}/v1/payment/create-stripe-session-subscription`,
      {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify({
          email: userData.email,
          subscription_type: selectedSubscription.id,
          user_id: userData.id,
        }),
      }
    );

    const parsedRes = await response.json();
    if (parsedRes.statusCode === 409) {
      // const data = await response.json();
      if (parsedRes && parsedRes.redirectUrl) {
        window.location.href = parsedRes.redirectUrl;
      }
      setIsPaynmentLoading(false);
    } else {
      // const session = await response.json();
      // console.log(session)
      stripePromise.redirectToCheckout({
        sessionId: parsedRes.sessionID,
      });
      setIsPaynmentLoading(false);
    }
  };

  return (
    <Layout heading="Choose your plan" step="3">
      {reason === "subscription-expired" && (
        <p style={{ color: "red", marginBottom: "10px", fontSize: "14px" }}>
          Your 15 day free trial is over
        </p>
      )}

      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="signup-forms-input">
          <div className="input-group mb-20">
            <GlobalDropDown
              stateHandler={selectedSubscription}
              setStateHandler={setSelectedSubscription}
              options={
                reason === "subscription-expired"
                  ? subscriptionDropWithoutFreeTrial
                  : subscriptionDrop
              }
              background="#fff"
              label={"Select Subscription*"}
            />
            <p className="global-input-error">
              {subscriptionError && !selectedSubscription && subscriptionError}
            </p>
          </div>
        </div>
        <Flex align="center" justify="flex-end">
          {conditionalRender(
            signupState.error,
            <p className="error-step3">{signupState.error}</p>,
            null
          )}
        </Flex>
        <div
          className="input-group terms-check"
          style={{ position: "relative" }}
        >
          <input
            type="checkbox"
            id="check"
            {...register("userAgreement", {
              required: "Please Agree the terms and conditions",
            })}
          />
          <label htmlFor="check">
            I accept <span>terms and Condtions</span>
          </label>
          {errors.userAgreement && (
            <p className="error">{errors.userAgreement.message}</p>
          )}
        </div>

        <div className="submit-btn-outer">
          {selectedSubscription && (
            <button className="btn-outline" type="submit">
              {conditionalRender(
                updateLoading || isPaynmentLoading,
                <BtnLoader color="#3e0ffe" />,
                conditionalRender(
                  selectedSubscription.id === 9,
                  "Complete Signup",
                  "Continue to checkout"
                )
              )}
            </button>
          )}
        </div>
      </form>

      {/* {updateSuccess && (
        <Popup
          popUp={signupPopup}
          setPopUp={() => {}}
          className="signup-success-popup"
        >
          <img src="/icons/success.png" alt="success" />
          <h3>
            {" "}
            Congratulations,{" "}
            {signup.first_name && signup.last_name
              ? concatNames(signup.first_name, signup.last_name)
              : ""}{" "}
            you have <br /> signed up successfully{" "}
          </h3>
          <Button
            label="Sign In"
            variant="rounded-outline"
            handleClick={() => {
              dispatch(clearStateTemp());
              navigate("/login");
            }}
          />
        </Popup>
      )} */}
    </Layout>
  );
};

export default Step3;
