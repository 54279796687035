import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin } from "react-feather";

const Footer = ({ data }) => {
  return (
    <footer className="classic-template-footer">
      <div className="classic-template-footer-left">
        <Link
          to={`/websites/${data.business_url}/home`}
          className="footer-logo"
        >
          <img src={"/footer/logo2.png"} alt="" />
        </Link>

        <div className="vertical-line"></div>
        <div className="social-media-links">
          {data?.facebook_url && data?.facebook_url?.length > 0 && (
            <Link to={data?.facebook_url} target="_blank">
              <div className="social-media-outer">
                <Facebook color="#FFF" fill="#FFF" size={15} />
              </div>
            </Link>
          )}
          {data?.instagram_url && data?.instagram_url?.length > 0 && (
            <Link to={data?.instagram_url} target="_blank">
              <div className="social-media-outer">
                <Instagram color="#FFF" size={15} />
              </div>
            </Link>
          )}
          {data?.linkedin_url && data?.linkedin_url?.length > 0 && (
            <Link to={data?.linkedin_url} target="_blank">
              <div className="social-media-outer">
                <Linkedin color="#FFF" fill="#FFF" size={15} />
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="classic-template-footer-middle">
        <h2 className="classic-template-footer-middle">
          {data.business_id.business_name} All Rights Reserved
        </h2>
      </div>
      <div className="classic-template-footer-right">
        <Link to={"/templates/classic-template"}>Terms & Conditions</Link>
      </div>
    </footer>
  );
};

export default Footer;
