import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteService,
  getAllServices,
  resetState,
} from "../../../features/ServiceProviderPortal/servicesSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import {
  getCurrentDate,
  transformDuration,
} from "../../../utills/prettifyDate";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import {
  addDiscount,
  clearState,
  deleteDiscount,
  updateDiscount,
} from "../../../features/ServiceProviderPortal/discountsSlice";
import { toast } from "react-toastify";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";
const Services = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  const { loading, error, services, delLoading, delSuccess, delError } =
    useSelector((state) => state.services);
  const {
    addLoading,
    addError,
    success,
    updateLoading,
    updateError,
    updateSuccess,
    delLoading: discountDelLoading,
    delSuccess: discountDelSuccess,
  } = useSelector((state) => state.discounts);
  const dispatch = useDispatch();
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm({
    mode: "onBlur",
  });
  const {
    register: updateRegister,
    formState: { errors: updateErrors },
    handleSubmit: updateHandleSubmit,
    watch: updateWatch,
    control: updateControl,
    reset: updateReset,
  } = useForm({
    mode: "onBlur",
  });
  useEffect(() => {
    if (token) {
      dispatch(getAllServices({ token, query: { currentPage, itemsPerPage } }));
    }
    if (delSuccess) {
      toast.success("Service deleted Successfully", {
        position: "top-right",
      });
      setDelPopup(false);
      dispatch(resetState());
    }
    if (success) {
      toast.success("Discount added successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setAddDiscountPopup(false);
    }
    if (updateSuccess) {
      toast.success("Discount updated successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setUpdateDiscountPopup(false);
    }
    if (discountDelSuccess) {
      toast.success("Discount deleted successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      setUpdateDiscountPopup(false);
    }
  }, [
    token,
    dispatch,
    delSuccess,
    currentPage,
    itemsPerPage,
    success,
    reset,
    updateSuccess,
    discountDelSuccess,
  ]);

  const handleDeleteService = () => {
    dispatch(deleteService({ token, id: delId }));
  };

  const [addDiscountPopup, setAddDiscountPopup] = useState(false);
  const [updateDiscountPopup, setUpdateDiscountPopup] = useState(false);
  const [activeResRow, setActiveResRow] = useState(1);

  const start_date = watch("start_date");
  const remove_discount = updateWatch("remove_discount");
  const [selectedService, setSelectedService] = useState(null);
  return (
    <div className="services-main-container" style={{ position: "relative" }}>
      <Flex
        align="center"
        justify="space-between"
        className="scheduling-main-container-head"
      >
        <h1 className="team-member-details-head">Services</h1>
        {checkIfPermittedToSingleTab(permissions, 36, userData?.roles) && (
          <Flex align="center" gap={10}>
            <Link to="/portal/service-category">
              <Button label="Manage Categories" />
            </Link>
            <Link to="/portal/add-service">
              <Button label="Add Service" />
            </Link>
            
          </Flex>
        )}
      </Flex>

      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Service</TableHeadData>
                  <TableHeadData>Category</TableHeadData>
                  <TableHeadData>Cost</TableHeadData>
                  <TableHeadData>Discount</TableHeadData>
                  <TableHeadData>Duration</TableHeadData>
                  {!(
                    !checkIfPermittedToSingleTab(
                      permissions,
                      35,
                      userData?.roles
                    ) &&
                    !checkIfPermittedToSingleTab(
                      permissions,
                      37,
                      userData?.roles
                    ) &&
                    !checkIfPermittedToSingleTab(
                      permissions,
                      18,
                      userData?.roles
                    )
                  ) ? (
                    <TableHeadData>Action</TableHeadData>
                  ) : null}
                </TableRow>
              </TableHead>
              {conditionalRender(
                services?.items?.length > 0,
                <>
                  <TableBody>
                    {services?.items.map((service, index) => {
                      return (
                        <TableRow>
                          <TableBodyData>
                            <TableText variant="link">{service?.id}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{service?.name}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{service?.service_category_id?.name}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>£{service?.cost}</TableText>
                          </TableBodyData>
                          <TableBodyData className="service-discounts-table-container">
                            <TableText>
                              {service?.discounts
                                ? `${service?.discounts?.discount}%`
                                : "-"}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              {transformDuration(service?.duration)}
                            </TableText>
                          </TableBodyData>
                          {!(
                            !checkIfPermittedToSingleTab(
                              permissions,
                              35,
                              userData?.roles
                            ) &&
                            !checkIfPermittedToSingleTab(
                              permissions,
                              37,
                              userData?.roles
                            ) &&
                            !checkIfPermittedToSingleTab(
                              permissions,
                              18,
                              userData?.roles
                            )
                          ) ? (
                            <TableBodyData className="services-actions-container">
                              <Flex align="center" justify="center" gap={10}>
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  35,
                                  userData?.roles
                                ) && (
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setDelPopup(true);
                                      setDelId(service.id);
                                    }}
                                  />
                                )}

                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  37,
                                  userData?.roles
                                ) && (
                                  <Link
                                    to={`/portal/edit-service/${service.id}`}
                                  >
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                )}

                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  18,
                                  userData?.roles
                                ) &&
                                  (!service?.discounts ? (
                                    <Button
                                      label={"Add Discount"}
                                      variant="outline"
                                      handleClick={() => {
                                        setAddDiscountPopup(true);
                                        setSelectedService(service);
                                      }}
                                    />
                                  ) : (
                                    <Button
                                      label="Edit Discount"
                                      variant="outline"
                                      handleClick={() => {
                                        setUpdateDiscountPopup(true);
                                        setSelectedService(service);
                                        updateReset({
                                          discount: service.discounts.discount,
                                          start_date:
                                            service.discounts.start_date,
                                          end_date: service.discounts.end_date,
                                        });
                                      }}
                                    />
                                  ))}
                              </Flex>
                            </TableBodyData>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>,
                <GlobalEmptyDataHandler label="No Services to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                services?.items?.length > 0,
                services?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Service</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {item?.name}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Category</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {item?.service_category_id?.name}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Cost</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{item?.cost}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Duration</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {transformDuration(item?.duration)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          {!(
                            !checkIfPermittedToSingleTab(
                              permissions,
                              35,
                              userData?.roles
                            ) &&
                            !checkIfPermittedToSingleTab(
                              permissions,
                              37,
                              userData?.roles
                            ) &&
                            !checkIfPermittedToSingleTab(
                              permissions,
                              18,
                              userData?.roles
                            )
                          ) ? (
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <Flex
                                  align="center"
                                  justify="flex-start"
                                  gap={10}
                                >
                                  {checkIfPermittedToSingleTab(
                                  permissions,
                                  35,
                                  userData?.roles
                                ) && (
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setDelPopup(true);
                                      setDelId(item.id);
                                    }}
                                  />
                                )}

                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  37,
                                  userData?.roles
                                ) && (
                                  <Link
                                    to={`/portal/edit-service/${item.id}`}
                                  >
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                )}

                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  18,
                                  userData?.roles
                                ) &&
                                  (!item?.discounts ? (
                                    <Button
                                      label={"Add Discount"}
                                      variant="outline"
                                      handleClick={() => {
                                        setAddDiscountPopup(true);
                                        setSelectedService(item);
                                      }}
                                    />
                                  ) : (
                                    <Button
                                      label="Edit Discount"
                                      variant="outline"
                                      handleClick={() => {
                                        setUpdateDiscountPopup(true);
                                        setSelectedService(item);
                                        updateReset({
                                          discount: item.discounts.discount,
                                          start_date:
                                          item.discounts.start_date,
                                          end_date: item.discounts.end_date,
                                        });
                                      }}
                                    />
                                  ))}
                                </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          ) : null}
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {services?.meta?.totalItems > services?.meta?.itemsPerPage && !error && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={services?.meta?.totalPages}
        />
      )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteService}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(resetState());
            }}
          />
        </Popup>
      )}

      {addDiscountPopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={() => {}}
          popUp={addDiscountPopup}
        >
          <h3>Add Discount to {selectedService.name}</h3>
          <form
            onSubmit={handleSubmit((values) => {
              values.service_id = selectedService.id;
              values.cost = selectedService.cost;
              console.log(values);
              dispatch(addDiscount({ token, data: { ...values } }));
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="discount">Discount %*</label>
              <div className="global-input-container-input">
                <input
                  type={"number"}
                  placeholder="Enter Discount"
                  {...register("discount", {
                    required: "Please Enter Discount",
                    maxLength: {
                      value: 2,
                      message: "Discount should not be more then 2 characters",
                    },
                    min: { value: 0, message: "Discount cannot be negative" },
                  })}
                  id="discount"
                  // min={0}
                />
              </div>
              <p className="global-input-error mb-10">
                {errors?.discount && errors?.discount?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="start_date">Start Date*</label>
              <div className="global-input-container-input">
                <Controller
                  control={control}
                  name="start_date"
                  rules={{ required: "Please select start date" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={getCurrentDate()}
                      placeholderText="Select Start Date"
                    />
                  )}
                />
              </div>
              <p className="global-input-error">
                {errors?.start_date && errors?.start_date?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="end_date">End Date *</label>
              <div className="global-input-container-input">
                <Controller
                  control={control}
                  name="end_date"
                  rules={{
                    required: "Please select end date",
                    validate: (value) =>
                      value > start_date ||
                      "Start date cannot be less then or equal to end from",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={getCurrentDate()}
                      placeholderText="Select Start Date"
                    />
                  )}
                />
              </div>
              <p className="global-input-error">
                {errors?.end_date && errors?.end_date?.message}
              </p>
            </Flex>
            <Flex justify="flex-end" align="center" className="mb-10">
              <p className="global-input-error">{addError && addError}</p>
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => {
                  setAddDiscountPopup(false);
                  reset(undefined);
                }}
              />
              <Button
                label="Add Discount"
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
      {updateDiscountPopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={() => {}}
          popUp={updateDiscountPopup}
        >
          <h3>Update Discount</h3>
          <form
            onSubmit={updateHandleSubmit((values) => {
              if (values.remove_discount) {
                dispatch(
                  deleteDiscount({ token, id: selectedService.discounts.id })
                );
              } else {
                values.service_id = selectedService.id;
                values.cost = selectedService.cost;
                dispatch(
                  updateDiscount({
                    token,
                    data: { ...values },
                    id: selectedService.discounts.id,
                  })
                );
              }
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="discount">Discount %*</label>
              <div className="global-input-container-input">
                <input
                  type={"number"}
                  placeholder="Enter Discount"
                  {...updateRegister("discount", {
                    required: "Please Enter Discount",
                    maxLength: {
                      value: 2,
                      message: "Discount should not be more then 2 characters",
                    },
                    min: { value: 0, message: "Number cannot be less than 0" },
                  })}
                  id="discount"
                />
              </div>
              <p className="global-input-error mb-10">
                {updateErrors?.discount && updateErrors?.discount?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="start_date">Start Date*</label>
              <div className="global-input-container-input">
                <Controller
                  control={updateControl}
                  name="start_date"
                  rules={{ required: "Please select start date" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={getCurrentDate()}
                      placeholderText="Select Start Date"
                    />
                  )}
                />
              </div>
              <p className="global-input-error">
                {updateErrors?.start_date && updateErrors?.start_date?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="end_date">End Date *</label>
              <div className="global-input-container-input">
                <Controller
                  control={updateControl}
                  name="end_date"
                  rules={{
                    required: "Please select end date",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={getCurrentDate()}
                      placeholderText="Select Start Date"
                    />
                  )}
                />
              </div>
              <p className="global-input-error">
                {updateErrors?.end_date && updateErrors?.end_date?.message}
              </p>
            </Flex>
            <div className="mb-20">
              <Checkbox
                register={updateRegister}
                name="remove_discount"
                label="Remove Discount"
                id="remove_discount"
              />
            </div>
            <Flex justify="flex-end" align="center" className="mb-10">
              <p className="global-input-error">{updateError && updateError}</p>
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => setUpdateDiscountPopup(false)}
              />

              {remove_discount ? (
                <Button
                  label="Remove"
                  type="submit"
                  loaderColor="#fff"
                  loading={discountDelLoading}
                />
              ) : (
                <Button
                  label="Save"
                  type="submit"
                  loading={updateLoading}
                  loaderColor="#fff"
                />
              )}
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default Services;
