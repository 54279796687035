import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/LandingPageSection/Home/Home";
import About from "./pages/LandingPageSection/About/About";
import Blogs from "./pages/LandingPageSection/Blogs/Blogs";
import Contact from "./pages/LandingPageSection/ContactUs/Contact";
import Features from "./pages/LandingPageSection/Features/Features";
import Pricing from "./pages/LandingPageSection/Pricing/Pricing";
import Steps from "./pages/LandingPageSection/Steps/Steps";
import Terms from "./pages/LandingPageSection/Terms/Terms";
import SignUp from "./pages/SignUp/SignUp/SignUp";
import Login from "./pages/Login/Login";
import PortalLayout from "./pages/PortalSection/PortalLayout/PortalLayout";
import Dashboard from "./pages/PortalSection/Dashboard/Dashboard";
import EditClient from "./pages/PortalSection/EditClient/EditClient";
import EditVoucher from "./pages/PortalSection/EditVoucher/EditVoucher";
import Clients from "./pages/PortalSection/Clients/Clients";
import ClientDetails from "./pages/PortalSection/ClientDetails/ClientDetails";
import Vouchers from "./pages/PortalSection/Vouchers/Vouchers";
import VoucherDetails from "./pages/PortalSection/VoucherDetails/VoucherDetails";
import Invoices from "./pages/PortalSection/Invoices/Invoices";
import InvoiceDetails from "./pages/PortalSection/InvoiceDetails/InvoiceDetails";
import Appoinments from "./pages/PortalSection/Appoinments/Appoinments";
import AddClient from "./pages/PortalSection/AddClient/AddClient";
import SendVoucher from "./pages/PortalSection/SendVoucher/SendVoucher";
import EditInvoice from "./pages/PortalSection/EditInvoice/EditInvoice";
import CreateInvoice from "./pages/PortalSection/CreateInvoice/CreateInvoice";
import AppoinmentDetails from "./pages/PortalSection/AppoinmentDetails/AppoinmentDetails";
import AppoinmentDetail from "./pages/PortalSection/AppoinmentDetail/AppoinmentDetail";
import TeamMembers from "./pages/PortalSection/TeamMembers/TeamMembers";
import TeamMemberDetails from "./pages/PortalSection/TeamMemberDetails/TeamMemberDetails";
import Scheduling from "./pages/PortalSection/Scheduling/Scheduling";
import Services from "./pages/PortalSection/Services/Services";
import WebsiteDesign from "./pages/PortalSection/WebsiteDesign/WebsiteDesign";
import CustomerIntakeForm from "./pages/PortalSection/CustomerIntakeForm/CustomerIntakeForm";
import Subscriptions from "./pages/PortalSection/Subscriptions/Subscriptions";
import SubscriptionInvoices from "./pages/PortalSection/SubscriptionInvoices/SubscriptionInvoices";
import ProfileSettings from "./pages/PortalSection/ProfileSettings/ProfileSettings";
import ClientReferrals from "./pages/PortalSection/ClientReferrals/ClientReferrals";
import AddClientReferrals from "./pages/PortalSection/AddClientReferrals/AddClientReferrals";
import BusinessReferrals from "./pages/PortalSection/BusinessReferrals/BusinessReferrals";
import AddBusinessReferrals from "./pages/PortalSection/AddBusinessReferrals/AddBusinessReferrals";
import Discounts from "./pages/PortalSection/Discounts/Discounts";
import Integrations from "./pages/PortalSection/Integrations/Integrations";
import Feedback from "./pages/PortalSection/Feedback/Feedback";
import PaynmentInvoices from "./pages/PortalSection/PaynmentInvoices/PaynmentInvoices";
import AppoinmentSetting from "./pages/PortalSection/AppoinmentSetting/AppoinmentSetting";
import EditAppointment from "./pages/PortalSection/EditAppointment/EditAppointment";
import AddAppointment from "./pages/PortalSection/AddAppointment/AddAppointment";
import AssignAppointment from "./pages/PortalSection/AssignAppointment/AssignAppointment";
import EditTeamMember from "./pages/PortalSection/EditTeamMember/EditTeamMember";
import EditScheduling from "./pages/PortalSection/EditScheduling/EditScheduling";
import EditService from "./pages/PortalSection/EditService/EditService";
import AddService from "./pages/PortalSection/AddService/AddService";
import AddSubscription from "./pages/PortalSection/AddSubscritpion/AddSubscription";
import EditSubscription from "./pages/PortalSection/EditSubscription/EditSubscription";
import EditSubscriptionInvoice from "./pages/PortalSection/EditSubscriptionInvoice/EditSubscriptionInvoice";
import EditClientReferral from "./pages/PortalSection/EditClientReferral/EditClientReferral";
import EditBusinessReferral from "./pages/PortalSection/EditBusinessReferral/EditBusinessReferral";
// import EditDiscount from "./pages/PortalSection/EditDiscount/EditDiscount";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import AddTeamMember from "./pages/PortalSection/AddTeamMember/AddTeamMember";
import Notifications from "./pages/PortalSection/Notifications/Notifications";

// standard template imports
import BlogDetails from "./pages/LandingPageSection/BlogDetails/BlogDetails";
// import AddDiscount from "./pages/PortalSection/AddDiscount/AddDiscount";
import AddSubscriptionInvoice from "./pages/PortalSection/AddSubscriptionInvoice/AddSubscriptionInvoice";
import HelpCenter from "./pages/LandingPageSection/HelpCenter/HelpCenter";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy/PrivacyPolicy";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateToken } from "./features/auth/authSlice";
import RegisterTeamMember from "./pages/Register/Team/RegisterTeamMember";
import { ToastContainer } from "react-toastify";
import WebsiteHome from "./pages/websites/WebsiteHome/WebsiteHome";
import Website from "./pages/websites/Website/WebsiteLayout";
import WebsiteServices from "./pages/websites/WebsiteServices/WebsiteServices";
import WebsiteAbout from "./pages/websites/WebsiteAbout/WebsiteAbout";
import WebsiteConsultation from "./pages/websites/WebsiteConsultation/WebsiteConsultation";
import WebsiteBook from "./pages/websites/WebsiteBook/WebsiteBook";
import WebsiteContact from "./pages/websites/WebsiteContact/WebsiteBook";
import WebsiteLogin from "./pages/websites/Login/TemplateLogin";
import WebsiteSignup from "./pages/websites/SignUp/TemplateSignup";
import AppointmentCalender from "./pages/PortalSection/AppointmentCalender/AppointmentCalender";
import AdminPortalLayout from "./pages/AdminPortal/AdminPortalLayout/AdminPortalLayout";
import AdminDashboard from "./pages/AdminPortal/AdminDashboard/AdminDashboard";
import ServiceProviders from "./pages/AdminPortal/ServiceProviders/ServiceProviders";
import AddServiceProvider from "./pages/AdminPortal/AddServiceProvider/AddServiceProvider";
import EditServiceProvider from "./pages/AdminPortal/EditServiceProvider/EditServiceProvider";
import ServiceProviderClients from "./pages/AdminPortal/ServiceProviderClients/ServiceProviderClients";
import AdminPortalScheduling from "./pages/AdminPortal/Scheduling/Scheduling";
import AddServiceProviderClient from "./pages/AdminPortal/AddServiceProviderClient/AddServiceProviderClient";
import EditServiceProviderClient from "./pages/AdminPortal/EditServiceProviderClient/EditServiceProviderClient";
import ServiceProviderClientDetails from "./pages/AdminPortal/ServiceProviderClientDetails/ServiceProviderClientDetails";
import EditSchedule from "./pages/AdminPortal/EditSchedule/EditSchedule";
import AddSchedule from "./pages/AdminPortal/AddSchedule/AddSchedule";
import AdminPortalSubscriptions from "./pages/AdminPortal/Subscriptions/Subscriptions";
import AdminPortalAddSubscription from "./pages/AdminPortal/AddSubscription/AddSubscription";
import AdminPortalEditSubscription from "./pages/AdminPortal/EditSubscription/EditSubscription";
import AdminPortalNotifications from "./pages/AdminPortal/Notifications/Notifications";
import AdminPortalReferrals from "./pages/AdminPortal/Referrals/Referrals";
import AdminPortalAddReferrals from "./pages/AdminPortal/AddReferral/AddReferral";
import AdminPortalEditReferrals from "./pages/AdminPortal/EditReferral/EditReferral";
import AdminPortalBlogs from "./pages/AdminPortal/MainWebsite/Blogs/Blogs";
import AdminPortalAddBlog from "./pages/AdminPortal/MainWebsite/Blogs/AddBlog/AddBlog";
import AdminPortalEditBlog from "./pages/AdminPortal/MainWebsite/Blogs/EditBlog/EditBlog";
import AdminPortalTestimonials from "./pages/AdminPortal/MainWebsite/Testimonials/Testimonials";
import AdminPortalAddTestimonial from "./pages/AdminPortal/MainWebsite/Testimonials/AddTestimonial/AddTestimonial";
import AdminPortalEditTestimonial from "./pages/AdminPortal/MainWebsite/Testimonials/EditTestimonial/EditTestimonial";
import AdminPortalPricing from "./pages/AdminPortal/MainWebsite/Pricing/Pricing";
import AdminPortalAddPricing from "./pages/AdminPortal/MainWebsite/Pricing/AddPricing/AddPricing";
import AdminPortalEditPricing from "./pages/AdminPortal/MainWebsite/Pricing/EditPricing/EditPricing";
import FeaturedBlogs from "./pages/AdminPortal/MainWebsite/Blogs/FeaturedBlogs/FeaturedBlogs";
import ViewBlog from "./pages/AdminPortal/MainWebsite/Blogs/ViewBlog/ViewBlog";
import AdminProfileSettings from "./pages/AdminPortal/ProfileSettings/ProfileSettings";
import SubscriptionInvoiceDetails from "./pages/PortalSection/SubscriptionInvoiceDetails/SubscriptionInvoiceDetails";
import TeamAccessLevels from "./pages/PortalSection/TeamAccessLevels/TeamAccessLevels";
import AssignTeamAccessLevels from "./pages/PortalSection/AssignTeamAccessLevels/AssignTeamAccessLevels";
// Client Portal
import ClientPortalLayout from "./pages/ClientPortal/ClientPortalLayout/ClientPortalLayout";
import ClientAppointments from "./pages/ClientPortal/Appointments/Appointments"
import ClientReviews from "./pages/ClientPortal/Reviews/Reviews"
// import PaymentMethod from "./pages/ClientPortal/PaymentMethod/PaymentMethod";
import ClientPortalReferrals from "./pages/ClientPortal/Referrals/Referrals";
import ClientSettings from "./pages/ClientPortal/Settings/Settings";
import ClientNotifications from './pages/ClientPortal/Notifications/Notifications'
import AddClientAppointment from './pages/ClientPortal/AddAppointment/AddAppointment'
import EditClientAppointment from './pages/ClientPortal/EditAppointment/EditAppointment'
import AddClientReview from './pages/ClientPortal/AddReview/AddReview'
import EditClientReview from './pages/ClientPortal/EditReview/EditReview'
// import AddClientPaymentMethod from './pages/ClientPortal/AddPaymentMethod/AddPaymentMethod'
import AddClientReferral from './pages/ClientPortal/AddReferral/AddReferral'
import EditReferral from './pages/ClientPortal/EditReferral/EditReferral'
import ServiceCategory from "./pages/PortalSection/ServiceCategory/ServiceCategory";
import JobTitle from "./pages/PortalSection/JobTitle/JobTitle";
import Payment from "./pages/LandingPageSection/Payment/Payment";
import PaymentSuccess from "./pages/LandingPageSection/PaymentSuccess/PaymentSuccess";
import CreateConnectAccount from "./pages/LandingPageSection/CreateConnectAccount/CreateConnectAccount";
import WebsitePayment from "./pages/websites/WebsitePayment/WebsitePayment";
import WebsitePaymentSuccess from "./pages/websites/WebsitePaymentSuccess/WebsitePaymentSuccess";
import BusinessClientReviews from "./pages/PortalSection/ClientReviews/ClientReviews";
import AppointmentTeamCalender from "./pages/PortalSection/AppointmentCalender/AppointmentTeamCalender";
function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(validateToken(token));
  }, [token, dispatch]);
  const [color,setColor] = useState('')
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/steps" element={<Steps />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/password/reset" element={<ForgetPassword />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/create-connect-account" element={<CreateConnectAccount />} />
        <Route path="/register/team" element={<RegisterTeamMember />} />
        <Route
          path="/portal"
          element={
            <ProtectedRoute>
              <PortalLayout />
            </ProtectedRoute> 
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="edit-client/:id" element={<EditClient />} />
          <Route path="edit-voucher/:id" element={<EditVoucher />} />
          <Route path="clients" element={<Clients />} />
          <Route path="client-details/:id" element={<ClientDetails />} />
          <Route path="vouchers" element={<Vouchers />} />
          <Route path="voucher-details/:id" element={<VoucherDetails />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="invoice-details/:id" element={<InvoiceDetails />} />
          <Route path="appointments" element={<Appoinments />} />
          <Route path="add-client" element={<AddClient />} />
          <Route path="send-voucher" element={<SendVoucher />} />
          <Route path="edit-invoice/:id" element={<EditInvoice />} />
          <Route path="create-invoice" element={<CreateInvoice />} />
          <Route path="appoinment-details" element={<AppoinmentDetails />} />
          <Route path="appoinment-detail/:id" element={<AppoinmentDetail />} />
          <Route path="team" element={<TeamMembers />} />
          <Route path="team/details/:id" element={<TeamMemberDetails />} />
          <Route path="scheduling" element={<Scheduling />} />
          <Route path="services" element={<Services />} />
          <Route path="service-category" element={<ServiceCategory />} />
          <Route path="job-title" element={<JobTitle />} />
          <Route path="website-design" element={<WebsiteDesign />} />
          <Route path="custom-intake-form" element={<CustomerIntakeForm />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route
            path="subscription-invoices"
            element={<SubscriptionInvoices />}
          />
          <Route path="profile-settings" element={<ProfileSettings />} />
          <Route path="client-referrals" element={<ClientReferrals />} />
          <Route path="add-client-referrals" element={<AddClientReferrals />} />
          <Route path="business-referrals" element={<BusinessReferrals />} />
          <Route
            path="add-business-referrals"
            element={<AddBusinessReferrals />}
          />
          <Route path="discounts" element={<Discounts />} />
          {/* <Route path="add-discount" element={<AddDiscount />} /> */}
          <Route path="integrations" element={<Integrations />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="payments-invoices" element={<PaynmentInvoices />} />
          <Route path="appoinment-setting" element={<AppoinmentSetting />} />
          <Route path="edit-appointment/:id" element={<EditAppointment />} />
          <Route path="add-appointment" element={<AddAppointment />} />
          <Route path="assign-appointment" element={<AssignAppointment />} />
          <Route path="edit-team-member/:id" element={<EditTeamMember />} />
          <Route path="edit-scheduling" element={<EditScheduling />} />
          <Route path="edit-service/:id" element={<EditService />} />
          <Route path="add-service" element={<AddService />} />
          <Route path="add-subscription" element={<AddSubscription />} />
          <Route path="edit-subscription/:id" element={<EditSubscription />} />
          <Route path="add-team-member" element={<AddTeamMember />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="client-reviews" element={<BusinessClientReviews />} />
          <Route
            path="appointment-calender"
            element={<AppointmentCalender />}
          />
          <Route
            path="appointment-team-calender"
            element={<AppointmentTeamCalender />}
          />
          <Route
            path="add-subscription-invoice"
            element={<AddSubscriptionInvoice />}
          />
          <Route
            path="subscription-invoice-details/:id"
            element={<SubscriptionInvoiceDetails />}
          />

          <Route
            path="edit-subscription-invoice/:id"
            element={<EditSubscriptionInvoice />}
          />
          <Route
            path="edit-client-referrals/:id"
            element={<EditClientReferral />}
          />
          <Route
            path="edit-business-referral/:id"
            element={<EditBusinessReferral />}
          />

          {/* Team access levels */}
          <Route path="access-levels" element={<TeamAccessLevels/>}/>
          <Route path="manage-access-levels" element={<AssignTeamAccessLevels/>}/>
        </Route>

        <Route path="/websites/:url" element={<Website color={color} setColor={setColor}/>}>
          <Route path="home" element={<WebsiteHome color={color} setColor={setColor}/>} />
          <Route path="services" element={<WebsiteServices />} />
          <Route path="about" element={<WebsiteAbout />} />
          <Route path="consultation" element={<WebsiteConsultation />} />
          <Route path="book" element={<WebsiteBook />} />
          <Route path="contact" element={<WebsiteContact color={color} setColor={setColor}/>} />
          <Route path="login" element={<WebsiteLogin />} />
          <Route path="signup" element={<WebsiteSignup />} />
          <Route path="book/payment" element={<WebsitePayment />} />
          <Route path="book/success" element={<WebsitePaymentSuccess />} />
        </Route>

        {/* ------------ Admin Portal ------------ */}

        <Route
          path="/admin-portal"
          element={
            <ProtectedRoute>
              <AdminPortalLayout />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="service-providers" element={<ServiceProviders />} />
          <Route path="add-service-provider" element={<AddServiceProvider />} />
          <Route
            path="edit-service-provider"
            element={<EditServiceProvider />}
          />
          <Route
            path="service-provider-clients"
            element={<ServiceProviderClients />}
          />
          <Route path="add-client" element={<AddServiceProviderClient />} />
          <Route path={`edit-client/:id`} element={<EditServiceProviderClient />} />
          <Route
            path="client-details/:id"
            element={<ServiceProviderClientDetails />}
          />
          <Route path="scheduling" element={<AdminPortalScheduling />} />
          <Route path="edit-schedule" element={<EditSchedule />} />
          <Route path="add-schedule" element={<AddSchedule />} />
          <Route path="subscriptions" element={<AdminPortalSubscriptions />} />
          <Route
            path="add-subscription"
            element={<AdminPortalAddSubscription />}
          />
          <Route
            path="edit-subscription/:id"
            element={<AdminPortalEditSubscription />}
          />
          <Route path="notifications" element={<AdminPortalNotifications />} />
          <Route path="referrals" element={<AdminPortalReferrals />} />
          <Route path="add-referral" element={<AdminPortalAddReferrals />} />
          <Route path="edit-referral" element={<AdminPortalEditReferrals />} />
          <Route path="blogs" element={<AdminPortalBlogs />} />
          <Route path="blog-details" element={<ViewBlog />} />
          <Route path="add-blog" element={<AdminPortalAddBlog />} />
          <Route path="edit-blog" element={<AdminPortalEditBlog />} />
          <Route path="testimonials" element={<AdminPortalTestimonials />} />
          <Route path="add-testimonial" element={<AdminPortalAddTestimonial />} />
          <Route path="edit-testimonial" element={<AdminPortalEditTestimonial />} />
          <Route path="pricing" element={<AdminPortalPricing />} />
          <Route path="add-pricing" element={<AdminPortalAddPricing />} />
          <Route path="edit-pricing" element={<AdminPortalEditPricing />} />
          <Route path="featured-blogs" element={<FeaturedBlogs />} />
          <Route path="profile-settings" element={<AdminProfileSettings />} />
        </Route>

        {/* Client Routes */}
        <Route path="/client-portal" element={ <ProtectedRoute><ClientPortalLayout/></ProtectedRoute>}>
          <Route path="appointments" element={<ClientAppointments/>}/>
          <Route path="reviews" element={<ClientReviews/>}/>
          {/* <Route path="payment-method" element={<PaymentMethod/>}/> */}
          <Route path="referrals" element={<ClientPortalReferrals/>}/>
          <Route path="settings" element={<ClientSettings/>}/>
          <Route path="notifications" element={<ClientNotifications/>}/>
          <Route path="add-appointment" element={<AddClientAppointment/>}/>
          <Route path="add-review" element={<AddClientReview/>}/>
          {/* <Route path="add-payment-method" element={<AddClientPaymentMethod/>}/> */}
          <Route path="add-referral" element={<AddClientReferral/>}/>
          <Route path="edit-appointment/:id" element={<EditClientAppointment/>}/>
          <Route path="edit-review/:id" element={<EditClientReview/>}/>
          <Route path="edit-referral" element={<EditReferral/>}/>
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
