import React, { useEffect, useState } from "react";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import "./style.css";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import Tag from "../../../components/styled/Tag/Tag";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  resetState,
  addService,
} from "../../../features/ServiceProviderPortal/servicesSlice";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import ServiceCategoriesDropdown from "../../../components/Portal/ServiceCategoriesDropdown/ServiceCategoriesDropdown";
import {
  addServiceCategory,
  clearState,
} from "../../../features/ServiceProviderPortal/serviceCategory";
const AddService = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [addCategoryPopup, setAddCategoryPopup] = useState(false);
  const { loading, success, error } = useSelector((state) => state.services);
  const { addLoading, addError, addSuccess } = useSelector(
    (state) => state.serviceCategory
  );
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      is_active: 1,
    },
  });
  const [selectedMember, setSelectedMember] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();

        image.onload = () => {
          const { width, height } = image;
          const minWidth = 100;
          const minHeight = 100;
          const maxWidth = 1500;
          const maxHeight = 1500;
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            setSelectedImage(file);
            setShowImage(reader.result);
            setErrorMessage("");
          } else {
            setErrorMessage(
              "Image size is not within the allowed limits(100-701 x 100-445). Please choose an image with appropriate dimensions."
            );
            setSelectedImage(null);
            setShowImage(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };

  const handleTeamDropChange = (member) => {
    if (selectedMembers.find((m) => m.id === member.id)) {
      return;
    } else {
      setSelectedMembers((prevData) => [...prevData, member]);
    }
  };

  const removeTag = (member) => {
    const updatedMembers = selectedMembers.filter((m) => m.id !== member.id);
    setSelectedMembers(updatedMembers);
  };

  useEffect(() => {
    if (success) {
      toast.success("Service added Successfully", {
        position: "top-right",
      });
      dispatch(resetState());
      navigate("/portal/services");
    }
    if (addSuccess) {
      toast.success("Service category added successfully");
      dispatch(clearState());
      setAddCategoryPopup(false);
    }
  }, [success, dispatch, navigate, addSuccess]);

  const handleAddService = (values) => {
    const apiData = new FormData();
    if (!duration.length > 0) {
      setDurationError("Please Enter Duration");
    } else {
      if (selectedMembers.length > 0) {
        const selectedTeam = selectedMembers.map((member) => member.id);
        apiData.append("users", JSON.stringify(selectedTeam));
      }
      if (values?.notes) {
        apiData.append("notes", values.notes);
      }
      apiData.append("name", values.name);
      apiData.append("cost", values.cost);
      apiData.append("duration", duration);
      apiData.append("file_name", selectedImage);
      apiData.append("service_category_id", values.category.id);
      // apiData.append(
      //   "is_active",
      //   values.is_active === 1 || values.isActive === true ? 1 : 0
      // );
      dispatch(addService({ token, data: apiData }));
    }
  };

  const [duration, setDuration] = useState("");
  const [durationError, setDurationError] = useState(null);
  const handleInputChange = (event) => {
    const cleanedValue = event.target.value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{2})(\d{2})/, "$1:$2");
    }
    setDuration(formattedValue);
  };
  const {
    register: addCategoryRegister,
    formState: { errors: addCategoryErrors },
    handleSubmit: addCategorySubmit,
    reset: addCategoryReset,
  } = useForm();
  const category = watch("category");
  return (
    <div className="outer">
      <Link to={"/portal/services"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Service</h1>
      <form onSubmit={handleSubmit(handleAddService)}>
        <div className="edit-service-inputs">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="name">Service Name*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"text"}
                placeholder={"Service Name"}
                {...register("name", {
                  required: "Please Enter Service Name",
                  minLength: {
                    value: 3,
                    message: "Should not be less then three characters",
                  },
                  maxLength: {
                    value: 20,
                    message: "Should not be greater then characters",
                  },
                })}
                id="name"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            <p className="global-input-error">
              {errors?.name && errors?.name?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="cost">Service Cost*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"number"}
                placeholder={"Cost"}
                {...register("cost", {
                  required: "Please Enter Service Cost",
                  maxLength: {
                    value: 5,
                    message: "Should not be greater then five characters",
                  },
                  min: { value: 0, message: "Cost cannot be negative" },
                })}
                id="cost"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            <p className="global-input-error">
              {errors?.cost && errors?.cost?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="cost">Duration*</label>
            <ReactInputMask
              mask="99:99"
              maskChar="_"
              value={duration}
              onChange={handleInputChange}
              placeholder="HH:MM"
              className="duration-mask"
            />
            <img
              src="/icons/user.png"
              alt="icon"
              style={{
                position: "absolute",
                top: "28%",
                left: "15px",
                zIndex: "2",
              }}
            />
            <p className="global-input-error">
              {durationError && !duration.length > 0 && durationError}
            </p>
          </Flex>

          <div className="add-team-member-input service-category-drop">
            <ServiceCategoriesDropdown
              value={category}
              label="Select Category"
              onChange={(option) => {
                setValue("category", option);
              }}
              refetch={addSuccess}
            />
            <button
              className="fill-previous-btn"
              onClick={() => setAddCategoryPopup(true)}
              type="button"
            >
              Add New Category
            </button>
          </div>

          <div className="add-team-member-input">
            <TeamMembersDrop
              selectedMember={selectedMember}
              setSelectedMember={setSelectedMember}
              label="Select Team Members"
              handleChange={handleTeamDropChange}
            />
            <div className="selected-team-members">
              {selectedMembers && selectedMembers.length > 0
                ? selectedMembers.map((member, index) => {
                    return (
                      <Tag onClick={() => removeTag(member)} key={index}>
                        {member?.full_name}
                      </Tag>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        <div className="lable-textarea-group lable-input-group mt-20 mb-20">
          <label htmlFor="notes">Description</label>
          <div className="edit-client-icon-textarea">
            {/* <input type="number" placeholder="sent" id="id" /> */}
            <img src="/icons/profile-2user.png" alt="" />
            <textarea
              name=""
              id="notes"
              cols="135"
              rows="3"
              placeholder="Notes"
              {...register("notes", { required: false })}
            ></textarea>
          </div>
        </div>
        {/* <Flex align="center" justify="flex-end">
          <div className="business-hours-checkbox">
            <Checkbox
              label="Display On Website"
              register={register}
              name="is_active"
            />
          </div>
        </Flex> */}

        <div className="lable-input-group mt-30">
          <label htmlFor="address">Service-picture</label>
          <p className="image-size-constraints">Please upload an image with a minimum size of 100x100 pixels and a maximum size of 1500x1500 pixels</p>
          <div className="upload-content-image-section " id="img-box">
            <label htmlFor="file">
              <div>
                {selectedImage ? (
                  <div className="upload-img">
                    {/* <img src={selectedImage} alt="Preview" /> */}
                    {selectedImage && <img src={showImage} alt="Selected" />}
                  </div>
                ) : (
                  // <img src={uploadimg} alt="Preview" />
                  <>
                    <div className="upload-photo">
                      <div className="up-img">
                        {/* <img src={uploadimg} alt="" /> */}
                        <span className="drag-drop">Select Image</span>
                      </div>
                    </div>
                    {/* {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )} */}
                  </>
                )}
              </div>
            </label>
            <input
              className="upload-content-image"
              type="file"
              accept="image/*"
              name="image"
              id="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="file" className="upload-content-label">
              Choose image
            </label>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>

        <div className="intake-form-btns mt-30">
          {/* <button className="edit-client-save">Save</button> */}
          <Link to="/portal/services">
            <Button variant="outline" label={"Cancel"} />
          </Link>
          <Button
            variant="primary"
            label={"Add"}
            type="submit"
            loading={loading}
            loaderColor="#fff"
          />
          {/* <button className="edit-client-cancel">Cancel</button> */}
        </div>
        <div className="mt-30"></div>
      </form>

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(resetState())}
          />
        </Popup>
      )}

      {addCategoryPopup && (
        <Popup
          className="add-discount-popup"
          setPopUp={setAddCategoryPopup}
          popUp={addCategoryPopup}
        >
          <h3>Add Service Category</h3>
          <form
            onSubmit={addCategorySubmit((values) => {
              dispatch(addServiceCategory({ token, data: { ...values } }));
            })}
          >
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="category">Category Name</label>
              <div className="global-input-container-input">
                <input
                  type={"text"}
                  {...addCategoryRegister("name", {
                    required: "Please Enter Category Name",
                    maxLength: {
                      value: 30,
                      message: "Name should not be more then 30 characters",
                    },
                    min: { value: 0, message: "Discount cannot be negative" },
                  })}
                  id="category"
                  // min={0}
                />
              </div>
              {
                <Flex align="center" justify="space-between">
                  <p className="global-input-error mb-10">
                    {addCategoryErrors?.name &&
                      addCategoryErrors?.name?.message}
                  </p>
                  <p className="global-input-error">{addError && addError}</p>
                </Flex>
              }
            </Flex>
            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => {
                  setAddCategoryPopup(false);
                  addCategoryReset(undefined);
                  dispatch(clearState());
                }}
              />
              <Button
                label="Add"
                type="submit"
                loading={addLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default AddService;
