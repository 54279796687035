import React, { useCallback, useMemo, useRef, useState } from "react";
import "./Navbar.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getTextColorBasedOnBackground } from "../../../../../utills/identifyColor";
const Navbar = ({ data, color, setColor, is_consultation_form_enables }) => {
  const navRef = useRef();
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);
  const handleNavDrop = () => {
    if (
      navRef.current.style.maxHeight.length === 0 ||
      navRef.current.style.maxHeight === "0px"
    ) {
      setNav(true);
      navRef.current.style.maxHeight = "430px";
    } else {
      setNav(false);
      navRef.current.style.maxHeight = "0px";
    }
  };

  const isActive = useCallback(
    (path) => {
      return path === pathname;
    },
    [pathname]
  );

  const isColorShouldBeBlack = useMemo(() => {
    return (
      pathname === `/websites/${data?.business_url}/contact` ||
      pathname === `/websites/${data?.business_url}/consultation` ||
      pathname === `/websites/${data?.business_url}/book/payment` ||
      pathname === `/websites/${data?.business_url}/book` ||
      pathname === `/websites/${data?.business_url}/services`
    );
  }, [pathname]);
  if (
    pathname === `/websites/${data?.business_url}/login` ||
    pathname === `/websites/${data?.business_url}/signup`
  ) {
    return null;
  }

  return (
    <div>
      <header
        className="max-width-1440"
        style={{ border: "1px solid transparent" }}
        // style={{border:'1px solid red'}}
      >
        <nav className="navbar border-none transparent-nav max-width-1440">
          <div className="header-logo">
            <Link to={`/websites/${data?.business_url}/home`}>
              <img src={data.logo} alt="" />
            </Link>
          </div>
          <ul className="nav-links">
            <Link to={`/websites/${data?.business_url}/home`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/home`)
                    ? color
                    : isColorShouldBeBlack
                    ? "#000"
                    : "#fff",
                }}
              >
                Home
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/services`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/services`)
                    ? color
                    : isColorShouldBeBlack
                    ? "#000"
                    : "#fff",
                }}
              >
                Services
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/book`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/book`)
                    ? color
                    : isColorShouldBeBlack
                    ? "#000"
                    : "#fff",
                }}
              >
                Book
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/about`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/about`)
                    ? color
                    : isColorShouldBeBlack
                    ? "#000"
                    : "#fff",
                }}
              >
                About Us
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/contact`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/contact`)
                    ? color
                    : isColorShouldBeBlack
                    ? "#000"
                    : "#fff",
                }}
              >
                Contact Us
              </li>
            </Link>
          </ul>
          <div className="contact-login-btn">
            {is_consultation_form_enables && (
              <Link to={`/websites/${data?.business_url}/consultation`}>
                <button
                  className="get-consultation-btn"
                  style={{
                    background: color,
                    color: getTextColorBasedOnBackground(color),
                    border: "none",
                  }}
                >
                  Get Consultation
                </button>
              </Link>
            )}

            <Link
              to={`/websites/${data?.business_url}/login`}
              className="login-btn"
              style={{ color }}
            >
              Login
            </Link>
          </div>
        </nav>
      </header>
      <div className="navbar2 max-width-1440">
        <div className="navbar2-inner border-none">
          <div className="navbar2-inner-top">
            <div className="header-logo">
              <Link to={`/websites/${data?.business_url}/home`}>
                <img src={data.logo} alt="" />
              </Link>
            </div>
            <div className="navbar2-right">
              {nav && (
                <img
                  src="/navbar/close-circle.png"
                  alt=""
                  className="close-nav"
                  onClick={handleNavDrop}
                />
              )}
              {!nav && (
                <img
                  src="/navbar/hamburger.png"
                  alt=""
                  className="hamburger"
                  onClick={handleNavDrop}
                />
              )}
            </div>
          </div>
          <div className="navbar2-inner-bottom" ref={navRef}>
            <ul className="nav-links">
              <Link to={`/websites/${data?.business_url}/home`}>
                <li
                  style={{
                    border: "1px solid red",
                    color: isActive(`/websites/${data?.business_url}/home`)
                      ? color
                      : "#fff",
                  }}
                  onClick={handleNavDrop}
                >
                  Home
                </li>
              </Link>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={`/websites/${data?.business_url}/services`}
              >
                <li onClick={handleNavDrop}>Services</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={`/websites/${data?.business_url}/book`}
              >
                <li onClick={handleNavDrop}>Book</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={`/websites/${data?.business_url}/about`}
              >
                <li onClick={handleNavDrop}>About Us</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to={`/websites/${data?.business_url}/contact`}
              >
                <li onClick={handleNavDrop}>Contact Us</li>
              </NavLink>
            </ul>
            <div className="break-line"></div>
            <div className="contact-login-btn">
              <Link to={`/websites/${data?.business_url}/contact`}>
                <button
                  className="get-consultation-btn"
                  onClick={handleNavDrop}
                >
                  Contact Us
                </button>
              </Link>
              <Link
                to={`/websites/${data?.business_url}/login`}
                onClick={handleNavDrop}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
