import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  businesses: null,
};

export const BusinessSlice = createSlice({
  name: "BusinessSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBusinesses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBusinesses.fulfilled, (state, action) => {
      state.loading = false;
      state.businesses = action.payload;
    });
    builder.addCase(getAllBusinesses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getAllBusinesses = createAsyncThunk(
  "getAllBusinesses",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData?.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/business/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);
