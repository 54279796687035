import moment from "moment";

export const prettifyDate = (dateValue) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(dateValue);
  return date.toLocaleDateString("en-US", options);
};

export function convertToAMPM(time) {
  var splitTime = time.split(":");
  if (splitTime[0] === "00") {
    return `12:${splitTime[1]} AM`;
  } else {
    var hours = parseInt(splitTime[0]);
    var minutes = splitTime[1];
    var period = hours >= 12 ? "PM" : "AM";
    hours = hours > 12 ? hours - 12 : hours;
    hours = hours === "00" ? 12 : hours;
    var time12 = hours + ":" + minutes + " " + period;
    return time12;
  }
}

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function deriveDayInfo(inputDate) {
  const parsedDate = new Date(inputDate);
  if (isNaN(parsedDate)) {
    return { error: "Invalid date" };
  }
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[parsedDate.getDay()];
  const dayOfMonth = parsedDate.getDate();

  return {
    dayOfWeek: dayOfWeek,
    dayOfMonth: dayOfMonth,
  };
}

export const transformDuration = (inputDuration) => {
  const [hours, minutes] = inputDuration.split(":").map(Number);
  const formattedHours =
    hours === 0 ? null : hours < 10 ? String(hours) : hours;
  const formattedMinutes =
    minutes === 0 ? null : minutes < 10 ? `0${minutes}` : minutes;
  let result = "";
  if (formattedHours !== null) {
    result += `${formattedHours} hour${formattedHours === 1 ? "" : "s"}`;
  }

  if (formattedMinutes === undefined || formattedMinutes === null) {
  } else {
    if (result.length > 0) {
      result += " ";
    }
    result += `${formattedMinutes} minute${formattedMinutes === 1 ? "" : "s"}`;
  }

  return result.trim();
};

export function calculateEndTime12Hour(startTime, durationsArray) {
  var [start, period] = startTime.split(" ");
  var [startHour12, startMinute12] = start.split(":").map(Number);
  var startHour24 = (period === "AM" ? startHour12 : startHour12 + 12) % 24;
  var totalDurationMinutes = durationsArray.reduce(
    (totalMinutes, durationObj) => {
      var [durationHour, durationMinute] = durationObj.duration
        .split(":")
        .map(Number);
      return totalMinutes + durationHour * 60 + durationMinute;
    },
    0
  );
  var endHour = startHour24 + Math.floor(totalDurationMinutes / 60);
  var endMinute = startMinute12 + (totalDurationMinutes % 60);
  if (endMinute >= 60) {
    endHour += Math.floor(endMinute / 60);
    endMinute %= 60;
  }
  var endPeriod = endHour < 12 ? "AM" : "PM";
  endHour %= 12;
  if (endHour === 0) {
    endHour = 12;
  }
  var formattedEndTime = `${String(endHour).padStart(2, "0")}:${String(
    endMinute
  ).padStart(2, "0")} ${endPeriod}`;

  return formattedEndTime;
}

export function getFormattedDate() {
  var today = new Date();
  var year = today.getFullYear();
  var month = (today.getMonth() + 1).toString().padStart(2, "0");
  var day = today.getDate().toString().padStart(2, "0");

  var formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
export function formatDate(date) {
  var today = new Date(date);
  var year = today.getFullYear();
  var month = (today.getMonth() + 1).toString().padStart(2, "0");
  var day = today.getDate().toString().padStart(2, "0");

  var formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function getDayOfWeek() {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDate = new Date();
  const dayIndex = currentDate.getDay();
  return daysOfWeek[dayIndex];
}

export function getMonthName(dateString) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString + "-01");
  const monthIndex = date.getMonth();
  return monthNames[monthIndex];
}

const parseDuration = (duration) => {
  const [hours, minutes] = duration.split(":").map(Number);
  return moment.duration(hours, "hours").add(minutes, "minutes");
};
const createTimeSlots = (settings, duration) => {
  const { opening_time, close_time } = settings;
  const [openHours, openMinutes] = opening_time.split(":").map(Number);
  const openingInMinutes = openHours * 60 + openMinutes;

  const [closeHours, closeMinutes] = close_time.split(":").map(Number);
  const closingInMinutes = closeHours * 60 + closeMinutes;
  const [durationHours, durationMinutes] = duration.split(":").map(Number);
  const durationInMinutes = durationHours * 60 + durationMinutes;

  const timeSlots = [];
  let currentTimeInMinutes = openingInMinutes;

  while (currentTimeInMinutes + durationInMinutes <= closingInMinutes) {
    const startHours = Math.floor(currentTimeInMinutes / 60);
    const startMinutes = currentTimeInMinutes % 60;
    const endHours = Math.floor(
      (currentTimeInMinutes + durationInMinutes) / 60
    );
    const endMinutes = (currentTimeInMinutes + durationInMinutes) % 60;

    const formatTime = (hours, minutes) => {
      const period = hours >= 12 ? "PM" : "AM";
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    };

    const startTime = formatTime(startHours, startMinutes);
    const endTime = formatTime(endHours, endMinutes);

    timeSlots.push({ startTime, endTime });
    currentTimeInMinutes += 30;
  }

  return timeSlots;
};
export const generateAvailableTimeSlots = (
  businessHours,
  appointments,
  selectedServices
) => {
  const duration = calculateServiceDuration(selectedServices);
  const timeSlots = createTimeSlots(businessHours, duration);
  const pruneConflictingSlots = removeOverlappingSlots(timeSlots, appointments);
  const finalSlots = [];
  pruneConflictingSlots.forEach((slot) => {
    finalSlots.push(`${slot.startTime} - ${slot.endTime}`);
  });
  return finalSlots;
};

function calculateServiceDuration(services) {
  let totalMinutes = 0;

  services.forEach((service) => {
    const [hours, minutes] = service.duration.split(":").map(Number);
    totalMinutes += hours * 60 + minutes;
  });

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return `${totalHours}:${remainingMinutes.toString().padStart(2, "0")}`;
}
const removeOverlappingSlots = (timeSlots, appointments) => {
  const toMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  return timeSlots.filter(({ startTime, endTime }) => {
    const slotStartMinutes = toMinutes(startTime.split(" ")[0]);
    const slotEndMinutes = toMinutes(endTime.split(" ")[0]);

    return !appointments.some(({ time, services }) => {
      const appointmentStartMinutes = toMinutes(time);
      const appointmentDurationMinutes = services.reduce((sum, service) => {
        const [hours, minutes] = service.duration.split(":").map(Number);
        return sum + hours * 60 + minutes;
      }, 0);

      const appointmentEndMinutes =
        appointmentStartMinutes + appointmentDurationMinutes;
      return (
        (slotStartMinutes >= appointmentStartMinutes &&
          slotStartMinutes < appointmentEndMinutes) ||
        (slotEndMinutes > appointmentStartMinutes &&
          slotEndMinutes <= appointmentEndMinutes) ||
        (slotStartMinutes <= appointmentStartMinutes &&
          slotEndMinutes >= appointmentEndMinutes)
      );
    });
  });
};

export function convertTo24Hour(timeStr) {
  const firstTime = timeStr.split(" - ")[0];
  const [time, modifier] = firstTime.split(" ");

  let [hours, minutes] = time.split(":");
  if (modifier === "PM" && hours !== "12") {
    hours = parseInt(hours, 10) + 12;
  } else if (modifier === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
}

export const getYears = (createdAt) => {
  const currentYear = new Date().getFullYear();
  const createdYear = new Date(createdAt).getFullYear();
  const years = [];
  for (let year = createdYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};

export function getWeeklyAppointmentMessage() {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const firstDate = startOfWeek.toLocaleDateString("en-US", options);
  const lastDate = endOfWeek.toLocaleDateString("en-US", options);
  return `List of appointments from ${firstDate} to ${lastDate}`;
}
