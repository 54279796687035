import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  appoinments: null,
  success: false,
  appointment: null,
  appoinmentDrop: null,
  appoinmentDropLoading: false,
  appoinmentDropError: null,
  updateError: null,
  updateLoading: false,
  delLoading: false,
  delSuccess: false,
  delError: null,
  singleLoading: false,
  singleError: null,
  appointmentsByDate: null,
  addLoading: false,
  addSuccess: false,
  addError: null,
};
export const appoinmentSlice = createSlice({
  name: "appoinments",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.updateError = null;
      state.delError = null;
      state.delSuccess = false;
      state.addSuccess = false;
      state.addError = null;
    },
  },
  extraReducers: (builder) => {
    // get All appoinments
    builder.addCase(getAllAppoinments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAppoinments.fulfilled, (state, action) => {
      state.loading = false;
      state.appoinments = action.payload;
    });
    builder.addCase(getAllAppoinments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleDayAppointments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDayAppointments.fulfilled, (state, action) => {
      state.loading = false;
      state.appointmentsByDate = action.payload;
    });
    builder.addCase(getSingleDayAppointments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Create appoinment
    builder.addCase(addAppoinment.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addAppoinment.fulfilled, (state) => {
      state.addLoading = false;
      state.addSuccess = true;
    });
    builder.addCase(addAppoinment.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });

    // Get appoinments dropdown
    builder.addCase(appoinmentDrops.pending, (state) => {
      state.appoinmentDropLoading = true;
    });
    builder.addCase(appoinmentDrops.fulfilled, (state, action) => {
      state.appoinmentDropLoading = false;
      state.appoinmentDrop = action.payload;
    });
    builder.addCase(appoinmentDrops.rejected, (state, action) => {
      state.appoinmentDropLoading = false;
      state.appoinmentDropError = action.payload;
    });
    // Update appoinment

    builder.addCase(updateAppointnment.pending, (state) => {
      state.loading = true;
      state.updateLoading = true;
    });
    builder.addCase(updateAppointnment.fulfilled, (state) => {
      state.loading = false;
      state.updateLoading = false;
      state.success = true;
    });
    builder.addCase(updateAppointnment.rejected, (state, action) => {
      state.loading = false;
      state.updateError = action.payload;
      state.updateLoading = false;
    });

    builder.addCase(getSingleAppoinment.pending, (state) => {
      state.loading = true;
      state.singleLoading = true;
    });
    builder.addCase(getSingleAppoinment.fulfilled, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.appointment = action.payload;
    });
    builder.addCase(getSingleAppoinment.rejected, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.error = action.payload;
      state.singleError = action.payload;
    });

    builder.addCase(deleteAppointment.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteAppointment.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteAppointment.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllAppoinments = createAsyncThunk(
  "getAppoinments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide the token");
      }

      let url = `${BASE_URL}/v1/appointments/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`;
      if (apiData.query.status === "Completed") {
        url += "&status=22";
      }
      if (apiData.query.status === "Upcoming") {
        url += "&status=23";
      }
      if (apiData.query.status === "No Show") {
        url += "&status=24";
      }
      if (apiData.query.status === "Cancelled") {
        url += "&status=25";
      }

      if (apiData.query?.sort === "Weekly") {
        url += "&is_weekly=1";
      }
      if (apiData.query?.sort === "Monthly") {
        url += "&is_monthly=1";
      }
      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occured please try again");
    }
  }
);
export const addAppoinment = createAsyncThunk(
  "addAppoinment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide the token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/appointments/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      console.log(data);

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occured please try again");
    }
  }
);

export const appoinmentDrops = createAsyncThunk(
  "appoinmentDrops",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/6`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getSingleAppoinment = createAsyncThunk(
  "getSingleAppoinment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("unauthorized - please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      console.log(apiData.token);

      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const updateAppointnment = createAsyncThunk(
  "updateAppoinment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/appointments/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "deleteAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/appointments/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleDayAppointments = createAsyncThunk(
  "getSingleDayAppointments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.date) {
        return rejectWithValue("Please provide date");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/team?date=${apiData.date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleTeamMemberAppointments = createAsyncThunk(
  "getSingleDayAppointments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide team member id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/user/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      const getDayOfWeek = (dateString) => {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const date = new Date(dateString);
        return daysOfWeek[date.getUTCDay()];
      };

      // Initialize the weekly calendar structure
      const weeklyCalendar = [
        { day: "Sunday", appointments: [] },
        { day: "Monday", appointments: [] },
        { day: "Tuesday", appointments: [] },
        { day: "Wednesday", appointments: [] },
        { day: "Thursday", appointments: [] },
        { day: "Friday", appointments: [] },
        { day: "Saturday", appointments: [] },
      ];

      // Iterate through the appointments and place them in the correct day
      data.result.forEach((appointment) => {
        const dayOfWeek = getDayOfWeek(appointment.date);
        const dayColumn = weeklyCalendar.find((day) => day.day === dayOfWeek);
        if (dayColumn) {
          dayColumn.appointments.push(appointment);
        }
      });
      return weeklyCalendar;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const { clearState } = appoinmentSlice.actions;
