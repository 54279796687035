import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  deleteDiscount,
  getAllDisconts,
  clearState,
} from "../../../features/ServiceProviderPortal/discountsSlice";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { handleUndefined } from "../../../utills/handleUndefined";

const Discounts = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    loading,
    discounts,
    error,
    delLoading,
    delError,
    delSuccess,
  } = useSelector((state) => state.discounts);

  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(getAllDisconts({ token }));
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
    }
  }, [token, dispatch, delSuccess]);

  const handleDiscountDelete = () => {
    dispatch(deleteDiscount({ token, id: delId }));
  };
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="edit-client-heading">Discounts</h1>
        <Link to="/portal/add-discount">
          <Button label="Add Discount" />
        </Link>
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadData>Service ID</TableHeadData>
                <TableHeadData>Service Name</TableHeadData>
                <TableHeadData>Cost</TableHeadData>
                <TableHeadData>Discount</TableHeadData>
                <TableHeadData>Actions</TableHeadData>
              </TableRow>
            </TableHead>
            {conditionalRender(
              discounts?.items?.length > 0,
              <TableBody>
                {discounts?.items?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableBodyData>
                        <TableText>{item?.id}</TableText>
                      </TableBodyData>
                      <TableBodyData>
                        <TableText>
                          {handleUndefined(item?.service_id?.name)}
                        </TableText>
                      </TableBodyData>
                      <TableBodyData>
                        <TableText>${item?.cost}</TableText>
                      </TableBodyData>
                      <TableBodyData>
                        <TableText>{item?.discount}%</TableText>
                      </TableBodyData>
                      <TableBodyData>
                        <Flex align="center" justify="center" gap={5}>
                          <Link to={`/portal/edit-discount/${item.id}`}>
                            <img src="/icons/edit.png" alt="edit" />
                          </Link>
                          <img
                            src="/icons/delete.png"
                            alt="delete"
                            onClick={() => {
                              setDelPopup(true);
                              setDelId(item.id);
                            }}
                          />
                          <Button label="Add Discount" variant="outline" />
                        </Flex>
                      </TableBodyData>
                    </TableRow>
                  );
                })}
              </TableBody>,
              <GlobalEmptyDataHandler label="No discounts to show" />
            )}
          </Table>,
          <h1>{error}</h1>
        )
      )}

      {delPopup && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDiscountDelete}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Discounts;
