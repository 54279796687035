import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  providers: null,
  
};

export const serviceProvidersSlice = createSlice({
  name: "serviceProvidersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllServiceProviders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllServiceProviders.fulfilled, (state, action) => {
      state.loading = false;
      state.providers = action.payload;
    });
    builder.addCase(getAllServiceProviders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getServiceProvidersForDropDown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServiceProvidersForDropDown.fulfilled, (state, action) => {
      state.loading = false;
      state.providers = action.payload;
    });
    builder.addCase(getServiceProvidersForDropDown.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getAllServiceProviders = createAsyncThunk(
  "getAllServiceProviders",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData?.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/users/manage?limit=10&page=1`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);


export const getServiceProvidersForDropDown = createAsyncThunk(
  "getServiceProvidersForDropDown",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/business/dropdown`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
