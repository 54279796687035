import React, { useCallback, useRef, useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { AlignLeft } from "react-feather";

// ... (imports)

const Navbar = ({ data, color, is_consultation_form_enables }) => {
  const navRef = useRef();
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);

  const handleNavDrop = () => {
    if (
      navRef.current.style.maxHeight.length === 0 ||
      navRef.current.style.maxHeight === "0px"
    ) {
      setNav(true);
      navRef.current.style.maxHeight = "430px";
    } else {
      setNav(false);
      navRef.current.style.maxHeight = "0px";
    }
  };

  const isActive = useCallback(
    (path) => {
      if (pathname === path) {
        return true;
      } else {
        return false;
      }
    },
    [pathname]
  );

  if (
    pathname === `/websites/${data?.business_url}/login` ||
    pathname === `/websites/${data?.business_url}/signup`
  ) {
    return null;
  }
  return (
    <div>
      <header
        className="max-width-1440"
        style={{ border: "1px solid transparent" }}
      >
        <nav className="navbar border-none">
          <div className="header-logo">
            <Link to={`/websites/${data?.business_url}/home`}>
              <img src={data?.logo} alt="" style={{ height: "50px" }} />
            </Link>
          </div>
          <ul className="nav-links">
            <Link to={`/websites/${data?.business_url}/home`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/home`)
                    ? color
                    : "",
                }}
              >
                Home
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/services`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/services`)
                    ? color
                    : "",
                }}
              >
                Services
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/book`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/book`)
                    ? color
                    : "",
                }}
              >
                Book
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/about`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/about`)
                    ? color
                    : "",
                }}
              >
                About Us
              </li>
            </Link>
            <Link to={`/websites/${data?.business_url}/contact`}>
              <li
                style={{
                  color: isActive(`/websites/${data?.business_url}/contact`)
                    ? color
                    : "",
                }}
              >
                Contact Us
              </li>
            </Link>
          </ul>
          <div className="contact-login-btn">
            {is_consultation_form_enables && (
              <Link to={`/websites/${data?.business_url}/consultation`}>
                <button
                  className="get-consultation-btn"
                  style={{ borderColor: color, color: color }}
                >
                  Get Consultation
                </button>
              </Link>
            )}

            <Link
              to={`/websites/${data?.business_url}/login`}
              className="login-btn"
              style={{ color: color }}
            >
              Login
            </Link>
          </div>
        </nav>
      </header>
      <div className="navbar2 max-width-1440">
        <div className="navbar2-inner border-none">
          <div className="navbar2-inner-top">
            <div className="header-logo">
              <Link to={`/websites/${data?.business_url}/home`}>
                <img src={data.logo} alt="" />
              </Link>
            </div>
            <div className="navbar2-right">
              {nav && (
                <img
                  src="/navbar/close-circle.png"
                  alt=""
                  className="close-nav"
                  onClick={handleNavDrop}
                />
              )}
              {!nav && (
                <AlignLeft color={color} size={30} onClick={handleNavDrop} />
              )}
            </div>
          </div>
          <div className="navbar2-inner-bottom" ref={navRef}>
            <ul className="nav-links">
              <Link to={`/websites/${data?.business_url}/home`}>
                <li
                  style={{
                    color: isActive(`/websites/${data?.business_url}/home`)
                      ? color
                      : "",
                  }}
                  onClick={handleNavDrop}
                >
                  Home
                </li>
              </Link>
              <Link to={`/websites/${data?.business_url}/services`}>
                <li
                  style={{
                    color: isActive(`/websites/${data?.business_url}/services`)
                      ? color
                      : "",
                  }}
                  onClick={handleNavDrop}
                >
                  Services
                </li>
              </Link>
              <Link to={`/websites/${data?.business_url}/book`}>
                <li
                  style={{
                    color: isActive(`/websites/${data?.business_url}/book`)
                      ? color
                      : "",
                  }}
                  onClick={handleNavDrop}
                >
                  Book
                </li>
              </Link>
              <Link to={`/websites/${data?.business_url}/about`}>
                <li
                  style={{
                    color: isActive(`/websites/${data?.business_url}/about`)
                      ? color
                      : "",
                  }}
                  onClick={handleNavDrop}
                >
                  About Us
                </li>
              </Link>
              <Link to={`/websites/${data?.business_url}/contact`}>
                <li
                  style={{
                    color: isActive(`/websites/${data?.business_url}/contact`)
                      ? color
                      : "",
                  }}
                  onClick={handleNavDrop}
                >
                  Contact Us
                </li>
              </Link>
            </ul>
            <div className="break-line"></div>
            <div className="contact-login-btn">
              <Link to={`/websites/${data?.business_url}/consultations`}>
                <button
                  className="get-consultation-btn"
                  style={{ borderColor: color, color: color }}
                  onClick={handleNavDrop}
                >
                  Get Consultation
                </button>
              </Link>
              <Link
                to={`/websites/${data?.business_url}/login`}
                style={{ color: color }}
                onClick={handleNavDrop}
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
