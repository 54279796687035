import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
import { saveSignUpData } from "./authSlice";
export const login = createAsyncThunk(
  "auth/login",
  async (userCredentials, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/signin`,
        userCredentials
      );
      return {
        userData: data.result,
        token: data.accessToken,
      };
    } catch (error) {
      if (error?.response?.data?.message) {
        if (typeof error?.response?.data?.message === "object") {
          return rejectWithValue("Please fillout the form correctly");
        } else {
          return rejectWithValue(error?.response?.data?.message);
        }
      } else {
        return rejectWithValue("Some Error Occured During Login");
      }
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (userData, { rejectWithValue,dispatch }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/signup`,
        userData
      );
      if (data.statusCode === 409) {
        return rejectWithValue(data.message);
      }
      dispatch(saveSignUpData(data.result))
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error?.response?.data?.statusCode === 409) {
          return rejectWithValue(error?.response?.data?.message);
        } else if (typeof error?.response?.data?.message === "object") {
          return rejectWithValue("Please fillout the form correctly");
        } else {
          return rejectWithValue(error?.response?.data?.message);
        }
      } else {
        return rejectWithValue("Some Error Occured During Login");
      }
    }
  }
);
export const clientSignup = createAsyncThunk(
  "auth/clientSignup",
  async (userData, { rejectWithValue,dispatch }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/client-signup`,
        userData
      );
      if (data.statusCode === 409) {
        return rejectWithValue(data.message);
      }
      dispatch(saveSignUpData(data.result))
    } catch (error) {
      if (error?.response?.data?.message) {
        if (error?.response?.data?.statusCode === 409) {
          return rejectWithValue(error?.response?.data?.message);
        } else if (typeof error?.response?.data?.message === "object") {
          return rejectWithValue("Please fillout the form correctly");
        } else {
          return rejectWithValue(error?.response?.data?.message);
        }
      } else {
        return rejectWithValue("Some Error Occured During Login");
      }
    }
  }
);

