import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../config";
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
  success: false,
  invoices: null,
  invoice: null,
  addInvoiveLoading: false,
  addInvoiceError: null,
  updateInvoiveLoading: false,
  updateInvoiceError: null,
  dropDown: null,
  delLoading: false,
  delSuccess: false,
  delError: null,
  getSingleLoading: false,
  getSingleError: null,
  downloadLoading: false,
  downloadError: null,
  downloadSuccess: false,
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addInvoiceError = null;
      state.updateInvoiceError = null;
      state.delError = null;
      state.delSuccess = null;
      state.downloadError = null;
      state.downloadSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // Get All Invouices cases
    builder.addCase(getAllInvoices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInvoices.fulfilled, (state, action) => {
      state.loading = false;
      state.invoices = action.payload;
    });
    builder.addCase(getAllInvoices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add Invoice
    builder.addCase(createInvoice.pending, (state) => {
      state.addInvoiveLoading = true;
    });
    builder.addCase(createInvoice.fulfilled, (state) => {
      state.addInvoiveLoading = false;
      state.success = true;
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.addInvoiveLoading = false;
      state.addInvoiceError = action.payload;
    });

    // Get Single Invoice

    builder.addCase(getSingleInvoice.pending, (state) => {
      state.loading = true;
      state.getSingleLoading = true;
    });
    builder.addCase(getSingleInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleLoading = false;
      state.invoice = action.payload;
    });
    builder.addCase(getSingleInvoice.rejected, (state, action) => {
      state.loading = false;
      state.getSingleLoading = false;
      state.error = action.payload;
      state.getSingleError = action.payload;
    });

    // Update Invoice

    builder.addCase(updateInvoice.pending, (state) => {
      state.updateInvoiveLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.updateInvoiveLoading = false;
      state.success = true;
    });
    builder.addCase(updateInvoice.rejected, (state, action) => {
      state.updateInvoiveLoading = false;
      state.updateInvoiceError = action.payload;
    });
    // Dropdown cases

    builder.addCase(getInvoiceDrop.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvoiceDrop.fulfilled, (state, action) => {
      state.loading = false;
      state.dropDown = action.payload;
    });
    builder.addCase(getInvoiceDrop.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteInvoice.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteInvoice.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteInvoice.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
    builder.addCase(downloadInvoice.pending, (state) => {
      state.downloadLoading = true;
    });
    builder.addCase(downloadInvoice.fulfilled, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = true;
    });
    builder.addCase(downloadInvoice.rejected, (state, action) => {
      state.downloadLoading = false;
      state.downloadError = action.payload;
    });
  },
});

export const getAllInvoices = createAsyncThunk(
  "getAllInvoices",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/invoices/manage?page=1&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const createInvoice = createAsyncThunk(
  "createInvoice",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/invoices/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const updateInvoice = createAsyncThunk(
  "updateInvoice",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/invoices/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleInvoice = createAsyncThunk(
  "getSingleInvoice",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/invoices/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const downloadInvoice = createAsyncThunk(
  "downloadInvoice",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/invoices/download/pdf/${apiData.id}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `invoice-${apiData.id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const deleteInvoice = createAsyncThunk(
  "deleteInvoice",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/invoices/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getInvoiceDrop = createAsyncThunk(
  "getInvoiceDrop",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/9`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = invoicesSlice.actions;
