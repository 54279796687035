import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import GlobalDropDown from "../../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import Button from "../../../../components/styled/Button/Button";
import Flex from "../../../../components/styled/Flex/Flex";
import { signup } from "../../../../features/auth/authActions";
import qs from "query-string";
import { businessType } from "../dropDowns";
export const locationDropData = [
  {
    name: "Virtual",
    id: 1,
  },
  {
    name: "On Site",
    id: 2,
  },
];
const Step2 = ({ setStep, formData, setFormData }) => {
  const signupState = useSelector((state) => state.signUpData);
  const { is_refferal, accesstoken } = qs.parse(window.location.search);

  const { loading, error } = useSelector((state) => state.authTemp);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      business_name: signupState?.business_name,
      region: signupState?.region,
    },
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    if (!selectedBusinessType) {
      setSelectedBusinessTypeError("Please Select Business Type");
    } else {
      data.business_type = selectedBusinessType.id;
      const apiData = { ...data, ...signupState };
      if (is_refferal) {
        apiData.is_refferal = true;
        apiData.accesstoken = accesstoken;
      }
      dispatch(signup(apiData));
    }
  };
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const [selectedBusinessTypeError, setSelectedBusinessTypeError] =
    useState(null);
  return (
    <div>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="input-group" style={{ marginBottom: "25px" }}>
            <label htmlFor="business_name"> Business Name *</label>
            <div className="input">
              <input
                type="text"
                id="business_name"
                {...register("business_name", {
                  required: "Please Enter Business Name",
                  minLength: "Not Should be less then five characters",
                  maxLength: "Not Should be more then twenty characters",
                })}
              />
              <img src="/icons/user.png" alt="" />
              <p className="error">
                {errors?.business_name && errors?.business_name?.message}
              </p>
            </div>
          </div>
          <div className="input-group business-type">
            <GlobalDropDown
              stateHandler={selectedBusinessType}
              setStateHandler={setSelectedBusinessType}
              options={businessType}
              defaultId={signupState?.business_type}
              label={"Business Type*"}
              background="#fff"
            />
            <p className="global-input-error">
              {selectedBusinessTypeError &&
                !selectedBusinessType &&
                selectedBusinessTypeError}
            </p>
          </div>
          <Flex align="center" justify="flex-end">
            <p className="global-input-error">{error && error}</p>
          </Flex>
        </div>

        <div className="submit-btn-outer step2">
          <Button
            label="Signup"
            type="submit"
            variant="rounded-outline"
            loading={loading}
            loaderColor="#3e0ffe"
          />
        </div>
      </form>
    </div>
  );
};

export default Step2;
