import React, { useState } from "react";
import "./FeaturedBlogs.css";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import Flex from "../../../../../components/styled/Flex/Flex";
import Button from "../../../../../components/styled/Button/Button";

const FeaturedBlogs = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedBinaryImage, setSelectedBinaryImage] = useState(null);
  const [selectedBinaryImage1, setSelectedBinaryImage1] = useState(null);
  const [selectedBinaryImage2, setSelectedBinaryImage2] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();

        image.onload = () => {
          // Get the width and height of the image
          const { width, height } = image;
          // console.log(image)
          // console.log(width,height)
          // Define the minimum and maximum allowed sizes
          const minWidth = 20;
          const minHeight = 20;
          const maxWidth = 6600;
          const maxHeight = 6600;

          // Perform size validation here
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            // Image size is valid, set the selected image and show success message
            if (event.target.name === "image") {
              // const imageUrl = window.URL.createObjectURL(file);
              setSelectedBinaryImage(file);
              setSelectedImage(reader.result);
            }
            if (event.target.name === "image1") {
              // const imageUrl = window.URL.createObjectURL(file);
              setSelectedImage1(reader.result);
              setSelectedBinaryImage1(file);
            }
            if (event.target.name === "image2") {
              // const imageUrl = window.URL.createObjectURL(file);
              setSelectedImage2(reader.result);
              setSelectedBinaryImage2(file);
            }

            setErrorMessage("");
          } else {
            // Image size is not valid, show an error message
            setErrorMessage(
              "Image size is not within the allowed limits(100-701 x 100-445). Please choose an image with appropriate dimensions."
            );
            setSelectedImage(null);
            setSelectedImage1(null);
            setSelectedImage2(null);
            setSelectedBinaryImage(null);
            setSelectedBinaryImage1(null);
            setSelectedBinaryImage2(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };
  return (
    <div className="outer">
      <Link to={"/admin-portal/blogs"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head mt-20">Featured Blog</h1>
        <Button label="Add Blog" />
      </Flex>
      <form className="featured-blogs">
        <h1 className="team-member-details-head mt-20">First Blog</h1>
        <div className="featured-blogs-inner mt-20">
          <div className="add-team-member-inputs" style={{ width: "100%" }}>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogTitle">Blog Title</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Title"}
                  id="blogTitle"
                />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogKeywords">Blog Keywords</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Keywords"}
                  id="blogKeywords"
                />
              </div>
            </Flex>
          </div>
          <div className="mt-20">
            <div className="lable-textarea-group lable-input-group featured-blogs-textarea">
              <label htmlFor="notes">Blog Features content </label>
              <div className="edit-client-icon-textarea">
                {/* <input type="number" placeholder="sent" id="id" /> */}
                <textarea
                  name=""
                  id="about-company"
                  cols="135"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="lable-input-group mt-20">
            <label htmlFor="address">Add Image</label>
            <div
              className="upload-content-image-section width-343 "
              style={{ width: "343px" }}
              id="img-box"
            >
              <label htmlFor="file">
                <div>
                  {selectedImage ? (
                    <div className="upload-img" style={{ width: "182px" }}>
                      {/* <img src={selectedImage} alt="Preview" /> */}
                      {selectedImage && (
                        <img src={selectedImage} alt="Selected" />
                      )}
                    </div>
                  ) : (
                    // <img src={uploadimg} alt="Preview" />
                    <>
                      <div
                        className="upload-photo"
                        style={{ width: "182px", borderRadius: "0px" }}
                      >
                        <div className="up-img">
                          {/* <img src={uploadimg} alt="" /> */}
                          <span className="drag-drop">Select Image</span>
                        </div>
                      </div>
                      <div className="error-message">{errorMessage}</div>
                    </>
                  )}
                </div>
              </label>
              <input
                className="upload-content-image"
                type="file"
                accept="image/*"
                name="image"
                id="file"
                onChange={handleImageUpload}
              />
            </div>
          </div>
          <Button label="Save" />
        </div>
      </form>
      <form className="featured-blogs">
        <h1 className="team-member-details-head mt-20">First Blog</h1>
        <div className="featured-blogs-inner mt-20">
          <div className="add-team-member-inputs" style={{ width: "100%" }}>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogTitle">Blog Title</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Title"}
                  id="blogTitle"
                />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogKeywords">Blog Keywords</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Keywords"}
                  id="blogKeywords"
                />
              </div>
            </Flex>
          </div>
          <div className="mt-20">
            <div className="lable-textarea-group lable-input-group featured-blogs-textarea">
              <label htmlFor="notes">Blog Features content </label>
              <div className="edit-client-icon-textarea">
                {/* <input type="number" placeholder="sent" id="id" /> */}
                <textarea
                  name=""
                  id="about-company"
                  cols="135"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="lable-input-group mt-20">
            <label htmlFor="address">Add Image</label>
            <div
              className="upload-content-image-section width-343 "
              style={{ width: "343px" }}
              id="img-box"
            >
              <label htmlFor="file1">
                <div>
                  {selectedImage1 ? (
                    <div className="upload-img" style={{ width: "182px" }}>
                      {/* <img src={selectedImage} alt="Preview" /> */}
                      {selectedImage1 && (
                        <img src={selectedImage1} alt="Selected" />
                      )}
                    </div>
                  ) : (
                    // <img src={uploadimg} alt="Preview" />
                    <>
                      <div
                        className="upload-photo"
                        style={{ width: "182px", borderRadius: "0px" }}
                      >
                        <div className="up-img">
                          {/* <img src={uploadimg} alt="" /> */}
                          <span className="drag-drop">Select Image</span>
                        </div>
                      </div>
                      <div className="error-message">{errorMessage}</div>
                    </>
                  )}
                </div>
              </label>
              <input
                className="upload-content-image"
                type="file"
                accept="image/*"
                name="image1"
                id="file1"
                onChange={handleImageUpload}
              />
            </div>
          </div>
          <Button label="Save" />
        </div>
      </form>
      <form className="featured-blogs">
        <h1 className="team-member-details-head mt-20">First Blog</h1>
        <div className="featured-blogs-inner mt-20">
          <div className="add-team-member-inputs" style={{ width: "100%" }}>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogTitle">Blog Title</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Title"}
                  id="blogTitle"
                />
              </div>
            </Flex>
            <Flex
              className={`global-input-container add-team-member-input featured-blogs-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="blogKeywords">Blog Keywords</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Blog Keywords"}
                  id="blogKeywords"
                />
              </div>
            </Flex>
          </div>
          <div className="mt-20">
            <div className="lable-textarea-group lable-input-group featured-blogs-textarea">
              <label htmlFor="notes">Blog Features content </label>
              <div className="edit-client-icon-textarea">
                {/* <input type="number" placeholder="sent" id="id" /> */}
                <textarea
                  name=""
                  id="about-company"
                  cols="135"
                  rows="4"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="lable-input-group mt-20">
            <label htmlFor="address">Add Image</label>
            <div
              className="upload-content-image-section width-343 "
              style={{ width: "343px" }}
              id="img-box"
            >
              <label htmlFor="file2">
                <div>
                  {selectedImage2 ? (
                    <div className="upload-img" style={{ width: "182px" }}>
                      {/* <img src={selectedImage} alt="Preview" /> */}
                      {selectedImage2 && (
                        <img src={selectedImage2} alt="Selected" />
                      )}
                    </div>
                  ) : (
                    // <img src={uploadimg} alt="Preview" />
                    <>
                      <div
                        className="upload-photo"
                        style={{ width: "182px", borderRadius: "0px" }}
                      >
                        <div className="up-img">
                          {/* <img src={uploadimg} alt="" /> */}
                          <span className="drag-drop">Select Image</span>
                        </div>
                      </div>
                      <div className="error-message">{errorMessage}</div>
                    </>
                  )}
                </div>
              </label>
              <input
                className="upload-content-image"
                type="file"
                accept="image/*"
                name="image2"
                id="file2"
                onChange={handleImageUpload}
              />
            </div>
          </div>
          <Button label="Save" />
        </div>
      </form>
      <div className="mt-20">
        <Link>
          <Button label="Cancel" variant="outline" />
        </Link>
      </div>
      <div className="mt-30"></div>
    </div>
  );
};

export default FeaturedBlogs;
