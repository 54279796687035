import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  appointments: null,
  success: false,
  appointment: null,
  appointmentDrop: null,
  appointmentDropLoading: false,
  appointmentDropError: null,
  updateError: null,
  updateLoading: false,
  delLoading: false,
  delSuccess: false,
  delError: null,
  singleLoading: false,
  singleError: null,
  appointmentsByDate: null,
  addLoading: false,
  addSuccess: false,
  addError: null,
};
export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.updateError = null;
      state.delError = null;
      state.delSuccess = false;
      state.addSuccess = false;
      state.addError = null;
    },
  },
  extraReducers: (builder) => {
    // get All appointments
    builder.addCase(getAllAppointments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAppointments.fulfilled, (state, action) => {
      state.loading = false;
      state.appointments = action.payload;
    });
    builder.addCase(getAllAppointments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleDayAppointments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDayAppointments.fulfilled, (state, action) => {
      state.loading = false;
      state.appointmentsByDate = action.payload;
    });
    builder.addCase(getSingleDayAppointments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Create appointment
    builder.addCase(addAppointment.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addAppointment.fulfilled, (state) => {
      state.addLoading = false;
      state.addSuccess = true;
    });
    builder.addCase(addAppointment.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });

    // Get appointments dropdown
    builder.addCase(appointmentDrops.pending, (state) => {
      state.appointmentDropLoading = true;
    });
    builder.addCase(appointmentDrops.fulfilled, (state, action) => {
      state.appointmentDropLoading = false;
      state.appointmentDrop = action.payload;
    });
    builder.addCase(appointmentDrops.rejected, (state, action) => {
      state.appointmentDropLoading = false;
      state.appointmentDropError = action.payload;
    });
    // Update appointment

    builder.addCase(updateAppointment.pending, (state) => {
      state.loading = true;
      state.updateLoading = true;
    });
    builder.addCase(updateAppointment.fulfilled, (state) => {
      state.loading = false;
      state.updateLoading = false;
      state.success = true;
    });
    builder.addCase(updateAppointment.rejected, (state, action) => {
      state.loading = false;
      state.updateError = action.payload;
      state.updateLoading = false;
    });

    builder.addCase(getSingleAppointment.pending, (state) => {
      state.loading = true;
      state.singleLoading = true;
    });
    builder.addCase(getSingleAppointment.fulfilled, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.appointment = action.payload;
    });
    builder.addCase(getSingleAppointment.rejected, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.error = action.payload;
      state.singleError = action.payload;
    });

    builder.addCase(deleteAppointment.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteAppointment.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteAppointment.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllAppointments = createAsyncThunk(
  "getAppointments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide the token");
      }

      let url = `${BASE_URL}/v1/appointments/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`;

      if (apiData.query.status === "Completed") {
        url += "&status=22";
      }
      if (apiData.query.status === "Upcoming") {
        url += "&status=23";
      }
      if (apiData.query.status === "No Show") {
        url += "&status=24";
      }
      if (apiData.query.status === "Cancelled") {
        url += "&status=25";
      }

      if (apiData.query?.sort === "Weekly") {
        url += "&is_weekly=1";
      }
      if (apiData.query?.sort === "Monthly") {
        url += "&is_monthly=1";
      }
      const { data } = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occured please try again");
    }
  }
);
export const addAppointment = createAsyncThunk(
  "addAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - please provide the token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/appointments/create-client-appointment`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      console.log(data);

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }
      return rejectWithValue("Some Error occurred please try again");
    }
  }
);

export const appointmentDrops = createAsyncThunk(
  "appointmentDrops",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/lookup/dropdown/6`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occurred Please Try Again Later");
      }
    }
  }
);

export const getSingleAppointment = createAsyncThunk(
  "getSingleAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("unauthorized - please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      console.log(apiData.token);

      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occurred Please Try Again Later");
      }
    }
  }
);
export const updateAppointment = createAsyncThunk(
  "updateAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/appointments/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occurred Please Try Again Later");
      }
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "deleteAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/appointments/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleDayAppointments = createAsyncThunk(
  "getSingleDayAppointments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.date) {
        return rejectWithValue("Please provide date");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/team?date=${apiData.date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleTeamMemberAppointments = createAsyncThunk(
  "getSingleDayAppointments",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.date) {
        return rejectWithValue("Please provide date");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide team member id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointments/user/${apiData.id}?date=${apiData.date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const { clearState } = appointmentSlice.actions;
