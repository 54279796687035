import React from "react";
import "./Blogs.css";
import Flex from "../../../../components/styled/Flex/Flex";
import { Link } from "react-router-dom";
import Button from "../../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../../components/styled/Table/Table";

const Blogs = () => {
  return (
    <div className="outer">
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head">Blogs</h1>
        <Flex align="center" gap={15}>
          <Link to="/admin-portal/featured-blogs">
            <Button variant="outline" label="View Featured Blogs" />
          </Link>
          <Link to="/admin-portal/add-blog">
            <Button label="Add Blog" />
          </Link>
        </Flex>
      </Flex>
      <div className="mt-30">
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadData>Blog Id</TableHeadData>
              <TableHeadData>Blog Title</TableHeadData>
              <TableHeadData>Keywords</TableHeadData>
              <TableHeadData>Actions</TableHeadData>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableBodyData>
                <Link to="/admin-portal/blog-details">
                  <TableText variant="link">12342211111</TableText>
                </Link>
              </TableBodyData>
              <TableBodyData>
                <TableText>XYZ</TableText>
              </TableBodyData>
              <TableBodyData>
                <div className="keywords-div">
                    <Keyword label='Technology' />
                    <Keyword label='Technology' />
                </div>
              </TableBodyData>

              <TableBodyData>
                <Flex align="center" justify="center" gap={5}>
                  <Link to="/admin-portal/edit-blog">
                    <img src="/icons/edit.png" alt="edit" />
                  </Link>
                  <Link>
                    <img src="/icons/delete.png" alt="delete" />
                  </Link>
                </Flex>
              </TableBodyData>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

function Keyword({ label }) {
  return <span className="keyword">{label}</span>;
}

export default Blogs;
