export const timeStamps = [
    {
        value:"8",
        label:"8:00 AM"
    },
    {
        value:"10",
        label:"10:00 AM"
    },
    {
        value:"12",
        label:"12:00 PM"
    },
    {
        value:"14",
        label:"2:00 PM"
    },
    {
        value:"16",
        label:"4:00 PM"
    },
    {
        value:"18",
        label:"6:00 PM"
    },
    {
        value:"20",
        label:"8:00 PM"
    },
    {
        value:"22",
        label:"10:00 PM"
    },
    {
        value:"24",
        label:"12:00 AM"
    },
    {
        value:"2",
        label:"2:00 AM"
    },
    {
        value:"4",
        label:"4:00 AM"
    },
    {
        value:"6",
        label:"6:00 AM"
    },
]