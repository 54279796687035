import React, { useEffect, useState } from "react";
import "./SendVoucher.css";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  addVoucher,
  resetState,
} from "../../../features/ServiceProviderPortal/vouchersSlice";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import { getCurrentDate, prettifyDate } from "../../../utills/prettifyDate";
import Flex from "../../../components/styled/Flex/Flex";
import { X } from "react-feather";
import { toast } from "react-toastify";
import { concatNames } from "../../../utills/concatNames";
import ReactDatePicker from "react-datepicker";
const SendVoucher = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({ mode: "onBlur" });

  const { loading, error, success } = useSelector((state) => state.vouchers);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddVoucher = (values) => {
    values.status = 26;
    if (!selectedClient) {
      setClientDropError("Please Select Client");
    } else {
      values.client = selectedClient.id;
      dispatch(addVoucher({ token, data: { ...values } }));
    }
  };

  const [clientDropError, setClientDropError] = useState(null);
  const [selectedClient, setSelectedCLient] = useState(null);
  const [previewVoucher, setPreviewVoucher] = useState(false);
  useEffect(() => {
    if (success) {
      toast.success("Voucher sent Successfully", {
        position: "top-right",
      });
      dispatch(resetState());
      navigate("/portal/vouchers");
    }
  }, [success, navigate, dispatch]);
  useEffect(() => {
    if (selectedClient) {
      setClientDropError(null);
    }
  }, [selectedClient]);

  const valid_date_from = watch("valid_date_from");
  const total_amount = watch("total_amount");
  const valid_date_to = watch("valid_date_to")

  return (
    <div className="outer">
      <Link to={"/portal/vouchers"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Send Voucher</h1>
      <form onSubmit={handleSubmit(handleAddVoucher)}>
        <div className="send-voucher-inputs">
          <div className="send-voucher-input">
            <ClientsDropDown
              label="Select Client*"
              selectedClient={selectedClient}
              setSelectedCLient={setSelectedCLient}
            />
            {clientDropError && <p className="error">{clientDropError}</p>}
          </div>
          <Flex
            className={`global-input-container send-voucher-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="total_amount">Amount*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"number"}
                placeholder={"Amount"}
                {...register("total_amount", {
                  required: "Please Enter Ammount",
                  maxLength: {
                    value: 5,
                    message: "Amount not should be more then 5 characters",
                  },
                  min:{value: 0, message:"Amount cannot be negative"}
                })}
                id="total_amount"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            <p className="global-input-error mb-10">
              {errors?.total_amount && errors?.total_amount?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container send-voucher-input react-datepicker-wrapper`}
            direction="column"
            gap={10}
          >
            <label htmlFor="valid_date_from">Valid From*</label>
            <div className="react-datepicker-wrapper">
              <div className="global-input-container-input global-input-container-input-with-image">
                {/* <input
                  className="react-datepicker-ignore-onclickoutside"
                  type={"date"}
                  placeholder={"Valid From"}
                  {...register("valid_date_from", {
                    required: "Please select valid from date",
                  })}
                  id="valid_date_from"
                  min={getCurrentDate()}
                /> */}
                <Controller
                  control={control}
                  name="valid_date_from"
                  rules={{ required: "Please select valid from date" }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      minDate={getCurrentDate()}
                      placeholderText="Please select date"
                    />
                  )}
                />
                <img src="/icons/appointments.png" alt="icon" />
              </div>
            </div>
            <p className="global-input-error">
              {errors?.valid_date_from && errors?.valid_date_from?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container send-voucher-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="valid_date_to">Valid To*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              {/* <input
                type={"date"}
                placeholder={"Valid To"}
                {...register("valid_date_to", {
                  required: "Please select valid from date",
                  validate: (value) =>
                    value > valid_date_from ||
                    "Valid to cannot be less then or equal to valid from",
                })}
                id="valid_date_to"
                min={getCurrentDate()}
              /> */}
              <Controller
                control={control}
                name="valid_date_to"
                rules={{
                  required: "Please select valid to date",
                  validate: (value) =>
                    value > valid_date_from ||
                    "Valid to cannot be less then or equal to valid from",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <ReactDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    minDate={getCurrentDate()}
                    placeholderText="Please select date"
                  />
                )}
              />
              <img src="/icons/appointments.png" alt="icon" />
            </div>
            <p className="global-input-error">
              {errors?.valid_date_to && errors?.valid_date_to?.message}
            </p>
          </Flex>
        </div>

        <div className="lable-textarea-group lable-input-group">
          <label htmlFor="notes">Message</label>
          <div
            className="edit-client-icon-textarea"
            style={{ position: "relative" }}
          >
            
            <textarea
              name=""
              id="notes"
              cols="135"
              rows="3"
              {...register("notes", { required: "Please Add Notes" })}
            ></textarea>
            {errors?.notes && <p className="error">{errors?.notes?.message}</p>}
          </div>
          <span className="voucher-code" style={{ marginTop: "20px" }}>
            Please note voucher code will be auto generated once created
          </span>
        </div>
        <div className="edit-client-btns">
          <Button
            variant="outline"
            label={"Preview Voucher"}
            handleClick={() => {
              if (!total_amount || !selectedClient || !valid_date_from || !valid_date_to) {
                toast.error("Please fill out the form correctly", {
                  position: "top-right",
                });
              } else {
                setPreviewVoucher(true);
              }
            }}
          />
          <Button
            variant="primary"
            label={"Send Voucher"}
            type="submit"
            loading={loading}
            loaderColor="#fff"
          />
        </div>
      </form>

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(resetState())}
          />
        </Popup>
      )}

      {previewVoucher && (
        <Popup className="preview-voucher-popup" setPopUp={() => {}}>
          <div className="voucher-preview-popup-inner">
            <h1>Voucher</h1>
            <h3>Special for you</h3>

            <div className="preview-voucher-details">
              <Flex align="center" justify="space-between">
                <p>Voucher Amount</p>
                <p>£{total_amount}</p>
              </Flex>

              <Flex align="center" justify="space-between">
                <p>Sent To</p>
                <p>
                  {concatNames(
                    selectedClient?.first_name,
                    selectedClient?.last_name
                  )}
                </p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Email</p>
                <p>{selectedClient?.email}</p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Valid from</p>
                <p>{prettifyDate(valid_date_from)}</p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p>Valid to</p>
                <p>{prettifyDate(valid_date_to)}</p>
              </Flex>
            </div>
          </div>
          <img src="/portal/voucher.png" alt="" />

          <div className="preview-voucher-top-bar">
            <div className="preview-voucher-top-bar-inner"></div>
          </div>
          <div className="preview-voucher-bottom-bar">
            <div className="preview-voucher-bottom-bar-inner"></div>
          </div>
          {/* <span>www.bizopspro.com/modernstyler</span> */}
          <X
            className="preview-voucher-close"
            onClick={() => setPreviewVoucher(false)}
          />
        </Popup>
      )}
    </div>
  );
};

export default SendVoucher;
