import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Flex from "../../styled/Flex/Flex";
import "./style.css";
import { ChevronUp, ChevronDown, User } from "react-feather";
import { conditionalRender } from "../../../utills/conditionalRender";
import { getServiceProvidersForDropDown } from "../../../features/SuperAdminPortal/serviceProvidersSlice";
const ServiceProviderDropDown = ({
  label,
  selectedServiceProvider,
  setSelectedServiceProvider,
  defaultSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { loading, providers } = useSelector((state) => state.providers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getServiceProvidersForDropDown({ token }));
  }, [dispatch, token]);
  useEffect(() => {
    if (defaultSelected) {
      setSelectedServiceProvider(defaultSelected);
    }
  }, [defaultSelected, setSelectedServiceProvider]);
  return (
    <div className="global-client-dropdown">
      <div
        className="global-client-drop-head"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <h2>{label}</h2>
        <Flex
          className="global-client-drop-input"
          align="center"
          justify="space-between"
          gap={10}
        >
          <Flex className="global-client-drop-input-left" gap={15}>
            <img src="/icons/user.png" alt="user" />
            <h3>
              {conditionalRender(
                loading,
                "Loading...",
                conditionalRender(
                  selectedServiceProvider,
                  selectedServiceProvider?.business_name,
                  "Select Service Provider"
                )
              )}
            </h3>
          </Flex>
          {conditionalRender(
            open,
            <ChevronUp color="#666666" />,
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>

      <div
        className="global-client-drop-options"
        style={{
          maxHeight: `${open ? "300px" : "0px"}`,
          overflow: `${open ? "auto" : "hidden"}`,
        }}
      >
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Flex
            direction="column"
            gap={10}
            className="global-client-drop-options-inner"
          >
            {providers && providers?.length > 0 ? (
              providers?.map((provider, index) => {
                // const fullName = concatNames(
                //   provider?.first_name,
                //   provider?.last_name
                // ).toLowerCase();
                return (
                  conditionalRender(provider?.business_name, <div
                    style={{
                      display: `${
                        query
                          ? provider?.business_name?.includes(query.toLowerCase())
                            ? "block"
                            : "none"
                          : "block"
                      }`,
                    }}
                    >
                      <Flex
                        className="global-client-drop-option"
                        gap={15}
                        align="center"
                        key={index}
                      >
                        <Flex
                          align="center"
                          justify="center"
                          className="global-clients-drop-user"
                        >

                          {conditionalRender(provider?.image_url, <img src={provider?.image_url} alt="" />, <User color="#14142B" fill="#14142B" size={15} />)}
                          
                          
                        </Flex>
                        <span
                          onClick={() => {
                            setSelectedServiceProvider(provider);
                            setOpen(false);
                          }}
                          className={`${
                            provider?.id === selectedServiceProvider?.id
                              ? "client-drop-option-active"
                              : ""
                          }`}
                        >
                          {provider?.business_name}
                        </span>
                      </Flex>
                    </div>, '')
                );
              })
            ) : (
              <p className="global-empty-para">No service providers to show</p>
            )}
            {/* {setClientPopup && (
              <p className="global-empty-para">
                Or Click{" "}
                <button onClick={() => setClientPopup(true)} type="button">
                  here
                </button>{" "}
                to add a new provider
              </p>
            )} */}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default ServiceProviderDropDown;
