import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";

import { useForm } from "react-hook-form";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import {
  clearState,
  createTeamMember,
  getTeamDrops,
} from "../../../features/ServiceProviderPortal/teamMemberSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalDropDown from "../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import {
  nameMaxLength,
  nameMinLength,
  namePattern,
  phonePattern,
  userNamePattern,
} from "../../../utills/Schems";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import queryString from "query-string";
import Navbar from "../../../components/LandingPage/Navbar/Navbar";

const dropOptions = [
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-31T22:50:17.559Z",
    updated_at: "2023-12-31T22:50:17.559Z",
    created_by: "hasnain72.qureshi@gmail.com",
    updated_by: "hasnain72.qureshi@gmail.com",
    id: 17,
    name: "Hair Stylist",
    alias: "",
    description: "Hair Stylist",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-31T22:50:46.090Z",
    updated_at: "2024-01-01T08:49:07.536Z",
    created_by: "hasnain72.qureshi@gmail.com",
    updated_by: "hasnain72.qureshi@gmail.com",
    id: 18,
    name: "Makeup Artist",
    alias: "",
    description: "Makeup Artist",
  },
  {
    meta_data: null,
    notes: null,
    status: null,
    is_active: 1,
    created_at: "2023-12-31T22:51:00.541Z",
    updated_at: "2024-01-01T08:48:34.587Z",
    created_by: "hasnain72.qureshi@gmail.com",
    updated_by: "hasnain72.qureshi@gmail.com",
    id: 19,
    name: "Nail Artist",
    alias: "",
    description: "Nail Artist",
  },
];
const AddTeamMember = () => {
  const query = queryString.parse(window.location.search);
  const [successPopup, setSuccessPopup] = useState(false);
  const {
    teamMembersDropLoading,
    teamMembersDropError,
    loading,
    error,
    success,
  } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (query.accessToken) {
      dispatch(getTeamDrops({ token: query.accessToken }));
    }
  }, [query.accessToken, dispatch]);

  const [jobError, setJobError] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);

  const handleAddTeamMember = (values) => {
    if (!jobTitle) {
      setJobError("Please Select Job");
    } else {
      let apiData = {};
      let timings = [];
      apiData.first_name = values.first_name;
      apiData.last_name = values.last_name;
      apiData.mobile = values.mobile;
      apiData.username = values.username;
      apiData.email = query.email;
      apiData.job_title = jobTitle.name;
      apiData.roles = [2];
      if (values.monday_opening_time.length > 0) {
        const timingData = {
          day: "Monday",
          opening_time: values.monday_opening_time,
          close_time: values.monday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.tuesday_opening_time.length > 0) {
        const timingData = {
          day: "Tuesday",
          opening_time: values.tuesday_opening_time,
          close_time: values.tuesday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.wednesday_opening_time.length > 0) {
        const timingData = {
          day: "Wednesday",
          opening_time: values.wednesday_opening_time,
          close_time: values.wednesday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.thursday_opening_time.length > 0) {
        const timingData = {
          day: "Thursday",
          opening_time: values.thursday_opening_time,
          close_time: values.thursday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.friday_opening_time.length > 0) {
        const timingData = {
          day: "Friday",
          opening_time: values.friday_opening_time,
          close_time: values.friday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.saturday_opening_time.length > 0) {
        const timingData = {
          day: "Saturday",
          opening_time: values.saturday_opening_time,
          close_time: values.saturday_closing_time,
        };
        timings.push(timingData);
      }
      if (values.sunday_opening_time.length > 0) {
        const timingData = {
          day: "Sunday",
          opening_time: values.sunday_opening_time,
          close_time: values.sunday_closing_time,
        };
        timings.push(timingData);
      }
      apiData.access_token = query.accesstoken;
      apiData.working_hours = timings;
      console.log(query);
      dispatch(createTeamMember({ token: query.accesstoken, data: apiData }));
    }
  };

  const monday_opening_time = watch("monday_opening_time");
  const tuesday_opening_time = watch("tuesday_opening_time");
  const wednesday_opening_time = watch("wednesday_opening_time");
  const thursday_opening_time = watch("thursday_opening_time");
  const friday_opening_time = watch("friday_opening_time");
  const saturday_opening_time = watch("saturday_opening_time");
  const sunday_opening_time = watch("sunday_opening_time");
  const monday_closing_time = watch("monday_closing_time");
  const tuesday_closing_time = watch("tuesday_closing_time");
  const wednesday_closing_time = watch("wednesday_closing_time");
  const thursday_closing_time = watch("thursday_closing_time");
  const friday_closing_time = watch("friday_closing_time");
  const saturday_closing_time = watch("saturday_closing_time");
  const sunday_closing_time = watch("sunday_closing_time");
  return (
    <>
      <Navbar />
      <div className="register-team-member-container">
        <h1 style={{ textAlign: "center" }} className="edit-client-heading">
          Enter details to join the team
        </h1>
        {conditionalRender(
          teamMembersDropLoading,
          <PageLoader />,
          conditionalRender(
            !teamMembersDropError,
            <form
              onSubmit={handleSubmit(handleAddTeamMember)}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="add-team-member-inputs">
                <Flex
                  className={`global-input-container add-team-member-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="firstName">First Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"First Name"}
                      {...register("first_name", {
                        required: "Please Enter First Name",
                        pattern: namePattern,
                        maxLength: nameMaxLength,
                        minLength: nameMinLength,
                      })}
                      id="firstName"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  {errors?.first_name && (
                    <p className="global-input-error">
                      {errors?.first_name?.message}
                    </p>
                  )}
                </Flex>
                <Flex
                  className={`global-input-container add-team-member-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="last_name">Last Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      {...register("last_name", {
                        required: "Please Enter Last Name",
                        pattern: namePattern,
                        maxLength: nameMaxLength,
                        minLength: nameMinLength,
                      })}
                      id="last_name"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  {errors?.last_name && (
                    <p className="global-input-error">
                      {errors?.last_name?.message}
                    </p>
                  )}
                </Flex>
                <Flex
                  className={`global-input-container add-team-member-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="username">User Name*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"text"}
                      placeholder={"User Name"}
                      {...register("username", {
                        required: "Please Enter user name",
                        pattern: userNamePattern,
                        maxLength: {
                          value: 20,
                          message: "Should not be greater then 20 characters",
                        },
                        minLength: {
                          value: 5,
                          message: "Should not be less then 5 characters",
                        },
                      })}
                      id="username"
                    />
                    <img src="/icons/user.png" alt="icon" />
                  </div>
                  {errors?.username && (
                    <p className="global-input-error">
                      {errors?.username?.message}
                    </p>
                  )}
                </Flex>

                <Flex
                  className={`global-input-container add-team-member-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="mobile">Phone Number*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <input
                      type={"number"}
                      placeholder={"Phone Number"}
                      {...register("mobile", {
                        required: "Please Enter Phone Number",
                        pattern: phonePattern,
                      })}
                      id="mobile"
                    />
                    <img src="/icons/call.png" alt="icon" />
                  </div>
                  {errors?.mobile && (
                    <p className="global-input-error">
                      {errors?.mobile?.message}
                    </p>
                  )}
                </Flex>

                <Flex
                  className="add-team-member-input"
                  direction="column"
                  gap={10}
                >
                  <GlobalDropDown
                    options={dropOptions}
                    label="Job Title"
                    setStateHandler={setJobTitle}
                    icon="/icons/user.png"
                    stateHandler={jobTitle}
                  />
                  {jobError && !jobTitle && (
                    <p className="global-input-error">{jobError}</p>
                  )}
                </Flex>
              </div>
              <h2 className="website-design-head mt-30">
                Setup Your Working Hours
              </h2>
              <div
                className="working-timings mt-20"
                style={{ justifyContent: "flex-start", maxWidth: "1100px" }}
              >
                <div className="working-time-open-close">
                  <label htmlFor="monday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="monday-timing"
                      value={"Nail Art"}
                      checked
                    />
                    <span className="business-service-label">Monday</span>
                  </label>
                  <div className="open-close-time">
                    <div className="open-time-label-input">
                      <label htmlFor="monday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="monday-timing"
                          {...register("monday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="monday-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="monday-close-timing"
                          {...register("monday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !monday_opening_time ||
                              (monday_opening_time.length > 0 &&
                                value > monday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.monday_closing_time && (
                    <p className="error">
                      {errors?.monday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="tuesday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="tuesday-timing"
                      value={"Tuesday"}
                      checked
                    />
                    <span className="business-service-label">Tuesday</span>
                  </label>
                  <div className="open-close-time">
                    {monday_opening_time?.length > 0 &&
                      monday_closing_time?.length > 0 &&
                      (!tuesday_opening_time?.length > 0 ||
                        !tuesday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              tuesday_opening_time: monday_opening_time,
                              tuesday_closing_time: monday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="tuesday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="tuesday-open-timing"
                          {...register("tuesday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="tuesday-close-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="tuesday-close-timing"
                          {...register("tuesday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !tuesday_opening_time ||
                              (tuesday_opening_time.length > 0 &&
                                value > tuesday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.tuesday_closing_time && (
                    <p className="error">
                      {errors?.tuesday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label
                    htmlFor="wednesday-timing"
                    className="business-service"
                  >
                    <input
                      type="checkbox"
                      name=""
                      id="wednesday-timing"
                      value={"Wednesday"}
                      checked
                    />
                    <span className="business-service-label">Wednesday</span>
                  </label>
                  <div className="open-close-time">
                    {tuesday_opening_time?.length > 0 &&
                      tuesday_closing_time?.length > 0 &&
                      (!wednesday_opening_time?.length > 0 ||
                        !wednesday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              wednesday_opening_time: tuesday_opening_time,
                              wednesday_closing_time: tuesday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="wednesday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="wednesday-open-timing"
                          {...register("wednesday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="wednesday-close-timing">
                        Finish Time
                      </label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="wednesday-close-timing"
                          {...register("wednesday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !wednesday_opening_time ||
                              (wednesday_opening_time.length > 0 &&
                                value > wednesday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.wednesday_closing_time && (
                    <p className="error">
                      {errors?.wednesday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="thursday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="thursday-timing"
                      value={"Thursday"}
                      checked
                    />
                    <span className="business-service-label">Thursday</span>
                  </label>
                  <div className="open-close-time">
                    {wednesday_opening_time?.length > 0 &&
                      wednesday_closing_time?.length > 0 &&
                      (!thursday_opening_time?.length > 0 ||
                        !thursday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              thursday_opening_time: wednesday_opening_time,
                              thursday_closing_time: wednesday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="thursday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="thursday-open-timing"
                          {...register("thursday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="thursday-close-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="thursday-close-timing"
                          {...register("thursday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !thursday_opening_time ||
                              (thursday_opening_time.length > 0 &&
                                value > thursday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.thursday_closing_time && (
                    <p className="error">
                      {errors?.thursday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="friday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="friday-timing"
                      value={"Friday"}
                      checked
                    />
                    <span className="business-service-label">Friday</span>
                  </label>
                  <div className="open-close-time">
                    {thursday_opening_time?.length > 0 &&
                      thursday_closing_time?.length > 0 &&
                      (!friday_opening_time?.length > 0 ||
                        !friday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              friday_opening_time: thursday_opening_time,
                              friday_closing_time: thursday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="friday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="friday-open-timing"
                          {...register("friday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="friday-close-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="friday-close-timing"
                          {...register("friday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !friday_opening_time ||
                              (friday_opening_time.length > 0 &&
                                value > friday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.friday_closing_time && (
                    <p className="error">
                      {errors?.friday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="saturday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="saturday-timing"
                      value={"Saturday"}
                      checked
                    />
                    <span className="business-service-label">Saturday</span>
                  </label>
                  <div className="open-close-time">
                    {friday_opening_time?.length > 0 &&
                      friday_closing_time?.length > 0 &&
                      (!saturday_opening_time?.length > 0 ||
                        !saturday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              saturday_opening_time: friday_opening_time,
                              saturday_closing_time: friday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="saturday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="saturday-open-timing"
                          {...register("saturday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="saturday-close-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="saturday-close-timing"
                          {...register("saturday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !saturday_opening_time ||
                              (saturday_opening_time.length > 0 &&
                                value > saturday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.saturday_closing_time && (
                    <p className="error">
                      {errors?.saturday_closing_time?.message}
                    </p>
                  )}
                </div>
                <div className="working-time-open-close">
                  <label htmlFor="sunday-timing" className="business-service">
                    <input
                      type="checkbox"
                      name=""
                      id="sunday-timing"
                      value={"Sunday"}
                      checked
                    />
                    <span className="business-service-label">Sunday</span>
                  </label>
                  <div className="open-close-time">
                    {saturday_opening_time?.length > 0 &&
                      saturday_closing_time?.length > 0 &&
                      (!sunday_opening_time?.length > 0 ||
                        !sunday_closing_time?.length > 0) && (
                        <button
                          className="fill-previous-btn"
                          onClick={() =>
                            reset({
                              sunday_opening_time: saturday_opening_time,
                              sunday_closing_time: saturday_closing_time,
                            })
                          }
                        >
                          Fill Previous Timing
                        </button>
                      )}
                    <div className="open-time-label-input">
                      <label htmlFor="sunday-open-timing">Start Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="sunday-open-timing"
                          {...register("sunday_opening_time", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    <div className="close-time-label-input">
                      <label htmlFor="sunday-close-timing">Finish Time</label>
                      <div className="edit-client-icon-input" id="width-118">
                        <input
                          type="time"
                          placeholder="09:00"
                          id="sunday-close-timing"
                          {...register("sunday_closing_time", {
                            required: false,
                            validate: (value) =>
                              !sunday_opening_time ||
                              (sunday_opening_time.length > 0 &&
                                value > sunday_opening_time) ||
                              "Finish Time Cannot be before then Start Time",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {errors?.sunday_closing_time && (
                    <p className="error">
                      {errors?.sunday_closing_time?.message}
                    </p>
                  )}
                </div>
              </div>

              {/* <h2 className="website-design-head mt-30">Access Level</h2> */}

              {/* <div className="integrations-container">
                <div className="extra-right-margin">
                  <Toggle
                    label={"Appointments"}
                    id={"is_appointment"}
                    register={register}
                    name="is_appointment"
                  />
                </div>
                <div className="extra-right-margin">
                  <Toggle
                    label={"Subscription"}
                    id={"is_subscription"}
                    register={register}
                    name="is_subscription"
                  />
                </div>
                <div className="extra-right-margin">
                  <Toggle
                    label={"Clients"}
                    id={"is_client"}
                    register={register}
                    name="is_client"
                  />
                </div>
              </div> */}

              <div className="edit-client-btns">
                <Button
                  variant="primary"
                  label={"Join Team"}
                  type="submit"
                  loading={loading}
                  loaderColor="#fff"
                />
              </div>
            </form>,
            <h1>{teamMembersDropError}</h1>
          )
        )}

        {error && (
          <Popup className="send-voucher-popup">
            <GlobalErrorPopup error={error} />
            <Button
              label="ok"
              variant="outline-sm"
              handleClick={() => dispatch(clearState())}
            />
          </Popup>
        )}
      </div>
      {success && (
        <Popup
          popUp={successPopup}
          setPopUp={setSuccessPopup}
          className="signup-success-popup"
        >
          <img src="/icons/success.png" alt="success" />
          <h3>
            Congratulations, you have Joined
            <br /> Now check your email and Setup a <br />
            password for your account{" "}
          </h3>
          <Button
            label="Ok"
            variant="rounded-outline"
            handleClick={() => {
              setSuccessPopup(false);
              dispatch(clearState());
              navigate("/");
            }}
          />
        </Popup>
      )}
    </>
  );
};

export default AddTeamMember;
