import React, { useState, useRef } from "react";
import "./AddBlog.css";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BASE_URL } from "../../../../../config/config";
import { useSelector } from "react-redux";
import Button from '../../../../../components/styled/Button/Button'
const AddBlog = () => {
  const { token } = useSelector((state) => state.auth);
  const [editorData, setEditorData] = useState("<p>Start writing your blog</p>");
  const editorInstanceRef = useRef(null);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file_name", file);
            fetch(`${BASE_URL}/files/upload`, {
              method: "POST",
              body,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: res.result?.base_url });
              })
              .catch((err) => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="outer">
      <Link to={"/admin-portal/blogs"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="team-member-details-head mt-20 mb-30">Add Blog</h1>
      <div className="mb-30"></div>
      <CKEditor
        config={{
          extraPlugins: [uploadPlugin],
          image: {
            toolbar: [
              "imageStyle:alignLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignRight",
              "|",
              "imageResize",
              "|",
              "imageTextAlternative",
            ],
          },
        }}
        editor={ClassicEditor}
        data={editorData}
        onReady={(editor) => {
          editorInstanceRef.current = editor;
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
        }}
        onBlur={(event, editor) => {
          // Handle onBlur event if needed
        }}
        onFocus={(event, editor) => {
          // Handle onFocus event if needed
        }}
      />
      <div className="mb-30"></div>
      <Button label='Add Blog' handleClick={()=>{
        console.log(editorData)
      }}/>
    </div>
  );
};

export default AddBlog;
