import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";



const MyAreaChart = ({data,name}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7d58a0" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#7d58a0" stopOpacity={0} />
          </linearGradient>
          {/* Define a gradient for profit if needed */}
        </defs>
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false}/>
        <Tooltip />
        <Legend />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#7d58a0"
          fillOpacity={1}
          fill="url(#colorRevenue)"
          name={name}
        />
        {/* Uncomment the following line if you want to display profit instead */}
        {/* <Area type="monotone" dataKey="profit" stroke="#82ca9d" fillOpacity={1} fill="url(#colorProfit)" /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MyAreaChart;
