import React, { useMemo } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Search, LogOut, Settings, AlignRight, User } from "react-feather";
import Flex from "../../styled/Flex/Flex";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { clearState } from "../../../features/auth/authSlice";
import { logout } from "../../../features/store";
const Navbar = ({
  setSideBarActive,
  notificationsLink,
  profileSettingsLink,
}) => {
  const { userData } = useSelector((state) => state.auth);
  const { notifications } = useSelector((state) => state.notifications);
  const showDot = useMemo(() => {
    if (notifications) {
      return notifications.some((notification) => notification.seen === 0);
    }
  }, [notifications]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  return (
    <nav className="portal-navbar" style={{ zIndex: "10" }}>
      <div className="portal-nav-inner">
        {pathname === "/portal/dashboard" &&
        !userData?.business_id.is_visited ? (
          <Link to="/portal/website-design" className="portal-nav-link">
            CLICK HERE TO GET STARTED ON YOUR WEBSITE
          </Link>
        ) : (
          <div></div>
        )}

        <div className="portal-nav-right">
          <div className="portal-nav-search">
            <input type="text" placeholder="Search..." />
            <div className="search-icon">
              <Search color="#3E0FFE" />
            </div>
          </div>
          <Link to={notificationsLink}>
            <div className="notifications-container">
              <img src="/icons/bell.png" alt="notifications" />
              {showDot && <div></div>}
            </div>
          </Link>
          <div className="nav-user-icon">
            {userData?.image_url ? (
              <img src={`${userData?.image_url}`} alt="user" />
            ) : (
              <div className="profile-dummy">
                <User />
              </div>
            )}
          </div>
          <div className="logout-popup-drop">
            <div className="popup-drop-settings">
              <Link to={profileSettingsLink}>
                {/* <img src="/icons/settings.png" alt="" /> */}
                <Settings size={16} />
                <span className="popup-drop-span">Profile Settings</span>
              </Link>
            </div>
            <div
              className="popup-drop-logout"
              onClick={() => {
                dispatch(clearState());
                logout();
                navigate("/login");
              }}
            >
              <LogOut size={16} />
              <span className="popup-drop-span">Logout</span>
            </div>
          </div>
        </div>
        {/* <LogoutPopup /> */}
      </div>
      <div className="portal-nav-responsive">
        <Flex align="center" justify="space-between">
          <img src="/logo.png" alt="logo" className="res-logo" />
          <Flex align="center" gap={20}>
            <Link to={notificationsLink}>
            <div className="notifications-container">
              <img src="/icons/bell.png" alt="notifications" />
              {showDot && <div></div>}
            </div>
            </Link>
            <img
              src={`${
                userData?.image_url ? userData?.image_url : "/portal/user.png"
              }`}
              alt="user"
              className="res-user nav-user-icon"
            />
            <div className="logout-popup-drop">
              <div className="popup-drop-settings">
                <Link to={"/portal/profile-settings"}>
                  {/* <img src="/icons/settings.png" alt="" /> */}
                  <Settings size={16} />
                  <span className="popup-drop-span">Profile Settings</span>
                </Link>
              </div>
              <div
                className="popup-drop-logout"
                onClick={() => {
                  dispatch(clearState());
                  logout();
                  navigate("/login");
                }}
              >
                {/* <img src="/icons/logout.png" alt="" /> */}
                <LogOut size={16} />
                <span className="popup-drop-span">Logout</span>
              </div>
            </div>
            <AlignRight
              color="#3E0FFE"
              size={35}
              cursor="pointer"
              onClick={() => setSideBarActive(true)}
            />
          </Flex>
        </Flex>
      </div>
    </nav>
  );
};

export default Navbar;
