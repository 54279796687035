import React from "react";
import "./About.css";
import LandingPageLayout from "../../../components/LandingPage/Layout/LandingPageLayout";
const About = () => {
  return (
    <LandingPageLayout>
      <div className="max-width-1440 about-page">
        <section className="section1">
          <div className="headings about-main-head">About Us</div>
          <p className="about-main-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s</p>
          <button className="about-contact-btn">Contact</button>
          <div className="about-main-img">
            <img src={"/about/aboutImg.png"} alt="" />
          </div>
        </section>
        <section className="section2">
          <div className="sec2-inner">
            <h1 className="headings sec2-heading">Our Mission</h1>
            <p className="sec2-para1">
              At BizOps Pro, we're on a mission to empower service providers and
              businesses to thrive in the digital age. With our cutting-edge
              software solutions, we enable you to take control of every aspect
              of your business, from scheduling and invoicing to collecting
              payments and establishing a strong online presence.
            </p>
            <p className="sec2-para2">
              Our team of dedicated professionals is passionate about
              simplifying the complexities of running a service-based business.
              We understand the unique challenges you face, and we've designed
              our software to be your ultimate partner in success.
            </p>
          </div>
        </section>
      </div>
    </LandingPageLayout>
  );
};

export default About;
