import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  discounts: null,
  discount: null,
  dropDown: null,
  addLoading: false,
  addError: null,
  delLoading: false,
  delError: null,
  delSuccess: false,
  updateLoading:false,
  updateError:null,
  updateSuccess:false
};

export const discountSlice = createSlice({
  name: "discountSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.addError = null;
      state.delSuccess = false;
      state.delError = null;
      state.updateSuccess = false;
      state.updateError = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDisconts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllDisconts.fulfilled, (state, action) => {
      state.loading = false;
      state.discounts = action.payload;
    });
    builder.addCase(getAllDisconts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addDiscount.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(addDiscount.fulfilled, (state) => {
      state.success = true;
      state.addLoading = false;
    });
    builder.addCase(addDiscount.rejected, (state, action) => {
      state.addError = action.payload;
      state.addLoading = false;
    });
    builder.addCase(updateDiscount.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateDiscount.fulfilled, (state) => {
      state.updateSuccess = true;
      state.updateLoading = false;
    });
    builder.addCase(updateDiscount.rejected, (state, action) => {
      state.updateError = action.payload;
      state.updateLoading = false;
    });

    builder.addCase(deleteDiscount.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteDiscount.fulfilled, (state) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(deleteDiscount.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllDisconts = createAsyncThunk(
  "getAllDisconts",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/discounts/manage?page=1&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      }

      return rejectWithValue("Some error occurred please try again");
    }
  }
);


export const addDiscount = createAsyncThunk(
  "addDiscount",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      const { data } = await axios.post(
        `${BASE_URL}/v1/discounts/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "deleteDiscount",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unaothorized");
      }
      if (!apiData.id) {
        return rejectWithValue("Please Provide id");
      }
      const { data } = await axios.delete(
        `${BASE_URL}/v1/discounts/delete/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "updateDiscount",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/discounts/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = discountSlice.actions;
