import React, { useEffect, useState } from "react";
import "./style.css";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import Flex from "../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccessLevels,
  getSingleUserAccessLevels,
  updateUserAccess,
  clearState,
} from "../../../features/ServiceProviderPortal/accessLevelsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { toast } from "react-toastify";
import {
  SUPER_ADMIN,
  checkIfRoleIsAssigned,
} from "../../../utills/authorizeRole";
import Button from "../../../components/styled/Button/Button";
import { useForm } from "react-hook-form";
import { CheckCircle, ChevronDown, ChevronUp } from "react-feather";
import Popup from "../../../components/styled/Popup/Popup";

const AssignTeamAccessLevels = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [selectedTeamMemberToCopy, setSelectedTeamMemberToCopy] =
    useState(null);
  const [permissionsPopup, setPermissionsPopup] = useState(false);
  const {
    loading,
    permissions,
    error,
    updateLoading,
    updateError,
    updateSuccess,
    delError,
    delSuccess,
    userPermissionLoading,
    userPermissions,
    usePermissionError,
    activeIds,
  } = useSelector((state) => state.permissions);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getAllAccessLevels({ token }));
    }
    if (delSuccess || updateSuccess) {
      dispatch(clearState());
      toast.success("Team member permissions updated successfully");
      setSelectedTeamMemberToCopy(null)
    }
  }, [token, dispatch, delSuccess, updateSuccess]);
  useEffect(() => {
    if (token && permissions && selectedTeamMember) {
      dispatch(
        getSingleUserAccessLevels({
          token,
          id: selectedTeamMemberToCopy
            ? selectedTeamMemberToCopy.id
            : selectedTeamMember.id,
          permissions,
        })
      );
    }
  }, [
    token,
    permissions,
    selectedTeamMember,
    dispatch,
    selectedTeamMemberToCopy,
  ]);

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
    }
    if (delError) {
      toast.error(delError);
    }
    if (activeIds) {
      setActiveTabIds(activeIds);
    }
  }, [delError, updateError, activeIds]);

  const { handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: { permisions: [] },
  });
  const userP = watch("permisions");
  const [activeTabIds, setActiveTabIds] = useState([]);
  useEffect(() => {
    if (userPermissions) {
      reset({ permisions: [...userPermissions] });
    }
  }, [userPermissions, reset]);

  const handlePermissions = (e, item, parent) => {
    if (e.target.checked) {
      if(parent){
        
        if(!(userP.find((p)=> p.name === parent.name))){
          const combined = []
          combined.push(item)
          combined.push(parent[0])
          // console.log(combined)
          setValue('permisions',[...userP, ...combined])
          // console.log(combined)
        }
      }else{
        setValue("permisions", [...userP, item]);
      }
    } else {
      const updatedPermissions = userP.filter((p) => p.name !== item.name);
      setValue("permisions", updatedPermissions);
    }
  };

  const togglePermissions = (item) => {
    if (activeTabIds.includes(item.id)) {
      const updatedIds = activeTabIds.filter((i) => item.id !== i);
      setActiveTabIds(updatedIds);
    } else {
      setActiveTabIds((prev) => [...prev, item.id]);
    }
  };


  return (
    <div className="outer select-access-levels">
      <div className="mb-20">
        <h1 className="team-member-details-head">Team Management</h1>
      </div>

      <Flex align="center" justify="space-between">
        <div
          className="access-level-team-member-drop mb-20"
          style={{ width: "500px" }}
        >
          <TeamMembersDrop
            label="Team Member"
            selectedMember={selectedTeamMember}
            setSelectedMember={setSelectedTeamMember}
            handleChange={() => setSelectedTeamMemberToCopy(null)}
            admin={false}
          />
          {selectedTeamMember && (
            <button
              className="fill-previous-btn"
              onClick={() => setPermissionsPopup(true)}
              type="button"
            >
              View All Permissions
            </button>
          )}
        </div>
        {selectedTeamMember && (
          <div
            className="access-level-team-member-drop mb-20"
            style={{ width: "500px" }}
          >
            <TeamMembersDrop
              label="Or copy access from"
              selectedMember={selectedTeamMemberToCopy}
              setSelectedMember={setSelectedTeamMemberToCopy}
              handleChange={() => {}}
              admin={false}
            />
          </div>
        )}
      </Flex>

      {conditionalRender(
        selectedTeamMember,
        conditionalRender(
          userPermissionLoading || loading,
          <PageLoader />,
          conditionalRender(
            selectedTeamMember?.role === SUPER_ADMIN,
            <h2 className="website-design-head">
              Selected User is Business Admin
            </h2>,
            conditionalRender(
              !error && !usePermissionError,
              <div>
                <table className="assign-access-levels-table">
                  <thead>
                    <tr>
                      <th>Features</th>
                      <th style={{ width: "500px" }}>Permissions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions?.items?.map((item, index) => {
                      return (
                        <>
                          <tr
                            style={{ background: "#F4F6F8", cursor: "pointer" }}
                            onClick={() => togglePermissions(item)}
                          >
                            <td>{item.name}</td>
                            <td>
                              {expandedItem === item ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}
                            </td>
                          </tr>
                          {activeTabIds.includes(item?.id) &&
                            item?.permissions?.map((permissionItem) => {
                              return (
                                <tr key={permissionItem.name}>
                                  <td>{permissionItem.name}</td>
                                  <td>
                                    <Flex align="center" gap={10}>
                                      <input
                                        type="checkbox"
                                        checked={checkIfRoleIsAssigned(
                                          userP,
                                          permissionItem
                                        )}
                                        onChange={(e) =>{
                                          if(permissionItem.is_parent === 1){
                                            
                                            handlePermissions(e, permissionItem, null)
                                          }else{
                                            const parent = item?.permissions.filter((p)=> p.is_parent === 1)
                                            handlePermissions(e, permissionItem, parent)
                                          }

                                        }
                                        }
                                      />
                                    </Flex>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <form
                  style={{ marginTop: "50px", marginBottom: "100px" }}
                  onSubmit={handleSubmit((values) => {
                    const permissions_array = values.permisions.map(
                      (p) => p.id
                    );
                    dispatch(
                      updateUserAccess({
                        token,
                        data: {
                          user_id: selectedTeamMember.id,
                          permissions_array,
                        },
                      })
                    );
                  })}
                >
                  <Flex align="center" justify="flex-end">
                    <Button
                      label="Save Access"
                      type="submit"
                      loading={updateLoading}
                      loaderColor="#fff"
                    />
                  </Flex>
                </form>
              </div>,

              <GlobalErrorHandler
                label={usePermissionError ? usePermissionError : error}
              />
            )
          )
        ),
        <h2 className="website-design-head">Select a team member</h2>
      )}

      {permissionsPopup && (
        <Popup
          popUp={permissionsPopup}
          setPopUp={setPermissionsPopup}
          className="view-all-permissions-popup"
        >
          <h3>Permissions</h3>
          <ul>
            {userPermissions &&
              userPermissions.map((item) => {
                return (
                  <li key={item.id}>
                    <Flex align="center" gap={10}>
                      <CheckCircle size={18} color="green" />
                      <p>{item.name}</p>
                    </Flex>
                  </li>
                );
              })}
          </ul>
          <Flex align="center" justify="flex-end">
            <Button
              label="Close"
              variant="outline-sm"
              handleClick={() => setPermissionsPopup(false)}
            />
          </Flex>
        </Popup>
      )}
    </div>
  );
};

export default AssignTeamAccessLevels;
