import React, { useEffect } from "react";
import "./AddClient.css";
import { Link } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Button from "../../../components/styled/Button/Button";
import Toggle from "../../../components/Toggle/Toggle";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addClient,
  clearState,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { useNavigate } from "react-router-dom";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../utills/Schems";
import Flex from "../../../components/styled/Flex/Flex";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { convBoolToNumber } from "../../../utills/conversion";
import { toast } from "react-toastify";


const AddClient = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode:'onBlur'
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { loading, error, success } = useSelector((state) => state.clients);
 
  const handleAddClient = (values) => {
    values.is_email = convBoolToNumber(values.is_email);
    values.is_sms = convBoolToNumber(values.is_sms);
    values.is_whatsapp = convBoolToNumber(values.is_whatsapp);
    values.enable_marketing = convBoolToNumber(values.enable_marketing);
    values.total_appointment = parseInt(values.total_appointment);
    console.log(values);
    const _apiData = {
      token,
      data: { ...values },
    };
    dispatch(addClient(_apiData));
  };

  useEffect(() => {
    if (success) {
      toast.success("Client added successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/portal/clients");
    }
  }, [error, success, navigate, dispatch]);

  return (
    <>
      <div className="outer">
        <Link to={"/portal/clients"} className="back">
          <ArrowLeft size={24} color="#5459FF" /> Back
        </Link>
        <h1 className="edit-client-heading">Add Client</h1>
        <form onSubmit={handleSubmit(handleAddClient)}>
          <div className="add-client-inputs-outer">
            <div className="add-client-inputs">
              <Flex
                className={`global-input-container add-client-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="first_name">First Name*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Last Name"}
                    {...register("first_name", {
                      required: "Please Enter First Name",
                      maxLength: {
                        value: 10,
                        message: "Not should be more then ten characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                      pattern: namePattern,
                    })}
                    id="first_name"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.first_name && errors?.first_name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-client-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="first_name">Last Name*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Last Name"}
                    {...register("last_name", {
                      required: "Please Enter Last Name",
                      maxLength: {
                        value: 10,
                        message: "Not should be more then ten characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                      pattern: namePattern,
                    })}
                    id="first_name"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.last_name && errors?.last_name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-client-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="email">Email *</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"email"}
                    placeholder={"Enter Email"}
                    {...register("email", {
                      required: "Please Enter Email",
                      pattern: emailPattern,
                    })}
                    id="email"
                  />
                  <img src="/icons/mail.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.email && errors?.email?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-client-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="phone">Phone*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    placeholder={"Enter phone number"}
                    {...register("phone", {
                      required: "Please Enter Phone Number",
                      pattern: phonePattern,
                      min:{value:0, message: "Phone number cannot be negative"},
                    })}
                    id="phone"
                  />
                  <img src="/icons/call-1.png" alt="icon" />
                </div>
                <p className="global-input-error mb-10">
                  {errors?.phone && errors?.phone?.message}
                </p>
              </Flex>
              {/* <Flex
                className={`global-input-container add-client-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="total_appointment">Total Appointment(s)*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    placeholder={"Enter Total Appointments"}
                    {...register("total_appointment", {
                      required: false,
                      maxLength: {
                        value: 3,
                        message: "Not should be more then three numbers",
                      },
                    })}
                    id="total_appointment"
                  />
                  <img src="/icons/appointments.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.total_appointment &&
                    errors?.total_appointment?.message}
                </p>
              </Flex> */}
            </div>

            <div className="lable-textarea-group lable-input-group">
              <label htmlFor="notes">
                Internal Notes{" "}
                <span>(This is only visible to internal users)</span>
              </label>
              <div className="edit-client-icon-textarea">
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="Enter Notes"
                  {...register("notes", { required: false })}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="notification-setting">
            <h1 className="notification-heading">Notification Settings</h1>
            <div className="notificaton-toggle-btn">
              <Toggle
                label={"Enable email notifications"}
                id={"email-toggle"}
                register={register}
                name="is_email"
              />
              <Toggle
                label={"Enable text notifications"}
                id={"text-notification"}
                register={register}
                name="is_sms"
              />
              <Toggle
                label={"Enable whatsapp communication"}
                id={"whatsapp-communication"}
                register={register}
                name="is_whatsapp"
              />
              <Toggle
                label={"Enable marketing notifications"}
                id={"marketing-notification"}
                register={register}
                name="enable_marketing"
              />
            </div>
          </div>

          <div className="edit-client-btns">
            <Button
              variant="primary"
              label={"Add Client"}
              type="submit"
              loaderColor="#fff"
              loading={loading}
            />
          </div>
        </form>
      </div>

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </>
  );
};

export default AddClient;
