import React from "react";
import "./ClientPortalTabs.css";
const ClientPortalTabs = ({ activeTab, setActiveTab, options, width }) => {
  return (
    <div className="client-portal-tabs-container" style={{ maxWidth: `${width}px` }}>
      {/* <div className="global-tabs-options"> */}
      {options.map((option, index) => {
        return (
          <div
            key={index}
            className={`client-portal-tabs-option ${
              option.toLowerCase() === activeTab.toLowerCase()
                ? "client-portal-tabs-option-active"
                : ""
            }`}
            onClick={() => setActiveTab(option)}
          >
            <h3>{option}</h3>
          </div>
        );
      })}
      {/* </div> */}
    </div>
  );
};

export default ClientPortalTabs;
