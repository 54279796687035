import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/LandingPage/Footer/Footer";
import "./style.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../../components/styled/BtnLoader/BtnLoader";
import { conditionalRender } from "../../utills/conditionalRender";
import { clearForgetPassUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { emailPattern } from "../../utills/Schems";
import Popup from "../../components/styled/Popup/Popup";
import Button from "../../components/styled/Button/Button";
import Flex from "../../components/styled/Flex/Flex";
import queryString from "query-string";
import {
  forgetPassRequest,
  tokenVerify,
  updateForgetPass,
  clearState,
} from "../../features/auth/forgetPasswordSlice";
import { ArrowLeft, Check, Eye, EyeOff, X } from "react-feather";

const ForgetPassword = () => {
  const query = queryString.parse(window.location.search);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch,
    formState: { errors: errors2 },
  } = useForm({ mode: "onBlur" });

  const {
    loading,
    success,
    error,
    verifyLoading,
    verifySuccess,
    verifyError,
    updateLoading,
    updateSuccess,
  } = useSelector((state) => state.forgetPass);

  const { forgetPassUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgetPassRequestPopup, setForgetPassRequestPopup] = useState(false);
  const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
  const [enteredOtp, setEnteredOtp] = useState("");
  const inputRefs = useRef(
    new Array(6).fill(null).map(() => React.createRef())
  );

  const handleLogin = (data) => {
    dispatch(forgetPassRequest(data));
  };

  // useEffect(() => {
  //   if (token) {
  //     navigate("/portal/dashboard");
  //   }
  // }, [token, dispatch, navigate]);
  useEffect(() => {
    if (verifySuccess) {
      dispatch(clearState());
    }
    if (updateSuccess) {
      dispatch(clearForgetPassUser());
      dispatch(clearState());
      setUpdateSuccessPopup(true);
    }
  }, [verifySuccess, dispatch, updateSuccess]);

  const handleOtpInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].current.focus();
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }

    setOtpInputs(newOtpInputs);
    setEnteredOtp(newOtpInputs.join(""));
  };

  const password = watch("password");

  const handleOtpSubmit = () => {
    if (enteredOtp.length === 6) {
      setOtpError(null);
      dispatch(tokenVerify({ verification_code: enteredOtp, mode: "forget" }));
    } else {
      setOtpError("Please enter all 6 digits.");
    }
  };
  const [otpError, setOtpError] = useState(null);
  const renderOtpInputs = () => {
    return otpInputs.map((value, index) => (
      <input
        key={index}
        type="number"
        value={value}
        onChange={(e) => handleOtpInputChange(index, e.target.value)}
        ref={inputRefs.current[index]}
      />
    ));
  };

  const handleUpdatePassword = (values) => {
    const apiData = {};
    if (!forgetPassUser && !query?.accesstoken) {
      window.alert("No user Found - Please follwo steps again");
    } else {
      const data = {};
      data.password = values.password;
      if (forgetPassUser) {
        apiData.token = forgetPassUser.accessToken;
      }
      if (query?.accesstoken) {
        apiData.token = query?.accesstoken;
        data.access_token = query?.accesstoken;
      }
      apiData.data = data;
      dispatch(updateForgetPass(apiData));
    }
  };

  const [passwordConstraints, setPasswordConstraints] = useState({
    length: false,
    uppercase: false,
    specialCharacter: false,
    number: false,
  });

  const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/;

  const handlePasswordChange = (e) => {
    setPasswordConstraints({
      length: e.target.value.length >= 8,
      uppercase: /[A-Z]/.test(e.target.value),
      specialCharacter: /[!@#$&*]/.test(e.target.value),
      number: /[0-9]/.test(e.target.value),
    });
  };

  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);

  const [updateSuccessPopup, setUpdateSuccessPopup] = useState(false);
  return (
    <div>
      <div className="max-width-1440 signup-main-container">
        <div className="signup-main-container-inner">
          <div className="signup-left">
            {/* <img src="/logo.png" alt="Logo" className="signup-logo" /> */}
            <img src="/signup/login-banner.png" alt="login page" />
          </div>
          <div className="signup-right">
            <Flex
              align="center"
              justify="center"
              className="signup-login-right-logo relative"
            >
          <Link to={"/login"} className="back forget-back">
              <ArrowLeft size={24} color="#5459FF" /> Back
            </Link>
              <Link to="/">
                <img src="/logo.png" alt="Logo" className="signup-logo" />
              </Link>
            </Flex>

            <h3>Enter Your Email Address</h3>

            <form className="signup-form" onSubmit={handleSubmit(handleLogin)}>
              <div className="signup-forms-input">
                <div className="input-group">
                  <label htmlFor="email"> Email*</label>
                  <div className="input">
                    <input
                      type="text"
                      id="email"
                      {...register("email", {
                        required: "Please Enter your email",
                        pattern: emailPattern,
                      })}
                    />
                    <img src="/icons/mail.png" alt="" />
                    {(error || errors.email) && (
                      <p className="error">
                        {errors?.email?.message
                          ? errors?.email?.message
                          : error}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="submit-btn-outer">
                <button className="btn-outline" type="submit">
                  {conditionalRender(
                    loading,
                    <BtnLoader color="#3e0ffe" />,
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
      {success && (
        <Popup
          className="forget-pass-request-popup"
          popUp={forgetPassRequestPopup}
          setPopUp={setForgetPassRequestPopup}
        >
          <h1>Please enter the six-digit code?</h1>
          <p className="signup-text" style={{marginTop:'0px', marginBottom:'20px'}}>If you haven’t received the email, please check your spam or junk folder. Sometimes emails may end up there by mistake.</p>
          <div className="forget-pass-request-inputs">
            <div className="forget-pass-inputs-inner">{renderOtpInputs()}</div>
            <Flex align="center" justify="flex-end">
              <p className="global-input-error">
                {otpError || verifyError
                  ? verifyError
                    ? verifyError
                    : otpError
                  : null}
              </p>
            </Flex>
          </div>

          <Button
            label="Submit"
            variant="rounded-outline"
            handleClick={handleOtpSubmit}
            loading={verifyLoading}
            loaderColor="#3E0FFE"
          />
        </Popup>
      )}
      {(forgetPassUser || query?.accesstoken) && (
        <Popup
          className="forget-pass-request-popup"
          popUp={forgetPassRequestPopup}
          setPopUp={setForgetPassRequestPopup}
        >
          <h1>Update Password</h1>
          <form
            className="update-pass-inner"
            onSubmit={handleSubmit2(handleUpdatePassword)}
          >
            <div className="updated-forget-pass-inputs">
            <div className="input-group">
            <label htmlFor="password"> Password*</label>
            <div className="input">
              <input
                type={`${showPass ? "text" : "password"}`}
                id="password"
                {...register2("password", {
                  required: "Please Enter your password",
                  pattern: passwordPattern,
                })}
                onChange={handlePasswordChange}
                style={{background:'#F4F6F8'}}
                placeholder="Please Enter Password"
              />
              <div className="password-view-icons">
                {showPass ? (
                  <Eye
                    onClick={() => setShowPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>
            </div>
            <div className="global-password-constraints">
              {errors2?.password && (
                <>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.length ? (
                      <Check size={15} color={"#14A800"} />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.length ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      At least 8 characters
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.uppercase ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.uppercase ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Capital letter(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.specialCharacter ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.specialCharacter
                            ? "#14A800"
                            : "#FF0000"
                        }`,
                      }}
                    >
                      Special character(s)
                    </p>
                  </Flex>
                  <Flex align="center" gap={5}>
                    {passwordConstraints.number ? (
                      <Check size={15} color="#14A800" />
                    ) : (
                      <X size={13} color="#FF0000" />
                    )}
                    <p
                      style={{
                        color: `${
                          passwordConstraints.number ? "#14A800" : "#FF0000"
                        }`,
                      }}
                    >
                      Number(s)
                    </p>
                  </Flex>
                </>
              )}
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="password"> Confirm Password *</label>
            <div className="input">
              <input
                type={`${showCPass ? "text" : "password"}`}
                id="password"
                {...register2("confirm_password", {
                  validate: (value) =>
                    value === password || "password doesn't match",
                  required: "confirm password is required",
                })}
                style={{background:'#F4F6F8'}}
                placeholder="Please Confirm Your Password"
              />
              <div className="password-view-icons">
                {showCPass ? (
                  <Eye
                    onClick={() => setShowCPass(false)}
                    className="eye"
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                ) : (
                  <EyeOff
                    className="eye-off"
                    onClick={() => setShowCPass(true)}
                    size={20}
                    color="#666"
                    cursor="pointer"
                  />
                )}
              </div>

              <p className="global-input-error" style={{ marginTop: "5px" }}>
                {errors2?.confirm_password && errors2?.confirm_password?.message}
              </p>
            </div>
          </div>
            </div>
            <Flex align="center" justify="flex-end">
              <Button
                label="Submit"
                variant="rounded-outline"
                type="submit"
                loading={updateLoading}
                loaderColor="#3E0FFE"
              />
            </Flex>
          </form>
        </Popup>
      )}
      {updateSuccessPopup && (
        <Popup
          popUp={updateSuccessPopup}
          setPopUp={setUpdateSuccessPopup}
          className="signup-success-popup"
        >
          <img src="/icons/success.png" alt="success" />
          <h3>
            {" "}
            Congratulations, your password has been <br /> updated successfully{" "}
          </h3>
          <Button
            label="Sign In"
            variant="rounded-outline"
            handleClick={() => {
              setUpdateSuccessPopup(false);
              navigate("/login");
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default ForgetPassword;
