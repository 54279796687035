import React, { useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  getSingleDayAppointments,
  getSingleTeamMemberAppointments,
} from "../../../features/ServiceProviderPortal/appoinmentSlice";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";
import { ArrowLeft, User } from "react-feather";
import ReactDatePicker from "react-datepicker";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import Flex from "../../../components/styled/Flex/Flex";
import {
  calculateEndTime12Hour,
  convertToAMPM,
} from "../../../utills/prettifyDate";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { timeStamps } from "../../../utills/data";

const colors = ["#BB86FF",'#F4C465','#8AD7B7','#8BC7FF']
const AppointmentCalender = () => {
  const query = queryString.parse(window.location.search);
  const { token } = useSelector((state) => state.auth);
  const { loading, error, appointmentsByDate } = useSelector(
    (state) => state.appoinments
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      if (query?.id && query?.date) {
        dispatch(
          getSingleTeamMemberAppointments({
            token,
            id: query.id,
            date: query.date,
          })
        );
      }
      if (query?.date) {
        dispatch(getSingleDayAppointments({ token, date: query?.date }));
      }
    }
  }, [token, dispatch, query?.date, query?.id]);

  function isAppointmentWithinTimeframe(startTime, endTime, initialTimeFrame) {
    let startHour = parseInt(startTime.split(":")[0]);
    startHour = startHour === 0 ? 24 : startHour;
    let initialHour = parseInt(initialTimeFrame);
    let finalHour = initialHour + 2;
    if (startHour >= initialHour && startHour < finalHour) {
      return true;
    }

    return false;
  }
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Link to={-1} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <div className="clients-container-top appointments-top-container">
        <h1 className="edit-client-heading">Appointments</h1>
        <div className="clients-container-top-buttons">
          <div className="appointment-sort-containers">
            <span className="appoinment-view-calender">Date</span>
            <ReactDatePicker
              selected={query?.date}
              onChange={(date) => {
                const inputDate = new Date(date);
                const finalDate = `${inputDate.getFullYear()}-${(
                  inputDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${inputDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;

                if (query?.id) {
                  navigate(
                    `/portal/appointment-calender?date=${finalDate}&id=${query?.id}`
                  );
                } else {
                  navigate(`/portal/appointment-calender?date=${finalDate}`);
                }
              }}
              className="appointment-calender-date-picker"
            />
          </div>
          <Link to="/portal/add-appointment">
            <Button label="Add Appointment" />
          </Link>
        </div>
      </div>

      <div className="appointment-calender-container">
        {conditionalRender(
          loading,
          <PageLoader />,
          conditionalRender(
            !error,
            conditionalRender(
              appointmentsByDate?.length > 0,
              <div className="appointments-calender-main-container">
                <table>
                  <thead>
                    <tr>
                      {appointmentsByDate &&
                        appointmentsByDate?.map((item, index) => (
                          <th key={item.id}>
                            <div className="appointment-calender-team">
                              <Flex align="center" justify="center" gap={10}>
                                <Flex
                                  align="center"
                                  justify="center"
                                  className="global-clients-drop-user"
                                >
                                  <User
                                    color="#14142B"
                                    fill="#14142B"
                                    size={15}
                                  />
                                </Flex>
                                <p>{item.full_name}</p>
                              </Flex>
                            </div>
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {timeStamps?.map((stamp, index) => (
                      <tr
                        key={stamp.value}
                        className="appointment-calender-row"
                      >
                        {appointmentsByDate?.map((member, memberIndex) => {
                          let appointmentFound = false;
                          member.appointments && member.appointments.forEach((appointment) => {
                            if (
                              isAppointmentWithinTimeframe(
                                appointment.time,
                                calculateEndTime12Hour(
                                  convertToAMPM(appointment.time),
                                  appointment.services
                                ),
                                stamp.value,
                                stamp.value + 2
                              )
                            ) {
                              appointmentFound = true;
                              return;
                            }
                          });

                          return (
                            <td key={member.id}>
                              {appointmentFound ? (
                                member.appointments.map((appointment) => {
                                  if (
                                    isAppointmentWithinTimeframe(
                                      appointment.time,
                                      calculateEndTime12Hour(
                                        convertToAMPM(appointment.time),
                                        appointment.services
                                      ),
                                      stamp.value,
                                      stamp.value + 2
                                    )
                                  ) {
                                    return (
                                      <Link
                                        to={`/portal/appoinment-detail/${appointment.id}`}
                                        key={appointment.id}
                                      >
                                        <div
                                          className="appointment-team-schedule"
                                          style={{
                                            background:colors[Math.floor(Math.random() * 4)]
                                          }}
                                        >
                                          <div className="appointment-calender-badge">
                                            {convertToAMPM(appointment.time)} -{" "}
                                            {calculateEndTime12Hour(
                                              convertToAMPM(appointment.time),
                                              appointment.services
                                            )}
                                          </div>
                                          <div className="appointment-calender-heading">
                                            {convertToAMPM(appointment.time)} -{" "}
                                            {calculateEndTime12Hour(
                                              convertToAMPM(appointment.time),
                                              appointment.services
                                            )}
                                          </div>
                                          <div className="appointment-calender-service-name">
                                            {appointment?.services?.map(
                                              (item, index) => (
                                                <div className="appointment-calender-service">
                                                  {item?.name}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </Link>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              ) : (
                                <div style={{ height: "50px" }}></div>
                              )}
                            </td>
                          );
                        })}
                        <span>{stamp.label}</span>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>,
              <GlobalEmptyDataHandler label="No appointments to show" />
            ),
            <GlobalErrorHandler label={error} />
          )
        )}
      </div>
    </div>
  );
};

export default AppointmentCalender;
