import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/LandingPage/Footer/Footer";
import "./style.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../../components/styled/BtnLoader/BtnLoader";
import { conditionalRender } from "../../utills/conditionalRender";
import { login } from "../../features/auth/authActions";
import { useNavigate } from "react-router-dom";
import Flex from "../../components/styled/Flex/Flex";
import { clearStateTemp } from "../../features/auth/authSlice";
import { Eye, EyeOff } from "react-feather";
import {
  BUSINESS_ADMIN,
  CLIENT,
  SUPER_ADMIN,
  TEAM_MEMBER,
  authorizeRole,
} from "../../utills/authorizeRole";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { loading, error } = useSelector((state) => state.authTemp);
  const { token, userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailPattern = {
    value: /^\S+@\S+\.\S+$/,
    message: "Not a valid email",
  };

  const handleLogin = (data) => {
    dispatch(login(data));
  };
  useEffect(() => {
    if (token) {
      if (authorizeRole(userData?.roles, SUPER_ADMIN)) {
        navigate("/admin-portal/dashboard");
      }
      if (
        authorizeRole(userData?.roles, BUSINESS_ADMIN) ||
        authorizeRole(userData?.roles, TEAM_MEMBER)
      ) {
        navigate("/portal/dashboard");
      }
      if (authorizeRole(userData?.roles, CLIENT)) {
        navigate("/client-portal/appointments");
      }
    }
  }, [token, dispatch, navigate, userData]);
  const [showPass, setShowPass] = useState(false);
  return (
    <div>
      <div className="max-width-1440 signup-main-container">
        <div className="signup-main-container-inner">
          <div className="signup-left">
            {/* <img src="/logo.png" alt="Logo" className="signup-logo" /> */}
            <img src="/signup/login-banner.png" alt="login page" />
          </div>
          <div className="signup-right">
            <Flex
              align="center"
              justify="center"
              className="signup-login-right-logo"
            >
              <Link to="/">
                <img src="/logo.png" alt="Logo" className="signup-logo" />
              </Link>
            </Flex>
            <h3>Login To Your Account</h3>

            <form className="signup-form" onSubmit={handleSubmit(handleLogin)}>
              <div className="signup-forms-input">
                <div className="input-group" style={{ marginBottom: "40px" }}>
                  <label htmlFor="email"> Email*</label>
                  <div className="input">
                    <input
                      type="email"
                      id="email"
                      {...register("email", {
                        required: "Please Enter your email",
                        pattern: emailPattern,
                      })}
                    />
                    <img src="/icons/mail.png" alt="" />
                    {errors.email && (
                      <p className="error">{errors.email.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="signup-forms-input">
                <div className="input-group">
                  <label htmlFor="password"> Password*</label>
                  <div className="input">
                    <input
                      type={`${showPass ? "text" : "password"}`}
                      id="password"
                      {...register("password", {
                        required: "Please Enter Your password",
                      })}
                    />
                    {/* <img src="/icons/mail.png" alt="" /> */}
                    <div className="password-view-icons">
                      {showPass ? (
                        <Eye
                          onClick={() => setShowPass(false)}
                          className="eye"
                          size={20}
                          color="#666"
                          cursor="pointer"
                        />
                      ) : (
                        <EyeOff
                          className="eye-off"
                          onClick={() => setShowPass(true)}
                          size={20}
                          color="#666"
                          cursor="pointer"
                        />
                      )}
                    </div>
                    {!error && errors.password && (
                      <p className="error">{errors.password.message}</p>
                    )}
                    <p className="forget-pass">
                      <Link to={"/password/reset"}>Forget Password?</Link>
                    </p>
                    {conditionalRender(
                      error,
                      <p className="error">{error}</p>,
                      null
                    )}
                  </div>
                </div>
              </div>

              <div className="submit-btn-outer">
                <button className="btn-outline" type="submit">
                  {conditionalRender(
                    loading,
                    <BtnLoader color="#3e0ffe" />,
                    "Login"
                  )}
                </button>
              </div>

              <p className="signup-text">
                Don’t have an account?{" "}
                <span>
                  <Link to="/signup?step=1" onClick={() => dispatch(clearStateTemp())}>
                    Sign Up
                  </Link>
                </span>
              </p>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Login;
