import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIntakeForm,
  handlePreConsultationForm,
  clearState,
} from "../../../../features/Websites/websitesSlice";
import PageLoader from "../../../../components/styled/PageLoader/PageLoader";
import { Controller, useForm } from "react-hook-form";
import Flex from "../../../../components/styled/Flex/Flex";
import { getCurrentDate } from "../../../../utills/prettifyDate";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import GlobalDropDown from "../../../../components/styled/Form/GlobalDropDown/GlobalDropDown";
import BtnLoader from "../../../../components/styled/BtnLoader/BtnLoader";
import { toast } from "react-toastify";
const Consultation = ({ data }) => {
  const {
    getLoading,
    getError,
    questions,
    consultationFormLoading,
    consultationFormSuccess,
    consultationFormError,
  } = useSelector((state) => state.websiteStyles);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIntakeForm({ url: data?.business_url }));
  }, [dispatch]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm();

  useEffect(() => {
    if (consultationFormSuccess) {
      toast.success("Consultation form submitted successfully");
      dispatch(clearState());
      reset(undefined);
    }
  }, [consultationFormSuccess, dispatch, reset]);

  const [selecetdAppointmentType, setSelectedAppointmentType] = useState(null);
  return (
    // <ClassicTemplateLayout>
    <div style={{ marginTop: "30px" }}>
      
      {getLoading ? (
        <PageLoader />
      ) : !getError ? (
        <div className="website-appointment-main-container">
          <div className="consultaion mb-30">
            <p className="consult-head">Pre-Consultation Form</p>
            <p className="consult-para">
            Complete the form and we will get back to you as soon as possible
            </p>
          </div>

          <form
            onSubmit={handleSubmit((values) => {
              dispatch(handlePreConsultationForm({ data: values }));
            })}
          >
            <div className="consultation-form-inputs">
              {questions?.is_full_name ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="name">Full Name*</label>
                  <div className="global-input-container-input">
                    <input
                      className="appointment-time-selector"
                      type={"text"}
                      {...register("name", {
                        required: questions?.is_full_name
                          ? "Please enter your name"
                          : false,
                      })}
                      id="name"
                    />
                  </div>
                  <p className="global-input-error">
                    {errors?.name && errors?.name?.message}
                  </p>
                </Flex>
              ) : null}

              {questions?.is_email ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="email">Email*</label>
                  <div className="global-input-container-input">
                    <input
                      className="appointment-time-selector"
                      type={"text"}
                      {...register("email", {
                        required: questions?.is_email
                          ? "Please Enter Your Email"
                          : false,
                      })}
                      id="email"
                    />
                  </div>
                  <p className="global-input-error">
                    {errors?.email && errors?.email?.message}
                  </p>
                </Flex>
              ) : null}
              {questions?.is_mobile_number ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="phone">Phone Number*</label>
                  <div className="global-input-container-input">
                    <input
                      className="appointment-time-selector"
                      type={"text"}
                      {...register("phone", {
                        required: questions?.is_mobile_number
                          ? "Please Enter Your Phone Number"
                          : false,
                      })}
                      id="phone"
                    />
                  </div>
                  <p className="global-input-error">
                    {errors?.phone && errors?.phone?.message}
                  </p>
                </Flex>
              ) : null}

              {/* <Flex
                className={`global-input-container consultation-form-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="phone">Phone Number*</label>
                <div className="global-input-container-input">
                  <input
                    className="appointment-time-selector"
                    type={"text"}
                    {...register("phone", {
                      required: "Please Enter Your Phone Number",
                    })}
                    id="phone"
                  />
                </div>
                <p className="global-input-error">
                  {errors?.phone && errors?.phone?.message}
                </p>
              </Flex> */}
              {questions?.is_date ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="date">Appoinment Date*</label>
                  <div className="global-input-container-input global-input-container-input-with-image">
                    <Controller
                      control={control}
                      name="date"
                      rules={{
                        required: questions?.is_date
                          ? "Please Select Appointment Date"
                          : false,
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => {
                              field.onChange(
                                date ? date.format("YYYY-MM-DD") : null
                              );
                            }}
                            format={"YYYY-MM-DD"}
                            // showTime
                            className="add-appointment-date-picker"
                            changeOnScroll
                            needConfirm={false}
                            minDate={dayjs(getCurrentDate())}
                          />
                        );
                      }}
                    />
                  </div>
                  <p className="global-input-error">
                    {errors?.date && errors?.date?.message}
                  </p>
                </Flex>
              ) : null}

              {questions?.is_service_type ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <GlobalDropDown
                    label="Service type"
                    options={[
                      { name: "Urgent", value: "urgent" },
                      { name: "Normal", value: "normal" },
                    ]}
                    stateHandler={selecetdAppointmentType}
                    setStateHandler={setSelectedAppointmentType}
                  />
                  <p className="global-input-error">
                    {errors?.phone && errors?.phone?.message}
                  </p>
                </Flex>
              ) : null}

              {questions?.is_long_hair ? (
                <Flex
                  className={`global-input-container consultation-form-input`}
                  direction="column"
                  gap={10}
                >
                  <label htmlFor="hair_length">How Long is Your Hair ?*</label>
                  <div className="global-input-container-input">
                    <input
                      className="appointment-time-selector"
                      type={"text"}
                      {...register("hair_length", {
                        required: questions?.is_long_hair ? "Required" : false,
                      })}
                      id="hair_length"
                    />
                  </div>
                  <p className="global-input-error">
                    {errors?.is_long_hair && errors?.is_long_hair?.message}
                  </p>
                </Flex>
              ) : null}
               {
              questions?.questions && questions.questions.map((item,index)=>{
                return  <Flex
                className={`global-input-container consultation-form-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor={`question-${index+1}`}>{item.question}</label>
                <div className="global-input-container-input">
                  <input
                    className="appointment-time-selector"
                    type={"text"}
                    {...register(`question-${index+1}`, {
                      required: false,
                    })}
                    id={`question-${index+1}`}
                  />
                </div>
                <p className="global-input-error">
                  {errors?.is_long_hair && errors?.is_long_hair?.message}
                </p>
              </Flex>
              })
            }
            </div>

            <Flex align="center" justify="flex-end">
              <p className="global-input-error">
                {consultationFormError && consultationFormError}
              </p>
              <button
                className="get-consultation-btn"
                style={{ borderColor: data?.color, color: data?.color }}
                type="submit"
              >
                {consultationFormLoading ? (
                  <BtnLoader color={data?.color} />
                ) : (
                  "Submit"
                )}
              </button>
            </Flex>
          </form>
        </div>
      ) : (
        <h1>{getError}</h1>
      )}
    </div>

    // </ClassicTemplateLayout>
  );
};

export default Consultation;
