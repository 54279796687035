import {createAsyncThunk,createSlice} from '@reduxjs/toolkit'
import axios from 'axios';
import {BASE_URL} from '../config'
const initialState = {
    loading:false,
    clients:null,
    error:null,
    addLoading:false,
    addError:null,
    addSuccess:false,
    delLoading:false,
    delError:null,
    delSuccess:false,
    updateLoading:false,
    updateSuccess:false,
    updateError:null,
}



export const adminClientsSlice = createSlice({
    name:"adminClientsSlice",
    initialState,
    reducers:{
        clearState:(state)=>{
            state.addError = null;
            state.delError = null;
            state.addSuccess = false;
            state.delSuccess = false;
            state.updateError = null;
            state.updateSuccess = false;
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(getAdminClients.pending,(state)=>{
            state.loading = true;
        })
        builder.addCase(getAdminClients.fulfilled,(state,action)=>{
            state.loading = false;
            state.clients = action.payload
        })
        builder.addCase(getAdminClients.rejected,(state,action)=>{
            state.loading = false;
            state.error = action.payload;
        })
    }

})


export const getAdminClients = createAsyncThunk("getAdminClients",async (apiData,{rejectWithValue})=>{
    try {
        if(!apiData.token){
            return rejectWithValue("Unauthorized please provide token")
        }

        const {data} = await axios.get(`${BASE_URL}/v1/clients/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,{
            headers:{
                'Authorization':`Bearer ${apiData.token}`
            }
        })
        return data.result
    } catch (error) {
        if(error?.response?.data?.message){
            return rejectWithValue(error?.response?.data?.message)
        }else{
            return rejectWithValue("Some error occured please try again later")
        }
    }
})