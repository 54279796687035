import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import {
  getAllSubscriptionInvoices,
  clearState,
  deleteSubscriptionInvoice,
} from "../../../features/ServiceProviderPortal/subscriptionInvoicesSlice";
import { handleUndefined } from "../../../utills/handleUndefined";
import { concatNames } from "../../../utills/concatNames";
import { prettifyDate } from "../../../utills/prettifyDate";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
const SubscriptionInvoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { loading, error, delLoading, delSuccess, delError, invoices } =
    useSelector((state) => state.subInvoices);

  useEffect(() => {
    if (token) {
      dispatch(
        getAllSubscriptionInvoices({
          token,
          query: { currentPage, itemsPerPage },
        })
      );
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
    }
  }, [token, dispatch, delSuccess, currentPage, itemsPerPage]);

  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteSubscriptionInvoice = () => {
    dispatch(deleteSubscriptionInvoice({ token, id: delId }));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div
      className="subscription-invoices-container outer"
      style={{ position: "relative" }}
    >
      <Flex
        align="center"
        justify="space-between"
        className="scheduling-main-container-head"
      >
        <h1 className="team-member-details-head">Subscription Invoices</h1>
        {/* <Flex align="center">
          <Link to="/portal/add-subscription-invoice">
            <Button label="Add Invoice" />
          </Link>
        </Flex> */}
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Name</TableHeadData>
                  <TableHeadData>Subscription</TableHeadData>
                  <TableHeadData>Total Amount</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>

              {conditionalRender(
                invoices?.items?.length > 0,
                <TableBody>
                  {invoices?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <Link
                            to={`/portal/subscription-invoice-details/${item?.id}`}
                          >
                            <TableText variant="link">
                              {handleUndefined(item?.id)}
                            </TableText>
                          </Link>
                        </TableBodyData>
                        <TableBodyData>
                          <Link
                            to={`/portal/client-details/${item?.client_id?.id}`}
                          >
                            <TableText variant="link">
                              {concatNames(
                                item?.client_id?.first_name,
                                item?.client_id?.last_name
                              )}
                            </TableText>
                          </Link>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>Super Pro</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>£{item?.total_amount}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.appointment_date)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            <Link
                              to={`/portal/edit-subscription-invoice/${item.id}`}
                            >
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                            <img
                              src="/icons/delete.png"
                              alt="edit"
                              onClick={() => {
                                setDelPopup(true);
                                setDelId(item.id);
                              }}
                            />
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No invoices to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                invoices?.items?.length > 0,
                invoices?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Name</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Link
                                to={`/portal/client-details/${item?.client_id?.id}`}
                              >
                                <PhoneTableSimpleText variant="link">
                                  {concatNames(
                                    item?.client_id?.first_name,
                                    item?.client_id?.last_name
                                  )}
                                </PhoneTableSimpleText>
                              </Link>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Subscription
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                Super Pro
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Total Amount
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{item?.total_amount}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.appointment_date)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex
                                align="center"
                                justify="flex-start"
                                gap={10}
                              >
                                {/* <Link
                                  to={`/portal/edit-subscription-invoice/${item.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link> */}
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelPopup(true);
                                    setDelId(item.id);
                                  }}
                                />
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {invoices?.meta?.totalItems > invoices?.meta?.itemsPerPage && !error && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={invoices?.meta?.totalPages}
        />
      )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteSubscriptionInvoice}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default SubscriptionInvoices;
