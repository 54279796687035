import React, { useEffect } from "react";
import "./Notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { readNotifications } from "../../../features/ServiceProviderPortal/notificationsSlice";
const Notifications = () => {
  const { error, notifications } = useSelector(
    (state) => state.notifications
  );
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (notifications) {
      dispatch(
        readNotifications({
          token,
          notifications: notifications.filter((not) => not.seen === 0),
        })
      );
    }
  }, [notifications]);

  return (
    <div className="outer">
      <h1 className="edit-client-heading">Notifications</h1>
      {!error ? (
        notifications?.map((item) => {
          return (
            <div style={{ marginBottom: "10px" }}>
              <NotificationBadge
                name={item?.username}
                message={item?.message}
              />
            </div>
          );
        })
      ) : (
        <>
          <h1>{error}</h1>
        </>
      )}
    </div>
  );
};

const NotificationBadge = ({ name, message }) => {
  return (
    <div className="notification-badge">
      <h2 className="notifiaction-sender-name">{name}</h2>
      <span className="notification-message">{message}</span>
    </div>
  );
};

export default Notifications;
