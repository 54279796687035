import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import { emailPattern } from "../../../utills/Schems";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
// import SubscriptionDrop from "../../../components/Portal/SubscriptionDrop/SubscriptionDrop";
import GlobalErrorHandler from '../../../components/styled/GlobalErrorHandler/GlobalErrorHandler'
import {
  clearState,
  getSingleBusinessRefferal,
  updateBusinessRefferal,
} from "../../../features/ServiceProviderPortal/businessReferralsSlice";

const EditBusinessReferral = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { id } = useParams();

  const { token } = useSelector((state) => state.auth);
  const {
    success,
    addLoading,
    addError,
    getLoading,
    getError,
    refferal,
  } = useSelector((state) => state.businessReferrals);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddBusinessRefferal = (values) => {
    values.amount = parseInt(values.amount);
    if (!subscriptionType) {
      setSubscriptionError("Please Select Subscription type");
    } else {
      values.subscription_type = subscriptionType.id;
      dispatch(updateBusinessRefferal({ token, data: { ...values }, id }));
    }
  };
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  
  useEffect(() => {
    if (success) {
      dispatch(clearState());
      navigate("/portal/business-referrals");
    }
  }, [success, dispatch, navigate]);
  useEffect(() => {
    if (token) {
      dispatch(getSingleBusinessRefferal({ token,id }));
    }
  }, [dispatch, token,id]);

  useEffect(()=>{
    if(refferal){
      reset({
        name:refferal?.name,
        email: refferal?.email
      })
    }
  },[refferal,reset])

  return (
    <div className="outer">
      <Link
        to={"/portal/business-referrals"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Update Business Referral</h1>
      {conditionalRender(
        getLoading,
        <PageLoader />,
        conditionalRender(
          !getError,
          <form onSubmit={handleSubmit(handleAddBusinessRefferal)}>
            <div className="add-business-refferal-inputs">
              <Flex
                className={`global-input-container add-business-refferal-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="name">Name *</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Enter Name"}
                    {...register("name", {
                      required: "Please Enter Name",
                    })}
                    id="name"
                  />
                  <img src="/icons/user.png" alt="email" />
                </div>
                <p className="global-input-error">
                  {errors?.name && errors?.name?.name}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-business-refferal-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="email">Email*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"email"}
                    placeholder={"Email"}
                    {...register("email", {
                      required: "Please Enter Email",
                      pattern: emailPattern,
                    })}
                    id="email"
                  />
                  <img src="/icons/mail.png" alt="email" />
                </div>
                <p className="global-input-error">
                  {errors?.email && errors?.name?.message}
                </p>
              </Flex>

              {/* <div className="add-business-refferal-input">
                <GlobalDropDown
                  stateHandler={subscriptionType}
                  setStateHandler={setSubscriptionType}
                  options={subscriptionDrop}
                  background="#F4F6F8"
                  label={"Select Subscription*"}
                  defaultId={refferal?.subscription_type}
                />

                <p className="global-input-error">
                  {subscriptionError && !subscriptionType && subscriptionError}
                </p>
              </div> */}
            </div>

            <Button
              label="Save"
              type="submit"
              loading={addLoading}
              loaderColor="#fff"
            />
          </form>,
        <GlobalErrorHandler label={getError}/>
        )
      )}
      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default EditBusinessReferral;
