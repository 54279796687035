import React, { useEffect, useState } from "react";
import Flex from "../../../../components/styled/Flex/Flex";
import Button from "../../../../components/styled/Button/Button";
import "./style.css";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../../components/styled/Table/Table";
import Popup from "../../../../components/styled/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { conditionalRender } from "../../../../utills/conditionalRender";
import PageLoader from "../../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import {
  getAllFeedBacks,
  updateFeedback,
  clearState,
  getSingleFeedBack,
} from "../../../../features/ServiceProviderPortal/feedbackSlice";
import { toast } from "react-toastify";
import { prettifyDate } from "../../../../utills/prettifyDate";
import Pagination from "../../../../components/styled/Pagination/Pagination";
import {handleUndefined} from '../../../../utills/handleUndefined'
const Testimonials = () => {
  const { token } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {
    loading,
    error,
    feedBacks,
    feedback,
    updateLoading,
    updateSuccess,
    getLoading,
  } = useSelector((state) => state.feedbacks);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(
        getAllFeedBacks({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (updateSuccess) {
      toast.success("Feedback updated successfully");
      dispatch(clearState());
      setActiveId(null);
    }
  }, [token, dispatch, currentPage, itemsPerPage, updateSuccess]);

  const [viewPopup, setViewPopup] = useState(false);
  const [activeId, setActiveId] = useState(null);
  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head">Testimonials</h1>
      </Flex>
      <div className="mt-30">
        {conditionalRender(
          loading,
          <PageLoader />,
          conditionalRender(
            !error,
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Name</TableHeadData>
                  <TableHeadData>Review</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>
              <TableBody>
                {conditionalRender(
                  feedBacks?.items?.length > 0,

                  feedBacks?.items?.map((item) => {
                    return (
                      <TableRow>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setViewPopup(true);
                                dispatch(
                                  getSingleFeedBack({ token, id: item?.id })
                                );
                              }}
                            >
                              <TableText variant="link">{item?.id}</TableText>
                            </div>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{handleUndefined(item?.business_id?.business_name)}</TableText>
                        </TableBodyData>
                        <TableBodyData className="testimonial-review">
                          <TableText>{item?.feedback}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="edit-testimonial-actions">
                          <Flex align="center" justify="center" gap={5}>
                            {item?.is_active ? (
                              <Button
                                label="Remove from website"
                                variant="outline"
                                handleClick={() => {
                                  setActiveId(item.id);
                                  dispatch(
                                    updateFeedback({
                                      token,
                                      data: { is_active: 0 },
                                      id: item?.id,
                                    })
                                  );
                                }}
                                loaderColor="#3E5FFE"
                                loading={updateLoading && item?.id === activeId}
                              />
                            ) : (
                              <Button
                                label="Add to website"
                                variant="outline"
                                handleClick={() => {
                                  setActiveId(item.id);
                                  dispatch(
                                    updateFeedback({
                                      token,
                                      data: { is_active: 1 },
                                      id: item?.id,
                                    })
                                  );
                                }}
                                loaderColor="#3E5FFE"
                                loading={updateLoading && item?.id === activeId}
                              />
                            )}
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  }),
                  <GlobalEmptyDataHandler label="No testimonials to show" />
                )}
              </TableBody>
            </Table>,
            <GlobalErrorHandler label={error} />
          )
        )}
        {feedBacks?.meta?.totalItems > feedBacks?.meta?.itemsPerPage &&
          !error && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={feedBacks?.meta?.totalPages}
            />
          )}
      </div>

      {viewPopup && (
        <Popup
          className="add-feedback-popup"
          popUp={viewPopup}
          setPopUp={setViewPopup}
        >
          {conditionalRender(
            getLoading,
            <PageLoader />,
            <form
              className="edit-client-lables-inputs"
              id="edit-voucher-lables-inputs"
            >
              <div className="lable-textarea-group lable-input-group">
                <label htmlFor="notes">Feedback</label>
                <div className="edit-client-icon-textarea">
                  <textarea
                    readOnly
                    name=""
                    id="notes"
                    cols="135"
                    rows="3"
                    placeholder="Message"
                    value={feedback?.feedback}
                  ></textarea>
                </div>
              </div>
              <Flex align="center" justify="flex-end" gap={10}>
                <Button
                  variant="outline"
                  label={"Close"}
                  handleClick={() => setViewPopup(false)}
                />
              </Flex>
            </form>
          )}
        </Popup>
      )}
    </div>
  );
};

export default Testimonials;
