import React, { useEffect, useState } from "react";
import "./ServiceProviderClients.css";
import Flex from "../../../components/styled/Flex/Flex";
import { Link } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useDispatch, useSelector } from "react-redux";
// import { getAdminClients } from "../../../features/SuperAdminPortal/clientsSlice";
import { getClients,deleteCLient, clearState } from "../../../features/ServiceProviderPortal/getClientsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { handleUndefined } from "../../../utills/handleUndefined";
import { ChevronDown } from "react-feather";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import Pagination from "../../../components/styled/Pagination/Pagination";
import { toast } from "react-toastify";
import Popup from "../../../components/styled/Popup/Popup";
const ServiceProviderClients = () => {
  const { loading, error, clients,deleteClientLoading,
    deleteClientSuccess, } = useSelector((state) => state.clients);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeResRow, setActiveResRow] = useState(1);
  useEffect(() => {
    if (token) {
      dispatch(getClients({ token, query: { currentPage, itemsPerPage } }));
    }
    if (deleteClientSuccess) {
      setClientPopUp(false);
      dispatch(clearState());
      toast.success("Client Deleted Successfully", {
        position: "top-right",
      });
    }
  }, [token, dispatch, currentPage, itemsPerPage, deleteClientSuccess]);

  const [clientPopUp, setClientPopUp] = useState(false);
  const [delId, setDelId] = useState(null);

  const handleDeleteClient = () => {
    const apiData = { token, id: delId };
    dispatch(deleteCLient(apiData));
    setDelId(null);
  };
  return (
    <div className="outer" style={{ position: "relative" }}>
      <div className="mt-20">
        <Flex align="center" justify="space-between">
          <h1 className="team-member-details-head">Clients</h1>
          <Flex align="center" gap={15}>
            {/* <Link to="/admin-portal/generate-invoice">
              <Button label="Generate Invoice" />
            </Link> */}
            <Link to="/admin-portal/add-client">
              <Button label="Add Client" />
            </Link>
          </Flex>
        </Flex>
      </div>

      <div className="mt-30">
        {conditionalRender(
          loading,
          <PageLoader />,
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Associated Service Provider</TableHeadData>
                  <TableHeadData>Number</TableHeadData>
                  <TableHeadData>Appointments</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                !error,
                conditionalRender(
                  clients?.items?.length > 0,
                  <TableBody>
                    {clients?.items &&
                      clients?.items.map((client, index) => {
                        return (
                          <TableRow key={index}>
                            <TableBodyData>
                              <Link
                                to={`/admin-portal/client-details/${client.id}`}
                              >
                                <TableText variant="link">{`${client.first_name} ${client.last_name}`}</TableText>
                              </Link>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>{client.email}</TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>{handleUndefined(client?.business_id?.business_name)}</TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>{client.phone}</TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <TableText>
                                {conditionalRender(
                                  client.total_appointment,
                                  client.total_appointment,
                                  "-"
                                )}
                              </TableText>
                            </TableBodyData>
                            <TableBodyData>
                              <Flex align="center" justify="center" gap={5}>
                                <Link to={`/admin-portal/edit-client/${client.id}`}>
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                                <Link>
                                  <img src="/icons/delete.png" alt="delete" onClick={()=>{setClientPopUp(true); setDelId(client?.id)}} />
                                </Link>
                              </Flex>
                            </TableBodyData>
                          </TableRow>
                        );
                      })}
                  </TableBody>,
                  <GlobalEmptyDataHandler label="No clients to show" />
                ),
                <GlobalErrorHandler label={error} />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                !error,
                conditionalRender(
                  clients?.items?.length > 0,
                  clients?.items?.map((client, index) => {
                    return (
                      <div key={index}>
                        <PhoneTable>
                          <PhoneTableHead
                            onClick={() => setActiveResRow(index + 1)}
                          >
                            <PhoneTableRow>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>Name</PhoneTableBoldText>
                              </PhoneTableHeadData>
                              <PhoneTableHeadData>
                                <Link
                                  to={`/admin-portal/client-details/${client.id}`}
                                >
                                  <PhoneTableBoldText>
                                    {handleUndefined(
                                      `${client.first_name} ${client.last_name}`
                                    )}
                                  </PhoneTableBoldText>
                                </Link>
                              </PhoneTableHeadData>
                            </PhoneTableRow>
                          </PhoneTableHead>
                          <PhoneTableBody
                            className={`${
                              index + 1 === activeResRow
                                ? "responsive-table-body-show"
                                : "responsive-table-body-hide"
                            }`}
                          >
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Email</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData isEmail={true}>
                                <PhoneTableSimpleText>
                                  {handleUndefined(client?.email)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData isEmail={true}>
                                <PhoneTableBoldText>
                                  Associated Service Provider
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData isEmail={true}>
                                <PhoneTableSimpleText>
                                  {client?.business_id?.business_name}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Number</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {client?.phone}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>
                                  Appointments
                                </PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(client?.total_appointment)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <Flex align="center" gap={10}>
                                  <img src="/icons/delete.png" alt="edit" onClick={()=>{setClientPopUp(true); setDelId(client?.id)}} />
                                  <Link to={`/admin-portal/edit-client/${client.id}`}>
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          </PhoneTableBody>
                        </PhoneTable>
                        <div className="global-responsive-table-chevron">
                          <ChevronDown
                            className={`${
                              index + 1 === activeResRow
                                ? "table-chevron-rotate"
                                : "table-chevron-rerotate"
                            }`}
                          />
                        </div>
                      </div>
                    );
                  }),
                  <GlobalEmptyDataHandler label={"No referrals To Show"} />
                ),
                <GlobalErrorHandler label={error} />
              )}
            </ResponsiveContainer>

            {clients?.meta?.totalItems > clients?.meta?.itemsPerPage &&
            !error && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={clients?.meta?.totalPages}
              />
            )}
          </div>
        )}
      </div>
      {clientPopUp && (
        <Popup
          className="client-popup"
          popUp={clientPopUp}
          setPopUp={setClientPopUp}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClient}
                loading={deleteClientLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setClientPopUp(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
    </div>
  );
};

export default ServiceProviderClients;
