import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  data: null,
  contactLoading: false,
  contactSuccess: false,
  contactError: null,
  getLoading: false,
  getError: null,
  questions: null,
  addLoading: false,
  addSuccess: false,
  addError: null,
  consultationFormLoading: false,
  consultationFormSuccess: false,
  consultationFormError: null,
  settings: null,
  getAvailalableDatesLoading:false,
  getAvailableDatesError:null,
  availableDates:null
};

export const websiteSLice = createSlice({
  name: "websiteSLice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.contactError = null;
      state.contactSuccess = false;
      state.consultationFormError = null;
      state.consultationFormSuccess = false;
      state.addError = null;
      state.addSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebsiteData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWebsiteData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.settings = action.payload.settings;
    });
    builder.addCase(getWebsiteData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(contactUs.pending, (state) => {
      state.contactLoading = true;
    });
    builder.addCase(contactUs.fulfilled, (state, action) => {
      state.contactLoading = false;
      state.contactSuccess = true;
    });
    builder.addCase(contactUs.rejected, (state, action) => {
      state.contactLoading = false;
      state.contactError = action.payload;
    });
    builder.addCase(getIntakeForm.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getIntakeForm.fulfilled, (state, action) => {
      state.getLoading = false;
      state.questions = action.payload;
    });
    builder.addCase(getIntakeForm.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
    builder.addCase(createAppointment.pending, (state) => {
      state.addLoading = true;
    });
    builder.addCase(createAppointment.fulfilled, (state) => {
      state.addLoading = false;
      state.addSuccess = true;
    });
    builder.addCase(createAppointment.rejected, (state, action) => {
      state.addLoading = false;
      state.addError = action.payload;
    });
    builder.addCase(handlePreConsultationForm.pending, (state) => {
      state.consultationFormLoading = true;
    });
    builder.addCase(handlePreConsultationForm.fulfilled, (state) => {
      state.consultationFormLoading = false;
      state.consultationFormSuccess = true;
    });
    builder.addCase(handlePreConsultationForm.rejected, (state, action) => {
      state.consultationFormLoading = false;
      state.consultationFormError = action.payload;
    });

    builder.addCase(getAvailableDates.pending,(state)=>{
      state.getAvailalableDatesLoading = true;
    });
    builder.addCase(getAvailableDates.fulfilled,(state,action)=>{
      state.getAvailalableDatesLoading = false;
      state.availableDates = action.payload
    });
    builder.addCase(getAvailableDates.rejected,(state,action)=>{
      state.getAvailalableDatesLoading = false;
      state.getAvailableDatesError = action.payload;
    });
  },
});

export const getWebsiteData = createAsyncThunk(
  "getWebsiteData",
  async (url, { rejectWithValue }) => {
    try {
      if (!url) {
        return rejectWithValue("Please provide url");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/website_design/business-website-data/${url}`
      );
      if (data.statusCode === 404) {
        return rejectWithValue("No website found with this URL");
      }
      return { data: data.result, settings: data.appointmentSetting };
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const contactUs = createAsyncThunk(
  "contactUs",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/contact-us`,
        apiData.data
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getIntakeForm = createAsyncThunk(
  "getIntakeForm",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/v1/intake_form/website/${apiData.url}`
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const createAppointment = createAsyncThunk(
  "createAppointment",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/appointments/web/create`,
        apiData.data
      );

      if (data?.statusCode === 409) {
        return rejectWithValue(data?.message);
      }
      console.log(data);
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const handlePreConsultationForm = createAsyncThunk(
  "handlePreConsultationForm",
  async (apiData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/pre-consultation`,
        apiData.data
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getAvailableDates = createAsyncThunk(
  "getAvailableDates",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData?.setting_id) {
        return rejectWithValue("PLease provide setting id");
      }
      if (!apiData?.date) {
        return rejectWithValue("Please provide date");
      }
      if (!apiData?.business_id) {
        return rejectWithValue("Please provide business id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/appointment_settings/working_hours?setting_id=${apiData.setting_id}&date=${apiData.date}&business_id=${apiData.business_id}`
      );
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = websiteSLice.actions;
