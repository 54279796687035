import React, { useState } from "react";
import { Link } from "react-router-dom";
import Flex from "../../../components/styled/Flex/Flex";
import { ArrowLeft } from "react-feather";
import ServiceProviderDropDown from "../../../components/Portal/ServiceProviderDropDown/ServiceProviderDropDown";
import Button from "../../../components/styled/Button/Button";

const EditReferral = () => {
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  return (
    <div className="outer">
      <Link to={"/client-portal/referrals"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Referral</h1>
      <form>
        <div className="add-appoinment-inputs" id="add-appoinment-inputs">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="">Name*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input type={"text"} placeholder={"Full Name"} id="name" />
              <img src="/icons/user.png" alt="icon" />
            </div>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="">Email*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"email"}
                placeholder={"m.iqbal@gmail.com"}
                id="email"
              />
              <img src="/icons/sms.png" alt="icon" />
            </div>
          </Flex>
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <ServiceProviderDropDown
              label="Service Provider"
              selectedServiceProvider={selectedServiceProvider}
              setSelectedServiceProvider={setSelectedServiceProvider}
              defaultSelected
            />
          </Flex>
        </div>
        <Flex className="mt-20" gap={20}>
          <Button
            variant="primary"
            label={"Save"}
            type="submit"
            loaderColor="#fff"
          />
          <Link to="/client-portal/referrals">
            <Button variant="outline" label={"Cancel"} />
          </Link>
        </Flex>
      </form>
    </div>
  );
};

export default EditReferral;
