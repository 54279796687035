import React, { useEffect, useState, useMemo } from "react";
import "./style.css";
import AreaChart from "../../../components/Portal/Charts/AreaChart";
import PortalDrop from "../../../components/Portal/PortalDrop/PortalDrop";
import PortalPieChart from "../../../components/Portal/Charts/PieChart";
import { conditionalRender } from "../../../utills/conditionalRender";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../features/ServiceProviderPortal/dashboardSlice";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { getMonthName, getYears } from "../../../utills/prettifyDate";
import "swiper/css";
import "swiper/css/pagination";
import { groupServicesByCategory } from "../../../utills/conversion";

const Dashboard = () => {
  const { token ,userData} = useSelector((state) => state.auth);
  const { loading, data, error } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [graphMode, setGraphMode] = useState("revenue");
  const [year,setYear] = useState(new Date().getFullYear())
  useEffect(() => {
    if (token) {
      dispatch(getDashboardData({ token,query:{year} }));
    }
  }, [token, dispatch,year]);
  const graphData = useMemo(() => {
    if (data?.graphData_revenue && data?.graphData_reservation) {
      if (graphMode === "revenue") {
        const filteredData = [];
        data?.graphData_revenue.map((item) => {
          filteredData.push({
            name: getMonthName(item.month),
            value: item.total,
          });
        });
        return { data: filteredData, name: "Revenue" };
      }
      if (graphMode === "reservations") {
        const filteredData = [];
        data?.graphData_reservation.map((item) => {
          filteredData.push({
            name: getMonthName(item.month),
            value: item.count,
          });
        });
        return { data: filteredData, name: "Appointments" };
      }
    }
  }, [graphMode, data]);

  const revenue_by_services = useMemo(() => {
    if (data?.revenue_by_services) {
      return groupServicesByCategory(data?.revenue_by_services);
    }
  }, [data?.revenue_by_services]);
  const years = useMemo(()=>{
    const years =  getYears(userData?.business_id?.created_at)
    return years
  },[userData?.business_id?.created_at])
  return (
    <div className="portal-dashboard" style={{ minHeight: "100vh" }}>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <div className="dashboard-stats-boxes">
              <div className="dashboard-stats-box">
                <h3>Total Appointments</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead
                    variant="blue"
                    text={
                      parseInt(data?.upcomming) +
                      parseInt(data?.noshow) +
                      parseInt(data?.completed) +
                      parseInt(data?.cancelled)
                    }
                  />
                </div>
              </div>
             

              <div className="dashboard-stats-box">
                <h3>Upcoming appointments</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead text={data?.upcomming} />
                </div>
              </div>
              <div className="dashboard-stats-box">
                <h3>Booked appointments</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead text={data?.completed} />
                </div>
              </div>
              <div className="dashboard-stats-box">
                <h3>Total Revenue</h3>
                <div className="dashboard-stats-inner">
                  <StatsHead text={data?.total_revenue} dollar={true} />
                </div>
              </div>
            </div>

            <div className="portal-dashboard-lower-section">
              <div className="portal-dashboard-left-side">
                <div className="portal-dashboard-left-side-inner">
                  <div className="portal-dashboard-left-side-top">
                    <DashboardHeading>Analytics</DashboardHeading>
                    <PortalDrop options={years} active={year} setAcvtive={setYear}/>
                  </div>
                  <div className="graph-outer">
                    <div className="portal-dashboard-area-chart-top">
                      {
                        graphMode === 'revenue' ?<span>Revenue</span>:<span>Appointments</span>
                      }
                      
                      <div className="portal-dashboard-area-chart-top-inputs">
                        <div className="portal-dashboard-area-chart-top-input">
                          <input
                            type="checkbox"
                            id="revenue"
                            checked={graphMode === "revenue"}
                            onChange={(e) => {
                              setGraphMode("revenue");
                            }}
                          />
                          <label htmlFor="revenue">Revenue</label>
                        </div>
                        <div className="portal-dashboard-area-chart-top-input">
                          <input
                            type="checkbox"
                            id="profit"
                            checked={graphMode === "reservations"}
                            onChange={(e) => {
                              setGraphMode("reservations");
                            }}
                          />
                          <label htmlFor="profit">Appointments</label>
                        </div>
                      </div>
                    </div>
                    <div className="portal-dashboard-area-chart">
                      {graphData?.data && (
                        <AreaChart
                          data={graphData.data}
                          name={graphData.name}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <DashboardHeading>Revenue by Services</DashboardHeading>
                {revenue_by_services && revenue_by_services?.length > 0 && (
                  <div className="revenue-by-service-boxes">
                    <Swiper
                      slidesPerView={revenue_by_services.length === 1 ? 1 : 2}
                      spaceBetween={50}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        1000: {
                          slidesPerView:
                            revenue_by_services.length === 1 ? 1 : 2,
                        },
                      }}
                      // loop={true}
                      keyboard={{
                        enabled: true,
                      }}
                      pagination={{
                        dynamicBullets: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {revenue_by_services &&
                        revenue_by_services.map((item) => {
                          return (
                            <SwiperSlide>
                              <RevenueByService
                                label={item.category_name}
                                options={item.services?.map(
                                  (i) => ({
                                    title: i.name,
                                    value: i.cost,
                                  })
                                )}
                              />
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </div>
                )}
              </div>

              <div className="portal-dashboard-right-side">
                <div className="portal-dashboard-piechart">
                  <PortalPieChart dashData={data} />
                </div>

                <div className="portal-today-bookings">
                  <div className="portal-today-bookings-heading">
                    <DashboardHeading>Total Bookings</DashboardHeading>
                  </div>
                  <div className="portal-today-bookings-inner">
                    {data?.total_appointment_by_services?.map((item) => {
                      return (
                        <div className="today-bookings-badge-top-left">
                          <DashboardHeading>
                            {item?.serviceName}
                          </DashboardHeading>
                          <span>{item?.appointmentCount}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>,
          <h1>{error}</h1>
        )
      )}
    </div>
  );
};

function StatsHead({ variant = "black", text, dollar = false }) {
  return (
    <h2 style={{ color: `${variant === "blue" ? "#5459FF" : "#25233C"}` }}>
      {dollar ? "£" : ""}
      {text}
    </h2>
  );
}



function DashboardHeading({ children }) {
  return <h2 className="dashboard-heading">{children}</h2>;
}

function RevenueByService({ label, options }) {
  return (
    <div className="revenue-by-service-box">
      <div className="revenue-by-service-head">
        <span>{label}</span>
      </div>
      <div className="revenue-by-service-content">
        {options.map((option, index) => {
          return (
            <div className="revenue-by-service-option">
              <span>{option.title}</span>
              <span>£{option.value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dashboard;
