import React, { useEffect, useState } from "react";
import SideBar from "../../../components/Portal/Sidebar/SideBar";
import SideBarSingleMenu from "../../../components/Portal/Sidebar/SideBarSingleMenu/SideBarSingleMenu";
import SideBarDropdownMenu from "../../../components/Portal/Sidebar/SideBarDropdownMenu/SideBarDropdownMenu";
import Navbar from "../../../components/Portal/Navbar/Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import "./style.css";
import { useLocation } from "react-router-dom";
import { getActiveTab } from "../../../utills/activeTabs";
import { useSelector } from "react-redux";
import {
  SUPER_ADMIN,
  TEAM_MEMBER,
  authorizeRole,
  checkIfPermittedToSingleTab,
  handlePaymentAndConnectAccountRedirection,
} from "../../../utills/authorizeRole";
import { useDispatch } from "react-redux";
import { getUserPermissions } from "../../../features/ServiceProviderPortal/accessLevelsSlice";
import { getUserData } from "../../../features/auth/authSlice";
import { getAllNotifications } from "../../../features/ServiceProviderPortal/notificationsSlice";
const PortalLayout = () => {
  const location = useLocation();
  const [sideBarActive, setSideBarActive] = useState(false);
  const { userData, token } = useSelector((state) => state.auth);
  const { getError, permissions } = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (authorizeRole(userData.roles, SUPER_ADMIN)) {
      navigate("/admin-portal/dashboard");
    }
  }, [userData, navigate]);
  useEffect(() => {
    if (token && authorizeRole(userData.roles, TEAM_MEMBER)) {
      dispatch(getUserPermissions({ token }));
    }
  }, [token, dispatch]);
  useEffect(() => {
    if (token) {
      dispatch(getAllNotifications({ token }));
      const intervalId = setInterval(() => {
        dispatch(getAllNotifications({ token }));
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (userData) {
      const response = handlePaymentAndConnectAccountRedirection(userData);
      if (!response.ok) {
        navigate(response.redirect);
      }
    }
  }, [navigate, userData]);

  useEffect(() => {
    if (token) {
      dispatch(getUserData());
    }
  }, [dispatch, token]);

  if (getError) {
    return <h1>{getError}</h1>;
  }
  return (
    <div>
      <Navbar
        setSideBarActive={setSideBarActive}
        notificationsLink="/portal/notifications"
        profileSettingsLink="/portal/profile-settings"
      />
      <SideBar
        sideBarActive={sideBarActive}
        setSideBarActive={setSideBarActive}
      >
        <SideBarSingleMenu
          label="Dashboard"
          link="/portal/dashboard"
          isActive={getActiveTab(location.pathname, ["/portal/dashboard"])}
          setSideBarActive={setSideBarActive}
        />

        {!(
          !checkIfPermittedToSingleTab(permissions, 29, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 38, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 39, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 40, userData?.roles)
        ) ? (
          <SideBarDropdownMenu
            label="Business and Website settings"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/services",
              "/portal/service-category",
              "/portal/website-design",
              "/portal/payments-invoices",
              "/portal/custom-intake-form",
              "/portal/add-service",
              "/portal/appoinment-setting",
              /^\/portal\/edit-service\/\d+$/,
              
            ])}
            options={[
              checkIfPermittedToSingleTab(permissions, 38, userData?.roles) && {
                title: "Services",
                link: "/portal/services",
                isActive: getActiveTab(location.pathname, [
                  "/portal/services",
                  "/portal/service-category",
                  /^\/portal\/edit-service\/\d+$/,
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 39, userData?.roles) && {
                title: "Website design",
                link: "/portal/website-design",
                isActive: getActiveTab(location.pathname, [
                  "/portal/website-design",
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 40, userData?.roles) && {
                title: "Custom Intake Form",
                link: "/portal/custom-intake-form",
                isActive: getActiveTab(location.pathname, [
                  "/portal/custom-intake-form",
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 28, userData?.roles) && {
                title: "Appointment Settings",
                link: "/portal/appoinment-setting",
                isActive: getActiveTab(location.pathname, [
                  "/portal/appoinment-setting",
                ]),
              }
            ]}
            dropHeight="300px"
          />
        ) : null}

        {!(
          !checkIfPermittedToSingleTab(permissions, 9, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 16, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 25, userData?.roles)
        ) ? (
          <SideBarDropdownMenu
            label="Client Management"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/clients",
              "/portal/vouchers",
              "/portal/invoices",
              "/portal/add-client",
              "/portal/create-invoice",
              "/portal/send-voucher",
              /^\/portal\/edit-client\/\d+$/,
              /^\/portal\/client-details\/\d+$/,
              /^\/portal\/edit-voucher\/\d+$/,
              /^\/portal\/voucher-details\/\d+$/,
              /^\/portal\/invoice-details\/\d+$/,
              /^\/portal\/edit-invoice\/\d+$/,
            ])}
            options={[
              checkIfPermittedToSingleTab(permissions, 9, userData?.roles)
                ? {
                    title: "Clients",
                    link: "/portal/clients",
                    isActive: getActiveTab(location.pathname, [
                      "/portal/clients",
                      "/portal/add-client",
                      /^\/portal\/edit-client\/\d+$/,
                      /^\/portal\/client-details\/\d+$/,
                    ]),
                  }
                : null,
              checkIfPermittedToSingleTab(permissions, 16, userData?.roles) && {
                title: "Vouchers",
                link: "/portal/vouchers",
                isActive: getActiveTab(location.pathname, [
                  "/portal/vouchers",
                  "/portal/send-voucher",
                  /^\/portal\/edit-voucher\/\d+$/,
                  /^\/portal\/voucher-details\/\d+$/,
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 25, userData?.roles) && {
                title: "Customer Invoices & Payments",
                link: "/portal/invoices",
                isActive: getActiveTab(location.pathname, [
                  "/portal/invoices",
                  "/portal/create-invoice",
                  /^\/portal\/invoice-details\/\d+$/,
                  /^\/portal\/edit-invoice\/\d+$/,
                ]),
              },
            ]}
            dropHeight="200px"
          />
        ) : null}

        {!(
          !checkIfPermittedToSingleTab(permissions, 5, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 28, userData?.roles)
        ) ? (
          <SideBarDropdownMenu
            label="Appointments"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/appointments",
              "/portal/appoinment-details",
              "/portal/appoinment-settings",
              "/portal/add-appointment",
              /^\/portal\/appoinment-detail\/\d+$/,
              /^\/portal\/edit-appointment\/\d+$/,
            ])}
            options={[
              checkIfPermittedToSingleTab(permissions, 5, userData?.roles) && {
                title: "Appointments",
                link: "/portal/appointments",
                isActive: getActiveTab(location.pathname, [
                  "/portal/appointments",
                  "/portal/appoinment-details",
                  "/portal/add-appointment",
                  /^\/portal\/appoinment-detail\/\d+$/,
                  /^\/portal\/edit-appointment\/\d+$/,
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 28, userData?.roles) && {
                title: "Appointment Settings",
                link: "/portal/appoinment-setting",
                isActive: getActiveTab(location.pathname, [
                  "/portal/appoinment-setting",
                ]),
              },
            ]}
            dropHeight="150px"
          />
        ) : null}

        {!(
          !checkIfPermittedToSingleTab(permissions, 41, userData?.roles) &&
          !checkIfPermittedToSingleTab(permissions, 53, userData?.roles)
        ) ? (
          <SideBarDropdownMenu
            label="Subscriptions"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/subscriptions",
              "/portal/subscription-invoices",
              "/portal/add-subscription",
              "/portal/add-subscription-invoice",
              /^\/portal\/edit-subscription\/\d+$/,
              /^\/portal\/subscription-invoice-details\/\d+$/,
            ])}
            options={[
              checkIfPermittedToSingleTab(permissions, 41, userData?.roles) && {
                title: "Subscriptions",
                link: "/portal/subscriptions",
                isActive: getActiveTab(location.pathname, [
                  "/portal/subscriptions",
                  "/portal/add-subscription",
                  /^\/portal\/edit-subscription\/\d+$/,
                ]),
              },
              checkIfPermittedToSingleTab(permissions, 53, userData?.roles) && {
                title: "Subscription Invoices",
                link: "/portal/subscription-invoices",
                isActive: getActiveTab(location.pathname, [
                  "/portal/add-subscription-invoice",
                  "/portal/subscription-invoices",
                  /^\/portal\/subscription-invoice-details\/\d+$/,
                ]),
              },
            ]}
            dropHeight="100px"
          />
        ) : null}

        <SideBarSingleMenu
          label="Profile Settings"
          link="/portal/profile-settings"
          isActive={getActiveTab(location.pathname, [
            "/portal/profile-settings",
          ])}
          setSideBarActive={setSideBarActive}
        />
        {checkIfPermittedToSingleTab(permissions, 54, userData?.roles) && (
          <SideBarDropdownMenu
            label="Referrals"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/client-referrals",
              "/portal/business-referrals",
              "/portal/add-client-referrals",
              "/portal/add-business-referrals",
              /^\/portal\/edit-client-referrals\/\d+$/,
              /^\/portal\/edit-business-referral\/\d+$/,
            ])}
            options={[
              {
                title: "Business Referrals",
                link: "/portal/business-referrals",
                isActive: getActiveTab(location.pathname, [
                  "/portal/business-referrals",
                  "/portal/add-business-referrals",
                  /^\/portal\/edit-business-referral\/\d+$/,
                ]),
              },
            ]}
            dropHeight="100px"
          />
        )}
        {checkIfPermittedToSingleTab(permissions, 64, userData?.roles) && (
          <SideBarSingleMenu
            label="Integrations"
            link="/portal/integrations"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, ["/portal/integrations"])}
          />
        )}

        {/* {authorizeRole(userData.roles, "Business Admin") ? (
          <SideBarSingleMenu
            label="Team Access Levels"
            link="/portal/access-levels"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/access-levels",
              "/portal/manage-access-levels",
            ])}
          />
        ) : null} */}

        {checkIfPermittedToSingleTab(permissions, 29, userData?.roles) ? (
          <SideBarDropdownMenu
            label="Manage Team"
            setSideBarActive={setSideBarActive}
            isActive={getActiveTab(location.pathname, [
              "/portal/access-levels",
              "/portal/manage-access-levels",
              "/portal/team",
              "/portal/job-title",
              "/portal/add-team-member",
              /^\/portal\/team\/details\/\d+$/,
              /^\/portal\/edit-team-member\/\d+$/,
             
            ])}
            options={[
              {
                title: "Team members",
                link: "/portal/team",
                isActive: getActiveTab(location.pathname, [
                  "/portal/team",
                  "/portal/job-title",
                  /^\/portal\/team\/details\/\d+$/,
                  /^\/portal\/edit-team-member\/\d+$/,
                ]),
              },
              authorizeRole(userData.roles, "Business Admin") && {
                title: "Manage team access",
                link: "/portal/access-levels",
                isActive: getActiveTab(location.pathname, [
                  "/portal/access-levels",
                  "/portal/manage-access-levels",
                ]),
              },
            ]}
            dropHeight="150px"
          />
        ) : null}

        {checkIfPermittedToSingleTab(permissions, 63, userData?.roles) && (
          <SideBarSingleMenu
            label="Feedback"
            link="/portal/feedback"
            isActive={getActiveTab(location.pathname, ["/portal/feedback"])}
            setSideBarActive={setSideBarActive}
          />
        )}
        <SideBarSingleMenu
          label="Client Reviews"
          link="/portal/client-reviews"
          isActive={getActiveTab(location.pathname, ["/portal/client-reviews"])}
          setSideBarActive={setSideBarActive}
        />
      </SideBar>
      <div className="portal-layout-right-side">
        <Outlet />
      </div>
      <div
        className={`portal-blackout-screen ${
          sideBarActive ? "portal-blackout-show" : "portal-blackout-hide"
        }`}
      ></div>
    </div>
  );
};

export default PortalLayout;
