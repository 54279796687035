import React from "react";
import "./Toggle.css";

const Toggle = ({ label, id, register, name, reverse = false, checked }) => {
  if (reverse) {
    return (
      <div className="toggle-btn toggle-btn-reverse">
        <label className="toggle-label" htmlFor={id}>
          {label}
        </label>
        <div className="toggle">
          <input
            type="checkbox"
            id={id}
            {...register(name)}
            defaultChecked={checked}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="toggle-btn">
      <div className="toggle">
        <input type="checkbox" id={id} {...register(name)} />
      </div>
      <label className="toggle-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Toggle;
