
import CryptoJS from 'crypto-js';

export const encryptObject = (obj) => {
    let encJson = CryptoJS.AES.encrypt(JSON.stringify(obj), 'Secret').toString()
    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
    return encData
  };
 export const decryptObject = (encryptedString) => {
    let decData = CryptoJS.enc.Base64.parse(encryptedString).toString(CryptoJS.enc.Utf8)
    let bytes = CryptoJS.AES.decrypt(decData, 'Secret').toString(CryptoJS.enc.Utf8)
    return JSON.parse(bytes)
  };