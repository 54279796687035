import React, { useEffect, useState } from "react";
import "./AddAppointment.css";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import {
  convertToAMPM,
  getCurrentDate,
  getDayOfWeek,
} from "../../../utills/prettifyDate";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  addAppointment,
} from "../../../features/ClientPortal/appointmentSlice";
import { toast } from "react-toastify";
import Tag from "../../../components/styled/Tag/Tag";
import { getAppointemtSettings } from "../../../features/ServiceProviderPortal/appointmentSettingsSlice";
import { isAppointmentAllowed } from "../../../utills/appointments";
// import { addAppoinment } from "../../../features/ServiceProviderPortal/appoinmentSlice";

const AddAppointment = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [dropError, setDropError] = useState(null);
  const navigate = useNavigate();
  const settingsState = useSelector((state) => state.appointmentSettings);
  const { addLoading, addSuccess } = useSelector(
    (state) => state.appointments
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      date: new Date(Date.now()),
    },
  });

  useEffect(() => {
    if (token) {
      dispatch(getAppointemtSettings(token));
    }
  }, [token, dispatch]);
  // const handleAddAppointment = (values) => {
  //   if (!selectedServices.length > 0) {
  //     setDropError("Please select service");
  //   } else {
  //     let apiData = {};
  //     apiData.status = 23;
  //     const services = selectedServices.map((service) => service.id);
  //     apiData.services = services;
  //     apiData.date = values.date;
  //     apiData.time = values.time;
  //     // apiData.client_id = userData.id;
  //     // apiData.user_id = 180;
  //     dispatch(addAppointment({ token, data: { ...apiData } }));
  //   }
  // };
  const handleAddAppointment = (values) => {
    if (!selectedServices.length > 0) {
      setDropError("Please Select");
    } else {
      let apiData = {};
      apiData.status = 23;
      apiData.time = values.time;
      apiData.name = values.name;
      apiData.date = values.date;
      apiData.notes = values.notes;
      const services = selectedServices.map((service) => service.id);
      apiData.services = services;
      if (
        settingsState.settings?.prevent_booking ||
        settingsState?.settings?.min_day_before_booking > 0
      ) {
        const user_selected_date_str = values.date;
        const user_selected_time_str = values.time;
        const user_selected_date = new Date(user_selected_date_str);
        const [hours, minutes] = user_selected_time_str.split(":").map(Number);
        user_selected_date.setHours(hours);
        user_selected_date.setMinutes(minutes);
        user_selected_date.setSeconds(0);
        const total_preventing_hours =
          settingsState?.settings?.prevent_booking +
          settingsState?.settings?.min_day_before_booking * 24;
        if (isAppointmentAllowed(total_preventing_hours, user_selected_date)) {
          dispatch(addAppointment({ token, data: { ...apiData } }));
        } else {
          toast.error(
            `You can't make appointment ${
              settingsState.settings.prevent_booking +
              settingsState?.settings?.min_day_before_booking * 24
            } hours after current time`
          );
        }
      } else {
        dispatch(addAppointment({ token, data: { ...apiData } }));
      }
    }
  };

  useEffect(() => {
    if (addSuccess) {
      toast.success("Appointment added Successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/client-portal/appointments");
    }
  }, [addSuccess, navigate, dispatch]);
  const timeValidation = (value) => {
    const day = getDayOfWeek();
    const currentDay = settingsState?.settings?.working_hours?.filter(
      (d) => d.day === day
    );
    if (currentDay?.length > 0) {
      const selectedTime = value;
      const minTime = currentDay[0].opening_time;
      const maxTime = currentDay[0].close_time;
      if (selectedTime < minTime || selectedTime > maxTime) {
        return (
          "Please select a time between " +
          convertToAMPM(minTime) +
          " and " +
          convertToAMPM(maxTime)
        );
      }

      return true;
    } else {
      return true;
    }
  };
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  return (
    <div className="outer">
      <Link to={"/client-portal/appointments"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Request Appointment</h1>
      <form onSubmit={handleSubmit(handleAddAppointment)}>
        <div className="add-appoinment-inputs mb-20" id="add-appoinment-inputs">
          <Flex
            className={`global-input-container add-team-member-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="">Date*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ReactDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    minDate={getCurrentDate()}
                    excludeDates={settingsState?.settings?.unavailable_dates?.map(
                      (dateString) => new Date(dateString)
                    )}
                  />
                )}
              />

              <img src="/icons/calendar.png" alt="icon" />
            </div>
            <p className="global-input-error">
              {errors?.date && errors?.date?.message}
            </p>
          </Flex>
          <Flex
            className={`global-input-container add-appoinment-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="time">Appointment Time*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                className="appointment-time-selector"
                type={"time"}
                {...register("time", {
                  required: "Please Select Appointment Time",
                  validate: timeValidation,
                })}
                id="time"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            <p className="global-input-error">
              {errors?.time && errors?.time?.message}
            </p>
          </Flex>
        </div>
        <div className="add-team-member-input">
          <ServicesDropDown
            label="Select Service*"
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            handleChange={(service) => {
              if (selectedServices.find((m) => m.id === service.id)) {
                return;
              } else {
                setSelectedServices((prevData) => [...prevData, service]);
              }
            }}
          />
          <p className="global-input-error">
            {dropError && !selectedService && dropError}
          </p>
        </div>
        <div className="selected-team-members selected-services-in-appointments">
          {selectedServices && selectedServices.length > 0
            ? selectedServices.map((service, index) => {
                return (
                  <Tag
                    onClick={() => {
                      const updatedMembers = selectedServices.filter(
                        (m) => m.id !== service.id
                      );
                      setSelectedServices(updatedMembers);
                    }}
                    key={index}
                  >
                    {service?.name}
                  </Tag>
                );
              })
            : null}
        </div>
        <div className="edit-client-btns">
          <Button
            variant="primary"
            label={"Add Appointment"}
            type="submit"
            loaderColor="#fff"
            loading={addLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddAppointment;
