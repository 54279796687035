import React, { useEffect } from "react";
import "../../../LandingPageSection/ContactUs/Contact.css";
import "./style.css";
import Flex from "../../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../../../../components/styled/BtnLoader/BtnLoader";
import {
  contactUs,
  clearState,
} from "../../../../features/Websites/websitesSlice";
import { useForm } from "react-hook-form";
import { emailPattern } from "../../../../utills/Schems";
import { toast } from "react-toastify";
import { useFileHandler } from "6pp";
import {
  updateWebsiteDesign,
  clearState as updateClearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import Button from "../../../../components/styled/Button/Button";
const Contact = ({ color, data, isEditAccess }) => {
  const { contactLoading, contactSuccess, contactError } = useSelector(
    (state) => state.websiteStyles
  );
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (contactSuccess) {
      toast.success("Email Sent Successfully");
      reset(undefined);
      dispatch(clearState());
    }
  }, [contactSuccess, reset, dispatch]);
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website Updated Successfully");
      dispatch(updateClearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(updateClearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  const contact_us_image = useFileHandler("single");
  return (
    <div className="max-width-1440 contact">
      <div className="contact-inner">
        <div className="contact-left">
          <img
            src={
              contact_us_image.file
                ? contact_us_image.preview
                : data?.contact_us_image
                ? data?.contact_us_image
                : "/templates/contact.png"
            }
            alt="Contact"
          />
          {isEditAccess && (
            <div className="update-banner-image-input" style={{ zIndex: 100 }}>
              <label htmlFor="banner-image">
                <div
                  className="update-banner-image"
                  style={{
                    color: "#000",
                    borderColor: "#000",
                    cursor: "pointer",
                  }}
                >
                  Update Contact Image
                </div>
              </label>
              <input
                type="file"
                id="banner-image"
                onChange={contact_us_image.changeHandler}
              />
            </div>
          )}
          {
            isEditAccess && (
              <form
              onSubmit={(e) => {
                e.preventDefault();
                const apiData = {};
                if (contact_us_image) {
                  apiData.contact_us_image = contact_us_image.preview;
                } else {
                  apiData.contact_us_image = "/templates/contact.png";
                }
                dispatch(updateWebsiteDesign({ data: apiData }));
              }}
            >
              <Button
                label="Save Changes"
                type="submit"
                loading={updateLoading}
                loaderColor="#fff"
              />
            </form>
            )
          }
         
        </div>
        <div className="website-contact">
          <h2 className="getInTouch">Connect with us</h2>
          <p className="contact-para">
            We value your engagement and look forward to hearing from you
          </p>
          <form
            onSubmit={handleSubmit((values) => {
              values.business_id = data.business_id.id;
              dispatch(contactUs({ data: values }));
            })}
          >
            <div className="website-contact-inputs">
              <Flex
                className={`global-input-container website-contact-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="first_name">First Name*</label>
                <div className="global-input-container-input">
                  <input
                    type={"text"}
                    {...register("first_name", {
                      required: "Please Enter First Name",
                      maxLength: {
                        value: 15,
                        message: "Not should be more then 15 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                    })}
                    id="first_name"
                  />
                </div>
                <p className="global-input-error">
                  {errors?.first_name && errors?.first_name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container website-contact-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="last_name">Last Name*</label>
                <div className="global-input-container-input">
                  <input
                    type={"text"}
                    {...register("last_name", {
                      required: "Please Enter First Name",
                      maxLength: {
                        value: 15,
                        message: "Not should be more then 15 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                    })}
                    id="last_name"
                  />
                </div>
                <p className="global-input-error">
                  {errors?.last_name && errors?.last_name?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container website-contact-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="email">Email*</label>
                <div className="global-input-container-input">
                  <input
                    type={"text"}
                    {...register("email", {
                      required: "Please Enter Your Email",
                      maxLength: {
                        value: 40,
                        message: "Not should be more then 20 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                      pattern: emailPattern,
                    })}
                    id="email"
                  />
                </div>
                <p className="global-input-error">
                  {errors?.email && errors?.email?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container website-contact-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="phone">Phone*</label>
                <div className="global-input-container-input">
                  <input
                    type={"number"}
                    {...register("phone", {
                      required: "Please Enter Your Phone number",
                      maxLength: {
                        value: 15,
                        message: "Not should be more then 15 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then three characters",
                      },
                    })}
                    id="phone"
                  />
                </div>
                <p className="global-input-error">
                  {errors?.phone && errors?.phone?.message}
                </p>
              </Flex>
            </div>
            <div className="label-input">
              <label htmlFor="message">Message</label>
              <div className="icon-textarea" style={{ background: "#f4f6f8" }}>
                <textarea
                  name="message"
                  id="message"
                  rows={3}
                  cols={67}
                  style={{ background: "#f4f6f8" }}
                  {...register("message", {
                    required: "Please Enter Messsage",
                    maxLength: {
                      value: 150,
                      message: "Should not be more then 150 characters",
                    },
                    minLength: {
                      value: 10,
                      message: "Should Not be less then 10 characters",
                    },
                  })}
                ></textarea>
              </div>
              <p className="global-input-error">
                {errors?.message && errors?.message?.message}
              </p>
            </div>
            <Flex align="center" justify="space-between">
              <button
                className="website-contact-btn"
                style={{ color, border: `1px solid ${color}` }}
                type="submit"
              >
                {contactLoading ? <BtnLoader color={color} /> : "Submit"}
              </button>
              <p className="global-input-error">
                {contactError && contactError}
              </p>
            </Flex>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
