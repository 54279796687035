import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, globalClearState, globalInitialState } from "../config";

const initialState = {
  ...globalInitialState,
  pricings: null,
  pricing:null,
  features:null,
  dropLoading:false,
  dropError:null
};

export const pricingSlice = createSlice({
  name: "pricingSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      globalClearState(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPricing.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPricing.fulfilled, (state, action) => {
      state.loading = false;
      state.pricings = action.payload;
    });
    builder.addCase(getAllPricing.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSinglePricing.pending,(state)=>{
      state.getLoading = true;
    })
    builder.addCase(getSinglePricing.fulfilled,(state,action)=>{
      state.getLoading = false;
      state.pricing = action.payload;
    })
    builder.addCase(getSinglePricing.rejected,(state,action)=>{
      state.getLoading = false;
      state.getError = action.payload;
    })
    // Dropdown cases
    builder.addCase(getPricingsForDropDown.pending,(state)=>{
      state.dropLoading = true;
    })
    builder.addCase(getPricingsForDropDown.fulfilled,(state,action)=>{
      state.dropLoading = false;
      state.features = action.payload;
    })
    builder.addCase(getPricingsForDropDown.rejected,(state,action)=>{
      state.dropLoading = false;
      state.dropError = action.payload;
    })
  },
});

export const getAllPricing = createAsyncThunk(
  "getAllPricing",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Invalid token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/subscriptions/pricing/manage`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occurred please try again later");
      }
    }
  }
);

export const getSinglePricing = createAsyncThunk("getSinglePricing",async(apiData,{rejectWithValue})=>{
  try {
    if(!apiData.token){
      return rejectWithValue("Please provide token")
    }
    if(!apiData.id){
      return rejectWithValue("Please provide id")
    }
    const {data} = await axios.get(`${BASE_URL}/v1/subscriptions/pricing/view/${apiData.id}`,{
      headers:{
        Authorization:`Bearer ${apiData.token}`
      }
    })
    return data.result
  } catch (error) {
    if (error?.response?.data?.message) {
      return rejectWithValue(error?.response?.data?.message);
    } else {
      return rejectWithValue("Some error occurred please try again later");
    }
  }
})


export const getPricingsForDropDown = createAsyncThunk("getPricingsForDropDown",async (apiData,{rejectWithValue})=>{
  try {
    if(!apiData.token){
      return rejectWithValue("Please provide token")
    }
    const {data} = await axios.get(`${BASE_URL}/v1/lookuptype/view/15`,{
      headers:{
        Authorization:`Bearer ${apiData.token}`
      }
    })
    return data.result.lookups
  } catch (error) {
    if (error?.response?.data?.message) {
      return rejectWithValue(error?.response?.data?.message);
    } else {
      return rejectWithValue("Some error occurred please try again later");
    }
  }
})