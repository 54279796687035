import React, { useMemo, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import "./StandardTemplateLayout.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const StandardTemplateLayout = ({ data,color,setColor }) => {
  const websiteDesign = useSelector((state) => state.auth.websiteDesign);
  const { token } = useSelector((state) => state.auth);
  const { uploadLoading, uploadSuccess, uploadError } = useSelector(
    (state) => state.webDesign
  );
  const {
    getLoading,
    getError,
    questions,
    consultationFormLoading,
    consultationFormSuccess,
    consultationFormError,
  } = useSelector((state) => state.websiteStyles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const is_consultation_form_enables = useMemo(()=>{
    return questions?.is_active === 1?true:false
  })
  return (
    <div className="" style={{ position: "relative" }}>
      <Navbar data={data} color={color}  is_consultation_form_enables={is_consultation_form_enables}/>
      <div className="standard-template-layout" style={{ minHeight: "90vh" }}>
        <Outlet />
      </div>
      <Footer data={data}/>

    </div>
  );
};

export default StandardTemplateLayout;
