import React from "react";
import { TimePicker as AntDTimePicker } from "antd";
import dayjs from "dayjs";
const TimePicker = ({ value, onChange,className }) => {
  return (
    <AntDTimePicker
      value={value ? dayjs(value, "HH:mm") : null}
      onChange={(time, timeString) =>
        onChange(dayjs(timeString, "HH:mm A").format("HH:mm"))
      }
      showSecond={false}
      use12Hours={true}
      changeOnScroll
      needConfirm={false}
      className={className}
    />
  );
};

export default TimePicker;
