import React, { useEffect, useMemo, useState } from "react";
import "./Book.css";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getAvailableDates,
  clearState,
} from "../../../../features/Websites/websitesSlice";
import Flex from "../../../../components/styled/Flex/Flex";
import {
  emailPattern,
  namePattern,
  phonePattern,
} from "../../../../utills/Schems";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  convertTo24Hour,
  generateAvailableTimeSlots,
  getCurrentDate,
} from "../../../../utills/prettifyDate";
import BtnLoader from "../../../../components/styled/BtnLoader/BtnLoader";
import { isAppointmentAllowed } from "../../../../utills/appointments";
import ServicesPublicDropdown from "../../../../components/Portal/ServiceDropDown/ServicesPublicDropdown";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import {
  decryptObject,
  encryptObject,
} from "../../../../utills/encryptionDecription";
import moment from "moment";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import GlobalDropDown from "../../../../components/styled/Form/GlobalDropDown/GlobalDropDown";

const Book = () => {
  const { url } = useParams();
  const query = queryString.parse(window.location.search);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [selectedServices, setSelectedServices] = useState(
    query?.data ? decryptObject(query.data) : []
  );
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [dropError, setDropError] = useState(null);
  const dispatch = useDispatch();
  const { settings, addLoading, data, availableDates, addSuccess } =
    useSelector((state) => state.websiteStyles);
  const navigate = useNavigate();
  const unavailableDatesSet = new Set(
    settings.unavailable_dates.map((date) => moment(date).format("YYYY-MM-DD"))
  );

  // Create a set of available weekdays
  const availableWeekdaysSet = new Set(
    data.business_hours.map((timing) => timing.day)
  );
  const disabledDate = (current) => {
    if (!current) {
      return false;
    }

    // Check if the current date is in the unavailable dates set
    if (unavailableDatesSet.has(current.format("YYYY-MM-DD"))) {
      return true;
    }

    // Get the day name of the current date
    const dayName = current.format("dddd"); // e.g., "Monday"

    // Disable the date if the day is not in the available weekdays set
    return !availableWeekdaysSet.has(dayName);
  };
  const time_slots = useMemo(() => {
    if (availableDates && selectedServices && selectedServices.length > 0) {
      const available_slots = generateAvailableTimeSlots(
        availableDates.setting_info,
        availableDates.result,
        selectedServices
      );
      return available_slots;
    } else {
      return null;
    }
  }, [availableDates, selectedServices]);

  useEffect(() => {
    if (addSuccess) {
      toast.success("Appointment requested successfully");
      dispatch(clearState());
    }
  }, [addSuccess, dispatch]);

  const total_amount = useMemo(() => {
    return selectedServices.map((service) => service.cost).reduce((a, b) => a + b, 0);
  }, [selectedServices]);

  
  return (
    <div
      className="standard-booking-section1"
      style={{
        // background: `url('/templates/standardTemplate/home/home.png')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "90vh",
        position: "relative",
        // zIndex: "-1"
        // paddingTop:"150px"
      }}
    >
      <div className="standard-booking-sec1-overlay"></div>
      <div
        style={{
          paddingTop: "100px",
          position: "relative",
          zIndex: "",
          height: "100px",
          width: "100%",
        }}
      ></div>
      <div className="standard-template-top">
        <Flex align="center" justify="space-between" className="inner">
          <h1 style={{ color: "#333", fontWeight: 400, marginTop: "50px" }}>
            Schedule an appointment
          </h1>
          {selectedServices.length > 0 && (
            <div
              className="website-selected-services"
              style={{
                background: data?.color || "#3E0FFE",
                padding: "10px 20px",
                borderRadius: "10px",
                cursor: "pointer",
                transform: "translateY(15px)",
              }}
            >
              <Flex align="center" gap={10}>
                <p
                  style={{
                    color: getTextColorBasedOnBackground(
                      data?.color || "#3E0FFE"
                    ),
                  }}
                >
                  {" "}
                  {selectedServices.length}{" "}
                  {selectedServices.length === 1 ? "Service" : "Services"}{" "}
                  Selected
                </p>
                {/* <ArrowRight
              color={getTextColorBasedOnBackground(data?.color || "#3E0FFE")}
              size={20}
            /> */}
              </Flex>
            </div>
          )}
        </Flex>
      </div>
      <div className="standard-booking-sec1-overlay-inner website-appointment-main-container">
        <form
          onSubmit={handleSubmit(async (values) => {
            if (selectedTimeSlot) {
              let apiData = {};
              apiData.status = 23;
              apiData.time = convertTo24Hour(selectedTimeSlot.name);
              apiData.name = values.name;
              apiData.date = values.date;
              apiData.notes = values.notes;
              apiData.first_name = values.first_name;
              apiData.last_name = values.last_name;
              apiData.email = values.email;
              apiData.phone = values.phone;
              const services = selectedServices.map((service) => ({
                service_id: service.id,
                amount: service.cost,
              }));
              apiData.business_id = data?.business_id?.id;
              apiData.business_name = data?.business_id?.business_name;
              apiData.services = services;
              if (
                settings?.prevent_booking ||
                settings?.min_day_before_booking > 0
              ) {
                const user_selected_date_str = values.date;
                const user_selected_time_str = convertTo24Hour(
                  selectedTimeSlot.name
                );
                const user_selected_date = new Date(user_selected_date_str);
                const [hours, minutes] = user_selected_time_str
                  .split(":")
                  .map(Number);
                user_selected_date.setHours(hours);
                user_selected_date.setMinutes(minutes);
                user_selected_date.setSeconds(0);
                const total_preventing_hours =
                  settings?.prevent_booking +
                  settings?.min_day_before_booking * 24;
                if (
                  isAppointmentAllowed(
                    total_preventing_hours,
                    user_selected_date
                  )
                ) {
                  const total_amount = selectedServices
                    .map((service) => service.cost)
                    .reduce(
                      (first, second) => Number(first) + Number(second),
                      0
                    );
                  const encryptedToken = encryptObject(apiData);
                  navigate(
                    `/websites/${url}/book/payment?cost=${total_amount}&data=${encryptedToken}`
                  );
                } else {
                  toast.error(
                    `You can't make appointment ${
                      settings.prevent_booking +
                      settings?.min_day_before_booking * 24
                    } hours after current time`
                  );
                }
              } else {
                const total_amount = selectedServices
                  .map((service) => service.cost)
                  .reduce((first, second) => Number(first) + Number(second), 0);
                const encryptedToken = encryptObject(apiData);
                navigate(
                  `/websites/${url}/book/payment?cost=${total_amount}&data=${encryptedToken}`
                );
              }
            } else {
              toast.error("please Select Time Slot");
            }
          })}
        >
          <div
            className="website-appointment-form-inputs"
            id="website-appointment-form-inputs"
          >
            <Flex
              className={`global-input-container website-appointment-form-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name" style={{ color: "#333" }}>
                First Name*
              </label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"First Name"}
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container website-appointment-form-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name" style={{ color: "#333" }}>
                Last Name*
              </label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container website-appointment-form-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email" style={{ color: "#333" }}>
                Email *
              </label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"Enter Email"}
                  {...register("email", {
                    required: "Please Enter Email",
                    pattern: emailPattern,
                  })}
                  id="email"
                />
                <img src="/icons/mail.png" alt="icon" />
              </div>
              <p className="global-input-error">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container website-appointment-form-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="phone" style={{ color: "#333" }}>
                Phone*
              </label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"number"}
                  placeholder={"Enter phone number"}
                  {...register("phone", {
                    required: "Please Enter Phone Number",
                    pattern: phonePattern,
                    min: {
                      value: 0,
                      message: "Phone number cannot be negative",
                    },
                  })}
                  id="phone"
                />
                <img src="/icons/call-1.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors?.phone && errors?.phone?.message}
              </p>
            </Flex>
            <div
              className="website-appointment-form-input"
              style={{ transform: "translateY(10px)" }}
            >
              <ServicesPublicDropdown
                label={`${
                  selectedServices.length > 0
                    ? "Add additional service"
                    : "Select Service*"
                }`}
                labelColor="#000"
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                handleChange={(service) => {
                  if (selectedServices.find((m) => m.id === service.id)) {
                    return;
                  } else {
                    setSelectedServices((prevData) => [...prevData, service]);
                  }
                }}
                id={data?.business_id?.id}
              />
              <p className="global-input-error">
                {dropError && !selectedService && dropError}
              </p>
            </div>
            <Flex
              className={`global-input-container website-appointment-form-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="date" style={{ color: "#333" }}>
                Appoinment Date*
              </label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => {
                    return (
                      <DatePicker
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) => {
                          dispatch(
                            getAvailableDates({
                              business_id: data.business_id.id,
                              setting_id: settings.id,
                              date: date.format("YYYY-MM-DD"),
                            })
                          );
                          field.onChange(
                            date ? date.format("YYYY-MM-DD") : null
                          );
                        }}
                        format={"YYYY-MM-DD"}
                        // showTime
                        className="add-appointment-date-picker"
                        changeOnScroll
                        needConfirm={false}
                        minDate={dayjs(getCurrentDate())}
                        disabledDate={disabledDate}
                      />
                    );
                  }}
                />

                {/* <img src="/icons/user.png" alt="icon" /> */}
              </div>
              <p className="global-input-error">
                {errors?.date && errors?.date?.message}
              </p>
            </Flex>

            {time_slots && (
              <GlobalDropDown
                labelColor="#333"
                label="Select Time Slot *"
                options={time_slots.map((s) => {
                  return { name: s, value: s };
                })}
                stateHandler={selectedTimeSlot}
                setStateHandler={setSelectedTimeSlot}
              />
            )}
          </div>
          <Flex align="center" justify="space-between" className="mt-20">
            {
              <p>{ selectedServices.length > 0 && `Total Amount - £${total_amount}`}</p>
            }
            
            <button
              className="get-consultation-btn"
              style={{ borderColor: data?.color, color: data?.color }}
              type="submit"
            >
              {addLoading ? <BtnLoader color={data?.color} /> : "Book appointment"}
            </button>
          </Flex>
        </form>
      </div>
    </div>
  );
};
export default Book;
