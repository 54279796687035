import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./style.css";
import ScrollToTop from "../../ScrollToTop/ScrollToTop";
import { useLocation } from "react-router-dom";

const LandingPageLayout = ({ children, className }) => {
  const { hash } = useLocation();
  return (
    <div className="max-width-1440">
      <Navbar />
      {hash ? null : <ScrollToTop />}
      <div className={className}>{children}</div>
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
