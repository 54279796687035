import React, { useMemo } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import {  Outlet } from "react-router-dom";
import { useSelector } from "react-redux";


const ProfessionalTemplateLayout = ({ data,color,setColor }) => {
  const {
    questions,
  } = useSelector((state) => state.websiteStyles);
  const is_consultation_form_enables = useMemo(()=>{
    return questions?.is_active === 1?true:false
  },[questions?.is_active])
  return (
    <div className="profesional-layout">
      <Navbar data={data} color={color} setColor={setColor} is_consultation_form_enables={is_consultation_form_enables}/>
      <div style={{minHeight:'90vh'}}>
        <Outlet />
      </div>

      <Footer data={data}/>
    </div>
  );
};

export default ProfessionalTemplateLayout;
