import React, { useEffect, useState } from "react";
import "./AddReview.css";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Button from "../../../components/styled/Button/Button";
import Flex from "../../../components/styled/Flex/Flex";
import ServicesDropDown from "../../../components/Portal/ServiceDropDown/ServiceDropDown";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  addTestimonial,
  clearState,
} from "../../../features/SuperAdminPortal/testimonialsSlice";
import { toast } from "react-toastify";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { Rate } from "antd";

const AddReview = () => {
  const [selectedService, setSelectedService] = useState(null);
  const { token, userData } = useSelector((state) => state.auth);
  const { addLoading, addError, addSuccess } = useSelector(
    (state) => state.testimonials
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const handleAddClientReview = (values) => {
    if (!selectedService) {
      setDropError("Please Select");
    } else {
      values.selectedService = selectedService.id;
      values.service_provider = userData.business_id.id;
      dispatch(addTestimonial({ token, data: { ...values } }));
    }
  };

  useEffect(() => {
    if (addSuccess) {
      toast.success("Review added successfully", {
        position: "top-right",
      });
      dispatch(clearState());
      navigate("/client-portal/reviews");
    }
  }, [addSuccess, navigate, dispatch]);
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const [dropError, setDropError] = useState(null);
  const rating = watch("rating");
  return (
    <div className="outer">
      <Link to={"/client-portal/reviews"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Review</h1>
      <form onSubmit={handleSubmit(handleAddClientReview)}>
        <Flex
          className={`global-input-container add-team-member-input`}
          direction="column"
          gap={10}
        >
          <ServicesDropDown
            label={"Service*"}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            // defaultSelected
            handleChange={() => {}}
          />
        </Flex>
        <p className="global-input-error">
          {dropError && !selectedService && dropError}
        </p>
        <div className="lable-textarea-group lable-input-group mt-20">
          <label htmlFor="notes">Review*</label>
          <div className="edit-client-icon-textarea">
            <textarea
              name=""
              id="notes"
              cols="135"
              rows="6"
              placeholder="Enter Review"
              {...register("review", {
                required: "Please enter review",
              })}
            ></textarea>
          </div>
          <p className="global-input-error">
            {errors?.review && errors?.review?.message}
          </p>
        </div>
        <div className="lable-textarea-group lable-input-group mt-20">
          <label htmlFor="notes">Rating*</label>
          <div>
          <Rate
            tooltips={desc}
            onChange={(value) => {
              setValue("rating", value);
            }}
            value={rating}
          />
          </div>
          <p className="global-input-error">
            {errors?.rating && errors?.rating?.message}
          </p>
        </div>
        <Flex className="mt-20">
          <Button
            variant="primary"
            label={"Add Review"}
            type="submit"
            loading={addLoading}
            loaderColor="#fff"
          />
        </Flex>
      </form>
      {addError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={addError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddReview;
