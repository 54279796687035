import React from "react";
import "./style.css";
import Flex from "../Flex/Flex";
const TableTag = ({ children, className }) => {
  return (
    <Flex
      align="center"
      justify="center"
      className={`global-tag ${className}`}
    >
      <p>{children}</p>
    </Flex>
  );
};

export default TableTag;
