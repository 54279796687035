import "./style.css";
import Button from "../../../components/styled/Button/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const WebsitePaymentSuccess = () => {
  const { data } = useSelector((state) => state.websiteStyles);
  return (
    <div style={{ marginTop: "150px" }}>
      <div className="payment-success-main-container">
        <div>

        <img src="/icons/success.png" alt="" />
        <h1>
          Appointment Created <br /> Successfully
        </h1>
        <Link to={`/websites/${data.business_url}/home`}>
          <Button label="Go Back" variant="outline" />
        </Link>
        </div>
      </div>
    </div>
  );
};

export default WebsitePaymentSuccess;
