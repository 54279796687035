import React, { useState } from "react";
import Navbar from "../../../components/Portal/Navbar/Navbar";
import SideBar from "../../../components/Portal/Sidebar/SideBar";
import { Outlet, useLocation } from "react-router-dom";
import SideBarSingleMenu from "../../../components/Portal/Sidebar/SideBarSingleMenu/SideBarSingleMenu";
import { getActiveTab } from "../../../utills/activeTabs";
const ClientPortalLayout = () => {
  const [sideBarActive, setSideBarActive] = useState(false);
  const location = useLocation();
  return (
    <div>
      <Navbar
        setSideBarActive={setSideBarActive}
        notificationsLink={"/client-portal/notifications"}
        profileSettingsLink={"/client-portal/settings"}
      />
      <SideBar 
        sideBarActive={sideBarActive}
        setSideBarActive={setSideBarActive}
      >
        <SideBarSingleMenu
          label="Appointments"
          link="/client-portal/appointments"
          isActive={getActiveTab(location.pathname, [
            "/client-portal/appointments",
            "/client-portal/add-appointment",
            /^\/client-portal\/edit-appointment\/\d+$/,
          ])}
          setSideBarActive={setSideBarActive}
        />
        <SideBarSingleMenu
          label="Reviews"
          link="/client-portal/reviews"
          isActive={getActiveTab(location.pathname, [
            "/client-portal/reviews",
            "/client-portal/add-review",
            "/client-portal/edit-review",
          ])}
          setSideBarActive={setSideBarActive}
        />
        {/* <SideBarSingleMenu
          label="Payment Method"
          link="/client-portal/payment-method"
          isActive={getActiveTab(location.pathname, [
            "/client-portal/payment-method",
            "/client-portal/add-payment-method",
            "/client-portal/edit-payment-method",
          ])}
          setSideBarActive={setSideBarActive}
        /> */}
        <SideBarSingleMenu
          label="Referrals"
          link="/client-portal/referrals"
          isActive={getActiveTab(location.pathname, [
            "/client-portal/referrals",
            "/client-portal/add-referral",
            "/client-portal/edit-referral",
          ])}
          setSideBarActive={setSideBarActive}
        />
        <SideBarSingleMenu
          label="Settings"
          link="/client-portal/settings"
          isActive={getActiveTab(location.pathname, [
            "/client-portal/settings",
          ])}
          setSideBarActive={setSideBarActive}
        />
      </SideBar>
      <div className="portal-layout-right-side">
        <Outlet />
      </div>
      <div
        className={`portal-blackout-screen ${
          sideBarActive ? "portal-blackout-show" : "portal-blackout-hide"
        }`}
      ></div>
    </div>
  );
};

export default ClientPortalLayout;
