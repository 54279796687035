import React, { useEffect } from "react";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper/modules";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight, Edit2 } from "react-feather";
import { getTextColorBasedOnBackground } from "../../../../utills/identifyColor";
import { useForm } from "react-hook-form";
import { useFileHandler } from "6pp";
import { toast } from "react-toastify";
import {
  updateWebsiteDesign,
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import Flex from "../../../../components/styled/Flex/Flex";
import { ColorPicker, Rate } from "antd";
import Button from "../../../../components/styled/Button/Button";
import { concatNames } from "../../../../utills/concatNames";

const Home = ({ data, color, setColor, isEditAccess }) => {
  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      heading:
        data?.heading || `Welcome to ${data?.business_id?.business_name}`,
      sub_heading:
        data?.sub_heading ||
        `${data?.business_id?.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      review_text: data?.review_text || "Reviews By Our Clients",
    },
  });
  const banner_image = useFileHandler("single");
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website update successfully");
      dispatch(clearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  return (
    <>
      <div className="first-section-p" style={{ position: "relative" }}>
        {isEditAccess && (
          <>
            <div className="update-banner-image-input" style={{ zIndex: 2 }}>
              <label htmlFor="banner-image">
                <div className="update-banner-image">Update banner Image</div>
              </label>
              <input
                type="file"
                id="banner-image"
                onChange={banner_image.changeHandler}
              />
            </div>
            <div className="website-color-picker" style={{ zIndex: "1000" }}>
              <Flex align="center" gap={7}>
                <label htmlFor="color" style={{ color: "#000" }}>
                  Choose Color
                </label>
                <ColorPicker
                  value={color}
                  onChangeComplete={(color) => {
                    setColor(color.toHexString());
                  }}
                />
              </Flex>
            </div>
          </>
        )}

        <img
          src={
            banner_image?.file
              ? banner_image?.preview
              : data?.main_banner_image
              ? data?.main_banner_image
              : "/professionaltemplate/home/Rectangle 34624882.png"
          }
          alt=""
          style={{ height: "100%" }}
        />
        <div
          className="first-section-overlay"
          style={{
            maxWidth: "1440px",
            margin: "0 auto",
          }}
        >
          <div
            className="Slider-side"
            style={{
              height: "200px",
              maxWidth: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0",
            }}
          >
            <div className="pro-slider-sider-slider">
              <div
                className="Slider-sideinner"
                style={{
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Swiper
                  slidesPerView={2}
                  spaceBetween={20}
                  keyboard={{
                    enabled: true,
                  }}
                  // autoplay={{
                  //   delay: 1500,
                  //   disableOnInteraction: false,
                  // }}
                  navigation={{
                    nextEl: ".upper-slider-next",
                    prevEl: ".upper-slider-prev",
                  }}
                  modules={[Navigation, keyboard, Autoplay]}
                  className="mySwiper"
                  loop={true}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <SwiperSlide>
                    <img src={"/professionaltemplate/home/slide1.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"/professionaltemplate/home/slide2.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"/professionaltemplate/home/slide1.png"} alt="" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={"/professionaltemplate/home/slide2.png"} alt="" />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <button
              className="upper-slider-prev"
              style={{ background: color ? color : "#D8AA5D" }}
            >
              <ChevronLeft
                className="upper-slider-next-chevron"
                stroke={getTextColorBasedOnBackground(
                  color ? color : "#D8AA5D"
                )}
              />
            </button>
            <button
              className="upper-slider-next"
              style={{ background: color ? color : "#D8AA5D" }}
            >
              <ChevronRight
                className="upper-slider-previous-chevron"
                stroke={getTextColorBasedOnBackground(
                  color ? color : "#D8AA5D"
                )}
              />
            </button>
          </div>
          <div className="text-side">
            <textarea
              {...register("heading")}
              className="professional-heading"
              readOnly={!isEditAccess}
            ></textarea>
            <textarea
              {...register("sub_heading")}
              className="professional-sub-heading"
              readOnly={!isEditAccess}
            ></textarea>
            {isEditAccess && (
              <>
                <Edit2
                  className="professional-heading-edit"
                  // style={{
                  //   position: "absolute",
                  //   right: "0px",
                  //   top: "-10px",
                  // }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("heading");
                  }}
                  color="#fff"
                />
                <Edit2
                  className="professional-sub-heading-edit"
                  // style={{
                  //   position: "absolute",
                  //   right: "-10px",
                  //   top: "140px",
                  // }}
                  size={17}
                  cursor="pointer"
                  onClick={() => {
                    setFocus("sub_heading");
                  }}
                  color="#fff"
                />
              </>
            )}

            <button
              style={{
                background: color ? color : "#D8AA5D",
                color: getTextColorBasedOnBackground(color ? color : "#D8AA5D"),
              }}
            >
              Get Consultation
            </button>
          </div>
        </div>
        <div className="global-overlay"></div>
        <img
          src="/wave.png"
          alt=""
          className="professional-template-wave"
          style={{}}
        />
      </div>

      <div className="section-two-p">
        {isEditAccess && (
          <Flex align="center" justify="flex-start">
            <div className="save-button-website" style={{ position: "static" }}>
              <form
                onSubmit={handleSubmit((values) => {
                  const apiData = {};
                  apiData.heading = values.heading;
                  apiData.sub_heading = values.sub_heading;
                  // apiData.review_text = values.review_text;
                  apiData.color = color;
                  if (banner_image.file) {
                    apiData.main_banner_image = banner_image.preview;
                  }
                  dispatch(updateWebsiteDesign({ data: apiData }));
                })}
              >
                <Button
                  label="Save Changes"
                  type="submit"
                  loading={updateLoading}
                  loaderColor="#fff"
                />
              </form>
            </div>
          </Flex>
        )}
        {data?.business_id?.testimonials &&
          data?.business_id?.testimonials.length > 1 && (
            <>
              <h2 className="review">Reviews</h2>
              <div style={{ position: "relative" }}>
                <textarea
                  className="para"
                  {...register("review_text")}
                  readOnly={!isEditAccess}
                ></textarea>
                {isEditAccess && (
                  <Edit2
                    style={{
                      position: "absolute",
                      right: "-10px",
                      top: "0px",
                    }}
                    size={17}
                    cursor="pointer"
                    onClick={() => {
                      setFocus("review_text");
                    }}
                    color="#000"
                  />
                )}
              </div>

              <div
                className="Home-slider-p"
                style={{
                  maxWidth: "1440px",
                  margin: "0 auto",
                  position: "relative",
                }}
              >
                <div className="home-slider-inner-p">
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={20}
                    keyboard={{
                      enabled: true,
                    }}
                    navigation={{
                      nextEl: ".reviews-slider-next",
                      prevEl: ".reviews-slider-prev",
                    }}
                    modules={[Navigation, keyboard, Autoplay]}
                    className="mySwiper"
                    loop={true}
                    breakpoints={{
                      230: { slidesPerView: 1 },
                      431: { slidesPerView: 2 },
                    }}
                  >
                    {data?.business_id?.testimonials.map((item) => {
                      return (
                        <SwiperSlide>
                          <div className="second-slider-outer">
                            <div className="card-p">
                              <div className="image-rating-p">
                                {/* <img
                                  src={
                                    "/professionaltemplate/home/Ellipse 5182.png"
                                  }
                                  alt=""
                                /> */}
                                <div className="ratings-p" style={{marginBottom:'20px'}}>
                                  <h3>{concatNames(item?.client_id?.first_name,item?.client_id?.last_name)}</h3>
                                  <Rate value={parseInt(item?.rating)} disabled/>
                                </div>
                              </div>
                              <p style={{height:'150px'}}>
                              {item?.review}
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <button
                  className="reviews-slider-prev"
                  style={{ backgroundColor: color ? color : "#D8AA5D" }}
                >
                  <ChevronLeft
                    className="review-slider-previous-chevron"
                    color={getTextColorBasedOnBackground(
                      color ? color : "#D8AA5D"
                    )}
                  />
                </button>
                <button
                  className="reviews-slider-next"
                  style={{ backgroundColor: color ? color : "#D8AA5D" }}
                >
                  <ChevronRight
                    className="review-slider-next-chevron"
                    color={getTextColorBasedOnBackground(
                      color ? color : "#D8AA5D"
                    )}
                  />
                </button>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default Home;
