import React, { useEffect, useState } from "react";
import "./About.css";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useFileHandler } from "6pp";
import Button from "../../../../components/styled/Button/Button";
import {
  updateWebsiteDesign,
  clearState,
} from "../../../../features/ServiceProviderPortal/websiteDesignSlice";
import { toast } from "react-toastify";
import { Edit2 } from "react-feather";
import { Switch } from "antd";
import Flex from "../../../../components/styled/Flex/Flex";
import { convertToAMPM } from "../../../../utills/prettifyDate";

const About = ({ data, isEditAccess }) => {
  const { updateLoading, updateSuccess, updateError } = useSelector(
    (state) => state.webDesign
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateSuccess) {
      toast.success("Website Updated Successfully");
      dispatch(clearState());
    }
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateSuccess, updateError, dispatch]);
  const { register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      about_header: data?.about_header ? data?.about_header : "About Us",
      about_company: data?.about_company
        ? data?.about_company
        : `${data.business_id.business_name} is a dynamic and innovative company dedicated to providing top-notch services to meet the diverse needs of our clients. With a focus on excellence and customer satisfaction`,
      about_team: data?.about_team
        ? data?.about_team
        : `At ${data.business_id.business_name}, our team is our greatest asset. Comprising passionate and talented professionals, each member brings unique expertise and a shared commitment to excellence. our diverse team collaborates to deliver exceptional results for our clients. We believe in fostering a supportive and dynamic work environment where creativity and dedication thrive, driving our success and ensuring we consistently exceed expectations.`,
      team_header: data?.team_header ? data?.team_header : "About Our Team",
      booking_cancellation_policy:
        data?.booking_cancellation_policy || "Booking Cancellation Policy",
    },
  });
  const team_photo = useFileHandler("single");
  const about_us_image = useFileHandler("single");
  const [showTeamSection, setShowTeamSection] = useState(
    data?.show_team_section === "false" ? false : true
  );
  const [resetAboutImage, setResetAboutImage] = useState(null);
  const [resetTeamImage, setResetImage] = useState(null);

  return (
    // <ClassicTemplateLayout>
    <div style={{ position: "relative" }}>
      <div className="about-main" style={{ paddingTop: "120px" }}>
        <div className="about-left" style={{ position: "relative" }}>
          <input
            className="about-head"
            {...register("about_header")}
            disabled={!isEditAccess}
          />
          <textarea
            className="about-para"
            {...register("about_company")}
            maxLength={400}
            readOnly={!isEditAccess}
          ></textarea>
          {isEditAccess && (
            <>
              <Edit2
                style={{
                  position: "absolute",
                  left: "-10px",
                  top: "-10px",
                }}
                size={18}
                cursor="pointer"
                onClick={() => {
                  setFocus("about_header");
                }}
              />
              <Edit2
                style={{
                  position: "absolute",
                  left: "-20px",
                  top: "70px",
                }}
                size={18}
                cursor="pointer"
                onClick={() => {
                  setFocus("about_company");
                }}
              />
            </>
          )}
        </div>
        <div
          className="about-right"
          // style={{ position: "relative", width: "650px", height: "400px" }}
        >
          <img
            src={
              resetAboutImage
                ? resetAboutImage
                : about_us_image.file
                ? about_us_image.preview
                : data?.about_us_image
                ? data?.about_us_image
                : "/clasicTemplate/about/Rectangle 1457.png"
            }
            alt=""
            style={{ height: "100%", width: "100%", borderRadius: "20px" }}
          />
          {isEditAccess && (
            <div
              className="update-banner-image-input"
              style={{ zIndex: 1, top: "20px" }}
            >
              <label htmlFor="banner-image">
                <div className="update-banner-image">Update about image</div>
              </label>
              <input
                type="file"
                id="banner-image"
                onChange={about_us_image.changeHandler}
              />
            </div>
          )}
          {isEditAccess && (
            <div className="use-default-button-container about">
              <button
                onClick={() =>
                  setResetAboutImage("/clasicTemplate/about/Rectangle 1457.png")
                }
              >
                Use Default
              </button>
            </div>
          )}

          <div className="about-image-overlay"></div>
        </div>
      </div>

      <div className="about-section-main-container">
        <div className="about-section-two">
          {isEditAccess && (
            <Flex align="center" gap={5} className="mb-30">
              <label htmlFor="team">Show Team Section</label>
              <Switch
                defaultChecked={showTeamSection}
                onChange={(checked) => {
                  setShowTeamSection(checked);
                }}
              />
            </Flex>
          )}

          {showTeamSection && (
            <>
              <div style={{ position: "relative" }}>
                <input
                  className="secction-two-head"
                  type="text"
                  {...register("team_header")}
                  disabled={!isEditAccess}
                />
                {isEditAccess && (
                  <Edit2
                    style={{
                      position: "absolute",
                      left: "-15px",
                      top: "-10px",
                      zIndex: "1000",
                    }}
                    size={18}
                    cursor="pointer"
                    onClick={() => {
                      setFocus("team_header");
                    }}
                  />
                )}
              </div>
              <div className="section-two-main">
                <div
                  className="section-two-left"
                  style={{ position: "relative" }}
                >
                  <textarea
                    className="about-left-para"
                    {...register("about_team")}
                    maxLength={550}
                    readOnly={!isEditAccess}
                  ></textarea>
                  {isEditAccess && (
                    <Edit2
                      style={{
                        position: "absolute",
                        left: "-15px",
                        top: "-20px",
                        zIndex: "1000",
                      }}
                      size={18}
                      cursor="pointer"
                      onClick={() => {
                        setFocus("about_team");
                      }}
                    />
                  )}
                </div>
                <div className="section-two-right about-right">
                  <img
                    src={
                      resetTeamImage
                        ? resetTeamImage
                        : team_photo.file
                        ? team_photo.preview
                        : data?.team_photo
                        ? data?.team_photo
                        : "/clasicTemplate/about/Rectangle 34624252.png"
                    }
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px",
                    }}
                  />
                  {isEditAccess && (
                    <div
                      className="update-banner-image-input"
                      style={{ zIndex: 1, top: "20px" }}
                    >
                      <label htmlFor="team-image">
                        <div className="update-banner-image">
                          Update team image
                        </div>
                      </label>
                      <input
                        type="file"
                        id="team-image"
                        onChange={team_photo.changeHandler}
                      />
                    </div>
                  )}
                  {isEditAccess && (
                    <div className="use-default-button-container about">
                      <button
                        onClick={() =>
                          setResetImage(
                            "/clasicTemplate/about/Rectangle 34624252.png"
                          )
                        }
                      >
                        Use Default
                      </button>
                    </div>
                  )}

                  <div
                    className="about-image-overlay"
                    style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
        {isEditAccess && (
          <div style={{ position: "absolute", top: "550px", right: "75px" }}>
            <form
              onSubmit={handleSubmit((values) => {
                const apiData = {};
                apiData.about_company = values.about_company;
                apiData.about_header = values.about_header;
                apiData.team_header = values.team_header;
                apiData.about_team = values.about_team;
                apiData.show_team_section = showTeamSection;
                apiData.booking_cancellation_policy =
                  values.booking_cancellation_policy;

                if (resetTeamImage) {
                  apiData.team_photo = resetTeamImage;
                } else if (team_photo?.file) {
                  apiData.team_photo = team_photo.preview;
                } else {
                }
                if(resetAboutImage){
                  apiData.about_us_image = resetAboutImage;
                }
                else if (about_us_image.file) {
                  apiData.about_us_image = about_us_image.preview;
                }else{}
                dispatch(updateWebsiteDesign({ data: apiData }));
              })}
            >
              <Button
                label="Save Changes"
                type="submit"
                loading={updateLoading}
                loaderColor="#fff"
              />
            </form>
          </div>
        )}
        {data?.show_business_hours && (
          <div className="business-timings-container">
            <h1>Business Hours</h1>
            <table>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Timing</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Monday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Monday" && d?.opening_time && d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Monday")
                            ?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Monday")
                            ?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Tuesday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Tuesday" && d?.opening_time && d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Tuesday"
                          )?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Tuesday"
                          )?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Wednesday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Wednesday" &&
                        d?.opening_time &&
                        d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Wednesday"
                          )?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Wednesday"
                          )?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Thursday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Thursday" &&
                        d?.opening_time &&
                        d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Thursday"
                          )?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Thursday"
                          )?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Friday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Friday" && d?.opening_time && d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Friday")
                            ?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Friday")
                            ?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Saturday" &&
                        d?.opening_time &&
                        d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Saturday"
                          )?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find(
                            (d) => d?.day === "Saturday"
                          )?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
                <tr>
                  <td>Sunday</td>
                  <td>
                    {" "}
                    {data?.business_hours?.find(
                      (d) =>
                        d?.day === "Sunday" && d?.opening_time && d?.close_time
                    )
                      ? `${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Sunday")
                            ?.opening_time
                        )} - ${convertToAMPM(
                          data?.business_hours?.find((d) => d?.day === "Sunday")
                            ?.close_time
                        )}`
                      : "Closed"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="booking-cancellation-policy">
          <h1>Booking Cancellation Policy</h1>
          <div style={{ position: "relative" }}>
            <textarea
              {...register("booking_cancellation_policy")}
              maxLength={1000}
              readOnly={!isEditAccess}
            ></textarea>
            {isEditAccess && (
              <Edit2
                style={{
                  position: "absolute",
                  left: "-15px",
                  top: "-15px",
                }}
                size={18}
                cursor="pointer"
                onClick={() => {
                  setFocus("booking_cancellation_policy");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
    // </ClassicTemplateLayout>
  );
};

export default About;
