import React, { useEffect, useState } from "react";
import "./style.css";
import Badge from "../../../components/styled/Badge/Badge";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clearState,
  deleteInvoice,
  downloadInvoice,
  getAllInvoices,
} from "../../../features/ServiceProviderPortal/invoiceSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { Link } from "react-router-dom";
import { concatNames } from "../../../utills/concatNames";
import { prettifyDate } from "../../../utills/prettifyDate";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import { toast } from "react-toastify";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import BtnLoader from "../../../components/styled/BtnLoader/BtnLoader";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";

const Invoices = () => {
  const dispatch = useDispatch();
  const [activeResRow, setActiveResRow] = useState(1);
  const { token, userData } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    invoices,
    delLoading,
    delError,
    delSuccess,
    downloadError,
    downloadLoading,
  } = useSelector((state) => state.invoices);
  const { permissions } = useSelector((state) => state.permissions);

  const [delPopup, setDelPopup] = useState(false);
  const [downloadActiveId, setDownloadActiveId] = useState(null);
  const [delId, setDelId] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(getAllInvoices({ token }));
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
      toast.success("Invoice deleted successfully", {
        position: "top-right",
      });
    }
  }, [token, dispatch, delSuccess]);

  const handleDeleteInvoice = () => {
    dispatch(deleteInvoice({ token, id: delId }));
  };
  return (
    <div className="client-details-container" style={{ position: "relative" }}>
      <div className="clients-container-top">
        <h1 className="edit-client-heading">Invoices</h1>
        <div className="">
          {checkIfPermittedToSingleTab(permissions, 26, userData?.roles) && (
            <Link to="/portal/create-invoice">
              {" "}
              <Button label="Create Invoice" />
            </Link>
          )}
        </div>
      </div>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Client Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Due Date</TableHeadData>
                  <TableHeadData>Cell No</TableHeadData>
                  <TableHeadData>Invoice Amount</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                invoices?.items?.length > 0,
                <TableBody>
                  {invoices?.items.map((invoice, index) => {
                    return (
                      <TableRow key={invoice?.id}>
                        <TableBodyData>
                          {checkIfPermittedToSingleTab(
                            permissions,
                            23,
                            userData?.roles
                          ) ? (
                            <Link to={`/portal/invoice-details/${invoice?.id}`}>
                              <TableText variant="link">
                                {invoice?.id}
                              </TableText>
                            </Link>
                          ) : (
                            <TableText>{invoice?.id}</TableText>
                          )}
                        </TableBodyData>
                        <TableBodyData>
                          {checkIfPermittedToSingleTab(
                            permissions,
                            9,
                            userData?.roles
                          ) ? (
                            <Link
                              to={`/portal/client-details/${invoice?.client?.id}`}
                            >
                              <TableText variant="link">
                                {conditionalRender(
                                  invoice?.client,
                                  concatNames(
                                    invoice?.client?.first_name,
                                    invoice?.client?.last_name
                                  ),
                                  "-"
                                )}
                              </TableText>
                            </Link>
                          ) : (
                            <TableText>
                              {conditionalRender(
                                invoice?.client,
                                concatNames(
                                  invoice?.client?.first_name,
                                  invoice?.client?.last_name
                                ),
                                "-"
                              )}
                            </TableText>
                          )}
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {conditionalRender(
                              invoice?.client,
                              invoice?.client?.email,
                              "-"
                            )}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(invoice?.due_date)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {conditionalRender(
                              invoice?.client,
                              invoice?.client?.phone,
                              "-"
                            )}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>£{invoice?.total_amount}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                invoice?.status === 30
                                  ? "error"
                                  : invoice?.status === 29
                                  ? "success"
                                  : "info"
                              }
                            >
                              {invoice?.status === 30
                                ? "Overdue"
                                : invoice?.status === 29
                                ? "Paid"
                                : "Unpaid"}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" gap={10} justify="center">
                            {downloadLoading &&
                            invoice?.id === downloadActiveId ? (
                              <BtnLoader color="#5151FE" />
                            ) : (
                              <img
                                src="/icons/download.png"
                                alt="edit"
                                onClick={() => {
                                  setDownloadActiveId(invoice.id);

                                  dispatch(
                                    downloadInvoice({ token, id: invoice.id })
                                  );
                                }}
                              />
                            )}
                            {checkIfPermittedToSingleTab(
                              permissions,
                              24,
                              userData?.roles
                            ) && (
                              <Link to={`/portal/edit-invoice/${invoice?.id}`}>
                                <img src="/icons/edit.png" alt="edit" />
                              </Link>
                            )}
                            {checkIfPermittedToSingleTab(
                              permissions,
                              27,
                              userData?.roles
                            ) && (
                              <img
                                src="/icons/delete.png"
                                alt="delete"
                                onClick={() => {
                                  setDelPopup(true);
                                  setDelId(invoice.id);
                                }}
                              />
                            )}
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Invoices Found" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                invoices?.items?.length > 0,
                invoices?.items?.map((invoice, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {invoice?.id}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Client Name
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Link
                                to={`/portal/invoice-details/${invoice?.id}`}
                              >
                                <PhoneTableSimpleText>
                                  {conditionalRender(
                                    invoice?.client,
                                    concatNames(
                                      invoice?.client?.first_name,
                                      invoice?.client?.last_name
                                    ),
                                    "-"
                                  )}
                                </PhoneTableSimpleText>
                              </Link>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {conditionalRender(
                                  invoice?.client,
                                  invoice?.client?.email,
                                  "-"
                                )}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Due Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(invoice?.due_date)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Phone Number
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {conditionalRender(
                                  invoice?.client,
                                  invoice?.client?.phone,
                                  "-"
                                )}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Total Amount
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{invoice?.total_amount}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <Badge
                                  tone={
                                    invoice?.status === 30
                                      ? "error"
                                      : invoice?.status === 29
                                      ? "success"
                                      : "info"
                                  }
                                >
                                  {invoice?.status === 30
                                    ? "Overdue"
                                    : invoice?.status === 29
                                    ? "Paid"
                                    : "Unpaid"}
                                </Badge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" gap={10}>
                                {downloadLoading &&
                                invoice?.id === downloadActiveId ? (
                                  <BtnLoader color="#5151FE" />
                                ) : (
                                  <img
                                    src="/icons/download.png"
                                    alt="edit"
                                    onClick={() => {
                                      setDownloadActiveId(invoice.id);

                                      dispatch(
                                        downloadInvoice({
                                          token,
                                          id: invoice.id,
                                        })
                                      );
                                    }}
                                  />
                                )}
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  24,
                                  userData?.roles
                                ) && (
                                  <Link
                                    to={`/portal/edit-invoice/${invoice?.id}`}
                                  >
                                    <img src="/icons/edit.png" alt="edit" />
                                  </Link>
                                )}
                                {checkIfPermittedToSingleTab(
                                  permissions,
                                  27,
                                  userData?.roles
                                ) && (
                                  <img
                                    src="/icons/delete.png"
                                    alt="delete"
                                    onClick={() => {
                                      setDelPopup(true);
                                      setDelId(invoice.id);
                                    }}
                                  />
                                )}
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}

      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteInvoice}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
      {downloadError && (
        <Popup className="send-voucher-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={downloadError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Invoices;
