import React, { useRef, useState } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const navRef = useRef();

  const [nav, setNav] = useState(false);
  const handleNavDrop = () => {
    if (
      navRef.current.style.maxHeight.length === 0 ||
      navRef.current.style.maxHeight === "0px"
    ) {
      setNav(true);
      navRef.current.style.maxHeight = "430px";
    } else {
      setNav(false);
      navRef.current.style.maxHeight = "0px";
    }
  };
  return (
    <div>
      <header className="max-width-1440">
        <nav className="navbar">
          <div className="header-logo">
            <Link to={"/"}>
              <img src={"/footer/logo.png"} alt="" />
            </Link>
          </div>
          <ul className="nav-links">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to="/"
            >
              <li>Home</li>
            </NavLink>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to="/features"
            >
              <li>Features</li>
            </NavLink>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to="/pricing"
            >
              <li>Pricing</li>
            </NavLink>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to="/about"
            >
              <li>About</li>
            </NavLink>
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
              to="/blogs"
            >
              <li>Blog</li>
            </NavLink>
          </ul>
          <div className="contact-login-btn">
            <Link to={"/signup?step=1"}>
              {/* <button className="contact">Contact Us</button> */}
              Sign Up
            </Link>
            <Link to={"/login"}>Login</Link>
          </div>
        </nav>
      </header>
      <div className="navbar2 max-width-1440">
        <div className="navbar2-inner">
          <div className="navbar2-inner-top">
            <div className="header-logo">
              <Link to={"/"}>
                <img src={"/footer/logo.png"} alt="" />
              </Link>
            </div>
            <div className="navbar2-right">
              {nav && (
                <img
                  src="/navbar/close-circle.png"
                  alt=""
                  className="close-nav"
                  onClick={handleNavDrop}
                />
              )}
              {!nav && (
                <img
                  src="/navbar/hamburger.png"
                  alt=""
                  className="hamburger"
                  onClick={handleNavDrop}
                />
              )}
            </div>
          </div>
          <div className="navbar2-inner-bottom" ref={navRef}>
            <ul className="nav-links">
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to="/"
              >
                <li>Home</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to="/features"
              >
                <li>Features</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to="/pricing"
              >
                <li>Pricing</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to="/about"
              >
                <li>About</li>
              </NavLink>
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                to="/blogs"
              >
                <li>Blog</li>
              </NavLink>
            </ul>
            <div className="break-line"></div>
            <div className="contact-login-btn">
              <Link to={"/signup?step=1"}>
                Sign Up
              </Link>
              <Link to={"/login"}>Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
