import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  success: false,
  error: null,
  getLoading: false,
  getError: null,
  form: null,
};

export const intakeFormSlice = createSlice({
  name: "intakeFormSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.success = false;
      state.getError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addIntakeForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addIntakeForm.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(addIntakeForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateIntakeForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateIntakeForm.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(updateIntakeForm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getIntakeForm.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getIntakeForm.fulfilled, (state, action) => {
      state.getLoading = false;
      state.form = action.payload;
    });
    builder.addCase(getIntakeForm.rejected, (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    });
  },
});

export const addIntakeForm = createAsyncThunk(
  "addIntakeForm",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/intake_form/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured please try again later");
      }
    }
  }
);

export const getIntakeForm = createAsyncThunk(
  "getIntakeForm",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/intake_form/view`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      return data.result;
    } catch (error) {
      if (error?.response?.status === 404) {
        return null;
      } else {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occured please try again later");
        }
      }
    }
  }
);

export const updateIntakeForm = createAsyncThunk(
  "updateIntakeForm",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/intake_form/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured please try again later");
      }
    }
  }
);

export const { clearState } = intakeFormSlice.actions;
