import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ClassicTemplateLayout from "../layouts/ClassicTemplateLayout/ClassicTemplateLayout";
import ProfessionalTemplateLayout from "../layouts/ProfessionalTemplateLayout/ProfessionalTemplateLayout";
import StandardTemplateLayout from "../layouts/StandardTemplateLayout/StandardTemplateLayout";
import { useSelector, useDispatch } from "react-redux";
import { getIntakeForm, getWebsiteData } from "../../../features/Websites/websitesSlice";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
const Website = ({color,setColor}) => {
  const { url } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.websiteStyles);
  useEffect(() => {
    dispatch(getWebsiteData(url));
    dispatch(getIntakeForm({ url }));
  }, [dispatch, url]);

  useEffect(()=>{
    if(data){
      setColor(data?.color?data?.color:'#5459FF')
    }
  },[data])
  // console.log('from layout ',data)

  useEffect(() => {
    if (
      (pathname === `/websites/${url}` || pathname === `/websites/${url}/`) &&
      !error
    ) {
      navigate(`/websites/${url}/home`);
    }
  }, [navigate, pathname, url, error]);
  if (loading) {
    return <PageLoader />;
  }
  if (error) {
    return <h1>{error}</h1>;
  }
  if (data?.template === 1) {
    return (
      <ClassicTemplateLayout
        data={data}
        color={color} setColor={setColor}
      />
    );
  }
  if (data?.template === 2) {
    return <StandardTemplateLayout data={data} color={color} setColor={setColor}/>;
  }
  if (data?.template === 3) {
    return <ProfessionalTemplateLayout data={data} color={color} setColor={setColor}/>;
  }
};

export default Website;
