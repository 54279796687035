import React from "react";
import "./style.css";
import Toggle from "../../../components/Toggle/Toggle";
import {useForm} from 'react-hook-form'
const Integrations = () => {

  const {register} = useForm()
  return (
    <>
    <div className="outer">
    <div className="integrations-heading">Integrations</div>
      <div className="integrations-container">
        <div className="extra-right-margin">
          <Toggle label={"Google-Calendar"} id={"google-calendar"} register={register} name='google-calendar'/>
        </div>
        <div className="extra-right-margin">
          <Toggle label={"Quickbook"} id={"quickbook"} register={register} name='quickbook'/>
        </div>
        <div className="extra-right-margin">
          <Toggle label={"Zero"} id={"zero"} register={register} name='zero'/>
        </div>
        <div className="extra-right-margin">
          <Toggle label={"Outlook"} id={"outlook"} register={register} name='outlook'/>
        </div>
      </div>

    </div>
    </>
  );
};

export default Integrations;
