import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ClassicTemplateHome from "../ClassicTemplate/Home/Home";
import StandardTemplateHome from "../StandardTemplate/Home/Home";
import ProfessioalTemplateHome from "../ProfessionalTemplate/Home/Home";
import { BUSINESS_ADMIN, authorizeRole } from "../../../utills/authorizeRole";

const WebsiteHome = ({color,setColor}) => {
  const { loading, error, data } = useSelector((state) => state.websiteStyles);
  const {token,userData} = useSelector(state => state.auth)

  const isEditAccess = useMemo(()=>{
    if(token && data.business_id.id === userData.business_id.id && authorizeRole(userData.roles,BUSINESS_ADMIN)){
      return true
    }else{
      return false
    }
  },[token,data?.business_id,userData?.business_id,userData?.roles])
  if (loading) {
    return <PageLoader />;
  }
  if(error){
    return <h1>{error}</h1>
  }
  if (data?.template === 1) {
    return <ClassicTemplateHome data={data} color={color} setColor={setColor} isEditAccess={isEditAccess}/>;
  }
  if (data?.template === 2) {
    return <StandardTemplateHome data={data} color={color} setColor={setColor} isEditAccess={isEditAccess}/>;
  }
  if (data?.template === 3) {
    return <ProfessioalTemplateHome data={data} color={color} setColor={setColor} isEditAccess={isEditAccess}/>;
  }
};

export default WebsiteHome;
