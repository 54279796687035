import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Layout from "../Layout/Layout";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import Button from "../../../components/styled/Button/Button";
import { useNavigate } from "react-router-dom";
import { clearStateTemp } from "../../../features/auth/authSlice";
import qs from "query-string";
import Flex from "../../../components/styled/Flex/Flex";
import {
  clearState,
  signUpOtpVerification,
} from "../../../features/auth/forgetPasswordSlice";
import { toast } from "react-toastify";
const SignUp = () => {
  // const [step, setStep] = useState(1);
  const { step,accesstoken,is_refferal } = qs.parse(window.location.search);
  const [formData, setFormData] = useState({});
  const signupState = useSelector((state) => state.authTemp);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyLoading, verifyError, verifySuccess } = useSelector(
    (state) => state.forgetPass
  );

  const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
  const [enteredOtp, setEnteredOtp] = useState("");
  const inputRefs = useRef(
    new Array(6).fill(null).map(() => React.createRef())
  );
  const handleOtpInputChange = (index, value) => {
    const newOtpInputs = [...otpInputs];
    newOtpInputs[index] = value;

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1].current.focus();
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    }

    setOtpInputs(newOtpInputs);
    setEnteredOtp(newOtpInputs.join(""));
  };
  useEffect(() => {
    if (verifySuccess) {
      dispatch(clearState());
      dispatch(clearStateTemp());
      toast.success("Email verified successfully", {
        position: "top-right",
      });
      navigate(`/payment`);
    }
  }, [verifySuccess, dispatch, navigate,accesstoken,is_refferal]);

  const [otpError, setOtpError] = useState(null);
  const renderOtpInputs = () => {
    return otpInputs.map((value, index) => (
      <input
        key={index}
        type="number"
        value={value}
        onChange={(e) => handleOtpInputChange(index, e.target.value)}
        ref={inputRefs.current[index]}
      />
    ));
  };

  const handleOtpSubmit = () => {
    if (enteredOtp.length === 6) {
      setOtpError(null);
      dispatch(signUpOtpVerification({ verification_code: enteredOtp }));
    } else {
      setOtpError("Please enter all 6 digits.");
    }
  };

  return (
    <Layout step={step}>
      {step === "1" && <Step1 formData={formData} setFormData={setFormData} />}
      {step === "2" && <Step2 formData={formData} setFormData={setFormData} />}

      
      {signupState.success && (
        <Popup className="forget-pass-request-popup" setPopUp={() => {}}>
          <h1>Please verify your email</h1>
          <h3>Please enter the verification code sent to your email</h3>
          <div className="forget-pass-request-inputs">
            <div className="forget-pass-inputs-inner">{renderOtpInputs()}</div>
            <Flex align="center" justify="flex-end">
              <p className="global-input-error">
                {otpError || verifyError
                  ? verifyError
                    ? verifyError
                    : otpError
                  : null}
              </p>
            </Flex>
          </div>
          <div className="forget-pass-request-button">
          <Button
            label="Submit"
            variant="rounded-outline"
            handleClick={handleOtpSubmit}
            loading={verifyLoading}
            loaderColor="#3E0FFE"
          />

          </div>
        </Popup>
      )}
    </Layout>
  );
};

export default SignUp;
