import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { scrollToSection } from "../../../pages/LandingPageSection/Steps/Steps";
const Footer = () => {
  return (
    <footer>
      <section className="section7 max-width-1440">
        <div className="sec7-inner">
          <Link to={"/"}>
            <img src={"/footer/logo.png"} alt="" />
          </Link>
          <div className="sec7-middle">
            <div className="first-col">
              <Link to={"/steps"}>How it works?</Link>
              <Link to={"/help-center"}>Help Center</Link>
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </div>
            <div className="second-col">
              <Link to={"/about"}>About Us</Link>
              <Link
                to={"/steps#faqs-section"}
                onClick={() => scrollToSection("faqs-section")}
              >
                FAQ
              </Link>
              <Link to={"/terms"}>Terms & Conditions</Link>
            </div>
          </div>
          <div className="sec7-right">
            <span className="follow">Follow Us</span>
            <div className="social-icons">
              <Link
                to={"https://www.facebook.com/profile.php?id=61552361994469"}
                target="_blank"
              >
                <img src={"/footer/fb.png"} alt="" />
              </Link>
              <Link to={"https://www.instagram.com/bizopspro/"} target="_blank">
                <img src={"/footer/insta.png"} alt="" />
              </Link>
              <Link
                to={"https://www.linkedin.com/company/bizops-pro/"}
                target="_blank"
              >
                <img src={"/footer/linkedIn.png"} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <footer className="max-width-1440">
        <div className="footer-inner">
          <span className="footer-span">BizOps Pro All Rights Reserved</span>
        </div>
      </footer>
    </footer>
  );
};

export default Footer;
