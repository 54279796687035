import React from 'react'
import './Features.css'
import LandingPageLayout from '../../../components/LandingPage/Layout/LandingPageLayout'
const Features = () => {
  return (
    <LandingPageLayout>
      <div className='max-width-1440 features'>
      <div className="features-inner">
        <h1 className="headings features-heading">Features Highlights</h1>
        <p className="paragraphs features-paragraph">Manage bookings, scheduling and client management all in one place</p>
        <div className="features-cards">
          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/appointment 1.png'} alt="" />
              <p className='appointment-heading'>Appointments and Bookings</p>
            </div>
            <ul className='list-items'>
              <li>Appointments and Bookings</li>
              <li>Customer login and profiles</li>
              <li>Book via Instagram</li>
              <li>Text message appointment reminders</li>
              <li>Book via Facebook</li>
            </ul>
          </div>

          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/clientMng.png'} alt="" />
              <p className='appointment-heading'>Client Management</p>
            </div>
            <ul className='list-items'>
              <li>View customer profiles</li>
              <li>Custom Forms</li>
              
            </ul>
          </div>

          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/communication.png'} alt="" />
              <p className='appointment-heading'>Communication</p>
            </div>
            <ul className='list-items'>
              <li>Appointment confirmation, cancellation emails</li>
              <li>'Contact Us' feature on custom website</li>
              
            </ul>
          </div>

          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/integration 1.png'} alt="" />
              <p className='appointment-heading'>Integrations</p>
            </div>
            <ul className='list-items list-diplay'>
              <div className="fisrt-line">
                <li>Quickbook</li>
                <li>Zero</li>
              </div>
              <div className="second-line">
                <li>Google</li>
                <li>Outlook</li>
              </div>
            </ul>
          </div>

          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/payment.png'} alt="" />
              <p className='appointment-heading'>Payment Processing</p>
            </div>
            <ul className='list-items'>
              <li>Appointment deposits</li>
              <li>Cardless checkout</li>
              <li>Online payments via third parties</li>
              <li>Invoices</li>
            </ul>
          </div>
          <div className="features-card">
            <div className="image-appoinment">
              <img src={'/home/marketing.png'} alt="" />
              <p className='appointment-heading'>Marketing</p>
            </div>
            <ul className='list-items'>
              <li>Gather customer reviews</li>
              <li>Custom website</li>
              <li>Coupons & Promotion codes</li>
              <li>Portolio /feed from instagram</li>
            </ul>
          </div>



        </div>
      </div>
    </div>
    </LandingPageLayout>
  )
}

export default Features
