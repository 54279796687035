import React, { useEffect, useState } from "react";
import "./style.css";
import Badge from "../../../components/styled/Badge/Badge";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteVoucher,
  getAllVouchers,
  resetState,
} from "../../../features/ServiceProviderPortal/vouchersSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Popup from "../../../components/styled/Popup/Popup";
import { concatNames } from "../../../utills/concatNames";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import { toast } from "react-toastify";
import { handleUndefined } from "../../../utills/handleUndefined";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";
const Vouchers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const dispatch = useDispatch();
  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  const {
    loading,
    error,
    vouchers,
    deleteVoucherLoading,
    deleteVoucherError,
    deleteVoucherSuccess,
  } = useSelector((state) => state.vouchers);

  const [voucherPopup, setVoucherPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  useEffect(() => {
    if (token) {
      dispatch(getAllVouchers({ token, query: { currentPage, itemsPerPage } }));
    }
    if (deleteVoucherSuccess) {
      setVoucherPopup(false);
      dispatch(resetState());
      toast.success("Voucher deleted successfully", {
        position: "top-right",
      });
    }
  }, [token, dispatch, currentPage, itemsPerPage, deleteVoucherSuccess]);

  const handleDeleteClient = () => {
    const apiData = {
      token,
      id: delId,
    };
    dispatch(deleteVoucher(apiData));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div className="client-details-container" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="edit-client-heading">Vouchers</h1>
        {checkIfPermittedToSingleTab(permissions, 15, userData?.roles) && (
          <div>
            <Link to="/portal/send-voucher">
              <Button label="Send Voucher" />
            </Link>
          </div>
        )}
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>ID</TableHeadData>
                  <TableHeadData>Client Name</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Cell No</TableHeadData>
                  <TableHeadData>Voucher Amount</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                  {!(
                    !checkIfPermittedToSingleTab(
                      permissions,
                      14,
                      userData?.roles
                    ) &&
                    !checkIfPermittedToSingleTab(
                      permissions,
                      13,
                      userData?.roles
                    )
                  ) ? (
                    <TableHeadData>Action</TableHeadData>
                  ) : null}
                </TableRow>
              </TableHead>
              {conditionalRender(
                vouchers?.items?.length > 0,
                <TableBody>
                  {vouchers?.items &&
                    vouchers?.items.map((voucher, index) => (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>{voucher?.id}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {voucher?.client
                              ? concatNames(
                                  voucher?.client?.first_name,
                                  voucher?.client?.last_name
                                )
                              : "-"}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {handleUndefined(voucher?.client?.email)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {handleUndefined(voucher?.client?.phone)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>£{voucher?.total_amount}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                voucher?.status === "Sent"
                                  ? "info"
                                  : voucher?.status === "Expired"
                                  ? "error"
                                  : "success"
                              }
                            >
                              {voucher?.status}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                        {!(
                          !checkIfPermittedToSingleTab(
                            permissions,
                            14,
                            userData?.roles
                          ) &&
                          !checkIfPermittedToSingleTab(
                            permissions,
                            13,
                            userData?.roles
                          )
                        ) ? (
                          <TableBodyData>
                            <Flex align="center" gap={10} justify="center">
                              {checkIfPermittedToSingleTab(
                                permissions,
                                14,
                                userData?.roles
                              ) && (
                                <Link to={`/portal/edit-voucher/${voucher.id}`}>
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              )}
                              {checkIfPermittedToSingleTab(
                                permissions,
                                13,
                                userData?.roles
                              ) && (
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setDelId(voucher.id);
                                    setVoucherPopup(true);
                                  }}
                                />
                              )}
                            </Flex>
                          </TableBodyData>
                        ) : null}
                      </TableRow>
                    ))}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Vouchers to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                vouchers?.items?.length > 0,
                vouchers?.items?.map((voucher, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(voucher.id, voucher.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Client Name
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Link to={`/portal/client-details/${voucher.id}`}>
                                <PhoneTableSimpleText variant="link">
                                  {concatNames(
                                    voucher?.client?.first_name,
                                    voucher?.client?.last_name
                                  )}
                                </PhoneTableSimpleText>
                              </Link>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {voucher?.client?.email}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Cell No</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {voucher?.client?.phone}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Voucher Amount
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                £{voucher?.total_amount}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <Badge
                                  tone={
                                    voucher?.status === "Sent"
                                      ? "info"
                                      : voucher?.status === "Expired"
                                      ? "error"
                                      : "success"
                                  }
                                >
                                  {voucher?.status}
                                </Badge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            {!(
                              !checkIfPermittedToSingleTab(
                                permissions,
                                14,
                                userData?.roles
                              ) &&
                              !checkIfPermittedToSingleTab(
                                permissions,
                                13,
                                userData?.roles
                              )
                            ) ? (
                              <PhoneTableBodyData>
                                <Flex align="center" gap={10}>
                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    14,
                                    userData?.roles
                                  ) && (
                                    <Link
                                      to={`/portal/edit-voucher/${voucher.id}`}
                                    >
                                      <img src="/icons/edit.png" alt="edit" />
                                    </Link>
                                  )}
                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    13,
                                    userData?.roles
                                  ) && (
                                    <img
                                      src="/icons/delete.png"
                                      alt="delete"
                                      onClick={() => {
                                        setDelId(voucher.id);
                                        setVoucherPopup(true);
                                      }}
                                    />
                                  )}
                                </Flex>
                              </PhoneTableBodyData>
                            ) : null}
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>,
          <GlobalErrorHandler label={error} />
        )
      )}
      {vouchers?.meta?.totalItems > vouchers?.meta?.itemsPerPage && !error && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={vouchers?.meta?.totalPages}
        />
      )}

      {voucherPopup && (
        <Popup
          className="client-popup"
          popUp={voucherPopup}
          setPopUp={setVoucherPopup}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClient}
                loading={deleteVoucherLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setVoucherPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {deleteVoucherError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={deleteVoucherError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setVoucherPopup(false);
              dispatch(resetState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Vouchers;
