import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Flex from "../../styled/Flex/Flex";
import "./style.css";
import { ChevronUp, ChevronDown, User } from "react-feather";
import { conditionalRender } from "../../../utills/conditionalRender";
import { concatNames } from "../../../utills/concatNames";
import {
  getTeamMembersDropDown,
} from "../../../features/ServiceProviderPortal/teamMemberSlice";
import { Link } from "react-router-dom";
import { BUSINESS_ADMIN, authorizeRole } from "../../../utills/authorizeRole";

const TeamMembersDrop = ({
  label,
  selectedMember,
  setSelectedMember,
  handleChange,
  admin = true,
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { loading, error, teamMemberDrops } = useSelector(
    (state) => state.team
  );
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  useEffect(() => {
    dispatch(getTeamMembersDropDown({ token }));
  }, [dispatch, token]);

  // useEffect(() => {
  //   if (teamMemberDrops?.length === 1 && admin) {
  //     setSelectedMember(teamMemberDrops[0]);
  //   }
  // });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="global-client-dropdown" ref={dropdownRef}>
      <div
        className="global-client-drop-head"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <h2>{label}</h2>
        <Flex
          className="global-client-drop-input"
          align="center"
          justify="space-between"
          gap={10}
        >
          <Flex className="global-client-drop-input-left" gap={15}>
            <img src="/icons/user.png" alt="user" />
            <h3>
              {conditionalRender(
                loading,
                "Loading...",
                conditionalRender(
                  selectedMember,
                  concatNames(
                    selectedMember?.first_name,
                    selectedMember?.last_name
                  ),
                  "Select Team Member"
                )
              )}
            </h3>
          </Flex>
          {conditionalRender(
            open,
            <ChevronUp color="#666666" />,
            <ChevronDown color="#666666" />
          )}
        </Flex>
      </div>

      <div
        className="global-client-drop-options"
        style={{
          maxHeight: `${open ? "300px" : "0px"}`,
          overflow: `${open ? "auto" : "hidden"}`,
        }}
      >
        <div style={{ padding: "10px" }}>
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Flex
            direction="column"
            gap={10}
            className="global-client-drop-options-inner"
          >
            {!error ? (
              teamMemberDrops && teamMemberDrops?.length > 0 ? (
                teamMemberDrops?.map((member, index) => {
                  const fullName = concatNames(
                    member?.first_name,
                    member?.last_name
                  )?.toLowerCase();
                  if (admin) {
                    return (
                      <div
                        style={{
                          display: `${
                            query
                              ? fullName.includes(query.toLowerCase())
                                ? "block"
                                : "none"
                              : "block"
                          }`,
                        }}
                        key={index}
                      >
                        <Flex
                          className="global-client-drop-option"
                          gap={15}
                          align="center"
                        >
                          <Flex
                            align="center"
                            justify="center"
                            className="global-clients-drop-user"
                          >
                            <User color="#14142B" fill="#14142B" size={15} />
                          </Flex>
                          <span
                            onClick={() => {
                              setSelectedMember(member);
                              setOpen(false);
                              handleChange(member);
                            }}
                            className={`${
                              member?.id === selectedMember?.id
                                ? "client-drop-option-active"
                                : ""
                            }`}
                          >
                            {concatNames(member?.first_name, member?.last_name)}
                          </span>
                        </Flex>
                      </div>
                    );
                  } else {
                    if (authorizeRole(member?.roles, BUSINESS_ADMIN)) {
                      return null;
                    } else {
                      return (
                        <div
                          style={{
                            display: `${
                              query
                                ? fullName.includes(query.toLowerCase())
                                  ? "block"
                                  : "none"
                                : "block"
                            }`,
                          }}
                          key={index}
                        >
                          <Flex
                            className="global-client-drop-option"
                            gap={15}
                            align="center"
                          >
                            <Flex
                              align="center"
                              justify="center"
                              className="global-clients-drop-user"
                            >
                              <User color="#14142B" fill="#14142B" size={15} />
                            </Flex>
                            <span
                              onClick={() => {
                                setSelectedMember(member);
                                setOpen(false);
                                handleChange(member);
                              }}
                              className={`${
                                member?.id === selectedMember?.id
                                  ? "client-drop-option-active"
                                  : ""
                              }`}
                            >
                              {concatNames(
                                member?.first_name,
                                member?.last_name
                              )}
                            </span>
                          </Flex>
                        </div>
                      );
                    }
                  }
                })
              ) : (
                <p className="global-empty-para">
                  No Member to show, Click{" "}
                  <Link to={"/portal/add-team-member"}>here</Link> to add one
                </p>
              )
            ) : (
              <p className="dropdown-empty">{error}</p>
            )}
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default TeamMembersDrop;
