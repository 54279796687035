import React, { useEffect, useState } from "react";
import "./style.css";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/styled/Button/Button";
import Flex from "../../../components/styled/Flex/Flex";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addIntakeForm,
  getIntakeForm,
  clearState,
  updateIntakeForm,
} from "../../../features/ServiceProviderPortal/intakeFormSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { toast } from "react-toastify";
const CustomerIntakeForm = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, success, getLoading, getError, form } = useSelector(
    (state) => state.form
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getIntakeForm({ token }));
    }
    if (success) {
      dispatch(clearState());
      if (form) {
        toast.success("Intake form updates successfully", {
          position: "top-right",
        });
      } else {
        toast.success("Intake form added successfully", {
          position: "top-right",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, success]);
  useEffect(() => {
    if (form) {
      setQuestions(form?.questions);
    }
  }, [form]);

  const { register, handleSubmit } = useForm();
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");

  const convBoolToNum = (bool) => {
    if (bool) {
      return 1;
    } else {
      return 0;
    }
  };
  const handleIntakeForm = (values) => {
    for (let key of Object.keys(values)) {
      values[key] = convBoolToNum(values[key]);
    }
    values.questions = [];
    questions.forEach((question) => values.questions.push(question));
    if (form) {
      dispatch(updateIntakeForm({ token, id: form.id, data: values }));
    } else {
      dispatch(addIntakeForm({ token, data: values }));
    }
  };
  return (
    <div className="outer custom-intake-form-container">
      <Flex align="center" justify="space-between">
        <h1 className="edit-client-heading">Customer Intake Form</h1>
        {form && (
          <Checkbox
            label={"Show Form On Website"}
            id={"is_active"}
            register={register}
            name="is_active"
            checked={form?.is_active}
          />
        )}
      </Flex>
      {conditionalRender(
        getLoading,
        <PageLoader />,
        conditionalRender(
          true,
          <form onSubmit={handleSubmit(handleIntakeForm)}>
            <div className="intake-form-checkboxes">
              <Checkbox
                label={"Full Name"}
                id={"full-name"}
                register={register}
                name="is_full_name"
                checked={form?.is_full_name}
              />
              <Checkbox
                label={"Email"}
                id={"email"}
                register={register}
                name="is_email"
                checked={form?.is_email}
              />
              <Checkbox
                label={"Mobile Number"}
                id={"mbl-number"}
                register={register}
                name="is_mobile_number"
                checked={form?.is_mobile_number}
              />
              <Checkbox
                label={"Service Category"}
                id={"service-category"}
                register={register}
                name="is_service_category"
                checked={form?.is_service_category}
              />
              <Checkbox
                label={"Date"}
                id={"date"}
                register={register}
                name="is_date"
                checked={form?.is_date}
              />
              <Checkbox
                label={"Service Type(Urgent or Normal)"}
                id={"service-type"}
                register={register}
                name="is_service_type"
                checked={form?.is_service_type}
              />
              <Checkbox
                label={"Preferred Appointment Type"}
                id={"appointment-type"}
                register={register}
                name="is_preffared_appointment"
                checked={form?.is_preffared_appointment}
              />
              <Checkbox
                label={"How Long is your hair?"}
                id={"hair"}
                register={register}
                name="is_long_hair"
                checked={form?.is_long_hair}
              />
            </div>

            <div className="intake-add-question">
              <h1 className="intake-question-head mt-30">Add Question</h1>
              <input
                type="text"
                className="intake-question-input"
                placeholder="Enter your question here."
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>

            <Button
              variant="primary"
              label={"Add question"}
              handleClick={() => {
                if (
                  questions.find(
                    (q) => q.question.toLowerCase() === question.toLowerCase()
                  )
                ) {
                  return;
                } else {
                  if (!question.length > 0) {
                    return;
                  } else {
                    setQuestions([...questions, { question }]);
                    setQuestion("");
                  }
                }
              }}
            />
            <div className="intake-questions">
              {questions.map((question, index) => (
                <Flex className="questions-container mt-30">
                  <h1 className="">{question?.question}</h1>
                  <X
                    size={18}
                    fontWeight={700}
                    cursor="pointer"
                    onClick={() => {
                      const updatedQuestions = questions.filter(
                        (q) => q.question !== question.question
                      );
                      setQuestions(updatedQuestions);
                    }}
                  />
                </Flex>
              ))}
            </div>

            <div className="intake-form-btns mt-30">
              {/* <Button variant="outline" label={"Cancel"} /> */}
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loading={loading}
                loaderColor="#fff"
              />
            </div>
          </form>,
          <h1>{getError}</h1>
        )
      )}
    </div>
  );
};

export default CustomerIntakeForm;
