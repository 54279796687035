import React, { useEffect, useState } from "react";
import "./Reviews.css";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { Link } from "react-router-dom";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import Popup from "../../../components/styled/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import { clearState, deleteTestimonial, getAllTestimonials } from "../../../features/SuperAdminPortal/testimonialsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { prettifyDate } from "../../../utills/prettifyDate";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import { PhoneTable, PhoneTableBody, PhoneTableBodyData, PhoneTableBoldText, PhoneTableHead, PhoneTableHeadData, PhoneTableRow, PhoneTableSimpleText } from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { handleUndefined } from "../../../utills/handleUndefined";

const Reviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [activeOption, setActiveOption] = useState("All");
  // const [activeTab, setActiveTab] = useState("All");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { token } = useSelector((state) => state.auth);
  const { loading, error, success, testimonials, delSuccess, delError, delLoading } = useSelector(
    (state) => state.testimonials
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(getAllTestimonials({ token, query:{currentPage, itemsPerPage} }));
    }
    if (delSuccess) {
      setDelPopup(false);
      dispatch(clearState());
    }
  }, [token, dispatch, delSuccess, currentPage, itemsPerPage]);

  const handleDeleteClientReview = () => {
    dispatch(deleteTestimonial({ token, id: delId }));
  };
 
  const [delPopup, setDelPopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const [activeResRow, setActiveResRow] = useState(1);
  
  return (
    <div
      className="outer client-portal-reviews"
      style={{ position: "relative" }}
    >
      <Flex align={"center"} justify={"space-between"} gap={10}>
        <h1 className="edit-client-heading">Reviews</h1>
        <Link to={"/client-portal/add-review"}>
          <Button variant="primary" label={"Add Review"} />
        </Link>
      </Flex>
      {/* <ClientPortalTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        options={["All", "Pending", "Completed"]}
        width={400}
      /> */}
      {/* <Table>
        <TableHead>
          <TableRow>
            <TableHeadData>Name</TableHeadData>
            <TableHeadData>Email</TableHeadData>
            <TableHeadData>Service</TableHeadData>
            <TableHeadData>Review</TableHeadData>
            <TableHeadData>Date</TableHeadData>
            <TableHeadData>Status</TableHeadData>
            <TableHeadData>Action</TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableBodyData>
              <TableText>{"Muhammad Iqbal"}</TableText>
            </TableBodyData>
            <TableBodyData>
              <Link>
                <TableText>{"Iqbal@gmail.com"}</TableText>
              </Link>
            </TableBodyData>
            <TableBodyData>
              <TableText>{"Nail Work"}</TableText>
            </TableBodyData>
            <TableBodyData className="feedback-text-container">
              <TableText className="feedback-text">
                {"Nice  Experience"}
              </TableText>
            </TableBodyData>
            <TableBodyData>
              <TableText>{"15 Aug 2023"}</TableText>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center">
                <Badge tone={"success"}>{"Completed"}</Badge>
              </Flex>
            </TableBodyData>
            <TableBodyData>
              <Flex align="center" justify="center" gap={10}>
                <Link to={`/client-portal/edit-review`}>
                  <img src="/icons/edit.png" alt="edit" />
                </Link>
                <Link>
                  <img
                    src="/icons/delete.png"
                    alt="delete"
                    onClick={() => setDelPopup(true)}
                  />
                </Link>
              </Flex>
            </TableBodyData>
          </TableRow>
        </TableBody>
      </Table> */}
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          error,
          <GlobalErrorHandler label={error} />,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Service Provider</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Service</TableHeadData>
                  <TableHeadData>Review</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Action</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                testimonials?.items?.length > 0,
                <TableBody>
                  {testimonials?.items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableBodyData>
                          <TableText>
                            {handleUndefined(item?.business_id?.business_name)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>{handleUndefined(item?.business_id?.updated_by)}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>Hair Dresser</TableText>
                        </TableBodyData>
                        <TableBodyData className="feedback-text-container">
                          <TableText className={"feedback-text"}>{item?.review}</TableText>
                        </TableBodyData>
                        {/* <TableBodyData>
                          <TableText>{item?.from?.full_name}</TableText>
                        </TableBodyData> */}
                        
                        
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(item?.created_at)}
                          </TableText>
                        </TableBodyData>
                        
                        <TableBodyData>
                          <Flex align="center" justify="center" gap={10}>
                            <img
                              src="/icons/delete.png"
                              alt="edit"
                              onClick={() => {
                                setDelId(item?.id);
                                setDelPopup(true);
                              }}
                            />
                            <Link
                              to={`/client-portal/edit-review/${item?.id}`}
                            >
                              <img src="/icons/edit.png" alt="edit" />
                            </Link>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No Referrals to show" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                testimonials?.items?.length > 0,
                testimonials?.items?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Name</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {handleUndefined(item?.business_id?.business_name)}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Email</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                <TableText>{handleUndefined(item?.business_id?.updated_by)}</TableText>
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Service</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {"Hair Dresser"}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Review</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData isEmail={true}>
                              <PhoneTableSimpleText>
                                {item?.review}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Date</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.created_at)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                            {/* <PhoneTableBodyData></PhoneTableBodyData> */}
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Actions</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex
                                align="center"
                                justify="flex-start"
                                gap={10}
                              >
                                <img
                                  src="/icons/delete.png"
                                  alt="edit"
                                  onClick={() => {
                                    setDelId(item?.id);
                                    setDelPopup(true);
                                  }}
                                />
                                <Link
                                  to={`/portal/edit-client-referrals/${item?.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Invoices To Show"} />
              )}
            </ResponsiveContainer>
          </>
        )
      )}
      {testimonials?.meta?.totalItems > testimonials?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={testimonials?.meta?.totalPages}
          />
        )}
      {delPopup && !delError && (
        <Popup className="client-popup" popUp={delPopup} setPopUp={setDelPopup}>
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteClientReview}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDelPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDelPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Reviews;