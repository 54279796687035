export const TEAM_MEMBER = "Team User";
export const SUPER_ADMIN = "Super Admin";
export const BUSINESS_ADMIN = "Business Admin";
export const CLIENT = "Client";

export const authorizeRole = (userRoles = [], role) => {
  if (userRoles.find((r) => r.name === role)) {
    return true;
  } else {
    return false;
  }
};

export const checkIfRoleIsAssigned = (userPermissions = [], check) => {
  if (userPermissions?.find((permission) => permission.name === check.name)) {
    return true;
  } else {
    return false;
  }
};

export const checkIfPermittedToSingleTab = (
  userPermissions = [],
  id,
  roles
) => {
  if (authorizeRole(roles, BUSINESS_ADMIN)) {
    return true;
  } else {
    if (userPermissions?.find((p) => p?.id === id)) {
      return true;
    } else {
      return false;
    }
  }
};

function isWithinFifteenDays(createdAt) {
  const createdDate = new Date(createdAt);
  const currentDate = new Date();
  const timeDifference = currentDate - createdDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference <= 15;
}

export const chekcIfPermittedToMultipleTab = (
  userPermissions = [],
  ids = []
) => {
  const firstIds = userPermissions.map((obj) => obj.id);
  return ids.every((id) => firstIds.includes(id));
};

export const handlePaymentAndConnectAccountRedirection = (userData) => {
  if (userData.subscription_type === 9 || userData.subscription_type === '15 day free trial') {
    if (!(isWithinFifteenDays(userData?.created_at))) {
      return {
        ok: false,
        redirect: "/payment?reason=subscription-expired",
      };
    } 
    else {
      return {
        ok: true,
      };
    }
  } else {
    if (
      userData &&
      !userData?.is_payment &&
      !authorizeRole(userData.roles, TEAM_MEMBER)
    ) {
      return {
        ok: false,
        redirect: "/payment?reason=no-subscription",
      };
    } 
    else {
      return {
        ok: true,
      };
    }
  }
};
