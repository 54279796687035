import React, { useEffect, useState } from "react";
import "./style.css";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button from "../../../components/styled/Button/Button";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { getUserData } from "../../../features/auth/authSlice";
import { Link } from "react-router-dom";
import {
  businessProfileUpdate,
  updateIsVisited,
} from "../../../features/ServiceProviderPortal/updateProfileSlice";
import TimePicker from "../../../components/styled/Form/DateTimePickers/TimePicker";
import { useFileHandler } from "6pp";
import {
  websiteUpload,
  clearState,
  getWebsiteDesign,
  updateWebsiteDesign,
} from "../../../features/ServiceProviderPortal/websiteDesignSlice";
import { BASE_URL, FRONTEND_BASE_URL } from "../../../features/config";
import { toast } from "react-toastify";
import Popup from "../../../components/styled/Popup/Popup";
import { useMutation } from "@tanstack/react-query";

const WebsiteDesign = () => {
  const { token, userData } = useSelector((state) => state.auth);
  const { loading: updateBusinessLoading, success: updateBusinessSuccess } =
    useSelector((state) => state.updateProfile);
  const {
    uploadLoading,
    uploadSuccess,
    uploadError,
    loading,
    websiteData,
    error,
    updateLoading,
    updateError,
    updateSuccess,
  } = useSelector((state) => state.webDesign);
  const settingsState = useSelector((state) => state.appointmentSettings);
  const dispatch = useDispatch();
  const [createConnectAccountPopup, setCreateConnectAccountPopup] =
    useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const logo = useFileHandler("single");
  useEffect(() => {
    if (!userData?.business_id?.is_visited) {
      dispatch(
        updateIsVisited({
          token,
          data: {
            is_visited: true,
            business_name: userData?.business_id.business_name,
          },
          id: userData?.business_id.id,
        })
      );
    }
    if (updateBusinessSuccess) {
      dispatch(getUserData(token));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData?.business_id?.is_visited,
    dispatch,
    token,
    updateBusinessSuccess,
  ]);

  const { register, handleSubmit, formState, watch, reset, setValue } = useForm(
    {
      mode: "onBlur",
    }
  );
  const {
    register: connectAccountRegister,
    handleSubmit: connectAccountHandleSubmit,
    formState: { errors: connectAccountErrors },
  } = useForm();
  useEffect(() => {
    if (uploadSuccess) {
      dispatch(clearState());
      window.open(`${FRONTEND_BASE_URL}/websites/${business_url}`, "_blank");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadSuccess, dispatch]);
  useEffect(() => {
    if (token) {
      dispatch(getWebsiteDesign({ token }));
    }
    if (updateSuccess) {
      toast.success("Website updated successfully");
      dispatch(clearState());
    }
  }, [token, dispatch, uploadSuccess, updateSuccess]);
  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      dispatch(clearState());
    }
  }, [updateError, dispatch]);
  useEffect(() => {
    if (websiteData) {
      reset({
        facebook_url: websiteData?.facebook_url,
        linkedin_url: websiteData?.linkedin_url,
        instagram_url: websiteData?.instagram_url,
        business_url: websiteData?.business_url,
        monday_opening_time: websiteData?.business_hours[0]?.opening_time,
        monday_closing_time: websiteData?.business_hours[0]?.close_time,
        tuesday_opening_time: websiteData?.business_hours[1]?.opening_time,
        tuesday_closing_time: websiteData?.business_hours[1]?.close_time,
        wednesday_opening_time: websiteData?.business_hours[2]?.opening_time,
        wednesday_closing_time: websiteData?.business_hours[2]?.close_time,
        thursday_opening_time: websiteData?.business_hours[3]?.opening_time,
        thursday_closing_time: websiteData?.business_hours[3]?.close_time,
        friday_opening_time: websiteData?.business_hours[4]?.opening_time,
        friday_closing_time: websiteData?.business_hours[4]?.close_time,
        saturday_opening_time: websiteData?.business_hours[5]?.opening_time,
        saturday_closing_time: websiteData?.business_hours[5]?.close_time,
        sunday_opening_time: websiteData?.business_hours[6]?.opening_time,
        sunday_closing_time: websiteData?.business_hours[6]?.close_time,
      });
    }
  }, [websiteData, reset]);

  const handleAddWebsiteDesign = (values) => {
    const timings = [];

    if (values?.monday_opening_time?.length > 0) {
      const timingData = {
        day: "Monday",
        opening_time: values.monday_opening_time,
        close_time: values.monday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.tuesday_opening_time?.length > 0) {
      const timingData = {
        day: "Tuesday",
        opening_time: values.tuesday_opening_time,
        close_time: values.tuesday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.wednesday_opening_time?.length > 0) {
      const timingData = {
        day: "Wednesday",
        opening_time: values.wednesday_opening_time,
        close_time: values.wednesday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.thursday_opening_time?.length > 0) {
      const timingData = {
        day: "Thursday",
        opening_time: values.thursday_opening_time,
        close_time: values.thursday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.friday_opening_time?.length > 0) {
      const timingData = {
        day: "Friday",
        opening_time: values.friday_opening_time,
        close_time: values.friday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.saturday_opening_time?.length > 0) {
      const timingData = {
        day: "Saturday",
        opening_time: values.saturday_opening_time,
        close_time: values.saturday_closing_time,
      };
      timings.push(timingData);
    }
    if (values?.sunday_opening_time?.length > 0) {
      const timingData = {
        day: "Sunday",
        opening_time: values.sunday_opening_time,
        close_time: values.sunday_closing_time,
      };
      timings.push(timingData);
    }
    values.business_hours = timings;
    values.template = parseInt(values.template);
    values.logo = logo.preview;
    dispatch(websiteUpload({ token, data: { ...values } }));
  };

  const monday_opening_time = watch("monday_opening_time");
  const tuesday_opening_time = watch("tuesday_opening_time");
  const wednesday_opening_time = watch("wednesday_opening_time");
  const thursday_opening_time = watch("thursday_opening_time");
  const friday_opening_time = watch("friday_opening_time");
  const saturday_opening_time = watch("saturday_opening_time");
  const sunday_opening_time = watch("sunday_opening_time");
  const monday_closing_time = watch("monday_closing_time");
  const tuesday_closing_time = watch("tuesday_closing_time");
  const wednesday_closing_time = watch("wednesday_closing_time");
  const thursday_closing_time = watch("thursday_closing_time");
  const friday_closing_time = watch("friday_closing_time");
  const saturday_closing_time = watch("saturday_closing_time");
  const sunday_closing_time = watch("sunday_closing_time");
  const business_url = watch("business_url");

  const handleStripeConnectAccount = async () => {
    setIsFormLoading(true);
    const response = await fetch(
      `${BASE_URL}/v1/payment/create-stripe-connect-account`,
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201) {
      const data = await response.json();
      if (data && data.result.url) {
        window.open(data.result.url, "_blank");
      }
      setIsFormLoading(false);
    } else {
      setIsFormLoading(false);
    }
  };
  const businessUpdateMutation = useMutation({
    mutationFn: businessProfileUpdate,
    onError: (err) => {
      const message =
        err?.response?.data?.message || err?.message || "Some error occured";
      toast.error(message);
    },
    onSuccess: () => {
      dispatch(getUserData());
      toast.success("Stripe account added successfully");
      setCreateConnectAccountPopup(false);
    },
  });

  return (
    <div className="outer">
      <h1 className="edit-client-heading">Website Design</h1>
      {conditionalRender(
        settingsState.loading || updateBusinessLoading || loading,
        <PageLoader />,
        conditionalRender(
          !settingsState.error && !error,
          <>
            {websiteData ? (
              <>
                {" "}
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "20px",
                    marginBottom: "30px",
                  }}
                >
                   View and Edit your website from{" "}
                  <Link
                    style={{ color: "#4D43FE" }}
                    to={`/websites/${websiteData.business_url}/home`}
                    target="_blank"
                  >
                    here
                  </Link>
                </h1>
                <h2 className="website-design-head">01 Website Templates</h2>
                <span className="website-design-span">
                  Choose any one website template to build your website.
                </span>
                <form
                  onSubmit={handleSubmit((values) => {
                    console.log('Form Submitting')
                    const timings = [];

                    if (values?.monday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Monday",
                        opening_time: values.monday_opening_time,
                        close_time: values.monday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.tuesday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Tuesday",
                        opening_time: values.tuesday_opening_time,
                        close_time: values.tuesday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.wednesday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Wednesday",
                        opening_time: values.wednesday_opening_time,
                        close_time: values.wednesday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.thursday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Thursday",
                        opening_time: values.thursday_opening_time,
                        close_time: values.thursday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.friday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Friday",
                        opening_time: values.friday_opening_time,
                        close_time: values.friday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.saturday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Saturday",
                        opening_time: values.saturday_opening_time,
                        close_time: values.saturday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    if (values?.sunday_opening_time?.length > 0) {
                      const timingData = {
                        day: "Sunday",
                        opening_time: values.sunday_opening_time,
                        close_time: values.sunday_closing_time,
                      };
                      timings.push(timingData);
                    }
                    values.business_hours = timings;
                    values.template = parseInt(values.template);
                    if (logo.preview) {
                      values.logo = logo.preview;
                    }
                    dispatch(updateWebsiteDesign({ data: values }));
                  })}
                >
                  <div className="website-templates">
                    <label className="website-template" id="classic-template">
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="classic-template"
                          value={1}
                          defaultChecked={websiteData?.template === 1}
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                        />
                        <label htmlFor="classic-template">
                          Classic Template
                        </label>
                      </div>
                    </label>
                    <label className="website-template" id="standard-template">
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website2.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="standard-template"
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                          value={2}
                          defaultChecked={websiteData?.template === 2}
                        />
                        <label htmlFor="standard-template">
                          Standard Template
                        </label>
                      </div>
                    </label>
                    <label
                      className="website-template"
                      id="professional-template"
                    >
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website3.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="professional-template"
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                          value={3}
                          defaultChecked={websiteData?.template === 3}
                        />
                        <label htmlFor="professional-template">
                          Professional Template
                        </label>
                      </div>
                    </label>
                    <p className="global-input-error">
                      {formState?.errors?.template &&
                        formState?.errors?.template?.message}
                    </p>
                  </div>

                  <div className="about-page-section">
                    <div className="mt-30">
                      <div className="home-page-section-heading">
                        About Us Page
                      </div>
                    </div>
                    <div className="mt-30">
                      <div className="mt-20">
                        <div className="mt-30">
                          <div className="mt-30">
                            <div className="business-hours-checkbox">
                              <h2 className="website-design-head">
                                02 Tell your business hours
                              </h2>

                              <Checkbox
                                label="Display working time on website"
                                register={register}
                                name="show_business_hours"
                                checked={websiteData?.show_business_hours}
                              />
                            </div>

                            <div className="working-timings mt-20">
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="monday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="monday-timing"
                                    value={"Nail Art"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Monday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  <div className="open-time-label-input">
                                    <label htmlFor="monday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={monday_opening_time}
                                        onChange={(time) => {
                                          setValue("monday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="monday-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={monday_closing_time}
                                        onChange={(time) => {
                                          setValue("monday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <p className="website-design-date-error global-input-error">
                                    {formState?.errors?.monday_closing_time &&
                                      formState?.errors?.monday_closing_time
                                        ?.message}
                                  </p>
                                </div>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="tuesday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="tuesday-timing"
                                    value={"Tuesday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Tuesday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {monday_opening_time?.length > 0 &&
                                    monday_closing_time?.length > 0 &&
                                    (!tuesday_opening_time?.length > 0 ||
                                      !tuesday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            tuesday_opening_time:
                                              monday_opening_time,
                                            tuesday_closing_time:
                                              monday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="tuesday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={tuesday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "tuesday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="tuesday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={tuesday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "tuesday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.tuesday_closing_time &&
                                    formState?.errors?.tuesday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="wednesday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="wednesday-timing"
                                    value={"Wednesday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Wednesday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {tuesday_opening_time?.length > 0 &&
                                    tuesday_closing_time?.length > 0 &&
                                    (!wednesday_opening_time?.length > 0 ||
                                      !wednesday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            wednesday_opening_time:
                                              tuesday_opening_time,
                                            wednesday_closing_time:
                                              tuesday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="wednesday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={wednesday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "wednesday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="wednesday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={wednesday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "wednesday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.wednesday_closing_time &&
                                    formState?.errors?.wednesday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="thursday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="thursday-timing"
                                    value={"Thursday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Thursday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {wednesday_opening_time?.length > 0 &&
                                    wednesday_closing_time?.length > 0 &&
                                    (!thursday_opening_time?.length > 0 ||
                                      !thursday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            thursday_opening_time:
                                              wednesday_opening_time,
                                            thursday_closing_time:
                                              wednesday_closing_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                            tuesday_opening_time,
                                            tuesday_closing_time,
                                            wednesday_opening_time,
                                            wednesday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="thursday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={thursday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "thursday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="thursday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={thursday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "thursday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.thursday_closing_time &&
                                    formState?.errors?.thursday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="friday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="friday-timing"
                                    value={"Friday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Friday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {thursday_opening_time?.length > 0 &&
                                    thursday_closing_time?.length > 0 &&
                                    (!friday_opening_time?.length > 0 ||
                                      !friday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            friday_opening_time:
                                              thursday_opening_time,
                                            friday_closing_time:
                                              thursday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="friday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={friday_opening_time}
                                        onChange={(time) => {
                                          setValue("friday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="friday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={friday_closing_time}
                                        onChange={(time) => {
                                          setValue("friday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.friday_closing_time &&
                                    formState?.errors?.friday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="saturday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="saturday-timing"
                                    value={"Saturday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Saturday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {friday_opening_time?.length > 0 &&
                                    friday_closing_time?.length > 0 &&
                                    (!saturday_opening_time?.length > 0 ||
                                      !saturday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            saturday_opening_time:
                                              friday_opening_time,
                                            saturday_closing_time:
                                              friday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                            friday_opening_time,
                                            friday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="saturday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={saturday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "saturday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="saturday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={saturday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "saturday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.saturday_closing_time &&
                                    formState?.errors?.saturday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="sunday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="sunday-timing"
                                    value={"Sunday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Sunday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {saturday_opening_time?.length > 0 &&
                                    saturday_closing_time?.length > 0 &&
                                    (!sunday_opening_time?.length > 0 ||
                                      !sunday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            sunday_opening_time:
                                              saturday_opening_time,
                                            sunday_closing_time:
                                              saturday_closing_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                            friday_opening_time,
                                            friday_closing_time,
                                            saturday_closing_time,
                                            saturday_opening_time,
                                          })
                                        }
                                      >
                                        Fill Previous Timing
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="sunday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={sunday_opening_time}
                                        onChange={(time) => {
                                          setValue("sunday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="sunday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={sunday_closing_time}
                                        onChange={(time) => {
                                          setValue("sunday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.sunday_closing_time &&
                                    formState?.errors?.sunday_closing_time
                                      ?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lable-input-group">
                    <label htmlFor="address" style={{ color: "#000000" }}>
                      03 Logo for website
                    </label>
                    <div className="upload-content-image-section " id="img-box">
                      <label htmlFor="file">
                        <div>
                          {logo.file || websiteData?.logo ? (
                            <img
                              style={{ width: "111px", height: "104px" }}
                              src={
                                logo.preview ? logo.preview : websiteData?.logo
                              }
                              alt="Preview"
                            />
                          ) : (
                            <>
                              <div className="upload-photo">
                                <div className="up-img">
                                  <span className="drag-drop">
                                    Please Select Image
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </label>
                      <input
                        className="upload-content-image"
                        type="file"
                        accept="image/*"
                        name="image1"
                        id="file"
                        onChange={logo.changeHandler}
                      />
                      <label htmlFor="file" className="upload-content-label">
                        Choose image
                      </label>
                    </div>
                  </div>

                  <h2 className="website-design-head mt-30">
                    04 Social Media links
                  </h2>
                  <div className="mt-30">
                    <h2 className="website-design-head">Facebook</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Facebook Link"
                          id="facebook_url"
                          {...register("facebook_url", {
                            required: false,
                          })}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.facebook_url &&
                        formState?.errors?.facebook_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-10">
                    <h2 className="website-design-head">Instagram</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Intagram Link"
                          id="instagram_url"
                          {...register("instagram_url", {
                            required: false,
                          })}
                          // maxLength={100}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.instagram_url &&
                        formState?.errors?.instagram_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-10">
                    <h2 className="website-design-head">Linkedin</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Linkedin Link"
                          id="linkedin_url"
                          {...register("linkedin_url", {
                            required: false,
                          })}
                          // maxLength={100}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.linkedin_url &&
                        formState?.errors?.linkedin_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-30">
                    <h2 className="website-design-head">Business URL</h2>
                    <span className="website-design-span">
                    Your website URL is www.bizopspro.com/website/your name  e.g (bizopspro)
                    </span>
                    {/* <span
                      className="website-design-span"
                      style={{ display: "block" }}
                    >
                      Spaces and special characters are not allowes
                    </span> */}
                    <div className="mt-20">
                      <div className="edit-client-icon-input" id="width100" style={{gap:'2px'}}>
                        <input type="text" style={{width:'265px'}} value='www.bizopspro.com/websites/' disabled/>
                        <input
                          type="text"
                          placeholder="Business Name"
                          style={{paddingLeft:'0px'}}
                          id="business_url"
                          {...register("business_url", {
                            required: "Please Enter Your Business URL",
                            minLength: {
                              value: 3,
                              message:
                                "Url Should not be less then 3 characters",
                            },
                          })}
                          maxLength={20}
                        />
                      </div>
                    </div>
                    <p className="global-input-error">
                      {formState?.errors?.business_url &&
                        formState?.errors?.business_url?.message}
                    </p>
                  </div>
                  {/* {userData?.business_id?.is_stripe_account === "false" && (
                    <p className="accept-payment-text">
                      For accepting payments , click{" "}
                      <button
                        onClick={() => setCreateConnectAccountPopup(true)}
                        type="button"
                      >
                        here
                      </button>{" "}
                      to create a stripe account
                    </p>
                  )} */}

                  <div className="mt-30">
                    <div className="mt-30">
                      <Flex justify={"space-between"} gap={20} align="center">
                        <div>
                          <p className="global-input-error">
                            {uploadError && uploadError}
                          </p>
                        </div>
                        <Button
                          variant="primary"
                          label={"Update"}
                          type="submit"
                          loaderColor="#fff"
                          loading={updateLoading}
                        />
                      </Flex>
                    </div>
                  </div>

                  <div className="mt-30"></div>
                </form>
              </>
            ) : (
              <>
                {" "}
                <h2 className="website-design-head">01 Website Templates</h2>
                <span className="website-design-span">
                  Choose any one website template to build your website.
                </span>
                <form onSubmit={handleSubmit(handleAddWebsiteDesign)}>
                  <div className="website-templates">
                    <label className="website-template" id="classic-template">
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="classic-template"
                          value={1}
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                        />
                        <label htmlFor="classic-template">
                          Classic Template
                        </label>
                      </div>
                    </label>
                    <label className="website-template" id="standard-template">
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website2.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="standard-template"
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                          value={2}
                        />
                        <label htmlFor="standard-template">
                          Standard Template
                        </label>
                      </div>
                    </label>
                    <label
                      className="website-template"
                      id="professional-template"
                    >
                      <div className="inner-website-design">
                        <img src="/websiteDesign/website3.svg" alt="" />
                      </div>
                      <div className="web-design-input-label">
                        <input
                          type="radio"
                          name="website-template"
                          id="professional-template"
                          {...register("template", {
                            required: "Please Select any template",
                          })}
                          value={3}
                        />
                        <label htmlFor="professional-template">
                          Professional Template
                        </label>
                      </div>
                    </label>
                    <p className="global-input-error">
                      {formState?.errors?.template &&
                        formState?.errors?.template?.message}
                    </p>
                  </div>

                  <div className="about-page-section">
                    <div className="mt-30">
                      <div className="home-page-section-heading">
                        About Us Page
                      </div>
                    </div>
                    <div className="mt-30">
                      <div className="mt-20">
                        <div className="mt-30">
                          <div className="mt-30">
                            <div className="business-hours-checkbox">
                              <h2 className="website-design-head">
                                02 Tell your business hours
                              </h2>

                              <Checkbox
                                label="Display working time on website"
                                register={register}
                                name="display"
                              />
                            </div>

                            <div className="working-timings mt-20">
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="monday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="monday-timing"
                                    value={"Nail Art"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Monday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  <div className="open-time-label-input">
                                    <label htmlFor="monday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={monday_opening_time}
                                        onChange={(time) => {
                                          setValue("monday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="monday-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={monday_closing_time}
                                        onChange={(time) => {
                                          setValue("monday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <p className="website-design-date-error global-input-error">
                                    {formState?.errors?.monday_closing_time &&
                                      formState?.errors?.monday_closing_time
                                        ?.message}
                                  </p>
                                </div>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="tuesday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="tuesday-timing"
                                    value={"Tuesday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Tuesday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {monday_opening_time?.length > 0 &&
                                    monday_closing_time?.length > 0 &&
                                    (!tuesday_opening_time?.length > 0 ||
                                      !tuesday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            tuesday_opening_time:
                                              monday_opening_time,
                                            tuesday_closing_time:
                                              monday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="tuesday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={tuesday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "tuesday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="tuesday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={tuesday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "tuesday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.tuesday_closing_time &&
                                    formState?.errors?.tuesday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="wednesday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="wednesday-timing"
                                    value={"Wednesday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Wednesday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {tuesday_opening_time?.length > 0 &&
                                    tuesday_closing_time?.length > 0 &&
                                    (!wednesday_opening_time?.length > 0 ||
                                      !wednesday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            wednesday_opening_time:
                                              tuesday_opening_time,
                                            wednesday_closing_time:
                                              tuesday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="wednesday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={wednesday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "wednesday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="wednesday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={wednesday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "wednesday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.wednesday_closing_time &&
                                    formState?.errors?.wednesday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="thursday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="thursday-timing"
                                    value={"Thursday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Thursday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {wednesday_opening_time?.length > 0 &&
                                    wednesday_closing_time?.length > 0 &&
                                    (!thursday_opening_time?.length > 0 ||
                                      !thursday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            thursday_opening_time:
                                              wednesday_opening_time,
                                            thursday_closing_time:
                                              wednesday_closing_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                            tuesday_opening_time,
                                            tuesday_closing_time,
                                            wednesday_opening_time,
                                            wednesday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="thursday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={thursday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "thursday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="thursday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={thursday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "thursday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.thursday_closing_time &&
                                    formState?.errors?.thursday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="friday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="friday-timing"
                                    value={"Friday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Friday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {thursday_opening_time?.length > 0 &&
                                    thursday_closing_time?.length > 0 &&
                                    (!friday_opening_time?.length > 0 ||
                                      !friday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            friday_opening_time:
                                              thursday_opening_time,
                                            friday_closing_time:
                                              thursday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="friday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={friday_opening_time}
                                        onChange={(time) => {
                                          setValue("friday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="friday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={friday_closing_time}
                                        onChange={(time) => {
                                          setValue("friday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.friday_closing_time &&
                                    formState?.errors?.friday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="saturday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="saturday-timing"
                                    value={"Saturday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Saturday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {friday_opening_time?.length > 0 &&
                                    friday_closing_time?.length > 0 &&
                                    (!saturday_opening_time?.length > 0 ||
                                      !saturday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            saturday_opening_time:
                                              friday_opening_time,
                                            saturday_closing_time:
                                              friday_closing_time,
                                            monday_opening_time,
                                            monday_closing_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                            friday_opening_time,
                                            friday_closing_time,
                                          })
                                        }
                                      >
                                        Copy Previous Day
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="saturday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={saturday_opening_time}
                                        onChange={(time) => {
                                          setValue(
                                            "saturday_opening_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="saturday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={saturday_closing_time}
                                        onChange={(time) => {
                                          setValue(
                                            "saturday_closing_time",
                                            time
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.saturday_closing_time &&
                                    formState?.errors?.saturday_closing_time
                                      ?.message}
                                </p>
                              </div>
                              <div className="working-time-open-close">
                                <label
                                  htmlFor="sunday-timing"
                                  className="business-service"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="sunday-timing"
                                    value={"Sunday"}
                                    checked
                                  />
                                  <span className="business-service-label">
                                    Sunday
                                  </span>
                                </label>
                                <div className="open-close-time">
                                  {saturday_opening_time?.length > 0 &&
                                    saturday_closing_time?.length > 0 &&
                                    (!sunday_opening_time?.length > 0 ||
                                      !sunday_closing_time?.length > 0) && (
                                      <button
                                        className="fill-previous-btn"
                                        onClick={() =>
                                          reset({
                                            sunday_opening_time:
                                              saturday_opening_time,
                                            sunday_closing_time:
                                              saturday_closing_time,
                                            monday_closing_time,
                                            monday_opening_time,
                                            tuesday_closing_time,
                                            tuesday_opening_time,
                                            wednesday_closing_time,
                                            wednesday_opening_time,
                                            thursday_closing_time,
                                            thursday_opening_time,
                                            friday_opening_time,
                                            friday_closing_time,
                                            saturday_closing_time,
                                            saturday_opening_time,
                                          })
                                        }
                                      >
                                        Fill Previous Timing
                                      </button>
                                    )}
                                  <div className="open-time-label-input">
                                    <label htmlFor="sunday-open-timing">
                                      Opening Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={sunday_opening_time}
                                        onChange={(time) => {
                                          setValue("sunday_opening_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="close-time-label-input">
                                    <label htmlFor="sunday-close-timing">
                                      Closing Time
                                    </label>
                                    <div id="width-118">
                                      <TimePicker
                                        value={sunday_closing_time}
                                        onChange={(time) => {
                                          setValue("sunday_closing_time", time);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <p className="website-design-date-error global-input-error">
                                  {formState?.errors?.sunday_closing_time &&
                                    formState?.errors?.sunday_closing_time
                                      ?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lable-input-group">
                    <label htmlFor="address" style={{ color: "#000000" }}>
                      03 Logo for website
                    </label>
                    <div className="upload-content-image-section " id="img-box">
                      <label htmlFor="file">
                        <div>
                          {logo.file ? (
                            <img
                              style={{ width: "111px", height: "104px" }}
                              src={logo.preview}
                              alt="Preview"
                            />
                          ) : (
                            <>
                              <div className="upload-photo">
                                <div className="up-img">
                                  <span className="drag-drop">
                                    Please Select Image
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </label>
                      <input
                        className="upload-content-image"
                        type="file"
                        accept="image/*"
                        name="image1"
                        id="file"
                        onChange={logo.changeHandler}
                      />
                      <label htmlFor="file" className="upload-content-label">
                        Choose image
                      </label>
                    </div>
                  </div>

                  <h2 className="website-design-head mt-30">
                    04 Social Media links
                  </h2>
                  <div className="mt-30">
                    <h2 className="website-design-head">Facebook</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Facebook Link"
                          id="facebook_url"
                          {...register("facebook_url", {
                            required: false,
                          })}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.business_url &&
                        formState?.errors?.business_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-10">
                    <h2 className="website-design-head">Instagram</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Intagram Link"
                          id="instagram_url"
                          {...register("instagram_url", {
                            required: false,
                          })}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.business_url &&
                        formState?.errors?.business_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-10">
                    <h2 className="website-design-head">Linkedin</h2>
                    <div className="mt-10">
                      <div className="edit-client-icon-input" id="width100">
                        <input
                          type="text"
                          placeholder="Linkedin Link"
                          id="linkedin_url"
                          {...register("linkedin_url", {
                            required: false,
                          })}
                          maxLength={100}
                        />
                      </div>
                    </div>
                    {/* <p className="global-input-error">
                      {formState?.errors?.business_url &&
                        formState?.errors?.business_url?.message}
                    </p> */}
                  </div>
                  <div className="mt-30">
                    <h2 className="website-design-head">Business URL</h2>
                    <span className="website-design-span">
                    Your website URL is www.bizopspro.com/website/your name  e.g (bizopspro)
                  
                    </span>
                    {/* <span
                      className="website-design-span"
                      style={{ display: "block" }}
                    >
                      Spaces and special characters are not allowes
                    </span> */}
                     <div className="mt-20">
                      <div className="edit-client-icon-input" id="width100" style={{gap:'2px'}}>
                        <input type="text" style={{width:'265px'}} value='www.bizopspro.com/websites/' disabled/>
                        <input
                          type="text"
                          placeholder="Business Name"
                          style={{paddingLeft:'0px'}}
                          id="business_url"
                          {...register("business_url", {
                            required: "Please Enter Your Business URL",
                            minLength: {
                              value: 3,
                              message:
                                "Url Should not be less then 3 characters",
                            },
                          })}
                          maxLength={20}
                        />
                      </div>
                    </div>
                    <p className="global-input-error">
                      {formState?.errors?.business_url &&
                        formState?.errors?.business_url?.message}
                    </p>
                  </div>
                  {/* {userData?.business_id?.is_stripe_account === "false" && (
                    <p className="accept-payment-text">
                      For accepting payments , click{" "}
                      <button
                        onClick={() => setCreateConnectAccountPopup(true)}
                        type="button"
                      >
                        here
                      </button>{" "}
                      to create a stripe account
                    </p>
                  )} */}

                  <div className="mt-30">
                    <div className="mt-30">
                      <Flex justify={"space-between"} gap={20} align="center">
                        <div>
                          <p className="global-input-error">
                            {uploadError && uploadError}
                          </p>
                        </div>
                        <Button
                          variant="primary"
                          label={"Save & Edit"}
                          type="submit"
                          loaderColor="#fff"
                          loading={uploadLoading}
                        />
                      </Flex>
                    </div>
                  </div>

                  <div className="mt-30"></div>
                </form>
              </>
            )}
          </>,
          <h1>{settingsState.error}</h1>
        )
      )}

      {createConnectAccountPopup && (
        <Popup
          popUp={createConnectAccountPopup}
          setPopUp={setCreateConnectAccountPopup}
          className="create-connect-account-popup"
        >
          <div className="create-connect-account-inner">
            <Flex
              align="center"
              justify="center"
              className="create-connect-account-logo"
            >
              <img src="/footer/logo.png" alt="" />
            </Flex>
            <h1>Create Connect Account</h1>
            <p>
              Click The button and Complete your connect account from stripe to
              receive apppointment payments and provide secret and private key
              from your stripe dashboard
            </p>

            <Flex
              align="center"
              justify="center"
              className="create-connect-account-btn"
            >
              <Button
                label="Create Connect Account"
                variant="outline"
                handleClick={() => handleStripeConnectAccount()}
                loading={isFormLoading}
                loaderColor="#3E0FFE"
              />
            </Flex>
            <p>
              After completing account setup , copy your Public and private key
              from your dashboard and paste them here
            </p>
            <form
              onSubmit={connectAccountHandleSubmit((values) => {
                const apiData = new FormData();
                apiData.append("public_key", values.public_key);
                apiData.append("secret_key", values.secret_key);
                apiData.append("is_stripe_account", true);
                businessUpdateMutation.mutate({ token, data: apiData });
              })}
            >
              <Flex
                className={`global-input-container add-extra-service-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="public_key">Public Key *</label>
                <div className="global-input-container-input">
                  <input
                    type={"text"}
                    placeholder={"Paste your public key"}
                    {...connectAccountRegister("public_key", {
                      required: "Please provide public key",
                    })}
                    id="public_key"
                  />
                </div>
                <p className="global-input-error mb-10">
                  {connectAccountErrors?.public_key &&
                    connectAccountErrors?.public_key?.message}
                </p>
              </Flex>
              <Flex
                className={`global-input-container add-extra-service-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="secret_key">Secret Key *</label>
                <div className="global-input-container-input">
                  <input
                    type={"text"}
                    placeholder={"Paste your secret key"}
                    {...connectAccountRegister("secret_key", {
                      required: "Please provide secret key",
                    })}
                    id="secret_key"
                  />
                </div>
                <p className="global-input-error mb-10">
                  {connectAccountErrors?.secret_key &&
                    connectAccountErrors?.secret_key?.message}
                </p>
              </Flex>
              <Flex align="center" justify="space-between">
                <p className="global-input-error">{error && error}</p>
                <Button
                  type="submit"
                  label="Save"
                  loading={loading}
                  loaderColor="#fff"
                />
              </Flex>
            </form>
          </div>
        </Popup>
      )}

      {/* {checkUrl.error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={checkUrl.error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              dispatch(clearState());
            }}
          />
        </Popup>
      )} */}
    </div>
  );
};

export default WebsiteDesign;
