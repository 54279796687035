import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft, ChevronDown } from "react-feather";
import Badge from "../../../components/styled/Badge/Badge";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import Flex from "../../../components/styled/Flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  getClient,
  clearState,
} from "../../../features/ServiceProviderPortal/getClientsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import { concatNames } from "../../../utills/concatNames";
import { prettifyDate } from "../../../utills/prettifyDate";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Button from "../../../components/styled/Button/Button";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
const ClientDetails = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { loading, error, client } = useSelector((state) => state.clients);
  const { id } = useParams();
  useEffect(() => {
    if (token && id) {
      dispatch(getClient({ token, id }));
    }
  }, [token, id, dispatch]);
  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div className="client-details-container" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <Link
          to={"/portal/clients"}
          className="back"
          onClick={() => dispatch(clearState())}
        >
          <ArrowLeft size={24} color="#5459FF" /> Back
        </Link>
        <Link to={`/portal/voucher-details/${id}`}>
          <Button label="View Vouchers" />
        </Link>
      </Flex>
      {conditionalRender(
        loading,
        <PageLoader />,
        conditionalRender(
          !error,
          <>
            <h1 className="edit-client-heading">
              {`Client Record - ${concatNames(
                client?.first_name,
                client?.last_name
              )}`}
            </h1>
            <div
              className="client-information"
              style={{
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
            >
              <ClientDetailsBadge
                label="Client Id"
                value={conditionalRender(
                  client?.client_no,
                  client?.client_no,
                  "-"
                )}
              />
              <ClientDetailsBadge
                label="Joining Date"
                value={prettifyDate(client?.created_at)}
              />
              <ClientDetailsBadge label="Email" value={client?.email} />
              <ClientDetailsBadge label="Mobile Number" value={client?.phone} />
              <ClientDetailsBadge
                label="Total Appointments"
                value={client?.appointments?.length}
              />
              <ClientDetailsBadge
                  label="Vouchers"
                  value={client?.vouchers?.length}
                />
            </div>
            <div
              className="client-information-second"
              style={{ borderTopLeftRadius: "0", borderTopRightRadius: "0" }}
            >

              <ClientDetailsBadge label="Notes" value={client?.notes} />
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>Appointment ID</TableHeadData>
                  <TableHeadData>Service Type</TableHeadData>
                  <TableHeadData>Appointment Date</TableHeadData>
                  {/* <TableHeadData>Service provider</TableHeadData> */}
                  {/* <TableHeadData>Voucher</TableHeadData> */}
                  <TableHeadData>Amount</TableHeadData>
                  <TableHeadData>Status</TableHeadData>
                </TableRow>
              </TableHead>
              {conditionalRender(
                client?.appointments?.length > 0,
                <TableBody>
                  {client?.appointments.map((appointment, index) => {
                    return (
                      <TableRow key={appointment?.id}>
                        <TableBodyData>
                          <TableText variant="link">
                            {appointment?.id}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>Hair Styling</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {prettifyDate(appointment?.date)}
                          </TableText>
                        </TableBodyData>
                        {/* <TableBodyData>
                          <TableText>$120</TableText>
                        </TableBodyData> */}
                        <TableBodyData>
                          <TableText>£120</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <Flex align="center" justify="center">
                            <Badge
                              tone={
                                appointment?.status === 25
                                  ? "error"
                                  : appointment?.status === 22
                                  ? "success"
                                  : appointment?.status === 24
                                  ? "error"
                                  : "info"
                              }
                            >
                              {appointment?.status === 25
                                ? "Cancelled"
                                : appointment?.status === 22
                                ? "Completed"
                                : appointment?.status === 24
                                ? "No Show"
                                : "Upcoming"}
                            </Badge>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  })}
                </TableBody>,
                <GlobalEmptyDataHandler label="No appoinments associated with this client" />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                client?.appointments?.length > 0,
                client?.appointments?.map((item, index) => {
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <PhoneTable>
                        <PhoneTableHead
                          onClick={() => setActiveResRow(index + 1)}
                        >
                          <PhoneTableRow>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>Id</PhoneTableBoldText>
                            </PhoneTableHeadData>
                            <PhoneTableHeadData>
                              <PhoneTableBoldText>
                                {conditionalRender(item.id, item.id, "-")}
                              </PhoneTableBoldText>
                            </PhoneTableHeadData>
                          </PhoneTableRow>
                        </PhoneTableHead>
                        <PhoneTableBody
                          className={`${
                            index + 1 === activeResRow
                              ? "responsive-table-body-show"
                              : "responsive-table-body-hide"
                          }`}
                        >
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Service Type
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                Hair Styling
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Appointment Date
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                {prettifyDate(item?.date)}
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>
                                Service Provider
                              </PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>
                                Business
                              </PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          {/* <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Service Provider</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>Business</PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow> */}
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Amount</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>£120</PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Amount</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <PhoneTableSimpleText>£120</PhoneTableSimpleText>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                          <PhoneTableRow>
                            <PhoneTableBodyData>
                              <PhoneTableBoldText>Status</PhoneTableBoldText>
                            </PhoneTableBodyData>
                            <PhoneTableBodyData>
                              <Flex align="center" justify="flex-start">
                                <Badge
                                  tone={
                                    item?.status === 25
                                      ? "error"
                                      : item?.status === 22
                                      ? "success"
                                      : item?.status === 24
                                      ? "error"
                                      : "info"
                                  }
                                >
                                  {item?.status === 25
                                    ? "Cancelled"
                                    : item?.status === 22
                                    ? "Completed"
                                    : item?.status === 24
                                    ? "No Show"
                                    : "Upcomming"}
                                </Badge>
                              </Flex>
                            </PhoneTableBodyData>
                          </PhoneTableRow>
                        </PhoneTableBody>
                      </PhoneTable>
                      <div className="global-responsive-table-chevron">
                        <ChevronDown
                          className={`${
                            index + 1 === activeResRow
                              ? "table-chevron-rotate"
                              : "table-chevron-rerotate"
                          }`}
                        />
                      </div>
                    </div>
                  );
                }),
                <GlobalEmptyDataHandler label={"No Appointments to show"} />
              )}
            </ResponsiveContainer>
          </>,
          <h1>{error}</h1>
        )
      )}
    </div>
  );
};

export function ClientDetailsBadge({ label, value }) {
  return (
    <div className="client-details-badge">
      <h3>{label}</h3>
      <h2>{value}</h2>
    </div>
  );
}

export default ClientDetails;
