import React, { useEffect, useState } from "react";
import "./Referrals.css";
import Flex from "../../../components/styled/Flex/Flex";
import { Link } from "react-router-dom";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBusinessReferrals,
  clearState,
  addBusinessRefferal,
  getSingleBusinessRefferal,
  updateBusinessRefferal,
  deleteBusinessRefferal,
} from "../../../features/ServiceProviderPortal/businessReferralsSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import { prettifyDate } from "../../../utills/prettifyDate";
import { handleUndefined } from "../../../utills/handleUndefined";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import Pagination from "../../../components/styled/Pagination/Pagination";
import Popup from "../../../components/styled/Popup/Popup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ServiceProviderDropDown from "../../../components/Portal/ServiceProviderDropDown/ServiceProviderDropDown";
import { emailPattern, namePattern } from "../../../utills/Schems";

const Referrals = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const {
    loading,
    error,
    addSuccess,
    referrals,
    addError,
    addLoading,
    updateSuccess,
    updateLoading,
    updateError,
    refferal,
    delLoading,
    delError,
    delSuccess,
  } = useSelector((state) => state.businessReferrals);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: editRegister,
    handleSubmit: handleEditSubmit,
    formState: { errors: editErrors },
    reset: editReset,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeResRow, setActiveResRow] = useState(1);
  const [addReferralPopup, setAddReferralPopup] = useState(false);
  const [updateReferralPopup, setUpdateReferralPopup] = useState(false);
  const [deleteReferralPopup, setDeleteReferralPopup] = useState(false);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
  const [serviceProviderError, setServiceProviderError] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(
        getAllBusinessReferrals({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (addSuccess) {
      toast.success("Referral added successfully");
      dispatch(clearState());
      setAddReferralPopup(false);
      setSelectedServiceProvider(null);
      reset(undefined);
    }
    if (updateSuccess) {
      toast.success("Referral updated successfully");
      dispatch(clearState());
      setUpdateReferralPopup(false);
      setUpdateId(null);
    }
    if (delSuccess) {
      toast.success("Referral deleted successfully");
      dispatch(clearState());
      setDeleteReferralPopup(false);
      setUpdateId(null);
    }
  }, [
    dispatch,
    token,
    currentPage,
    itemsPerPage,
    reset,
    addSuccess,
    updateSuccess,
    delSuccess,
  ]);

  useEffect(() => {
    if (refferal) {
      editReset({
        name: refferal?.name,
        email: refferal?.email,
      });
    }
  }, [refferal, editReset]);

  const handleAddReferral = (values) => {
    if (!selectedServiceProvider) {
      setServiceProviderError("Please select service provider");
    } else {
      setServiceProviderError(null);
      values.business_id = selectedServiceProvider.id;
      dispatch(addBusinessRefferal({ token, data: { ...values } }));
    }
  };
  const handleUpdateReferral = (values) => {
    if (!selectedServiceProvider) {
      setServiceProviderError("Please Select Subscription");
    } else {
      values.business_id = selectedServiceProvider.id;
      dispatch(
        updateBusinessRefferal({ token, data: { ...values }, id: updateId })
      );
    }
  };

  return (
    <div className="outer" style={{ position: "relative" }}>
      <Flex align="center" justify="space-between">
        <h1 className="team-member-details-head">Referrals</h1>
        <Flex align="center">
          <Link>
            <Button
              label="Add Referral"
              handleClick={() => setAddReferralPopup(true)}
            />
          </Link>
        </Flex>
      </Flex>
      <div className="mt-30">
        {conditionalRender(
          loading,
          <PageLoader />,
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadData>From</TableHeadData>
                  <TableHeadData>To</TableHeadData>
                  <TableHeadData>Email</TableHeadData>
                  <TableHeadData>Date</TableHeadData>
                  <TableHeadData>Actions</TableHeadData>
                </TableRow>
              </TableHead>

              {conditionalRender(
                !error,
                conditionalRender(
                  referrals?.items?.length > 0,
                  <TableBody>
                    {referrals?.items.map((referral, index) => {
                      return (
                        <TableRow key={index}>
                          <TableBodyData>
                            <TableText>
                              {handleUndefined(
                                referral?.business_id?.business_name
                              )}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{referral?.name}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>{referral?.email}</TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <TableText>
                              {prettifyDate(referral?.created_at)}
                            </TableText>
                          </TableBodyData>
                          <TableBodyData>
                            <Flex align="center" justify="center" gap={5}>
                              <Link>
                                <img
                                  src="/icons/edit.png"
                                  alt="edit"
                                  onClick={() => {
                                    setUpdateReferralPopup(true);
                                    setUpdateId(referral?.id);
                                    dispatch(
                                      getSingleBusinessRefferal({
                                        token,
                                        id: referral?.id,
                                      })
                                    );
                                  }}
                                />
                              </Link>
                              <img
                                src="/icons/delete.png"
                                alt="delete"
                                onClick={() => {
                                  setUpdateId(referral?.id);
                                  setDeleteReferralPopup(true);
                                }}
                              />
                            </Flex>
                          </TableBodyData>
                        </TableRow>
                      );
                    })}
                  </TableBody>,
                  <GlobalEmptyDataHandler label="No referrals to show" />
                ),
                <GlobalErrorHandler label={error} />
              )}
            </Table>
            <ResponsiveContainer>
              {conditionalRender(
                !error,
                conditionalRender(
                  referrals?.items?.length > 0,
                  referrals?.items?.map((referral, index) => {
                    return (
                      <div key={index} style={{ position: "relative" }}>
                        <PhoneTable>
                          <PhoneTableHead
                            onClick={() => setActiveResRow(index + 1)}
                          >
                            <PhoneTableRow>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>From</PhoneTableBoldText>
                              </PhoneTableHeadData>
                              <PhoneTableHeadData>
                                <PhoneTableBoldText>
                                  {handleUndefined(
                                    referral?.business_id?.business_name
                                  )}
                                </PhoneTableBoldText>
                              </PhoneTableHeadData>
                            </PhoneTableRow>
                          </PhoneTableHead>
                          <PhoneTableBody
                            className={`${
                              index + 1 === activeResRow
                                ? "responsive-table-body-show"
                                : "responsive-table-body-hide"
                            }`}
                          >
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>To</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(referral?.name)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Email</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <PhoneTableSimpleText>
                                  {handleUndefined(referral?.email)}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Date</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData isEmail={true}>
                                <PhoneTableSimpleText>
                                  {handleUndefined(
                                    prettifyDate(referral?.created_at)
                                  )}
                                </PhoneTableSimpleText>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                            <PhoneTableRow>
                              <PhoneTableBodyData>
                                <PhoneTableBoldText>Actions</PhoneTableBoldText>
                              </PhoneTableBodyData>
                              <PhoneTableBodyData>
                                <Flex align="center" gap={10}>
                                  <img
                                    src="/icons/delete.png"
                                    alt="edit"
                                    onClick={() => {
                                      setUpdateId(referral?.id);
                                      setDeleteReferralPopup(true);
                                    }}
                                  />
                                  <img
                                    src="/icons/edit.png"
                                    alt="edit"
                                    onClick={() => {
                                      setUpdateReferralPopup(true);
                                      setUpdateId(referral?.id);
                                      dispatch(
                                        getSingleBusinessRefferal({
                                          token,
                                          id: referral?.id,
                                        })
                                      );
                                    }}
                                  />
                                </Flex>
                              </PhoneTableBodyData>
                            </PhoneTableRow>
                          </PhoneTableBody>
                        </PhoneTable>
                        <div className="global-responsive-table-chevron">
                          <ChevronDown
                            className={`${
                              index + 1 === activeResRow
                                ? "table-chevron-rotate"
                                : "table-chevron-rerotate"
                            }`}
                          />
                        </div>
                      </div>
                    );
                  }),
                  <GlobalEmptyDataHandler label={"No referrals To Show"} />
                ),
                <GlobalErrorHandler label={error} />
              )}
            </ResponsiveContainer>
            {referrals?.meta?.totalItems > referrals?.meta?.itemsPerPage &&
              !error && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={referrals?.meta?.totalPages}
                />
              )}
          </>
        )}
      </div>

      {addReferralPopup && (
        <Popup
          popUp={addReferralPopup}
          setPopUp={setAddReferralPopup}
          className="add-referral-popup"
        >
          <h1>Add Referral</h1>
          <form onSubmit={handleSubmit(handleAddReferral)}>
            <div className="add-testimonial-inputs">
              <div className="add-testimonial-input mb-10">
                <ServiceProviderDropDown
                  label="From*"
                  selectedServiceProvider={selectedServiceProvider}
                  setSelectedServiceProvider={setSelectedServiceProvider}
                />
                <p className="global-input-error mb-10">
                  {serviceProviderError && serviceProviderError}
                </p>
              </div>
              <Flex
                className={`global-input-container add-testimonial-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="title">To*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Full Name"}
                    {...register("name", {
                      required: "Please Enter Name",
                      maxLength: {
                        value: 20,
                        message: "Not should be more then 20 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then 3 characters",
                      },
                      pattern: namePattern,
                    })}
                    id="title"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.name && errors?.name?.message}
                </p>
                <div className="global-input-container-input global-input-container-input-with-image mt-20">
                  <input
                    type={"email"}
                    placeholder={"Enter Email"}
                    {...register("email", {
                      required: "Please Enter Email",
                      pattern: emailPattern,
                    })}
                    id="email"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {errors?.email && errors?.email?.message}
                </p>
              </Flex>
            </div>
            <Flex align="center" justify="flex-end">
              <p className="global-input-error mb-20">{addError && addError}</p>
            </Flex>

            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => setAddReferralPopup(false)}
              />
              <Button
                label="Submit"
                loading={addLoading}
                loaderColor="#fff"
                type="submit"
              />
            </Flex>
          </form>
        </Popup>
      )}
      {updateReferralPopup && (
        <Popup
          popUp={updateReferralPopup}
          setPopUp={setUpdateReferralPopup}
          className="add-referral-popup"
        >
          <h1>Update Referral</h1>
          <form onSubmit={handleEditSubmit(handleUpdateReferral)}>
            <div className="add-testimonial-inputs">
              <div className="add-testimonial-input mb-10">
                <ServiceProviderDropDown
                  label="From*"
                  selectedServiceProvider={selectedServiceProvider}
                  setSelectedServiceProvider={setSelectedServiceProvider}
                />
                <p className="global-input-error mb-10">
                  {serviceProviderError && serviceProviderError}
                </p>
              </div>
              <Flex
                className={`global-input-container add-testimonial-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="title">To*</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Full Name"}
                    {...editRegister("name", {
                      required: "Please Enter Name",
                      maxLength: {
                        value: 20,
                        message: "Not should be more then 20 characters",
                      },
                      minLength: {
                        value: 3,
                        message: "Not should be less then 3 characters",
                      },
                      pattern: namePattern,
                    })}
                    id="title"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {editErrors?.name && editErrors?.name?.message}
                </p>
                <div className="global-input-container-input global-input-container-input-with-image mt-20">
                  <input
                    type={"email"}
                    placeholder={"Enter Email"}
                    {...editRegister("email", {
                      required: "Please Enter Email",
                      pattern: emailPattern,
                    })}
                    id="email"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                <p className="global-input-error">
                  {editErrors?.email && editErrors?.email?.message}
                </p>
              </Flex>
            </div>
            <Flex align="center" justify="flex-end">
              <p className="global-input-error mb-20">
                {updateError && updateError}
              </p>
            </Flex>

            <Flex align="center" justify="flex-end" gap={10}>
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => setUpdateReferralPopup(false)}
              />
              <Button
                label="Submit"
                loading={updateLoading}
                loaderColor="#fff"
                type="submit"
              />
            </Flex>
          </form>
        </Popup>
      )}

      {deleteReferralPopup && !delError && (
        <Popup
          className="client-popup"
          popUp={deleteReferralPopup}
          setPopUp={setDeleteReferralPopup}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>

            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={() => {
                  dispatch(deleteBusinessRefferal({ token, id: updateId }));
                }}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setDeleteReferralPopup(false);
                  setUpdateId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}

      {delError && (
        <Popup className="del-referral-popup" setPopUp={() => {}}>
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setDeleteReferralPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default Referrals;
