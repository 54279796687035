import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL, globalClearState, globalInitialState } from "../config";
import axios from "axios";
import { checkIfRoleIsAssigned } from "../../utills/authorizeRole";

const initialState = {
  ...globalInitialState,
  userPermissions: null,
  userPermissionLoading: false,
  usePermissionError: null,
  permissions: null,
  activeIds: null,
};

export const accessLevelsSlice = createSlice({
  name: "accessLevelsSlice",
  initialState,
  reducers: {
    clearState: (state) => {
      globalClearState(state);
    },
    setActiveIds:(state,action)=>{
        state.activeIds = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAccessLevels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAccessLevels.fulfilled, (state, action) => {
      state.loading = false;
      state.permissions = action.payload;
    });
    builder.addCase(getAllAccessLevels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSingleUserAccessLevels.pending, (state) => {
      state.userPermissionLoading = true;
    });
    builder.addCase(getSingleUserAccessLevels.fulfilled, (state, action) => {
      state.userPermissionLoading = false;
      state.userPermissions = action.payload;
    });
    builder.addCase(getSingleUserAccessLevels.rejected, (state, action) => {
      state.userPermissionLoading = false;
      state.usePermissionError = action.payload;
    });
    builder.addCase(getUserPermissions.pending, (state) => {
      state.getLoading = true;
    });
    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.getLoading = false;
      state.permissions = action.payload;
    });
    builder.addCase(getUserPermissions.rejected, (state, action) => {
      state.getLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateUserAccess.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateUserAccess.fulfilled, (state, action) => {
      state.updateLoading = false;
      state.updateSuccess = true;
    });
    builder.addCase(updateUserAccess.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });
    builder.addCase(removeUserAccess.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(removeUserAccess.fulfilled, (state, action) => {
      state.delLoading = false;
      state.delSuccess = true;
    });
    builder.addCase(removeUserAccess.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });
  },
});

export const getAllAccessLevels = createAsyncThunk(
  "getAllAccessLevels",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/permissions/manage?page=1&limit=40`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);
export const getSingleUserAccessLevels = createAsyncThunk(
  "getSingleUserAccessLevels",
  async (apiData, { rejectWithValue, dispatch, getState }) => {
    try {
      const permissions = apiData.permissions;
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.get(
        `${BASE_URL}/v1/users/permissions/user/${apiData.id}`,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      const userPermissions = data.result;
      const activeIds = [];
      permissions.items.forEach((i) => {
        i.permissions.every((item) => {
          if (checkIfRoleIsAssigned(userPermissions, item)) {
            if (!activeIds.includes(i.id)) {
              activeIds.push(i.id);
            }
            return false;
          } else {
            return true;
          }
        });
      });
      if(activeIds.length > 0){
          dispatch(accessLevelsSlice.actions.setActiveIds(activeIds))
      }
      return data.result;
    } catch (error) {
        console.log(error)
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const getUserPermissions = createAsyncThunk(
  "getUserPermissions",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }
      const { data } = await axios.get(`${BASE_URL}/v1/users/permissions`, {
        headers: {
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const updateUserAccess = createAsyncThunk(
  "updateUserAccess",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/bulk/add/premission`,
        apiData.data,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);
export const removeUserAccess = createAsyncThunk(
  "removeUserAccess",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Please provide token");
      }

      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/remove/permission`,
        apiData.data,
        {
          headers: {
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some error occured please try again later");
      }
    }
  }
);

export const { clearState } = accessLevelsSlice.actions;
