import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";
const initialState = {
  loading: false,
  success: false,
  teamMembers: null,
  teamMember: null,
  error: null,
  teamMembersDrop: null,
  teamMembersDropLoading: false,
  teamMembersDropError: null,
  delLoading: false,
  delSuccess: false,
  delError: null,
  singleLoading: false,
  singleError: null,
  updateLoading: false,
  updateError: null,
  inviteLoading: false,
  inviteError: null,
  inviteSuccess: false,
  teamMemberDrops: null,
};

export const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = null;
      state.delSuccess = false;
      state.delError = null;
      state.singleError = null;
      state.updateError = null;
      state.inviteError = null;
      state.inviteSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // Get All Team Member cases
    builder.addCase(getAllTeamMembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTeamMembers.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMembers = action.payload;
    });
    builder.addCase(getAllTeamMembers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getTeamMembersDropDown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMembersDropDown.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMemberDrops = action.payload;
    });
    builder.addCase(getTeamMembersDropDown.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Get Dropdowns Data
    builder.addCase(getTeamDrops.pending, (state) => {
      state.teamMembersDropLoading = true;
    });
    builder.addCase(getTeamDrops.fulfilled, (state, action) => {
      state.teamMembersDropLoading = false;
      state.teamMembersDrop = action.payload;
    });
    builder.addCase(getTeamDrops.rejected, (state, action) => {
      state.teamMembersDropLoading = false;
      state.teamMembersDropError = action.payload;
    });

    // Add team member
    builder.addCase(createTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTeamMember.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(createTeamMember.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateTeamMember.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateTeamMember.fulfilled, (state) => {
      state.updateLoading = false;
      state.success = true;
    });
    builder.addCase(updateTeamMember.rejected, (state, action) => {
      state.updateLoading = false;
      state.updateError = action.payload;
    });

    // Get single team member
    builder.addCase(getSingleTeamMember.pending, (state) => {
      state.loading = true;
      state.singleLoading = true;
    });
    builder.addCase(getSingleTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.teamMember = action.payload;
    });
    builder.addCase(getSingleTeamMember.rejected, (state, action) => {
      state.loading = false;
      state.singleLoading = false;
      state.error = action.payload;
      state.singleError = action.payload;
    });

    // delete team member

    builder.addCase(deleteTeamMember.pending, (state) => {
      state.delLoading = true;
    });
    builder.addCase(deleteTeamMember.fulfilled, (state) => {
      state.delSuccess = true;
      state.delLoading = false;
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.delLoading = false;
      state.delError = action.payload;
    });

    // Invite Team Member

    builder.addCase(inviteTeamMember.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(inviteTeamMember.fulfilled, (state) => {
      state.inviteLoading = false;
      state.inviteSuccess = true;
    });
    builder.addCase(inviteTeamMember.rejected, (state, action) => {
      state.inviteLoading = false;
      state.inviteError = action.payload;
    });
  },
});

export const updateTeamMember = createAsyncThunk(
  "updateTeamMember",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        return rejectWithValue("Unauthorized - Please provide id");
      }
      if (!apiData.id) {
        return rejectWithValue("Please provide id");
      }
      const { data } = await axios.patch(
        `${BASE_URL}/v1/users/update/${apiData.id}`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getAllTeamMembers = createAsyncThunk(
  "getAllTeamMembers",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/users/manage?limit=${apiData.query.itemsPerPage}&page=${apiData.query.currentPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      // console.log(error)
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const getTeamDrops = createAsyncThunk(
  "getTeamDrops",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const jobTitleDrop = await axios.get(`${BASE_URL}/v1/lookup/dropdown/4`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });
      const systemRoleDrop = await axios.get(
        `${BASE_URL}/v1/roles/manage?page=1&limit=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      // console.log(jobTitleDrop)
      return {
        jobTitle: jobTitleDrop.data.result,
        systemRole: systemRoleDrop.data.result.items,
      };
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getTeamMembersDropDown = createAsyncThunk(
  "getTeamMembersDropDown",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const { data } = await axios.get(`${BASE_URL}/v1/users/dropdown`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiData.token}`,
        },
      });

      // console.log(jobTitleDrop)
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const createTeamMember = createAsyncThunk(
  "createTeamMember",
  async (apiData, { rejectWithValue }) => {
    try {
      console.log(apiData);
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }

      const { data } = await axios.post(
        `${BASE_URL}/v1/users/create`,
        apiData.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const getSingleTeamMember = createAsyncThunk(
  "getSingleTeamMember",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        rejectWithValue("Please Provide id");
      }

      const { data } = await axios.get(
        `${BASE_URL}/v1/users/view/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );

      if (!data.result) {
        return rejectWithValue("User Not Found");
      }
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const deleteTeamMember = createAsyncThunk(
  "deleteTeamMember",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.id) {
        rejectWithValue("Please Provide id");
      }

      const { data } = await axios.delete(
        `${BASE_URL}/v1/users/remove/${apiData.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);
export const inviteTeamMember = createAsyncThunk(
  "inviteTeamMember",
  async (apiData, { rejectWithValue }) => {
    try {
      if (!apiData.token) {
        rejectWithValue("Unauthorized - Please provide token");
      }
      if (!apiData.email) {
        rejectWithValue("Please Provide email");
      }

      console.log(apiData);
      const { data } = await axios.post(
        `${BASE_URL}/v1/users/invite-team-member`,
        { email: apiData.email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiData.token}`,
          },
        }
      );
      if (data?.statusCode === 302) {
        return rejectWithValue(data?.message);
      }
      return data.result;
    } catch (error) {
      if (error?.response?.data?.message) {
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue("Some Error occured Please Try Again Later");
      }
    }
  }
);

export const { clearState } = teamMemberSlice.actions;
