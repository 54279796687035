import React from "react";
import { useSelector } from "react-redux";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ClassicTemplateConsultation from "../ClassicTemplate/Consultation/Consultation";
import StandardTemplateConsultation from "../StandardTemplate/Consultation/Consultation";
import ProfessioalTemplateConsultation from "../ProfessionalTemplate/Book2/Book2";

const WebsiteConsultation = () => {
  const { loading, data } = useSelector((state) => state.websiteStyles);

  if (loading) {
    return <PageLoader />;
  }
  if (data?.template === 1) {
    return <ClassicTemplateConsultation data={data}/>;
  }
  if (data?.template === 2) {
    return <StandardTemplateConsultation data={data}/>;
  }
  if (data?.template === 3) {
    return <ProfessioalTemplateConsultation data={data}/>;
  }
};

export default WebsiteConsultation;
