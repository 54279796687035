import React, { useEffect, useState } from "react";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import Flex from "../../../components/styled/Flex/Flex";
import { useForm } from "react-hook-form";
import "./style.css";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import Tag from "../../../components/styled/Tag/Tag";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  getSingleService,
  resetState,
  updateService,
} from "../../../features/ServiceProviderPortal/servicesSlice";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import Checkbox from "../../../components/Checkbox/Checkbox";
const EditService = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    success,
    error,
    updateServiceLoading,
    getSingleLoading,
    getSingleError,
    service,
  } = useSelector((state) => state.services);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [selectedMember, setSelectedMember] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);
    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const image = new Image();

        image.onload = () => {
          const { width, height } = image;
          const minWidth = 100;
          const minHeight = 100;
          const maxWidth = 5500;
          const maxHeight = 5500;
          if (
            width >= minWidth &&
            height >= minHeight &&
            width <= maxWidth &&
            height <= maxHeight
          ) {
            setSelectedImage(file);
            setShowImage(reader.result);
            setErrorMessage("");
          } else {
            setErrorMessage(
              "Image size is not within the allowed limits(100-4500 x 100-4500). Please choose an image with appropriate dimensions."
            );
            setSelectedImage(null);
            setShowImage(null);
          }
        };

        image.src = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage("Please select an image");
    }
  };

  const handleTeamDropChange = (member) => {
    if (selectedMembers.find((m) => m.id === member.id)) {
      return;
    } else {
      setSelectedMembers((prevData) => [...prevData, member]);
    }
  };

  const removeTag = (member) => {
    const updatedMembers = selectedMembers.filter((m) => m.id !== member.id);
    setSelectedMembers(updatedMembers);
  };

  useEffect(() => {
    if (success) {
      toast.success("Service updated Successfully", {
        position: "top-right",
      });
      dispatch(resetState());
      navigate("/portal/services");
    }
  }, [success, dispatch, navigate]);
  useEffect(() => {
    dispatch(getSingleService({ token, id }));
  }, [token, id, dispatch]);
  useEffect(() => {
    if (service) {
      reset({
        name: service?.name,
        cost: service?.cost,
        duration: service?.duration,
        notes: service?.notes,
        is_active: service?.is_active,
      });
    }
  }, [service, reset]);
  useEffect(() => {
    if (service?.users?.length > 0) {
      setSelectedMembers(service?.users);
    }
  }, [service?.users]);
  const handleAddService = (values) => {
    const apiData = new FormData();
    if (selectedMembers.length > 0) {
      const selectedTeam = selectedMembers.map((member) => member.id);
      apiData.append("users", JSON.stringify(selectedTeam));
    }
    if (values?.name) {
      apiData.append("name", values.name);
    }
    if (values?.cost) {
      apiData.append("cost", values.cost);
    }
    if (duration?.length > 0) {
      apiData.append("duration", duration);
    }
    if (selectedImage) {
      apiData.append("file_name", selectedImage);
    }
    apiData.append(
      "is_active",
      values.is_active === 1 || values.is_active === true ? 1 : 0
    );
    dispatch(updateService({ token, data: apiData, id }));
  };
  const [duration, setDuration] = useState(
    service?.duration ? service?.duration : ""
  );
  useEffect(() => {
    if (service?.duration) {
      setDuration(service?.duration);
    }
  }, [service?.duration]);
  const handleInputChange = (event) => {
    const cleanedValue = event.target.value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{2})(\d{2})/, "$1:$2");
    }
    setDuration(formattedValue);
  };
  return (
    <div className="outer">
      <Link to={"/portal/services"} className="back">
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Update Service</h1>
      {conditionalRender(
        getSingleLoading,
        <PageLoader />,
        conditionalRender(
          !getSingleError,
          <form onSubmit={handleSubmit(handleAddService)}>
            <div className="edit-service-inputs">
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="name">Service Name</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"text"}
                    placeholder={"Service Name"}
                    {...register("name", {
                      required: false,
                      maxLength: {
                        value: 20,
                        message: "Should not be greater then characters",
                      },
                    })}
                    id="name"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                {errors?.name && (
                  <p className="global-input-error">{errors?.name?.message}</p>
                )}
              </Flex>
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="cost">Service Cost</label>
                <div className="global-input-container-input global-input-container-input-with-image">
                  <input
                    type={"number"}
                    placeholder={"Cost"}
                    {...register("cost", {
                      required: false,
                      maxLength: {
                        value: 5,
                        message: "Should not be greater then five characters",
                      },
                      min: { value: 0, message: "Cost cannot be negative" },
                    })}
                    id="cost"
                  />
                  <img src="/icons/user.png" alt="icon" />
                </div>
                {errors?.cost && (
                  <p className="global-input-error">{errors?.cost?.message}</p>
                )}
              </Flex>
              <Flex
                className={`global-input-container add-team-member-input`}
                direction="column"
                gap={10}
              >
                <label htmlFor="cost">Duration*</label>
                <ReactInputMask
                  mask="99:99"
                  maskChar="_"
                  value={duration}
                  onChange={handleInputChange}
                  placeholder="HH:MM"
                  className="duration-mask"
                />
                <img
                  src="/icons/user.png"
                  alt="icon"
                  style={{
                    position: "absolute",
                    top: "28%",
                    left: "15px",
                    zIndex: "2",
                  }}
                />
              </Flex>

              <div className="add-team-member-input">
                <TeamMembersDrop
                  selectedMember={selectedMember}
                  setSelectedMember={setSelectedMember}
                  label="Select Team Members"
                  handleChange={handleTeamDropChange}
                />
                <div className="selected-team-members">
                  {selectedMembers && selectedMembers.length > 0
                    ? selectedMembers.map((member, index) => {
                        return (
                          <Tag onClick={() => removeTag(member)}>
                            {member?.full_name}
                          </Tag>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            <div className="lable-textarea-group lable-input-group mt-20 mb-20">
              <label htmlFor="notes">Description</label>
              <div className="edit-client-icon-textarea">
                {/* <input type="number" placeholder="sent" id="id" /> */}
                <img src="/icons/profile-2user.png" alt="" />
                <textarea
                  name=""
                  id="notes"
                  cols="135"
                  rows="3"
                  placeholder="Notes"
                  {...register("notes", { required: false })}
                ></textarea>
              </div>
            </div>
            <Flex align="center" justify="flex-end">
              <div className="business-hours-checkbox">
                <Checkbox
                  label="Display On Website"
                  register={register}
                  name="is_active"
                  checked={service?.is_active}
                />
              </div>
            </Flex>

            <div className="lable-input-group mt-30">
              <label htmlFor="address">Service-picture</label>
              <div className="upload-content-image-section " id="img-box">
                <label htmlFor="file">
                  <div>
                    {showImage || !service?.base_url?.includes("null") ? (
                      <div className="upload-img">
                        {/* <img src={selectedImage} alt="Preview" /> */}
                        {(showImage ||
                          !service?.base_url?.includes("null")) && (
                          <img
                            src={showImage ? showImage : service?.base_url}
                            alt="Selected"
                          />
                        )}
                      </div>
                    ) : (
                      // <img src={uploadimg} alt="Preview" />
                      <>
                        <div className="upload-photo">
                          <div className="up-img">
                            {/* <img src={uploadimg} alt="" /> */}
                            <span className="drag-drop">Select Image</span>
                          </div>
                        </div>
                        {/* {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )} */}
                      </>
                    )}
                  </div>
                </label>
                <input
                  className="upload-content-image"
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="file" className="upload-content-label">
                  Choose image
                </label>
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>

            <div className="intake-form-btns mt-30">
              {/* <button className="edit-client-save">Save</button> */}
              <Button variant="outline" label={"Cancel"} />
              <Button
                variant="primary"
                label={"Save"}
                type="submit"
                loading={updateServiceLoading}
                loaderColor="#fff"
              />
              {/* <button className="edit-client-cancel">Cancel</button> */}
            </div>
            <div className="mt-30"></div>
          </form>,
          <h1>{getSingleError}</h1>
        )
      )}

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(resetState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default EditService;
