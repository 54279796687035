import React from 'react'
import './style.css'
const PaynmentInvoices = () => {
  return (
    <div className='paynment-invoices-page-container'>
      <h1>Paynment invoices oage</h1>
    </div>
  )
}

export default PaynmentInvoices
