import React, { useEffect, useState } from "react";
import "./AddClientReferrals.css";
import Button from "../../../components/styled/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { useForm } from "react-hook-form";
import Flex from "../../../components/styled/Flex/Flex";
import TeamMembersDrop from "../../../components/Portal/TeamMembersDropDown/TeamMembersDropDown";
import ClientsDropDown from "../../../components/Portal/ClientsDropDown/ClientsDropDown";
import { emailPattern } from "../../../utills/Schems";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import {
  clearState,
  addClientReferral,
} from "../../../features/ServiceProviderPortal/referralSlice";
const AddClientReferrals = () => {
  const { token } = useSelector((state) => state.auth);
  const { loading, error, success } = useSelector((state) => state.referrals);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [dropError, setDropError] = useState(null);
  const handleAddClientRef = (values) => {
    if (!to || !from) {
      setDropError("Please Select");
    } else {
      values.from = from.id;
      values.to = to.id;
      values.status = 32
      dispatch(addClientReferral({ token, data: { ...values } }));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(clearState());
      navigate("/portal/client-referrals");
    }
  }, [success, navigate, dispatch]);
  return (
    <div className="outer">
      <Link
        to={"/portal/client-referrals"}
        className="back"
        onClick={() => dispatch(clearState())}
      >
        <ArrowLeft size={24} color="#5459FF" /> Back
      </Link>
      <h1 className="edit-client-heading">Add Referral</h1>
      <form onSubmit={handleSubmit(handleAddClientRef)}>
        <div className="add-client-refs-inputs">
          <div className="add-client-refs-input">
            <TeamMembersDrop
              label="From*"
              selectedMember={from}
              setSelectedMember={setFrom}
              handleChange={() => {}}
            />
            <p className="global-input-error">
              {dropError && !from && dropError}
            </p>
          </div>
          <div className="add-client-refs-input">
            <ClientsDropDown
              label="To*"
              setSelectedCLient={setTo}
              selectedClient={to}
              handleChange={() => {}}
            />
            <p className="global-input-error">
              {dropError && !to && dropError}
            </p>
          </div>
          <Flex
            className={`global-input-container add-client-refs-input`}
            direction="column"
            gap={10}
          >
            <label htmlFor="email">Email*</label>
            <div className="global-input-container-input global-input-container-input-with-image">
              <input
                type={"text"}
                placeholder={"Service Name"}
                {...register("email", {
                  required: "Please Enter Email",
                  pattern: emailPattern,
                })}
                id="email"
              />
              <img src="/icons/user.png" alt="icon" />
            </div>
            {errors?.email && (
              <p className="global-input-error">{errors?.email?.message}</p>
            )}
          </Flex>
        </div>

        <div className="edit-client-btns">
          {/* <button className="edit-client-save">Save</button> */}
          <Button
            variant="primary"
            label={"Add Referral"}
            type="submit"
            loaderColor="#fff"
            loading={loading}
          />
        </div>
      </form>

      {error && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={error} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => dispatch(clearState())}
          />
        </Popup>
      )}
    </div>
  );
};

export default AddClientReferrals;
