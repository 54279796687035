import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config";

const initialState = {
  loading: false,
  error: null,
  success: false,
  categories: null,
  category: null,
  addLoading: false,
  addError: null,
  addSuccess: false,
  delLoading: false,
  delError: null,
  delSuccess: false,
  updateLoading:false,
  updateError:null,
  updateSuccess:false,
  dropdown: null,
  getDropLoading:false,
  getDropError:null
};

export const serviceCategorySlice = createSlice({
    name: "serviceCategorySlice",
    initialState,
    reducers: {
      clearState: (state) => {
        state.error = null;
        state.success = false;
        state.addSuccess = false;
        state.addError = null;
        state.delSuccess = false;
        state.delError = null;
        state.updateSuccess = false;
        state.updateError = null
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllCategories.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      });
      builder.addCase(getAllCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      builder.addCase(addServiceCategory.pending, (state) => {
        state.addLoading = true;
      });
      builder.addCase(addServiceCategory.fulfilled, (state) => {
        state.addSuccess = true;
        state.addLoading = false;
      });
      builder.addCase(addServiceCategory.rejected, (state, action) => {
        state.addError = action.payload;
        state.addLoading = false;
      });
      builder.addCase(updateServiceCategory.pending, (state) => {
        state.updateLoading = true;
      });
      builder.addCase(updateServiceCategory.fulfilled, (state) => {
        state.updateSuccess = true;
        state.updateLoading = false;
      });
      builder.addCase(updateServiceCategory.rejected, (state, action) => {
        state.updateError = action.payload;
        state.updateLoading = false;
      });
  
      builder.addCase(deleteServiceCategory.pending, (state) => {
        state.delLoading = true;
      });
      builder.addCase(deleteServiceCategory.fulfilled, (state) => {
        state.delLoading = false;
        state.delSuccess = true;
      });
      builder.addCase(deleteServiceCategory.rejected, (state, action) => {
        state.delLoading = false;
        state.delError = action.payload;
      });
      builder.addCase(getServiceCategoriesDrop.pending, (state) => {
        state.getDropLoading = true;
      });
      builder.addCase(getServiceCategoriesDrop.fulfilled, (state,action) => {
        state.getDropLoading = false;
        state.dropdown = action.payload;
      });
      builder.addCase(getServiceCategoriesDrop.rejected, (state, action) => {
        state.getDropLoading = false;
        state.getDropError = action.payload;
      });
    },
  });
  
  export const getAllCategories = createAsyncThunk(
    "getAllCategories",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized");
        }
        const { data } = await axios.get(
          `${BASE_URL}/v1/service_categories/manage?page=${apiData.query.currentPage}&limit=${apiData.query.itemsPerPage}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        }
  
        return rejectWithValue("Some error occurred please try again");
      }
    }
  );
  
  
  export const addServiceCategory = createAsyncThunk(
    "addServiceCategory",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        const { data } = await axios.post(
          `${BASE_URL}/v1/service_categories/create`,
          apiData.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const deleteServiceCategory = createAsyncThunk(
    "deleteServiceCategory",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized");
        }
        if (!apiData.id) {
          return rejectWithValue("Please Provide id");
        }
        const { data } = await axios.delete(
          `${BASE_URL}/v1/service_categories/delete/${apiData.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const updateServiceCategory = createAsyncThunk(
    "updateServiceCategory",
    async (apiData, { rejectWithValue }) => {
      try {
        if (!apiData.token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        if (!apiData.id) {
          return rejectWithValue("Please provide id");
        }
        const { data } = await axios.patch(
          `${BASE_URL}/v1/service_categories/update/${apiData.id}`,
          apiData.data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiData.token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  export const getServiceCategoriesDrop = createAsyncThunk(
    "getServiceCategoriesDrop",
    async (apiData, { rejectWithValue,getState }) => {
      try {

        const token = getState()?.auth?.token
        if (!token) {
          return rejectWithValue("Unauthorized - Please provide token");
        }
        const { data } = await axios.get(
          `${BASE_URL}/v1/service_categories/dropdown`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data.result;
      } catch (error) {
        if (error?.response?.data?.message) {
          return rejectWithValue(error?.response?.data?.message);
        } else {
          return rejectWithValue("Some Error occurred Please Try Again Later");
        }
      }
    }
  );
  
  export const { clearState } = serviceCategorySlice.actions;
  