import React, { useEffect, useState } from "react";
import "./style.css";
import Flex from "../../../components/styled/Flex/Flex";
import Button from "../../../components/styled/Button/Button";
import {
  Table,
  TableBody,
  TableBodyData,
  TableHead,
  TableHeadData,
  TableRow,
  TableText,
} from "../../../components/styled/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  clearState,
  deleteTeamMember,
  getAllTeamMembers,
  inviteTeamMember,
} from "../../../features/ServiceProviderPortal/teamMemberSlice";
import { Link } from "react-router-dom";
import { conditionalRender } from "../../../utills/conditionalRender";
import PageLoader from "../../../components/styled/PageLoader/PageLoader";
import GlobalEmptyDataHandler from "../../../components/styled/GlobalEmptyDataHandler/GlobalEmptyDataHandler";
import Popup from "../../../components/styled/Popup/Popup";
import GlobalErrorPopup from "../../../components/styled/GlobalErrorPopup/GlobalErrorPopup";
import { handleUndefined } from "../../../utills/handleUndefined";
import { emailPattern, namePattern } from "../../../utills/Schems";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFormattedDate } from "../../../utills/prettifyDate";
import Pagination from "../../../components/styled/Pagination/Pagination";
import ResponsiveContainer from "../../../components/styled/ResponsiveContainer/ResponsiveContainer";
import {
  PhoneTable,
  PhoneTableBody,
  PhoneTableBodyData,
  PhoneTableBoldText,
  PhoneTableHead,
  PhoneTableHeadData,
  PhoneTableRow,
  PhoneTableSimpleText,
} from "../../../components/styled/ResponsiveTable/ResponsiveTable";
import { ChevronDown } from "react-feather";
import GlobalErrorHandler from "../../../components/styled/GlobalErrorHandler/GlobalErrorHandler";
import { checkIfPermittedToSingleTab } from "../../../utills/authorizeRole";
const TeamMembers = () => {
  const { token, userData } = useSelector((state) => state.auth);
  const { permissions } = useSelector((state) => state.permissions);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const {
    loading,
    error,
    teamMembers,
    delLoading,
    delError,
    delSuccess,
    inviteLoading,
    inviteSuccess,
    inviteError,
  } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(
        getAllTeamMembers({ token, query: { currentPage, itemsPerPage } })
      );
    }
    if (delSuccess) {
      toast.success("Team Member deleted Successfully", {
        position: "top-right",
      });
      setTeamMembersPopup(false);
      dispatch(clearState());
    }
  }, [token, dispatch, delSuccess, currentPage, itemsPerPage]);
  useEffect(() => {
    if (inviteSuccess) {
      toast.success("Team Member Invited Successfully", {
        position: "top-right",
      });
      setInvitePopup(false);
      dispatch(clearState());
    }
    if (inviteError) {
      toast.error(inviteError, {
        position: "top-right",
      });
      setInvitePopup(false);
      dispatch(clearState());
    }
  }, [inviteSuccess, dispatch, token, inviteError]);

  const [teamMembersPopUp, setTeamMembersPopup] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [delId, setDelId] = useState(null);
  const handleDeleteTeamMember = () => {
    const apiData = { token, id: delId };
    dispatch(deleteTeamMember(apiData));
    setDelId(null);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  const handleInviteTeamMember = (values) => {
    values.token = token;
    dispatch(inviteTeamMember(values));
  };

  const [activeResRow, setActiveResRow] = useState(1);
  return (
    <div
      className="team-members-main-container"
      style={{ position: "relative" }}
    >
      <div className="team-members-top-buttons mb-20">
        <h1 className="edit-client-heading">{"Team Members"}</h1>
        {checkIfPermittedToSingleTab(permissions, 30, userData?.roles) && (
          <Flex gap={15}>
            <Link to="/portal/job-title">
              <Button label="Manage Job Titles" />
            </Link>
            <Button
              label="Invite Team Member"
              handleClick={() => {
                setInvitePopup(true);
                reset(undefined);
              }}
            />
            <Link to={"/portal/add-team-member"}>
              <Button label="Add Team Member" />
            </Link>
          </Flex>
        )}
      </div>
      {conditionalRender(
        loading,
        <PageLoader />,
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadData>ID</TableHeadData>
                <TableHeadData>Name</TableHeadData>
                <TableHeadData>Email</TableHeadData>
                <TableHeadData>Job Title</TableHeadData>
                <TableHeadData>System Role</TableHeadData>
                <TableHeadData>Action</TableHeadData>
              </TableRow>
            </TableHead>
            {conditionalRender(
              !error,
              <TableBody>
                {conditionalRender(
                  teamMembers?.items?.length > 0,
                  teamMembers?.items.map((member, index) => {
                    return (
                      <TableRow>
                        <TableBodyData>
                          <TableText>{member?.id}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          {checkIfPermittedToSingleTab(
                            permissions,
                            33,
                            userData?.roles
                          ) ? (
                            <Link to={`/portal/team/details/${member.id}`}>
                              <TableText variant="link">
                                {member?.full_name}
                              </TableText>
                            </Link>
                          ) : (
                            <TableText>{member?.full_name}</TableText>
                          )}
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>{member?.email}</TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {handleUndefined(member?.job_title?.name)}
                          </TableText>
                        </TableBodyData>
                        <TableBodyData>
                          <TableText>
                            {member?.roles ? member?.roles[0]?.name : "-"}
                          </TableText>
                        </TableBodyData>

                        <TableBodyData>
                          <Flex justify="center" align="center">
                            <Flex
                              align="center"
                              gap={10}
                              className="team-member-actions"
                              justify="center"
                            >
                              {checkIfPermittedToSingleTab(
                                permissions,
                                32,
                                userData?.roles
                              ) && (
                                <img
                                  src="/icons/delete.png"
                                  alt="delete"
                                  onClick={() => {
                                    setTeamMembersPopup(true);
                                    setDelId(member.id);
                                  }}
                                />
                              )}

                              {checkIfPermittedToSingleTab(
                                permissions,
                                31,
                                userData?.roles
                              ) && (
                                <Link
                                  to={`/portal/edit-team-member/${member.id}`}
                                >
                                  <img src="/icons/edit.png" alt="edit" />
                                </Link>
                              )}

                              <Link
                                to={`/portal/appointment-team-calender?id=${
                                  member?.id
                                }`}
                              >
                                <Button
                                  label="View Calender"
                                  variant="outline"
                                />
                              </Link>
                            </Flex>
                          </Flex>
                        </TableBodyData>
                      </TableRow>
                    );
                  }),
                  <GlobalEmptyDataHandler label="No Team Members to show" />
                )}
              </TableBody>,
              <GlobalErrorHandler label={error} />
            )}
          </Table>
          <ResponsiveContainer>
            {conditionalRender(
              teamMembers?.items?.length > 0,
              teamMembers?.items?.map((member, index) => {
                return (
                  <div key={index} style={{ position: "relative" }}>
                    <PhoneTable>
                      <PhoneTableHead
                        onClick={() => setActiveResRow(index + 1)}
                      >
                        <PhoneTableRow>
                          <PhoneTableHeadData>
                            <PhoneTableBoldText>Id</PhoneTableBoldText>
                          </PhoneTableHeadData>
                          <PhoneTableHeadData>
                            <PhoneTableBoldText>
                              {member?.id}
                            </PhoneTableBoldText>
                          </PhoneTableHeadData>
                        </PhoneTableRow>
                      </PhoneTableHead>
                      <PhoneTableBody
                        className={`${
                          index + 1 === activeResRow
                            ? "responsive-table-body-show"
                            : "responsive-table-body-hide"
                        }`}
                      >
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Name</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <Link to={`/portal/team/details/${member.id}`}>
                              <TableText variant="link">
                                {member?.full_name}
                              </TableText>
                            </Link>
                          </PhoneTableBodyData>
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Email</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData isEmail={true}>
                            <PhoneTableSimpleText>
                              {member?.email}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Job Title</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <PhoneTableSimpleText>
                              {handleUndefined(member?.job_title?.name)}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Role</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <PhoneTableSimpleText>
                              {member?.roles ? member?.roles[0]?.name : "-"}
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                        </PhoneTableRow>
                        <PhoneTableRow>
                          <PhoneTableBodyData>
                            <PhoneTableBoldText>Actions</PhoneTableBoldText>
                          </PhoneTableBodyData>
                          <PhoneTableBodyData>
                            <PhoneTableSimpleText>
                              <Flex justify="center" align="flex-start">
                                <Flex
                                  align="center"
                                  gap={10}
                                  className="team-member-actions"
                                  justify="center"
                                >
                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    32,
                                    userData?.roles
                                  ) && (
                                    <img
                                      src="/icons/delete.png"
                                      alt="delete"
                                      onClick={() => {
                                        setTeamMembersPopup(true);
                                        setDelId(member.id);
                                      }}
                                    />
                                  )}

                                  {checkIfPermittedToSingleTab(
                                    permissions,
                                    31,
                                    userData?.roles
                                  ) && (
                                    <Link
                                      to={`/portal/edit-team-member/${member.id}`}
                                    >
                                      <img src="/icons/edit.png" alt="edit" />
                                    </Link>
                                  )}

                                  <Link
                                    to={`/portal/appointment-calender/?date=${getFormattedDate()}&id=${
                                      member?.id
                                    }`}
                                  >
                                    <Button
                                      label="View Calender"
                                      variant="outline"
                                    />
                                  </Link>
                                </Flex>
                              </Flex>
                            </PhoneTableSimpleText>
                          </PhoneTableBodyData>
                        </PhoneTableRow>
                      </PhoneTableBody>
                    </PhoneTable>
                    <div className="global-responsive-table-chevron">
                      <ChevronDown
                        className={`${
                          index + 1 === activeResRow
                            ? "table-chevron-rotate"
                            : "table-chevron-rerotate"
                        }`}
                      />
                    </div>
                  </div>
                );
              }),
              <GlobalEmptyDataHandler label={"No Appointments To Show"} />
            )}
          </ResponsiveContainer>
        </>
      )}

      {teamMembers?.meta?.totalItems > teamMembers?.meta?.itemsPerPage &&
        !error && (
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={teamMembers?.meta?.totalPages}
          />
        )}
      {teamMembersPopUp && (
        <Popup
          className="client-popup"
          popUp={teamMembersPopUp}
          setPopUp={setTeamMembersPopup}
        >
          <Flex align="center" justify="center" direction="column" gap={50}>
            <h1>
              ARE YOU SURE YOU WANT TO <br /> DELETE?
            </h1>
            <Flex align="center" justify="center" gap={15}>
              <Button
                variant="rounded-primary"
                label="Delete"
                handleClick={handleDeleteTeamMember}
                loading={delLoading}
                loaderColor="#fff"
              />
              <Button
                variant="rounded-outline"
                label="Cancel"
                handleClick={() => {
                  setTeamMembersPopup(false);
                  setDelId(null);
                }}
              />
            </Flex>
          </Flex>
        </Popup>
      )}
      {delError && (
        <Popup className="send-voucher-popup">
          <GlobalErrorPopup error={delError} />
          <Button
            label="ok"
            variant="outline-sm"
            handleClick={() => {
              setTeamMembersPopup(false);
              dispatch(clearState());
            }}
          />
        </Popup>
      )}
      {invitePopup && (
        <Popup
          className="invite-team-member-popup"
          popUp={invitePopup}
          setPopUp={setInvitePopup}
        >
          <form onSubmit={handleSubmit(handleInviteTeamMember)}>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">First Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"First Name"}
                  {...register("first_name", {
                    required: "Please Enter First Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors?.first_name && errors?.first_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container`}
              direction="column"
              gap={10}
            >
              <label htmlFor="first_name">Last Name*</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("last_name", {
                    required: "Please Enter Last Name",
                    maxLength: {
                      value: 10,
                      message: "Not should be more then ten characters",
                    },
                    minLength: {
                      value: 3,
                      message: "Not should be less then three characters",
                    },
                    pattern: namePattern,
                  })}
                  id="first_name"
                />
                <img src="/icons/user.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors?.last_name && errors?.last_name?.message}
              </p>
            </Flex>
            <Flex
              className={`global-input-container invite-team-member-input`}
              direction="column"
              gap={10}
            >
              <label htmlFor="email">Email *</label>
              <div className="global-input-container-input global-input-container-input-with-image">
                <input
                  type={"email"}
                  placeholder={"Enter Email"}
                  {...register("email", {
                    required: "Please Enter Email",
                    pattern: emailPattern,
                  })}
                  id="email"
                />
                <img src="/icons/mail.png" alt="icon" />
              </div>
              <p className="global-input-error mb-10">
                {errors?.email && errors?.email?.message}
              </p>
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              className="invite-team-member-buttons"
              gap={15}
            >
              <Button
                label="Cancel"
                variant="outline"
                handleClick={() => setInvitePopup(false)}
              />
              <Button
                label="Invite"
                type="submit"
                variant="primary"
                loading={inviteLoading}
                loaderColor="#fff"
              />
            </Flex>
          </form>
        </Popup>
      )}
    </div>
  );
};

export default TeamMembers;
